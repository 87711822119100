.tablewithfilter__filters .filters__expandedcontent__content > .filter__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.tablewithfilter__filters .filters__expandedcontent__content > .filter__row > .filter__column {
  flex: 1 1 50%;
  padding-right: 10px;
}
.tablewithfilter__filters .filters__expandedcontent__content > .filter__row > .filter__column + .filter__column {
  padding-left: 10px;
  padding-right: 0;
}

.tablewithfilter {
  margin-top: 30px;
  position: relative;
}
.tablewithfilter__scrollcontainer {
  overflow-x: auto;
  max-width: 100%;
}
.tablewithfilter .overflow::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
@media (max-width: 750px) {
  .tablewithfilter .overflow::after {
    right: 55px;
  }
}
.tablewithfilter table {
  width: 100%;
}
.tablewithfilter .widezebra tbody > tr {
  background-color: var(--color-white);
}
.tablewithfilter .widezebra tbody > tr.even {
  background-color: var(--color-grey-light);
}

.tablewithfilter__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--color-white);
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}