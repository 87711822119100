/* The graph */
.aiexperts-graph {
  margin: 100px auto;
  position: relative;
  z-index: 0;
}
.aiexperts-graph::before {
  content: "";
  display: block;
  position: absolute;
  top: -7px;
  right: -7px;
  bottom: -7px;
  left: -7px;
  border: 14px solid var(--color-grey-light);
  border-radius: 50%;
}
.aiexperts-graph__domain {
  font-size: 14px;
  line-height: 130%;
  font-weight: 500;
  text-shadow: 0 0 1px #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  z-index: 2;
}
.aiexperts-graph__domain__label {
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;
  min-width: 160px;
  padding: 10px 15px 25px;
  font-size: 20px;
  line-height: 130%;
  position: absolute;
  cursor: pointer;
}
.aiexperts-graph__domain__label b {
  font-weight: 700;
  display: block;
}
.aiexperts-graph__domain__label__infoicon {
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.aiexperts-graph__subdomain {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  transition: transform 200ms, opacity 300ms;
}
.aiexperts-graph__subdomain--show {
  opacity: 1;
}
.aiexperts-graph__subdomain--blur {
  cursor: default;
  pointer-events: none;
}
.aiexperts-graph__subdomain--blur .aiexperts-graph__subdomain__label {
  opacity: 0.3;
}
.aiexperts-graph__subdomain__circle {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  background-color: #fff;
  transition: transform 200ms, background-color 200ms;
  transform: translate(-50%, -50%) scale(0.0001, 0.0001);
  cursor: pointer;
}
.aiexperts-graph__subdomain--show .aiexperts-graph__subdomain__circle {
  transform: translate(-50%, -50%) scale(1, 1);
}
.aiexperts-graph__subdomain--show .aiexperts-graph__subdomain__circle:hover {
  transform: translate(-50%, -50%) scale(1.1, 1.1);
}
.aiexperts-graph__subdomain__label {
  z-index: 1;
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
}
.aiexperts-graph__subdomain__label > span {
  display: block;
  position: absolute;
  width: 160px;
  padding: 5px 0 0;
}
.aiexperts-graph__subdomain__label--right > span {
  left: 0;
}
.aiexperts-graph__subdomain__label--left > span {
  right: 0;
  text-align: right;
}
.aiexperts-graph__subdomain__label--top > span {
  bottom: 0;
}
.aiexperts-graph__subdomain__label--bottom > span {
  top: 0;
}
.aiexperts-graph__subdomain__label--middle-up > span {
  transform: translateY(-50%);
}
.aiexperts-graph__subdomain__label--middle-down > span {
  transform: translateY(50%);
}
.aiexperts-graph__items {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 0;
}
.aiexperts-graph__items__item {
  position: absolute;
  width: 0;
  height: 0;
  transition: opacity 200ms;
}
.aiexperts-graph__items__item:hover, .aiexperts-graph__items__item--focus {
  z-index: 1;
}
.aiexperts-graph__items__item--blur {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
.aiexperts-graph__items__item__line {
  position: absolute;
  pointer-events: none;
  width: 1px;
  transform-origin: top right;
  background-color: rgb(220, 221, 222);
  max-height: 0;
  transition: opacity 600ms, max-height 2000ms, color 200ms;
}
.aiexperts-graph__items__item__line::before, .aiexperts-graph__items__item__line::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 1px;
  height: 0;
  transition: height 400ms;
}
.aiexperts-graph__items__item__line::before {
  bottom: 0;
}
.aiexperts-graph__items__item__line::after {
  top: 0;
}
.aiexperts-graph__items__item:hover .aiexperts-graph__items__item__line::before, .aiexperts-graph__items__item--focus .aiexperts-graph__items__item__line::before, .infoballoonvisible ~ .aiexperts-graph__items__item__line::before {
  height: 100%;
}
.aiexperts-graph__items__item__circle {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) scale(-1, 1) rotate(270deg);
  cursor: pointer;
}
.aiexperts-graph__items__item__circle > svg {
  width: 100%;
  height: 100%;
  transition: transform 200ms, opacity 200ms;
  opacity: 0;
  transform: scale(0.00001, 0.00001);
}
.aiexperts-graph__items__item:hover .aiexperts-graph__items__item__circle > svg {
  transform: scale(1.2, 1.2);
}
.aiexperts-graph__items--show .aiexperts-graph__items__item__line {
  max-height: 1000px;
}
.aiexperts-graph__items--show .aiexperts-graph__items__item__circle > svg {
  opacity: 1;
}
.aiexperts-graph__items--showdots .aiexperts-graph__items__item__circle > svg {
  transform: scale(1, 1);
}
.aiexperts-graph__items--show {
  opacity: 1;
}
.aiexperts-graph.hover-subdomain .aiexperts-graph__items__item__line, .aiexperts-graph.active-subdomain .aiexperts-graph__items__item__line, .aiexperts-graph.hover-item .aiexperts-graph__items__item__line {
  background-color: rgba(220, 221, 222, 0.3);
}
.aiexperts-graph-legenda {
  margin: 200px auto 100px;
  width: 830px;
  display: flex;
  color: var(--color-grey-dark);
}
.aiexperts-graph-legenda h4 {
  margin: 0;
  text-transform: uppercase;
}
.aiexperts-graph-legenda__block {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  display: flex;
}
.aiexperts-graph-legenda__block--itemlabels .aiexperts-graph-legenda__block__graph {
  width: 50px;
  padding-top: 5px;
}
.aiexperts-graph-legenda__block--itemlabels .aiexperts-graph-legenda__block__graph > div {
  height: 18px;
}
.aiexperts-graph-legenda__block--itemlabels .aiexperts-graph-legenda__block__graph > div:nth-child(2n) {
  text-align: right;
}
.aiexperts-graph-legenda__block--itemlabels .aiexperts-graph-legenda__block__graph > div:nth-child(2) svg {
  transform: rotate(90deg);
}
.aiexperts-graph-legenda__block--itemlabels .aiexperts-graph-legenda__block__graph > div:nth-child(3) svg {
  transform: rotate(90deg) scale(-1, 1);
}
.aiexperts-graph-legenda__block--domainsizes {
  padding: 0 30px;
}
.aiexperts-graph-legenda__block--connections .aiexperts-graph-legenda__block__graph::before {
  content: "";
  height: 16px;
  display: block;
  width: 60px;
  border-bottom: 1px solid var(--color-grey-lines);
}
.aiexperts-graph-legenda__block__graph {
  flex: none;
  width: 75px;
  padding-right: 15px;
}
.aiexperts-graph-legenda__block__description {
  flex: 1 1 auto;
  font-size: 13px;
  line-height: 150%;
}
.aiexperts-graph-legenda__block__description > *:first-child {
  margin-top: 0;
}
.aiexperts-graph-legenda__block__description > *:last-child {
  margin-bottom: 0;
}