/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.widget-newsevents {
  margin-bottom: clamp(30px, 50px, 5vw);
  margin-top: 50px !important;
  position: relative;
}
.widget-newsevents.main__contents--12col {
  grid-column: var(--main-12col-layout) !important;
  z-index: var(--zindex-above-deeplinks) !important;
}
.widget-newsevents__deeplinktitle {
  display: block;
  visibility: hidden;
  height: 0;
  margin: 0 !important;
  overflow: hidden;
  position: relative;
}
.widget-newsevents__deeplinktitle .wh-anchor {
  position: absolute;
  top: 0;
}
.widget-newsevents.widget-newsevents--withborder {
  margin-left: -30px;
  margin-right: -30px;
  padding: 50px 30px;
  border: 1px solid var(--color-grey-lines);
}
@media (max-width: 480px) {
  .widget-newsevents.widget-newsevents--withborder {
    margin-left: -20px;
    margin-right: -20px;
    padding: 50px 20px;
    border-left: 0 none;
    border-right: 0 none;
  }
}
.widget-newsevents__header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.widget-newsevents.noresults .widget-newsevents__header {
  margin-bottom: 0;
}
.widget-newsevents__grouptag .primarytag {
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  padding: 5px 10px;
  line-height: 20px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  color: #fff;
}
.widget-newsevents__more {
  color: var(--color-grey-dark);
  font-size: 13px;
}
.widget-newsevents__morelink {
  font-size: 15px;
  white-space: nowrap;
  text-underline-position: under;
}
.widget-newsevents__grouptag + .widget-newsevents__more {
  flex: none;
  width: 100%;
}
.widget-newsevents .widget-newsevents__title {
  text-transform: none;
  margin: 0 20px 0 0;
}
@media (max-width: 480px) {
  .widget-newsevents__header .widget-newsevents__title {
    flex: none;
    width: 100%;
    margin-bottom: 10px;
  }
  .widget-newsevents__grouptag {
    margin-right: 15px;
  }
  .widget-newsevents__grouptag + .widget-newsevents__more {
    flex: 0;
    width: auto;
  }
}
.widget-newsevents__noresults {
  display: none;
}
.widget-newsevents.noresults .widget-newsevents__noresults {
  display: block;
}
.widget-newsevents__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-newsevents__item {
  display: flex;
}
.widget-newsevents__item + .widget-newsevents__item {
  margin-top: 30px;
}
@media (max-width: 480px) {
  .widget-newsevents__item + .widget-newsevents__item {
    margin-top: 20px;
  }
}
.widget-newsevents__item__link {
  text-decoration: none;
  display: flex;
  height: 100%;
}
.widget-newsevents__item__image {
  flex: none;
  width: clamp(95px, 256px, 26dvw);
  margin-right: 30px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  aspect-ratio: 16/9;
  align-self: flex-start;
}
@media (max-width: 480px) {
  .widget-newsevents__item__image {
    margin-right: 20px;
  }
}
.widget-newsevents__item__image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
  vertical-align: bottom;
  transition: transform 300ms;
}
.widget-newsevents__item__link:hover .widget-newsevents__item__image > img {
  transform: scale(1.1);
}
.widget-newsevents__item__eventdate {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: var(--color-black);
  padding: 12px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 20px;
  min-width: 70px;
  height: 70px;
}
.widget-newsevents__item__eventdate__start::before, .widget-newsevents__item__eventdate__end::before {
  content: attr(data-day);
  font-weight: 700;
  display: block;
}
.widget-newsevents__item__eventdate__start::after, .widget-newsevents__item__eventdate__end::after {
  content: attr(data-month);
  font-weight: 300;
}
.widget-newsevents__item__eventdate__start:has(+ .widget-newsevents__item__eventdate__end)::before, .widget-newsevents__item__eventdate__end::before {
  display: inline;
}
.widget-newsevents__item__eventdate__start:has(+ .widget-newsevents__item__eventdate__end)::after {
  content: attr(data-month) "-";
}
.widget-newsevents__item__content {
  display: flex;
  flex-flow: column;
  flex: 1;
}
.widget-newsevents__item__title, .widget-newsevents__item__description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}
.widget-newsevents__item__title {
  flex: none;
  -webkit-line-clamp: 3;
  color: var(--color-black);
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: clamp(5px, 10px, 1.4dvw);
}
.widget-newsevents__item__descriptionwrapper {
  flex: 1;
}
.widget-newsevents__item__description {
  font-size: 15px;
  line-height: 165%;
  -webkit-line-clamp: 4;
  color: var(--color-grey-dark);
}
.widget-newsevents__item__location, .widget-newsevents__item__category {
  font-size: 13px;
}
.widget-newsevents__item__category {
  margin-bottom: clamp(5px, 10px, 1.4dvw);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.widget-newsevents__item__category i {
  flex: none;
  font-size: 20px;
  display: inline-block;
  margin-right: 6px;
}
.widget-newsevents__item__tags {
  margin-left: -5px;
  margin-top: clamp(3px, 8px, 1.1dvw);
  flex: none;
}
.widget-newsevents__item__tags > span {
  margin-left: 5px;
  margin-top: 5px;
  display: inline-flex;
  height: 30px;
  text-transform: uppercase;
  align-items: center;
  padding: 5px 10px;
  color: var(--color-black);
  background-color: var(--color-grey-light);
  font-size: 11px;
  border-radius: 3px;
  line-height: 15px;
  font-weight: 700;
}
.widget-newsevents__item__tags > span.primarytag {
  color: #fff;
}
@media (min-width: 751px) {
  .widget-newsevents--newsstories .widget-newsevents__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
  }
  .widget-newsevents--newsstories .widget-newsevents__item {
    flex: none;
    margin-left: 30px;
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child {
    width: calc(100% - 30px);
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child .widget-newsevents__item__image {
    width: calc(50% - 30px);
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child .widget-newsevents__item__link:hover .widget-newsevents__item__image > img {
    transform: scale(1.05);
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child .widget-newsevents__item__link {
    align-items: center;
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child .widget-newsevents__item__title {
    font-size: 26px;
  }
  .widget-newsevents--newsstories .widget-newsevents__item + .widget-newsevents__item {
    width: calc(33.3333% - 30px);
  }
  .widget-newsevents--newsstories .widget-newsevents__item + .widget-newsevents__item > .widget-newsevents__item__link {
    flex-flow: column;
  }
  .widget-newsevents--newsstories .widget-newsevents__item + .widget-newsevents__item .widget-newsevents__item__image {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 750px) {
  .widget-newsevents--newsstories .widget-newsevents__item:first-child {
    margin-bottom: 45px;
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child > .widget-newsevents__item__link {
    flex-flow: column;
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child .widget-newsevents__item__link:hover .widget-newsevents__item__image > img {
    transform: scale(1.05);
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child .widget-newsevents__item__image {
    width: 100%;
    margin-bottom: 20px;
  }
  .widget-newsevents--newsstories .widget-newsevents__item:first-child .widget-newsevents__item__title {
    font-size: clamp(18px, 26px, 3.5dvw);
  }
}
@media (max-width: 480px) {
  .widget-newsevents--newsstories .widget-newsevents__item + .widget-newsevents__item .widget-newsevents__item__description {
    display: none;
  }
}
.widget-newsevents--grouptag .widget-newsevents__item .primarytag {
  display: none;
}
.widget-newsevents--event .widget-newsevents__item__image {
  width: clamp(150px, 256px, 41.666dvw);
}
@media (min-width: 751px) {
  .widget-newsevents[data-itemcount="1"]:not(.widget-newsevents--event) .widget-newsevents__item__image {
    width: clamp(210px, 350px, 28dvw);
  }
}
.widget-newsevents--event .widget-newsevents__item {
  margin-bottom: 30px !important;
}
@media (min-width: 751px) {
  .widget-newsevents--event:not([data-itemcount="1"]) .widget-newsevents__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -30px;
  }
}
@media (min-width: 751px) and (max-width: 480px) {
  .widget-newsevents--event:not([data-itemcount="1"]) .widget-newsevents__list {
    margin-left: -20px;
  }
}
@media (min-width: 751px) {
  .widget-newsevents--event:not([data-itemcount="1"]) .widget-newsevents__item__link {
    flex-flow: column;
    padding-left: 30px;
  }
}
@media (min-width: 751px) and (max-width: 480px) {
  .widget-newsevents--event:not([data-itemcount="1"]) .widget-newsevents__item__link {
    padding-left: 20px;
  }
}
@media (min-width: 751px) {
  .widget-newsevents--event:not([data-itemcount="1"]) .widget-newsevents__item {
    flex: none;
    width: calc(50% - 15px);
  }
  .widget-newsevents--event:not([data-itemcount="1"]) .widget-newsevents__item__image {
    width: 100%;
    margin-bottom: 20px;
  }
  .widget-newsevents--event:not([data-itemcount="1"]) .widget-newsevents__item {
    flex-flow: column;
  }
  .widget-newsevents--event:not([data-itemcount="1"]) .widget-newsevents__item {
    width: 25%;
  }
}
@media (max-width: 480px) {
  .widget-newsevents__item__image {
    aspect-ratio: 1/1;
  }
  .widget-newsevents__item__image img {
    height: 100%;
  }
  .widget-newsevents--event .widget-newsevents__item {
    margin-bottom: 20px !important;
  }
  .widget-newsevents--event .widget-newsevents__item__image {
    aspect-ratio: 4/3;
  }
}
@media (min-width: 751px) {
  .widget-newsevents[data-itemcount="2"] .widget-newsevents__list {
    display: flex;
    justify-content: space-between;
  }
  .widget-newsevents[data-itemcount="2"] .widget-newsevents__item__link {
    flex-flow: column;
  }
  .widget-newsevents[data-itemcount="2"] .widget-newsevents__item + .widget-newsevents__item, .widget-newsevents--event .widget-newsevents__item + .widget-newsevents__item {
    margin: 0;
  }
  .widget-newsevents[data-itemcount="2"] .widget-newsevents__item {
    flex: none;
    width: calc(50% - 15px);
  }
  .widget-newsevents[data-itemcount="2"] .widget-newsevents__item__image {
    width: 100%;
    margin-bottom: 20px;
  }
  .widget-newsevents[data-itemcount="2"] .widget-newsevents__item {
    flex-flow: column;
  }
  .widget-newsevents--event[data-itemcount="3"] .widget-newsevents__item {
    flex: none;
    width: 33.333%;
  }
  .widget-newsevents--event[data-itemcount="3"] .widget-newsevents__item__image {
    width: 100%;
    margin-bottom: 20px;
  }
}