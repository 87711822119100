/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.widget-studyinnumbers {
  margin-top: 60px;
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
}
@media (max-width: 960px) {
  .widget-studyinnumbers {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .widget-studyinnumbers {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
.widget-studyinnumbers__scrollcontainer {
  position: relative;
  /*
  overflow: hidden;
  overflow-y: auto;
  max-height: 550px;
  */
}
.widget-studyinnumbers__percentagecircle {
  width: 72px;
  height: 72px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widget-studyinnumbers__percentagecircle svg {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 0;
  left: 0;
  stroke-dasharray: 214;
  stroke-dashoffset: 214;
  transition: stroke-dashoffset 1s;
}
.widget-studyinnumbers__percentagecircle div::after {
  content: "%";
}
@media (max-width: 650px) {
  .widget-studyinnumbers__percentagecircle {
    height: auto;
    width: auto;
    justify-content: flex-end;
  }
  .widget-studyinnumbers__percentagecircle svg {
    display: none;
  }
}
.widget-studyinnumbers__header {
  font-weight: 700;
  font-size: 18px;
  border-bottom: 1px solid #dcddde;
  display: flex;
  padding-bottom: 15px;
}
.widget-studyinnumbers__header__label:first-child {
  flex: 1 1 auto;
}
.widget-studyinnumbers__header__label:last-child {
  flex: none;
  width: 160px;
}
.widget-studyinnumbers .ratingchart__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}
.widget-studyinnumbers .ratingchart__header__title {
  flex: none;
  font-weight: 700;
  font-size: 80px;
  padding-right: 30px;
}
.widget-studyinnumbers .ratingchart__header__stars {
  font-size: 32px;
  white-space: nowrap;
  color: #f0f1f2;
}
.widget-studyinnumbers .ratingchart__header__stars i {
  display: inline-block;
}
.widget-studyinnumbers .ratingchart__header__stars i + i {
  padding-left: 10px;
}
.widget-studyinnumbers .ratingchart__header__note {
  padding-top: 5px;
  font-size: 13px;
}
.widget-studyinnumbers .ratingchart__chart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.widget-studyinnumbers .ratingchart__chart__stars {
  flex: none;
  width: 130px;
  font-size: 32px;
  white-space: nowrap;
  font-size: 16px;
  text-align: right;
  color: #FFD100;
  padding-right: 20px;
}
.widget-studyinnumbers .ratingchart__chart__stars i + i {
  margin-left: 5px;
}
.widget-studyinnumbers .ratingchart__chart__bars {
  flex: 1 1 auto;
  margin-top: 5px;
}
.widget-studyinnumbers .ratingchart__chart__bars > div {
  position: relative;
  width: 100%;
  background-color: #f0f1f2;
}
.widget-studyinnumbers .ratingchart__chart__bars span {
  display: block;
  height: 100%;
  width: 0;
  transition: width 1s;
  vertical-align: bottom;
}
.widget-studyinnumbers .ratingchart__chart__bar-ut {
  height: 16px;
}
.widget-studyinnumbers .ratingchart__chart__bar-ut span {
  background-color: #FFD100;
}
.widget-studyinnumbers .ratingchart__chart__bar-country {
  margin-top: 5px;
  height: 2px;
}
.widget-studyinnumbers .ratingchart__chart__bar-country span {
  background-color: #afb1b3;
}
.widget-studyinnumbers .ratingchart__chart__value {
  flex: none;
  width: 95px;
  font-size: 13px;
  font-weight: 700;
  text-align: right;
  padding-right: 30px;
  white-space: nowrap;
}
.widget-studyinnumbers .ratingchart__chart__value::after {
  content: "%";
}
.widget-studyinnumbers .ratingchart__chart__countryvalue {
  color: #53575b;
  font-size: 13px;
  position: absolute;
  right: 0;
  width: 160px;
  padding-left: 30px;
}
.widget-studyinnumbers .ratingchart__chart__countryvalue::after {
  content: "%";
}
.widget-studyinnumbers__line {
  border-bottom: 1px solid #dcddde;
  background-color: #fff;
  position: relative;
  z-index: 0;
}
.widget-studyinnumbers__line .mobile {
  display: none;
  margin-top: -20px;
  padding-top: 0;
}
.widget-studyinnumbers__line__mobilestars {
  white-space: nowrap;
  color: #f0f1f2;
  margin-bottom: 5px;
}
.widget-studyinnumbers__line__mobilestars i + i {
  margin-left: 5px;
}
.widget-studyinnumbers__line__container, .widget-studyinnumbers__line__footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.widget-studyinnumbers__line__footer .widget-studyinnumbers__line__value, .widget-studyinnumbers__line__footer .widget-studyinnumbers__line__countryvalue {
  font-size: 13px !important;
  font-weight: 400;
  padding-top: 0 !important;
}
.mobile .widget-studyinnumbers__line__label {
  display: block;
  font-weight: 400;
  font-size: 13px;
}
.widget-studyinnumbers__line::before {
  content: "";
  display: block;
  width: 160px;
  position: absolute;
  border-left: 1px solid #dcddde;
  background-color: #f7f8f8;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.widget-studyinnumbers__line--details {
  margin-top: -1px;
}
.widget-studyinnumbers__line--details > .widget-studyinnumbers__line__container {
  padding-top: 0;
}
.widget-studyinnumbers__line--details .widget-studyinnumbers__line__countryvalue {
  padding-top: 0;
  font-size: 36px;
  align-self: flex-start;
  padding-top: 8px;
}
.widget-studyinnumbers__line > div {
  padding-top: 30px;
  padding-bottom: 30px;
}
.widget-studyinnumbers__line__icon {
  font-size: 48px;
  padding-right: 30px;
  flex: none;
  width: 78px;
}
.widget-studyinnumbers__line .widget-studyinnumbers__line__details {
  flex: 1 1 auto;
  padding-top: 0;
}
.widget-studyinnumbers__line__label {
  align-self: center;
  font-weight: 700;
  font-size: 26px;
  flex: 1 1 auto;
  padding-right: 30px;
}
.widget-studyinnumbers__line__label--mobile {
  display: none;
  padding-bottom: 0 !important;
  margin-bottom: -10px;
}
.widget-studyinnumbers__line__label small {
  display: block;
  font-size: 15px;
  font-weight: 400;
}
.widget-studyinnumbers__line__value {
  font-size: 26px;
  font-weight: 700;
  text-align: right;
  padding-right: 30px;
  white-space: nowrap;
}
.widget-studyinnumbers__line__value[data-percentage]::after {
  content: "%";
}
.widget-studyinnumbers__line__countryvalue {
  flex: none;
  width: 160px;
  padding-left: 30px;
  font-size: 26px;
  font-weight: 300;
  color: #53575b;
  white-space: nowrap;
}
.widget-studyinnumbers__line__countryvalue[data-percentage]::after {
  content: "%";
}
.widget-studyinnumbers__line__countryvalue small {
  display: block;
  font-size: 13px;
}
.widget-studyinnumbers__lastupdate {
  --layout: 8col;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 640px;
  /*
  Activate when 1440+ design becomes available for all pages
    @media (min-width: $responsive-xxmedium-devices)
    {
      width: $largescreen-width-8col;
    }
  */
  margin-top: 5px;
  font-size: 13px;
  color: #5A5E62;
}
@media (max-width: 750px) {
  .widget-studyinnumbers__lastupdate {
    margin-left: 0;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .widget-studyinnumbers__lastupdate {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 0) {
  .widget-studyinnumbers__lastupdate {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}
.widget-studyinnumbers__footer {
  --layout: 8col;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 640px;
  /*
  Activate when 1440+ design becomes available for all pages
    @media (min-width: $responsive-xxmedium-devices)
    {
      width: $largescreen-width-8col;
    }
  */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
}
@media (max-width: 750px) {
  .widget-studyinnumbers__footer {
    margin-left: 0;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .widget-studyinnumbers__footer {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 0) {
  .widget-studyinnumbers__footer {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}
.widget-studyinnumbers__footer__image {
  flex: none;
  width: 256px;
}
.widget-studyinnumbers__footer__image > img {
  width: 100%;
  height: auto;
}
.widget-studyinnumbers__footer__image > div {
  padding-top: 64%;
  background: #f0f1f2 url("studie-in-cijfers-grafiek.png") 0 0/cover;
}
@media (max-width: 1546px) {
  .widget-studyinnumbers__footer__image {
    width: 16.56vw;
  }
}
@media (max-width: 1280px) {
  .widget-studyinnumbers__footer__image {
    width: 212px;
  }
}
@media (max-width: 650px) {
  .widget-studyinnumbers__footer__image {
    width: 32.6vw;
  }
}
.widget-studyinnumbers__footer__content {
  flex: 1 1 auto;
  padding-left: 30px;
  padding-top: 15px;
}
.widget-studyinnumbers__footer .title {
  font-size: 26px;
  font-weight: 700;
}
.widget-studyinnumbers__footer .title + p {
  margin-top: 10px;
}
.widget-studyinnumbers__footer p {
  color: #53575b;
}
.widget-studyinnumbers__footer .links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.widget-studyinnumbers__footer .links > div {
  flex: 1 1 50%;
}
.widget-studyinnumbers__footer .links a {
  text-decoration: underline;
  font-weight: 300;
  color: #53575b;
  font-size: 18px;
  text-underline-position: under;
  transition: color 0.3s;
}
.widget-studyinnumbers__footer .links a:hover {
  color: #1e2328;
}
@media (max-width: 600px) {
  .widget-studyinnumbers__footer {
    display: block;
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px;
  }
  .widget-studyinnumbers__footer__image {
    width: 100%;
  }
  .widget-studyinnumbers__footer__content {
    padding-right: 30px;
  }
}
@media (max-width: 480px) {
  .widget-studyinnumbers__footer__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1569vw) {
  .widget-studyinnumbers__header__label:last-child,
  .widget-studyinnumbers .ratingchart__chart__countryvalue, .widget-studyinnumbers__line__countryvalue, .widget-studyinnumbers__line::before {
    width: 10.2vw;
  }
}
@media (max-width: 1280px) {
  .widget-studyinnumbers__header__label:last-child,
  .widget-studyinnumbers .ratingchart__chart__countryvalue, .widget-studyinnumbers__line__countryvalue, .widget-studyinnumbers__line::before {
    width: 130px;
  }
}
@media (max-width: 750px) {
  .widget-studyinnumbers {
    padding-left: 0;
    padding-right: 0;
  }
  .widget-studyinnumbers__header, .widget-studyinnumbers__line {
    padding-left: 30px;
  }
  .widget-studyinnumbers__header__label:last-child,
  .widget-studyinnumbers .ratingchart__chart__countryvalue, .widget-studyinnumbers__line__countryvalue, .widget-studyinnumbers__line::before {
    width: 180px;
  }
}
@media (max-width: 750px) {
  .widget-studyinnumbers {
    padding-left: 0;
    padding-right: 0;
  }
  .widget-studyinnumbers__header, .widget-studyinnumbers__line {
    padding-left: 30px;
  }
  .widget-studyinnumbers__header__label:last-child,
  .widget-studyinnumbers .ratingchart__chart__countryvalue, .widget-studyinnumbers__line__countryvalue, .widget-studyinnumbers__line::before {
    width: 180px;
  }
}
@media (max-width: 930px) {
  .widget-studyinnumbers__line__label {
    font-size: 2.8vw;
  }
}
@media (max-width: 650px) {
  .widget-studyinnumbers__line__label {
    font-size: 18px;
  }
  .widget-studyinnumbers__line--details .widget-studyinnumbers__line__container {
    display: none;
  }
  .widget-studyinnumbers__line .mobile {
    display: block;
  }
}
@media (max-width: 600px) {
  .widget-studyinnumbers__line__label {
    display: none;
  }
  .widget-studyinnumbers__line__label--mobile {
    display: block;
    padding-right: 210px;
  }
  .widget-studyinnumbers__line__value {
    flex: 1 1 auto;
  }
}
@media (max-width: 576px) {
  .widget-studyinnumbers__header__label:last-child,
  .widget-studyinnumbers .ratingchart__chart__countryvalue, .widget-studyinnumbers__line__countryvalue, .widget-studyinnumbers__line::before {
    width: 31.25vw;
  }
  .widget-studyinnumbers__line__label--mobile {
    padding-right: 36vw;
  }
}
@media (max-width: 480px) {
  .widget-studyinnumbers__header, .widget-studyinnumbers__line {
    padding-left: 15px;
  }
  .widget-studyinnumbers__line__countryvalue {
    padding-left: 15px;
  }
  .widget-studyinnumbers__line__value {
    padding-right: 15px;
  }
}
@media (max-width: 450px) {
  .widget-studyinnumbers__header {
    font-size: 4vw;
  }
}
@media (max-width: 320px) {
  .widget-studyinnumbers__header {
    font-size: 13px;
  }
  .widget-studyinnumbers__header__label:last-child,
  .widget-studyinnumbers .ratingchart__chart__countryvalue, .widget-studyinnumbers__line__countryvalue, .widget-studyinnumbers__line::before {
    width: 100px;
  }
}