.ut-querymatch {
  display: inline-block;
  font-size: inherit;
  position: relative;
  z-index: 0;
}
.ut-querymatch::before {
  bottom: calc((100% - 1em) / 2 - 2px);
  content: "";
  display: block;
  font-size: inherit;
  height: 9px;
  left: -3px;
  opacity: 0.15;
  position: absolute;
  right: -3px;
  z-index: -1;
}