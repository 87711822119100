/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.itcmajorproject__metadata {
  margin-top: 20px;
}

.itcmajorproject__metadata table {
  border-collapse: collapse;
}

.itcmajorproject__metadata th {
  text-align: left;
  padding-right: 15px;
}

.itcmajorproject__metadata th,
.itcmajorproject__metadata td {
  vertical-align: baseline;
}

h3.itcmajorproject__title {
  font: 700 26px/30px "Univers Next W02", Arial, Helvetica, sans-serif;
  margin-bottom: 8px;
}

@media (max-width: 550px) {
  .itcmajorproject__title {
    font: 700 18px/20px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
}
.filteredoverview__link {
  color: var(--color-grey-dark);
  font-weight: 300;
  font-size: 16px;
  text-decoration: underline;
}

.itcmajorproject__metabar {
  display: flex;
  margin-top: 8px;
  font: 300 16px "Univers Next W02", Arial, Helvetica, sans-serif;
}

.itcmajorproject__associates {
  margin-top: 20px;
}

.itcmajorproject__relatedprojects li {
  padding: 4px 0 !important;
  font: 300 16px "Univers Next W02", Arial, Helvetica, sans-serif;
}