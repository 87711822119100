/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@import "@mod-utwente_design/scss/config-colors.css";
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*
Used by
- News & Events (only one importing the scss file)
- Top level home
- Vacancies
- .embeddedobject__contentlisting / embeddedobject--downloads
- .embeddedobject__studylist
- .embeddedobject.embeddednewsevents
*/
.summary {
  opacity: 1;
  transition: opacity 150ms;
}

.summary__link {
  color: var(--color-black);
  display: block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.summary__description,
.summary__readmore,
.summary__backlink {
  display: block;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}

.summary__readmore,
.summary__backlink {
  padding-top: 25px;
  text-decoration: underline;
}

.summary__title {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.summary__description {
  padding-top: 3px;
}

.summary__date,
.summary__meta {
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.summary__meta > span {
  display: inline-block;
}

.summary__meta > span + span {
  margin-left: 20px;
}

.summary__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -12px;
  /*
  opacity: .3;
  transition: opacity $widgetlist-animation-duration;

  .summary__icon:hover &
  {
    opacity: 1;
  }
  */
}
html.site--itc .summary__icon {
  color: #00675a;
}

.summary__arrow {
  height: 16px;
  margin-top: -8px;
  opacity: 0.3;
  position: absolute;
  right: 12px;
  top: 50%;
  transition: opacity 150ms;
  width: 16px;
}
.summary__link:hover .summary__arrow {
  opacity: 1;
}

.summary__subtitle {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  display: block;
}

.summary__calendar {
  display: inline-block;
  white-space: nowrap;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  text-decoration: underline;
  color: var(--color-grey-dark);
}
.summary__calendar:hover {
  color: #222;
}
.summary__calendar > .icon {
  margin-right: 10px;
  color: var(--color-grey-normal);
}

.summary--foldable .summary__item > .summary__link .summary__foldablecontent {
  display: none;
}
.summary--foldable .summary__item > .summary__link .summary__foldablecontent:after {
  content: "";
  display: block;
  clear: both;
}
.summary--foldable .summary__item > .summary__link .summary__foldablecontent .summary__description {
  font-size: 15px;
  padding: 15px 0;
}
.summary--foldable .summary__item > .summary__link .summary__foldablecontent .summary__calendar {
  padding-top: 20px;
  float: left;
}
.summary--foldable .summary__item > .summary__link .summary__foldablecontent .summary__readmore {
  padding-top: 20px;
  float: right;
  display: inline-block;
}
@media (max-width: 520px) {
  .summary--foldable .summary__item > .summary__link .summary__foldablecontent .summary__calendar, .summary--foldable .summary__item > .summary__link .summary__foldablecontent .summary__readmore {
    float: none;
    display: block;
  }
}
.summary--foldable .summary__item > .summary__link.foldablecontent--active:before {
  width: 100%;
}
.summary--foldable .summary__item > .summary__link.foldablecontent--active .summary__foldablecontent {
  display: block;
}
.summary--foldable .summary__item > .summary__link.foldablecontent--active .summary__arrow .close {
  display: inline-block;
}
.summary--foldable .summary__item > .summary__link .summary__arrow .close, .summary--foldable .summary__item > .summary__link.foldablecontent--active .summary__arrow .open {
  display: none;
}

.summary__introblocks {
  display: block;
  list-style-type: none;
  padding: 0;
}
.summary__introblocks:after {
  clear: both;
  content: "";
  display: block;
}
@media (max-width: 960px) {
  .summary__introblocks {
    display: none;
  }
}

.summary__introblock {
  display: block;
  float: left;
  padding-right: 10px;
  width: 50%;
}
.summary__introblock + .summary__introblock {
  padding-left: 10px;
  padding-right: 0;
}
.summary__introblock .summary__image {
  height: auto;
  margin-bottom: 15px;
  width: 100%;
}
.summary__introblock .summary__description {
  max-height: 83px;
  overflow: hidden;
}
.summary__introblock .summary__description > span {
  display: block;
}
.summary__introblock .summary__date {
  padding-top: 15px;
}

.summary__items {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.summary__items--mobileonly {
  display: none;
}
@media (max-width: 960px) {
  .summary__items--mobileonly {
    display: block;
  }
}

.summary h2 + .summary__items {
  margin-top: 25px;
}

.summary--noimage .summary__item > .summary__link,
.summary--noimage.summary__item > .summary__link {
  padding-left: 0;
  min-height: 0;
}

.summary__item {
  border-top: 1px solid var(--color-grey-lines);
  display: block;
  position: relative;
  z-index: 0;
}
.summary__item:last-of-type {
  border-bottom: 1px solid var(--color-grey-lines);
}
.summary__item--mobileonly {
  display: none;
}
@media (max-width: 960px) {
  .summary__item--mobileonly {
    display: block;
  }
}
.summary__item .summary__image {
  height: 58px;
  left: 0;
  position: absolute;
  top: 20px;
  width: 58px;
}
.summary__item .summary__link {
  min-height: 98px;
  padding: 20px 35px 20px 80px;
}
.summary__item .summary__link:before {
  background-color: #f7f8f8;
  bottom: 0;
  content: "";
  display: block;
  left: -20px;
  position: absolute;
  top: 0;
  transition: width 150ms ease;
  width: 0;
  z-index: -1;
}
.summary__item .summary__link:hover:before {
  width: 100%;
}
@media (max-width: 480px) {
  .summary__item .summary__image {
    left: 10px;
  }
  .summary__item .summary__link {
    padding-left: 90px;
  }
  .summary__item .summary__link:before {
    left: 0;
  }
}

.embeddedobject__contentlisting > .summary__item .summary__title {
  font-weight: 700;
}

/*******************************************************************************
 * CONFIGURATION
 */
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.filterbar--newsevents__daterange.filterbar--newsevents__daterange {
  width: 100%;
}

.filterbar--newsevents__daterange__fields {
  display: flex;
}

.filterbar--newsevents__daterange input + input,
.filterbar--newsevents__daterange .wh-form__dateinputgroup + .wh-form__dateinputgroup {
  margin-left: 20px;
}

.filterbar--newsevents .filters__expandedcontent .filter__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 749px) {
  .filterbar--newsevents .filters__expandedcontent .filter__row__item {
    width: 100%;
  }
  .filterbar--newsevents .filters__expandedcontent .filter__row__item + .filter__row__item {
    margin-top: 30px;
  }
}
@media (min-width: 750px) {
  .filterbar--newsevents .filters__expandedcontent {
    /* we don't know how many items end up in the last row,
       so we cannot cancel those margins, instead remove the margin
       of the filter__row after the one we use as grid.
    */
  }
  .filterbar--newsevents .filters__expandedcontent .filter__row:first-child .filter__row__item {
    width: calc(50% - 10px);
    margin-bottom: 30px;
  }
  .filterbar--newsevents .filters__expandedcontent .filter__row:first-child + .filter__row {
    margin-top: 0 !important;
  }
}
.filterbar--newsevents .filters__expandedcontent .filter__row__item + .filter__row__item {
  margin-left: 0 !important;
}

.ctalink {
  color: var(--color-grey-dark);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  text-decoration: underline;
}
@media (max-width: 480px) {
  .ctalink {
    display: block;
  }
}

.summaryarchivetabs {
  position: relative;
}

@media (max-width: 1399px) {
  .filterlink--upcoming,
  .filterlink--archive {
    margin-bottom: 10px;
  }
}
@media (min-width: 1400px) {
  .filterlink--upcoming,
  .filterlink--archive {
    position: absolute;
    left: 100%;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.summary__introblocks {
  margin-top: 0;
}

html.page--newseventsoverview .main__contents {
  padding-top: 30px;
}

#summaryfilterfeedback {
  color: var(--color-grey-normal);
  font-size: 12px;
}
#summaryfilterfeedback .filters {
  display: inline-block;
  margin-left: 10px;
}

.summaryintro {
  margin-bottom: 20px;
}

.summary--newsevents .summary__introblock .neoitem__meta {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 15px;
}
.summary--newsevents .summary__introblock .summary__date {
  display: inline;
  padding-top: 0;
}
.summary--newsevents .summary__introblock .location {
  display: block;
  /*
        margin-left: 20px;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
  */
}

html:not(.page--events-cultural) .summary--newsevents .summary__items .summary__link,
.template__newsevents-embed .summary__link {
  padding-left: 0;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 80px;
}
html:not(.page--events-cultural) .summary--newsevents .summary__items .summary__image,
.template__newsevents-embed .summary__image {
  left: auto;
  right: 0;
}
html:not(.page--events-cultural) .summary--newsevents .summary__items .tags,
.template__newsevents-embed .tags {
  margin-top: 3px;
}
@media (max-width: 480px) {
  html:not(.page--events-cultural) .summary--newsevents .summary__items,
  .template__newsevents-embed {
    /*
    .tags
    {
      display: none !important;
    }
    */
  }
  html:not(.page--events-cultural) .summary--newsevents .summary__items .summary__link,
  .template__newsevents-embed .summary__link {
    padding-left: 0px;
  }
}

.template__newsevents-embed .summary__arrow {
  display: none;
}

/* tags in summary and articles */
.article__meta .tags,
.summary .tags {
  display: block;
}

.article__meta .tags > *,
.summary .tags > * {
  display: inline-block;
  font-size: 12px;
  color: var(--color-grey-normal);
  background-color: var(--color-white);
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid var(--color-grey-normal);
  border-radius: 2px;
  padding: 2px 7px 1px;
  line-height: 15px;
  text-decoration: none;
  margin-right: 4px;
  transition: color 0.3s;
}

.article__meta .tags > .tag[data-filter]:hover,
.summary .tags > .tag[data-filter]:hover {
  color: var(--color-grey-dark);
  border-color: var(--color-grey-dark);
}

.article__meta .tags > a:active,
.summary .tags > a:active {
  color: var(--color-black);
  border-color: var(--color-black);
}

.summaryfilters {
  margin-top: 50px;
}
.summaryfilters--summaryfilters:not(.summaryfilters--havearchive) {
  display: none;
}

/* specific for event/cultural:
   (showing prices)
*/
.event__infolist {
  margin-top: 40px;
}

.event__infolist h3 {
  position: relative;
  padding-left: 35px;
}

.event__infolist h3 > .icon {
  font-size: 24px;
  color: var(--color-grey-normal);
  position: absolute;
  top: -3px;
  left: -4px;
}

.event__infolist table {
  border-collapse: collapse;
  padding: 0;
  margin: 15px 0;
  width: 100%;
  border-top: 1px solid #e0e1e1;
}

.event__infolist table th,
.event__infolist table td {
  vertical-align: top;
  text-align: left;
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  padding: 15px 0;
  border-bottom: 1px solid #e0e1e1;
}

.event__infolist table td {
  font-weight: 700;
  padding-left: 20px;
  width: 160px;
  color: var(--color-grey-dark);
  white-space: nowrap;
}

.moreresults {
  margin-top: 15px;
  display: block;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  position: relative;
}
.moreresults.loading {
  cursor: wait;
  opacity: 0.5;
  position: relative;
}
.moreresults.loading:after {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.moreresults__link {
  text-decoration: none;
}

.moreresults__link-plus {
  position: relative;
  top: 1px;
}

.moreresults__link-text {
  text-decoration: underline;
}