.datacloud__infoballoon {
  display: none;
  align-items: flex-start;
  justify-content: flex-start;
  width: 500px;
  max-width: calc(100vw - 20px);
  border-radius: 10px;
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid #DCDDDE;
  box-shadow: 3px 9px 22px 1px rgba(0, 0, 0, 0.17);
  transform-origin: 50% 100%;
  transform: scale(0.001, 0.001);
  opacity: 0;
  transition: transform 200ms, opacity 200ms;
}
.datacloud__infoballoon:not([hidden]) {
  display: flex;
}
.datacloud__infoballoon__close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 16px;
  color: var(--color-grey-dark);
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
}
.datacloud__infoballoon__close ~ .datacloud__infoballoon__content {
  margin-right: 20px;
}
.datacloud__infoballoon--visible {
  opacity: 1;
  transform: scale(1, 1);
}
.datacloud__infoballoon__thumb {
  flex: none;
  width: 80px;
  color: #fff;
  padding: 20px 0 20px 20px;
}
.datacloud__infoballoon__thumb > * {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  vertical-align: bottom;
}
.datacloud__infoballoon__thumb .placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-grey-lines);
  font-size: 60px;
}
.datacloud__infoballoon__content {
  padding: 20px;
  flex: 1 1 auto;
}
.datacloud__infoballoon__content > *:first-child {
  margin-top: 0;
}
.datacloud__infoballoon__content > *:last-child {
  margin-bottom: 0;
}
.datacloud__infoballoon__title, .datacloud__infoballoon__description, .datacloud__infoballoon__remark, .datacloud__infoballoon__shortdescription {
  margin: 0 0 15px;
}
.datacloud__infoballoon__shortdescription {
  margin-top: -10px;
  font-weight: 300;
  font-size: 18px;
}
.datacloud__infoballoon__shortdescription small {
  font-size: 17px;
}
.datacloud__infoballoon__domains h4 {
  margin: 0;
}
.datacloud__infoballoon__domains ul {
  padding: 0 0 15px;
  list-style-type: none;
  margin: 0;
}
.datacloud__infoballoon__domains ul li {
  padding: 0 0 2px 27px;
  font-size: 13px;
  line-height: 20px;
}
.datacloud__infoballoon__domains .domaindot {
  flex: none;
  position: relative;
  top: 2px;
  display: inline-block;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 7px;
}
.datacloud__infoballoon__link {
  text-decoration: none;
  font-weight: 300;
  display: flex;
  align-items: baseline;
}
.datacloud__infoballoon__link span {
  text-decoration: underline;
  text-underline-position: under;
}
.datacloud__infoballoon__link i {
  font-size: 16px;
  padding-right: 10px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.datacloud__infoballoon__buttonbar {
  display: flex;
  flex-wrap: wrap;
}
.datacloud__infoballoon__button {
  color: #fff;
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 300;
  border-radius: 20px;
  padding: 10px 15px 10px 20px;
  line-height: 20px;
  font-size: 18px;
}
.datacloud__infoballoon__button--outline {
  border-width: 2px;
  border-style: solid;
  padding: 8px 15px 8px 20px;
}
.datacloud__infoballoon__button i {
  font-size: 16px;
  display: inline-block;
  position: relative;
  top: 1px;
  padding-left: 20px;
}
.datacloud__infoballoon__angle {
  position: absolute;
  width: 20px;
  top: 100%;
  left: 100px;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #fff;
}
.datacloud__infoballoon--bottom .datacloud__infoballoon__angle {
  border-top: 0 none;
  border-bottom: 15px solid #fff;
  top: auto;
  bottom: 100%;
}