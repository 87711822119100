.coursefinder__detaillist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.coursefinder__detaillist > ul > li {
  position: relative;
  margin-top: 45px;
  line-height: 20px;
}
.coursefinder__detaillist > ul > li[hidden] {
  display: none;
}
.coursefinder__detaillist__source {
  position: relative;
}
.coursefinder__detaillist__title, .coursefinder__detaillist__meta, .coursefinder__detaillist__description, .coursefinder__detaillist__footer {
  padding-right: 300px;
}
.coursefinder__detaillist__themeinfo {
  position: relative;
  padding: 0 10px;
}
@media (max-width: 990px) {
  .coursefinder__detaillist__themeinfo {
    padding: 0 5px;
  }
}
@media (max-width: 768px) {
  .coursefinder__detaillist__themeinfo {
    padding: 0 15px;
  }
}
.coursefinder__detaillist__themeinfo::after {
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
  border-bottom: 1px solid #DCDDDE;
}
.coursefinder__detaillist__title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.coursefinder__detaillist__meta {
  padding-top: 5px;
  font-size: 13px;
  color: #5A5E62;
}
.coursefinder__detaillist__description {
  margin-top: 15px;
  font-size: 15px;
  color: #5A5E62;
  overflow: hidden;
  max-height: 60px;
  min-height: 60px;
  transition: max-height 0.3s;
}
.coursefinder__detaillist__description .foldcontainer {
  position: relative;
}
.coursefinder__detaillist__description.onhidefullcontent {
  max-height: 60px !important;
}
.coursefinder__detaillist__description .fulldescription, .coursefinder__detaillist__description .shortdescription {
  display: inline-block;
}
.coursefinder__detaillist__description .foldcontainer.hasshortdescription .fulldescription {
  visibility: hidden;
}
.coursefinder__detaillist__description.showfullcontent .foldcontainer.hasshortdescription .fulldescription {
  visibility: visible;
}
.coursefinder__detaillist__description.showfullcontent .foldcontainer.hasshortdescription .shortdescription {
  visibility: hidden;
  z-index: -1;
}
.coursefinder__detaillist__description.showfullcontent + .coursefinder__detaillist__footer .more, .coursefinder__detaillist__description + .coursefinder__detaillist__footer .less {
  display: none;
}
.coursefinder__detaillist__description.showfullcontent + .coursefinder__detaillist__footer .less {
  display: inline-block;
}
.coursefinder__detaillist__description .shortdescription {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.coursefinder__detaillist__description .shortdescription::after {
  content: "...";
}
.coursefinder__detaillist__footer {
  padding-top: 50px;
  color: #5A5E62;
  font-size: 18px;
  display: inline-block;
  font-weight: 300; /* light condensed */
  padding-bottom: 40px;
}
.coursefinder__detaillist__footer .toggle-description {
  min-width: 75px;
}
.coursefinder__detaillist__footer > span {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 20px;
  transition: color 0.2s;
  display: inline-block;
}
.coursefinder__detaillist__footer > span span {
  text-decoration: underline;
}
.coursefinder__detaillist__footer > span:hover {
  color: #1e2328;
}
li.showcourses .coursefinder__detaillist__footer .toggle-courses {
  visibility: hidden;
}
.coursefinder__detaillist__image {
  position: absolute;
  right: 10px;
  top: 0;
  width: 220px;
}
@media (max-width: 990px) {
  .coursefinder__detaillist__image {
    right: 5px;
  }
}
@media (max-width: 768px) {
  .coursefinder__detaillist__image {
    right: 15px;
  }
}
.coursefinder__detaillist__image::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.coursefinder__detaillist__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 700px) {
  .coursefinder__detaillist__title, .coursefinder__detaillist__meta, .coursefinder__detaillist__description, .coursefinder__detaillist__footer {
    padding-right: 37.5vw;
  }
}
@media (max-width: 625px) {
  .coursefinder__detaillist__image {
    width: 35.2vw;
  }
}
@media (max-width: 550px) {
  .coursefinder__detaillist__image {
    display: none;
  }
  .coursefinder__detaillist__title, .coursefinder__detaillist__meta, .coursefinder__detaillist__description, .coursefinder__detaillist__footer {
    padding-right: 0;
  }
  .coursefinder__detaillist__footer {
    padding-top: 15px;
    padding-bottom: 30px;
  }
  .coursefinder__detaillist > ul > li {
    margin-top: 25px;
  }
}
.coursefinder__detaillist__courses {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s;
  position: relative;
  background-color: #F0F1F2;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -1px;
}
@media (max-width: 900px) {
  .coursefinder__detaillist__courses {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 768px) {
  .coursefinder__detaillist__courses {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 0;
    margin-right: 0;
  }
}
.coursefinder__detaillist__courses::before, .coursefinder__detaillist__courses::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #DCDDDE;
}
.coursefinder__detaillist__courses::before {
  top: 0;
}
.coursefinder__detaillist__courses::after {
  bottom: 0;
}
.coursefinder__detaillist__courses.onhidefullcontent {
  max-height: 0 !important;
}
.coursefinder__detaillist__courses .foldcontainer {
  position: relative;
}
.coursefinder__detaillist__courses .foldcontainer__inner {
  position: relative;
  padding: 40px 10px;
}
.coursefinder__detaillist__courses .close {
  position: absolute;
  right: 10px;
  top: -59px;
  color: #5A5E62;
  cursor: pointer;
  transition: color 0.2s;
  z-index: 1; /* on top */
  text-decoration: underline;
  font-size: 18px;
  font-weight: 300;
}
@media (max-width: 550px) {
  .coursefinder__detaillist__courses .close {
    top: -49px;
  }
}
.coursefinder__detaillist__courses .close:hover {
  color: #1e2328;
}
.coursefinder__detaillist__courses__header, .coursefinder__detaillist__courses__line {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: border-color 0.2s;
  position: relative;
}
.coursefinder__detaillist__courses__header > span, .coursefinder__detaillist__courses__line > span {
  display: inline-block;
  font-size: 13px;
  line-height: 17px;
}
.coursefinder__detaillist__courses__header {
  color: #5A5E62;
}
.coursefinder__detaillist__courses__line {
  min-height: 45px;
  align-items: center;
}
.coursefinder__detaillist__courses__line[hidden] {
  display: none;
}
.coursefinder__detaillist__courses__line:hover {
  border-color: rgba(0, 0, 0, 0.4);
}
.coursefinder__detaillist__courses__header > span {
  padding-bottom: 20px;
  padding-right: 20px;
  font-weight: bold;
}
.coursefinder__detaillist__courses__line > span {
  padding: 10px 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.coursefinder__detaillist__courses .courses, .coursefinder__detaillist__courses .title {
  flex: 1 1 100%;
  color: #1E2328;
}
.coursefinder__detaillist__courses .credits, .coursefinder__detaillist__courses .source {
  flex: 0 0 160px;
}
.coursefinder__detaillist__courses .targetaudience {
  flex: 0 0 220px;
}
.coursefinder__detaillist__courses .availability {
  flex: 0 0 80px;
  min-width: 80px;
  padding-right: 0;
  display: flex;
  align-items: center;
}
.coursefinder__detaillist__courses .availability .showcoursealert {
  margin-left: 10px;
  cursor: pointer;
  padding: 5px;
}
.coursefinder__detaillist__courses .availability .showcoursealert:hover {
  color: var(--color-black);
}
@media (max-width: 768px) {
  .coursefinder__detaillist__courses .availability {
    flex: 0 0 75px;
    min-width: 75px;
  }
}
.coursefinder__detaillist__courses .coursecount {
  display: none; /* only visible in compactview */
  flex: 0 0 80px;
  min-width: 80px;
}
@media (max-width: 700px) {
  .coursefinder__detaillist__courses .credits {
    flex: 1 0 90px;
  }
}
@media (max-width: 600px) {
  .coursefinder__detaillist__courses .credits, .coursefinder__detaillist__courses .source {
    display: none;
  }
}
@media (max-width: 500px) {
  .coursefinder__detaillist__courses .targetaudience {
    display: none;
  }
}
.coursefinder__detaillist__compactheader, .coursefinder__detaillist__compacttitle {
  display: none;
  flex-flow: row;
  justify-content: space-between;
  font-size: 15px;
  line-height: 20px;
  padding: 0 10px;
  position: relative;
}
.coursefinder__detaillist__compactheader::after, .coursefinder__detaillist__compacttitle::after {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  content: "";
  display: block;
  height: 0;
  border-bottom: 1px solid #DCDDDE;
}
.coursefinder__detaillist__compactheader > span, .coursefinder__detaillist__compacttitle > span {
  display: inline-block;
  padding-bottom: 16px;
  padding-top: 18px;
}
.coursefinder__detaillist__compactheader .courses, .coursefinder__detaillist__compactheader .title, .coursefinder__detaillist__compacttitle .courses, .coursefinder__detaillist__compacttitle .title {
  flex: 1 1 100%;
}
.coursefinder__detaillist__compactheader .credits, .coursefinder__detaillist__compacttitle .credits {
  flex: 0 0 160px;
}
.coursefinder__detaillist__compactheader .targetaudience, .coursefinder__detaillist__compacttitle .targetaudience {
  flex: 0 0 110px;
}
.coursefinder__detaillist__compactheader .coursecount, .coursefinder__detaillist__compacttitle .coursecount {
  flex: 0 0 80px;
}
@media (max-width: 700px) {
  .coursefinder__detaillist__compactheader .credits, .coursefinder__detaillist__compacttitle .credits {
    flex: 1 0 90px;
  }
}
@media (max-width: 600px) {
  .coursefinder__detaillist__compactheader .credits, .coursefinder__detaillist__compacttitle .credits {
    display: none;
  }
}
@media (max-width: 500px) {
  .coursefinder__detaillist__compactheader .targetaudience, .coursefinder__detaillist__compacttitle .targetaudience {
    display: none;
  }
}
.coursefinder__detaillist__compacttitle {
  cursor: pointer;
}
.coursefinder__detaillist__compacttitle .title {
  text-transform: uppercase;
  font-weight: bold;
}
.coursefinder__detaillist__compacttitle .coursecount {
  text-decoration: underline;
  color: #5A5E62;
  transition: color 0.2s;
  font-weight: 500;
  text-transform: lowercase;
}
.coursefinder__detaillist__compactheader {
  font-weight: 600;
  font-size: 13px;
  cursor: default;
  color: #5A5E62;
}
.coursefinder__detaillist__compactheader:hover .domain-coursecount {
  color: #1e2328;
}
.coursefinder__detaillist .showfullcontent {
  height: auto;
  max-height: none;
}
.coursefinder__detaillist .showfullcontentready {
  overflow: visible;
}

.coursefinder__page--list .coursefinder__detaillist > ul > li {
  margin-top: 0;
}
.coursefinder__page--list .coursefinder__detaillist__compactheader, .coursefinder__page--list .coursefinder__detaillist__compacttitle {
  display: flex;
}
.coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__themeinfo {
  display: none;
}
.coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__courses {
  background-color: transparent;
  margin-top: 0;
}
.coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__courses::before, .coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__courses::after {
  display: none;
}
.coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__courses .foldcontainer {
  padding-top: 0;
  padding-bottom: 0;
}
.coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__courses .foldcontainer::before {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  bottom: 0;
  left: -10px;
  background-color: #F8F8F8; /* #F0F1F2 */
}
.coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__courses .close {
  display: none;
}
.coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__courses__header {
  padding-top: 15px;
}
.coursefinder__page--list .coursefinder__detaillist .coursefinder__detaillist__courses__header > span {
  padding-bottom: 15px;
}