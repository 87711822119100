.ut-spinner {
  display: inline-block;
  visibility: hidden;
  overflow: hidden;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 14px;
  font-size: 20px;
  color: var(--color-grey-normal);
  opacity: 0;
  font-weight: normal;
  transition: opacity 0.4s;
}

.ut-spinner--showembeddedspinner .ut-spinner {
  visibility: visible;
  opacity: 1;
  animation: ut-spinner-anim 2s infinite linear;
}

@-webkit-keyframes ut-spinner-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes ut-spinner-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}