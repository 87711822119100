.timetablewidget.ttw-fullscreen {
  position: relative; /* will be absolute after layout */
  background: #f5faff;
  color: #333333;
  width: 300px;
  margin: 0 !important; /* kill the margins defined on .embeddedobject */
}

@media (min-width: 600px) {
  .timetablewidget-popup .dialog__content {
    min-width: 450px;
  }
  .timetable-popup-content .ttevent-title {
    font-size: 21px;
  }
}
@media (max-width: 750px) {
  .timetablewidget {
    padding: 0 !important;
  }
}
/*
@media only screen and (max-width: 944px)
{
  / * mobile/tablet menu will overlap our popup if we don't shift it a little lower * /
  html.fluid-design .wh-popup-chrome.timetablewidget-popup
  {
    margin-top: 145px !important;
  }
}

.wh-popup-chrome.timetablewidget-popup
{
  margin-top: 10px;
  padding: 0;

  border-radius: 0;
  background-color: var(--color-white);
  border-color: #444444;
  color: var(--color-black);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
*/
.timetablewidget-popup .wh-popup-closebutton,
.timetablewidget .ttw-exit-fullscreen {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-white);
  width: 41px;
  height: 41px;
  background: var(--color-black) no-repeat scroll center center;
  background-size: 18px 18px;
  color: var(--color-white);
  line-height: 41px;
  text-align: center;
  cursor: pointer;
}

.wh-timetable-prev,
.wh-timetable-next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  background-color: rgba(0, 0, 0, 0.25);
  color: var(--color-white);
  cursor: pointer;
  transition: opacity 0.35s;
  opacity: 0;
  /* make sure when we are at the edge the button is 'away' so
     the events at the right can be clicked */
  pointer-events: none;
}

.wh-timetable-prev {
  left: 0;
}

.wh-timetable-next {
  right: 0;
}

.wh-timetable-prev.enabled,
.wh-timetable-next.enabled {
  pointer-events: auto;
}

.wh-timetable-prev.enabled:hover,
.wh-timetable-next.enabled:hover {
  transition: opacity 0.15s;
  opacity: 1;
}

.wh-timetable-prev::before,
.wh-timetable-next::before {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  margin-top: -25px;
  height: 50px;
  line-height: 50px;
  font-size: 25px;
}

.wh-timetable-prev::before {
  content: "<";
}

.wh-timetable-next::before {
  content: ">";
}

.timetablewidget.ttw-fullscreen {
  width: auto;
}

.timetablewidget .ttw-exit-fullscreen {
  display: none;
}

.timetablewidget.ttw-fullscreen .ttw-exit-fullscreen {
  display: block;
  z-index: 9999; /* force to be on op. ws2016 has z-index: 3; on headers */
}

.timetablewidget .ttw-enter-fullscreen {
  display: inline-block;
}

.timetablewidget .ttw-enter-fullscreen > svg {
  color: inherit;
  width: 22px;
  height: 22px;
}

.timetablewidget .ttw-enter-fullscreen > svg,
.timetablewidget .ttw-enter-fullscreen > .label {
  vertical-align: middle;
}

.timetablewidget.ttw-fullscreen .ttw-buttonsbar {
  display: none;
  cursor: pointer;
}

.ttw-buttonsbar {
  margin-top: 12px;
  display: block;
  text-align: right;
}

.timetable-popup-content {
  padding: 26px;
  max-width: 640px;
}

.timetablewidget-popup .ttevent {
  display: flex;
}

.timetablewidget-popup .ttevent-image {
  margin-left: 25px;
  max-width: 40%;
}

.timetable-popup-content .ttevent-title {
  font-weight: bold;
}

.timetable-popup-content table.ttevent-meta {
  border-collapse: collapse;
  margin: 15px 0 20px 0;
}

.timetable-popup-content .ttevent-meta td,
.timetable-popup-content .ttevent-meta th {
  white-space: nowrap;
  padding: 0;
}

.timetable-popup-content .ttevent-meta th {
  text-align: left;
  font-weight: normal;
  padding-right: 15px;
}

.timetable-popup-content .ttevent-button {
  margin-top: 30px;
  display: inline-block;
  height: 48px;
  padding: 0 28px;
  font: normal 15px/48px "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
  text-transform: uppercase;
  text-decoration: none;
}

.main .timetablewidget {
  margin-left: 0;
  margin-right: 0;
  z-index: 1; /* draw over .main__deeplinks (the h2 quicklinks) */
}

.wh-timetable-header {
  font-family: "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
  font-size: 17px;
  text-transform: uppercase;
  background-color: var(--color-white);
}

.wh-timetable-row {
  margin-bottom: 1px;
}

.wh-timetable-time {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}

.wh-timetable-time.whtt-allowmultiline {
  white-space: normal;
}

.wh-timetable-time > * {
  vertical-align: middle;
}

/* Icon to indicate there is a label which cannot be shown (due it cannot fit) */
.wh-timetable-time .icon {
  display: none;
  font-size: 20px;
  width: 100%;
  text-align: center;
}

.wh-timetable-time.label_ellipsis .icon,
.wh-timetable-time.label_hide .icon {
  display: block;
}

.wh-timetable-time {
  text-overflow: clip;
}

.wh-timetable-time.label_ellipsis {
  padding-right: 28px;
  text-overflow: ellipsis;
}

.wh-timetable-time.label_ellipsis .icon {
  position: absolute;
  right: 5px;
}

.wh-timetable-time.label_hide {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.wh-timetable-time.label_hide .icon {
  display: inline-block;
}

.wh-timetable-time.label_hide > span {
  display: none;
}

.wh-timetable-label {
  font-family: "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
  font-size: 17px;
  text-transform: uppercase;
  width: 100px;
}

.wh-timetable-time.green {
  background-color: #23a64c;
  color: var(--color-white);
}

.wh-timetable-time.whtt-time-overlapping {
  border-left: 1px solid var(--color-white);
  /* border-left: 1px solid rgba(255, 255, 255, 0.25); */
}

/*
.wh-timetable-time.green + .wh-timetable-time.green.whtt-time-overlapping
{
}
.wh-timetable-time.blue + .wh-timetable-time.blue.whtt-time-overlapping
{
  border-left: 1px solid var(--color-white);
}
.wh-timetable-time.orange + .wh-timetable-time.orange.whtt-time-overlapping
{
  border-left: 1px solid var(--color-white);
}
*/
.timetablewidget-popup.green .ttevent-title,
.timetablewidget-popup.green .ttevent-meta td {
  color: #23a64c;
}

.timetablewidget-popup.green .ttevent-button {
  background-color: #23a64c;
  color: var(--color-white) !important;
}

.wh-timetable-time.blue {
  background-color: #5eb0d6;
  color: var(--color-white);
}

.timetablewidget-popup.blue .ttevent-title,
.timetablewidget-popup.blue .ttevent-meta td {
  color: #5eb0d6;
}

.timetablewidget-popup.blue .ttevent-button {
  background-color: #5eb0d6;
  color: var(--color-white) !important;
}

.wh-timetable-time.orange {
  background-color: #fc9e53;
  color: var(--color-white);
}

.timetablewidget-popup.orange .ttevent-title,
.timetablewidget-popup.orange .ttevent-meta td {
  color: #fc9e53;
}

.timetablewidget-popup.orange .ttevent-button {
  background-color: #fc9e53;
  color: var(--color-white) !important;
}

.ttw-headerbar,
.ttw-tabstrip {
  background-color: #f5fafe;
}

.ttw-headerbar {
  border-top: 1px solid #e9f1f4;
  padding: 30px 33px 30px 33px;
}

.ttw-heading {
  margin: 0 0 30px 0;
}

.ttw-controlbar {
  margin-bottom: 30px;
}

/* replaced pulldown component */
.ttw-tabselect + .ut-pulldown {
  width: auto;
}

.ttw-tabstrip {
  position: relative;
  z-index: 0;
  padding-left: 33px;
  border-bottom: 2px solid #dde1e4;
  height: 50px;
  white-space: nowrap;
  overflow: hidden; /* tabs sticking out may not stretch the page */
}

.ttw-tab {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0 20px;
  line-height: 48px;
  height: 50px;
  font-family: "LinotypeUniversW02-Cn", "Arial Narrow", Arial;
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: -2px;
  border-top: 2px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  cursor: pointer;
}

.ttw-tab.ttw-tab.ttw-tab:not(.selected) {
  border-color: transparent !important;
}

/* NOTE: 2x .selected to override the priority of:
    html.scheme2 .br-c100
    {
      border-color: #ec7a08 !important;
    }
*/
.ttw-tab.selected.selected.selected {
  background-color: var(--color-white);
  border-top-color: inherit;
  border-left-color: #dde1e4 !important;
  border-right-color: #dde1e4 !important;
}

.ttw-tab + .ttw-tab {
  margin-left: 5px;
}

.ttw-tabpanel {
  /* DON'T USE display: none;
     It will prevent the $wh.TimeTable from doing measurements, making it fail to initialize.
  */
  visibility: hidden;
  height: 0px;
  overflow: hidden;
  background-color: var(--color-white);
}

.ttw-tabpanel.selected {
  visibility: visible;
  height: auto;
  overflow: auto;
}

/* do not hide .ttw-tabselect, because depending on the device it might be
   replaced by a custom pulldown */
.timetablewidget .ttw-controlbar {
  display: none;
}

.timetablewidget.hide_tabstrip .ttw-controlbar {
  display: block;
  /*
    visibility: hidden;
    height: 0px;
    overflow: hidden;
  */
}

/*.ttw-tabstrip.ttw-hide*/
.timetablewidget.hide_tabstrip .ttw-tabstrip {
  height: 2px;
}

/*.ttw-tabstrip.ttw-hide .ttw-tab */
.timetablewidget.hide_tabstrip .ttw-tabstrip .ttw-tab {
  visibility: hidden;
}

/*
  popout, e.g. the small dark-grey "note" that opens when the users hovers
  over the small questionmark-icon in a programmelist widget
*/
.popout {
  display: none;
  max-width: 270px;
  padding: 15px 15px 15px 15px;
  background-color: #444444;
  color: var(--color-white);
  font: italic 15px/22px Arial;
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 0;
  transition: opacity 0.2s;
}

.popout.active {
  opacity: 1;
}

.popout a {
  color: var(--color-white);
}