.embeddedobject__cssstudies table td {
  min-width: 200px;
  vertical-align: top;
  text-align: left;
  padding-bottom: 0;
}
.embeddedobject__cssstudies th {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}
.embeddedobject__cssstudies .sort {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  padding: 2px;
  font-size: 16px;
  margin-left: 5px;
}
.embeddedobject__cssstudies .sort__up, .embeddedobject__cssstudies .sort__down {
  position: absolute;
  top: 4px;
  left: 2px;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.embeddedobject__cssstudies .sort__down {
  top: 11px;
}
.embeddedobject__cssstudies .sort--down .sort__up, .embeddedobject__cssstudies .sort--up .sort__down {
  opacity: 0.2;
}
.embeddedobject__cssstudies .sort--down:hover .sort__up, .embeddedobject__cssstudies .sort--up:hover .sort__down {
  opacity: 0.6;
}
.embeddedobject__cssstudies .sort--up .sort__up, .embeddedobject__cssstudies .sort--down .sort__down {
  opacity: 1;
}
.embeddedobject__cssstudies tr.lastdaterow {
  border-bottom: 1px solid #DCDDDE;
}
.embeddedobject__cssstudies tr.lastdaterow td {
  position: relative;
  padding-bottom: 30px; /* space for link */
}
.embeddedobject__cssstudies tr.odd td {
  background: #f0f1f2;
}
.embeddedobject__cssstudies .link {
  position: absolute;
  right: 15px;
  bottom: 5px;
  font-size: 18px;
  font-weight: 300;
  white-space: nowrap;
  text-align: right;
}
.embeddedobject__cssstudies .title {
  font-weight: bold;
}
.embeddedobject__cssstudies th, .embeddedobject__cssstudies td {
  width: 33.3333%;
}