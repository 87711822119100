@charset "UTF-8";
.wh-styledinput input[type=checkbox] + label:before {
  border: 1px solid #cbcbcb;
}

.wh-styledinput input[type=radio] + label:before {
  border: 1px solid #abacae;
}

.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  position: relative;
  display: inline-block;
  height: 11px;
  width: 22px;
  min-width: 22px;
}

.wh-styledinput input[type=radio] + label:before,
.wh-styledinput input[type=checkbox] + label:before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  margin-top: -11px;
  top: 50%;
  content: " "; /* non breaking space */
  display: inline-block;
  height: 22px;
  width: 22px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  text-rendering: auto;
  text-align: center;
  background-color: var(--color-white);
}

.wh-styledinput input[type=radio] + label ~ input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label ~ input[type=checkbox] + label {
  margin-left: 25px;
}

.wh-styledinput input[type=checkbox],
.wh-styledinput input[type=radio] {
  position: absolute;
  left: -999em;
}

.wh-styledinput input[type=radio]:focus + label:before,
.wh-styledinput input[type=checkbox]:focus + label:before {
  border-color: #222;
}

.wh-styledinput input[type=radio] + label:hover:before,
.wh-styledinput input[type=checkbox] + label:hover:before {
  color: #f0f0f0;
  background-color: var(--color-white);
  border-color: var(--color-grey-lines);
}

.wh-styledinput input[type=radio]:checked + label:before,
.wh-styledinput input[type=checkbox]:checked + label:before {
  color: var(--color-white);
  background-color: #222;
  border-color: #222;
}

.wh-styledinput input[type=radio][disabled] + label:before,
.wh-styledinput input[type=checkbox][disabled] + label:before {
  opacity: 0.3;
  cursor: default;
}

.wh-styledinput .wh-form__fieldgroup--error input[type=radio] + label:before,
.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox] + label:before {
  border-color: #cd202c;
}

.wh-styledinput input[type=radio] + label:before {
  border-radius: 100%;
}

.wh-styledinput input[type=radio] + label:after {
  border-radius: 100%;
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -4px 0 0 -4px;
}

.wh-styledinput input[type=radio] + label:hover:after {
  background-color: var(--color-grey-light);
}

.wh-styledinput input[type=radio]:checked + label:after {
  background-color: var(--color-white);
}

.wh-styledinput input[type=checkbox] + label:before {
  border-radius: 2px;
}

.wh-styledinput input[type=checkbox] + label:hover:before {
  content: "\e931"; /* UT-icon-UT_icon_50_check-16px */
}

.wh-styledinput input[type=checkbox]:checked + label:before {
  content: "\e931"; /* UT-icon-UT_icon_50_check-16px */
}

.wh-styledinput .wh-form__subfieldlabel + input {
  margin-left: 10px;
}