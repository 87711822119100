.widget-eligibilityprogrammes {
  position: relative;
}
.widget-eligibilityprogrammes__form {
  border-radius: 2px;
  margin-top: 10px;
  border: 1px solid var(--color-grey-lines);
  background-color: var(--color-grey-lighter);
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.widget-eligibilityprogrammes__form label {
  flex: none;
  width: 100%;
}
.widget-eligibilityprogrammes__form__selector {
  flex: 1 1 auto;
}
.widget-eligibilityprogrammes__form .wh-form__button {
  flex: none;
  margin-left: 32px;
}
.widget-eligibilityprogrammes__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}