/* Hide the form and show the desktop-only message if the browser width is smaller than 720 pixels */
@media (min-width: 720px) {
  .ut-neoform-desktoponly {
    display: none;
  }
}

@media (max-width: 719px) {
  .wh-form.ut-neoform {
    display: none;
  }
}

/* Limit the height of potentially long lists of select checkboxes */
.ut-neoform-checkboxgroup {
  flex-wrap: wrap;
}
.ut-neoform-checkboxgroup .wh-form__fields {
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  max-height: 230px;
  overflow: auto;
  padding: 7px;
}
.ut-neoform-checkboxgroup .wh-form__fields:hover {
  border-color: #808080;
}
.ut-neoform-checkboxgroup input[data-isemployeesrole=false] + label {
  margin-left: 20px;
}
.ut-neoform-checkboxgroup .wh-form__infotextwrapper {
  flex: none;
  min-width: 380px;
}
.ut-neoform-checkboxgroup.wh-form__fieldgroup--error .wh-form__infotext {
  border-top-width: 1px;
}

/* Set the size of the header photo upload */
.ut-neoform-headerphoto {
  /* legacy form input */
  /* If the show blurry bg option is set, show the complete photo (background-size contain instead of cover) */
}
.ut-neoform-headerphoto wh-imgedit {
  --wh-imgedit-height: 260px;
  --wh-imgedit-width: 620px;
}
.ut-neoform-headerphoto .wh-form__imgedit {
  height: 260px;
  max-height: 260px;
  max-width: 620px;
  width: 620px;
}
.ut-neoform-headerphoto--blurrybg .wh-form__imgeditimg {
  background-color: var(--color-black);
  background-size: contain;
}
.ut-neoform-headerphoto .wh-form__infotextwrapper {
  max-width: 620px;
}

/* Set the size of the rtd editors */
.ut-neoform-rtd .wh-form__rtd {
  height: 400px;
  width: 620px;
}

.ut-neoform__warning {
  color: #e35a04;
  margin: 5px 0 0 11px;
}

/* If the mp_authorized field has an error, show an error for the mp_persontypes field as well */
.wh-form__fieldgroup--error[data-wh-form-group-for=mp_authorized] + .wh-form__fieldgroup[data-wh-form-group-for=mp_persontypes] input[type=checkbox] + label:before {
  border-color: #cd202c;
}