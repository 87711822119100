/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
html.datacloudpage {
  scroll-behavior: smooth;
}
html.datacloudpage .contentpart__header {
  display: none;
}
html.datacloudpage h1 {
  margin-top: 15px;
  font-size: clamp(26px, 4.5vw, 50px);
  line-height: 100%;
}
html.datacloudpage h1 + p {
  margin-top: 10px;
}
html.datacloudpage *:focus {
  outline: dotted 1px rgba(0, 0, 0, 0.05);
}

.datacloud-startpage__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: clamp(30px, 5vw, 50px);
}
.datacloud-startpage__header h2 {
  line-height: 115%;
}
.datacloud-startpage__header h2 + p {
  margin-top: 15px;
}
.datacloud-startpage__header__image {
  flex: none;
  width: 50%;
}
.datacloud-startpage__header__image img {
  width: 100%;
  border-radius: 10px;
  height: auto;
  vertical-align: bottom;
}
.datacloud-startpage__header__intro {
  flex: 1 1 auto;
  margin-left: 30px;
  max-width: 475px;
}
.datacloud-startpage__header__intro .datacloud-ctabutton {
  margin-top: 20px;
}
@media (max-width: 750px) {
  .datacloud-startpage__header {
    display: block;
    margin-top: 15px;
  }
  .datacloud-startpage__header__image {
    width: 100%;
    margin-bottom: 20px;
  }
  .datacloud-startpage__header__intro {
    margin-left: 0;
    max-width: 100%;
  }
  .datacloud-startpage__header__intro h2 + p {
    margin-top: 0;
  }
}

.datacloud-startpage__content {
  grid-column: grid-left/grid-right;
  margin-top: clamp(30px, 5vw, 50px);
}
.datacloud-startpage__content__columns {
  display: flex;
}
.datacloud-startpage__content .datacloud-contactperson {
  flex: none;
  width: 25%;
}
.datacloud-startpage__content .datacloud-startpage__tabpanels {
  margin-left: 30px;
  flex: 1;
}
@media (max-width: 750px) {
  .datacloud-startpage__content__columns {
    flex-flow: column;
  }
  .datacloud-startpage__content .datacloud-startpage__tabpanels {
    margin-left: 0;
  }
  .datacloud-startpage__content #startpage__tabpanels__tab-contact {
    display: inline-block;
  }
}

.datacloud-contactperson {
  line-height: 150%;
  margin-bottom: 30px;
  max-width: 50%;
}
@media (max-width: 750px) {
  .datacloud-contactperson {
    max-width: 100%;
    width: 100% !important;
    order: 2;
  }
  .datacloud-contactperson#startpage__tabpanels__panel-contact:not(.active) {
    display: none;
  }
}
.datacloud-contactperson h3 {
  margin-bottom: 10px;
  margin-top: 0;
}
.datacloud-contactperson__photo {
  border-radius: 50%;
  width: 100px;
  height: auto;
  vertical-align: bottom;
  margin-bottom: 10px;
}
.datacloud-contactperson__name {
  font-weight: var(--weight-bold-condensed);
}
.datacloud-contactperson__description {
  font-size: 12px;
}
.datacloud-contactperson__jobtitle {
  font-size: 12px;
  color: var(--color-grey-normal);
}
.datacloud-contactperson__phone, .datacloud-contactperson__email {
  border-top: 1px solid var(--color-grey-lines);
  border-bottom: 1px solid var(--color-grey-lines);
  margin-top: 15px;
}
.datacloud-contactperson__phone a, .datacloud-contactperson__email a {
  display: flex;
  text-decoration: none;
  color: var(--color-grey-dark);
  font-weight: var(--weight-normal-condensed);
  line-height: 20px;
  padding: 12px 0;
  position: relative;
  z-index: 0;
  padding-right: 15px;
}
.datacloud-contactperson__phone a::before, .datacloud-contactperson__email a::before {
  content: "";
  display: block;
  position: absolute;
  background-color: var(--color-grey-lighter);
  left: 0;
  top: 0;
  width: 0;
  transition: width 300ms;
  bottom: 0;
  z-index: -1;
}
.datacloud-contactperson__phone a:hover::before, .datacloud-contactperson__email a:hover::before {
  width: calc(100% - 22px);
}
.datacloud-contactperson__phone a span, .datacloud-contactperson__email a span {
  flex: 1;
}
.datacloud-contactperson__phone a i, .datacloud-contactperson__email a i {
  flex: none;
  font-size: 16px;
  color: var(--color-grey-normal);
}
.datacloud-contactperson__phone + .datacloud-contactperson__email {
  border-top: 0 none;
  margin-top: 0;
}

.datacloud-ctabutton {
  display: inline-flex;
  align-items: center;
  border: 2px solid transparent;
  text-decoration: none;
  background-color: var(--theme-color-cta);
  color: #fff;
  transition: all 300ms;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 20px;
  font-size: 18px;
  line-height: 26px;
  white-space: nowrap;
  padding: 5px 20px;
  position: relative;
  z-index: 0;
}
.datacloud-ctabutton::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms;
}
.datacloud-ctabutton:hover {
  color: #fff;
}
.datacloud-ctabutton:hover::before {
  background-color: rgba(0, 0, 0, 0.3);
}
.datacloud-ctabutton i {
  margin-left: -5px;
  flex: none;
  padding-right: 10px;
  font-size: 16px;
}

.datacloud-startpage__tabpanels__tabs {
  display: flex;
  border-bottom: 1px solid var(--color-grey-lines);
  margin-bottom: 20px;
}
.datacloud-startpage__tabpanels__tabs button + button {
  margin-left: clamp(5px, 1.5vw, 15px);
}
.datacloud-startpage__tabpanels .datacloud-startpage__tabpanels__tab {
  padding: 5px clamp(10px, 1.5vw, 15px) 15px;
  border-bottom: 2px solid transparent;
  vertical-align: bottom;
}
.datacloud-startpage__tabpanels .datacloud-startpage__tabpanels__tab.active {
  border-bottom: 2px solid var(--theme-color-cta);
  text-shadow: 0 0 1px #000;
}
.datacloud-startpage__tabpanels button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
}
.datacloud-startpage__tabpanels__panel {
  margin-bottom: 30px;
}
.datacloud-startpage__tabpanels__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.datacloud-startpage__tabpanels__item {
  margin-bottom: 15px;
}
.datacloud-startpage__tabpanels__item__link {
  display: flex;
  text-decoration: none;
}
.datacloud-startpage__tabpanels__item__image {
  flex: none;
  width: 36%;
}
.datacloud-startpage__tabpanels__item__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.datacloud-startpage__tabpanels__item__text {
  margin-left: 30px;
  flex: 1;
}
.datacloud-startpage__tabpanels__item__title {
  font-size: clamp(16px, 1.8vw, 18px);
  margin-bottom: 5px;
  line-height: 110%;
  font-weight: var(--weight-bold-condensed);
}
.datacloud-startpage__tabpanels__item__description {
  line-height: 165%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

body .datacloud-filters__dialog {
  width: 100%;
  padding: 0;
  border: 0 none;
  background-color: transparent;
  box-shadow: none !important;
}
body .datacloud-filters__dialog.ut-dialog__holder {
  height: 100vh !important;
  max-height: 100vh !important;
  max-width: 100% !important;
  background-color: var(--color-grey-lighter);
}
body .datacloud-filters__dialog.ut-dialog__holder > .ut-dialog {
  padding: 0;
}
body .datacloud-filters__dialog.ut-dialog__holder .ps__rail-y, body .datacloud-filters__dialog.ut-dialog__holder .ps__rail-y::before {
  background-color: transparent !important;
}
body .datacloud-filters__dialog.ut-dialog__holder .ut-dialog__closeicon {
  background-color: transparent;
}
body .datacloud-filters__dialog .datacloud__resultscounter {
  position: absolute;
  cursor: pointer;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 315px;
}
body .datacloud-filters__dialog .ut-dialog__header {
  background-color: var(--color-black);
  color: #fff;
  padding: 20px 50px 20px 30px;
}
@media (max-width: 480px) {
  body .datacloud-filters__dialog .ut-dialog__header {
    padding: 20px 50px 20px 20px;
  }
}
body .datacloud-filters__dialog .datacloud__filters__header__mobiletitle {
  display: block;
}
body .datacloud-filters__dialog .datacloud__filters__checkboxes {
  border-radius: 0;
  background-color: transparent;
}

.cloudfilterbtn--hide .cloudfilterbtn,
.cloudfilterbtn__remove,
.cloudfilterbtn--reset .cloudfilterbtn__add {
  display: none;
}

.datacloud__infoballoon--hideremark .datacloud__infoballoon__remark,
.datacloud__infoballoon--hidebuttons .datacloud__infoballoon__buttonbar {
  display: none;
}

.cloudfilterbtn--reset .cloudfilterbtn__remove {
  display: inline-block;
}

.datacloud__noresults {
  margin-top: 30px;
  background-color: #fff;
  text-align: center;
  text-transform: uppercase;
  display: none;
  font-size: 26px;
  font-weight: 700;
  line-height: 135%;
  padding: 15px 20px;
  font-size: clamp(20px, 2vw, 26px);
  justify-content: center;
  align-items: baseline;
}
.datacloud__noresults::before {
  width: 22px;
  height: 22px;
  border: 1px solid var(--color-grey-lines);
  border-radius: 50%;
  content: "";
  display: inline-block;
  margin-right: 15px;
  position: relative;
  top: 2px;
  flex: none;
}
.datacloud__graph-holder .datacloud__noresults {
  position: absolute;
  display: flex;
  opacity: 0;
  transform: scale(0.0001, 0.0001);
  max-width: 100%;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center center;
  z-index: 10;
  border: 1px solid #DCDDDE;
  background-color: #FFFFFF;
  box-shadow: 3px 9px 22px 1px rgba(0, 0, 0, 0.17);
  transition: transform 200ms, opacity 200ms;
}
.datacloud--noresults .datacloud__noresults {
  display: flex;
}
.datacloud--noresults .datacloud__graph-holder .datacloud__noresults {
  opacity: 1;
  transform: translateX(-50%) scale(1, 1);
}
.datacloud-header {
  margin-top: var(--header-margin);
  background-color: var(--color-grey-light);
  border-bottom: 1px solid var(--color-grey-lines);
  position: relative;
  z-index: 1;
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
@media (max-width: 960px) {
  .datacloud-header {
    padding-top: 0;
  }
}
@media (max-width: 480px) {
  .datacloud-header {
    grid-template-columns: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left] repeat(2, var(--main-grid-column)) [text-left] repeat(8, var(--main-grid-column)) [text-right] repeat(2, var(--main-grid-column)) [grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
  }
}
.datacloud-header__intro--nocloud {
  display: none;
}
.datacloud-header__wide {
  grid-column: grid-left/grid-right;
}
.datacloud-header__left {
  grid-column: grid-left/span 3;
}
.datacloud-header__right {
  grid-column: span 9/grid-right;
}
.datacloud-header h2:first-child {
  margin-top: 0;
}
.datacloud__resultscounter {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-grey-default);
  padding: 25px 0 5px;
}
@media (min-width: 961px) {
  .datacloud__resultscounter {
    position: sticky;
    bottom: 10px;
  }
}
.datacloud__resultscounter .datacloud__totalcount {
  display: inline-block;
  border: 2px solid transparent;
  background-color: var(--theme-color-cta);
  color: #fff;
  transition: all 300ms;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 20px;
  font-size: 18px;
  line-height: 26px;
  white-space: nowrap;
  padding: 5px 20px;
}
.datacloud__mobilereset {
  text-align: right;
}
.datacloud__filters--active ~ .datacloud__mobilereset > .datacloud__filters__reset {
  visibility: visible;
}
.datacloud__tabs {
  padding-top: 25px;
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  font-weight: 700;
}
.datacloud__tabs__tab {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  padding: 13px 15px 9px;
  background-color: transparent;
  transition: background-color 200ms;
  min-width: 160px;
  border: 1px solid var(--color-grey-lines);
  border-bottom: 0 none;
  white-space: nowrap;
  line-height: 25px;
  font: inherit;
  text-transform: uppercase;
}
.datacloud__tabs__tab i {
  display: inline-block;
  padding-right: 8px;
}
.datacloud__tabs__tab + .datacloud__tabs__tab {
  margin-left: -1px;
}
.datacloud__tabs__tab--active, .datacloud__tabs__tab:hover, .datacloud__tabs__tab:focus {
  background-color: #fff;
}
.datacloud__stickyholder, .datacloud__deeplinksholder {
  position: absolute;
  right: 170px;
  top: 0;
  width: 0;
  height: 100%;
  pointer-events: none;
}
.datacloud__deeplinks {
  pointer-events: auto;
  width: 170px;
  position: sticky;
  top: 120px;
  right: 0;
  z-index: 2;
  padding: 10px 0;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-start;
  line-height: 115%;
  background-color: rgba(255, 255, 255, 0.95);
}
@media (max-width: 1279px) {
  .datacloud__deeplinks {
    top: 30px;
  }
}
.datacloud__deeplinks li {
  padding: 0 10px;
  display: block;
  margin-bottom: 5px;
  display: flex;
  align-items: baseline;
}
.datacloud__deeplinks a {
  font-size: 13px;
  line-height: 18px;
  color: var(--color-grey-normal);
  text-decoration: none;
  transition: color 200ms;
  justify-content: flex-end;
  text-align: right;
  padding: 3px 0;
  display: flex;
  align-items: baseline;
  text-underline-position: under;
}
.datacloud__deeplinks a[hidden] {
  display: none;
}
.datacloud__deeplinks a > span {
  flex: 1 1 auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.datacloud__deeplinks a > i {
  flex: none;
  position: relative;
  top: 1px;
  font-family: "UT-iconfont" !important;
  display: inline-block;
  padding-left: 5px;
}
.datacloud__deeplinks a > i::after {
  content: "\e941";
}
.datacloud__deeplinks a:hover, .datacloud__deeplinks a.isvisible {
  color: var(--color-black);
}
.datacloud__deeplinks a.isbefore > i::after {
  content: "\e940";
}
.datacloud__deeplinks a.isvisible > i::after {
  content: "\e944";
}
@media (max-width: 749px) {
  .datacloud__deeplinksholder {
    flex: none;
    order: -1;
    position: relative;
    top: 0;
    left: 0;
    margin-left: 20px;
    width: calc(100% - 60px);
  }
  .datacloud__deeplinks {
    position: relative;
    top: 0;
    width: 100%;
    right: auto;
    padding: 0;
    display: block;
    background-color: transparent;
  }
  .datacloud__deeplinks li {
    display: inline-block;
    padding: 0 0 0 10px;
  }
  .datacloud__deeplinks a {
    text-decoration: underline;
  }
  .datacloud__deeplinks a i {
    display: none;
  }
}
@media (max-width: 480px) {
  .datacloud__deeplinksholder {
    margin-left: 10px;
    width: calc(100% - 40px);
  }
}
.datacloud__stickyholder {
  left: 20px;
  right: auto;
  display: none;
  transition: opacity 300ms;
  opacity: 0;
}
.datacloud__stickyholder.show {
  display: block;
}
.datacloud__stickyholder.aftershow {
  opacity: 1;
}
.datacloud__adjustfiltersbtn {
  pointer-events: auto;
  background-color: var(--color-black);
  color: #fff;
  height: 40px;
  display: flex;
  width: 160px;
  white-space: nowrap;
  align-items: center;
  position: sticky;
  top: calc(100% - 60px);
  left: 0;
  border-radius: 20px;
  z-index: 2;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  font-weight: 500;
}
.datacloud__adjustfiltersbtn i {
  display: inline-block;
  flex: none;
}
.datacloud__adjustfiltersbtn:hover {
  color: #fff;
}
.datacloud-content {
  position: relative;
  margin: 0 auto;
  min-height: 1400px;
  padding-bottom: 100px;
  scroll-margin-top: 180px;
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-full-layout);
}
@media (max-width: 1279px) {
  .datacloud-content {
    scroll-margin-top: 60px;
  }
}
.datacloud__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
}
.datacloud__results {
  margin-top: 20px;
  line-height: 135%;
  position: relative;
  display: none;
  flex-flow: column;
  min-height: 100%;
}
.datacloud__results__contentcontainer {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
.datacloud__results__sortorder {
  grid-column: text-left/text-right;
  margin: 20px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-grey-normal);
}
.datacloud__results__sortorder__separator {
  display: inline-block;
  padding: 0 7px;
}
.datacloud__results__sortorder__button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  text-underline-position: under;
  transition: color 300ms;
}
.datacloud__results__sortorder__button--active, .datacloud__results__sortorder__button:hover {
  color: var(--color-black);
  text-decoration: underline;
}
.datacloud__results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.datacloud__results__domain {
  padding-top: 50px;
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
.datacloud__results__domain > ul {
  margin-top: 30px;
}
.datacloud__results__domain__header, .datacloud__results__domain__content {
  grid-column: text-left/text-right;
}
.datacloud__results__domain__header {
  display: flex;
  align-items: baseline;
  padding-right: 20px;
}
.datacloud__results__domain__header__dot {
  flex: none;
  position: relative;
  top: 1px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
.datacloud__results__domain[hidden] {
  display: none;
}
.datacloud__results__domain__title {
  scroll-margin-top: 150px;
  flex: 1 1 auto;
  margin: 0;
}
@media (max-width: 1279px) {
  .datacloud__results__domain__title {
    scroll-margin-top: 10px;
  }
}
.datacloud__results__domain__title small {
  font-weight: 300;
  font-size: inherit;
}
.datacloud__results__domain__title .wh-anchor {
  position: absolute;
}
.datacloud__results__domain__counter {
  padding-top: 20px;
  font-size: 23px;
}
.datacloud__results__domain__infoicon {
  color: var(--color-grey-dark);
  flex: none;
  cursor: pointer;
  font-size: 16px;
}
.datacloud__results__list {
  border-top: 1px solid var(--color-grey-lines);
}
.datacloud__results__item {
  border-bottom: 1px solid var(--color-grey-lines);
  position: relative;
}
.datacloud__results__item.wh-details--open::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  background-color: var(--color-grey-lighter);
  z-index: -1;
}
.datacloud__results__item[hidden] {
  display: none;
}
.datacloud__results__item h4 {
  margin: 0;
  display: flex;
  align-items: baseline;
}
.datacloud__results__item.wh-details .datacloud__results__item__summary__photo > img {
  border-radius: 50%;
  border: 1px solid #E0E0E1;
}
.datacloud__results__item__summary {
  display: flex;
  align-items: flex-start;
  padding: 15px 40px 15px 20px;
}
.datacloud__results__item__summary::before, .datacloud__results__item__summary::after {
  right: 20px;
}
.datacloud__results__item__summary__photo {
  flex: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
}
.datacloud__results__item__summary__photo > img {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}
.datacloud__results__item__summary__photo .placeholder {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--color-grey-lines);
  font-size: 60px;
}
.datacloud__results__item__summary__text {
  flex: 1 1 auto;
  margin-left: 15px;
}
.datacloud__results__item__summary__text > h3 {
  margin-top: 0;
}
.datacloud__results__item__shortdescription {
  font-weight: 300;
  font-size: 18px;
  padding: 3px 0 2px;
}
.datacloud__results__item__shortdescription small {
  font-size: 17px;
}
.datacloud__results__item__details {
  padding: 0 20px;
}
.datacloud__results__item__details__subdomains {
  padding-bottom: 15px;
}
.datacloud__results__item__details__extra {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--color-grey-lines);
}
.datacloud__results__item__details__extra a {
  flex: 1 1 50%;
  max-width: calc(50% - 15px);
  margin-top: 5px;
  display: flex;
  padding: 15px 0 10px;
  line-height: 165%;
  text-decoration: none;
  align-items: baseline;
  margin-right: 15px;
  border-top: 1px solid var(--color-grey-lines);
  text-underline-position: under;
}
.datacloud__results__item__details__extra a:nth-child(2n) {
  margin-left: 15px;
  margin-right: 0;
}
.datacloud__results__item__details__extra a span {
  display: block;
  flex: 1 1 auto;
  font-size: 18px;
  font-weight: 300;
}
.datacloud__results__item__details__extra a .icon {
  flex: none;
  font-size: 16px;
  opacity: 0.5;
  transition: opacity 200ms;
}
.datacloud__results__item__details__extra a:hover > span {
  text-decoration: underline;
}
.datacloud__results__item__details__extra a:hover > .icon {
  opacity: 1;
}
@media (max-width: 600px) {
  .datacloud__results__item__details__extra {
    display: block;
    border-bottom: 0 none;
    margin-bottom: 15px;
  }
  .datacloud__results__item__details__extra a {
    max-width: 100%;
    margin-top: 0;
    margin-right: 0;
    border-top: 0 none;
    padding: 2px 0;
  }
  .datacloud__results__item__details__extra a span {
    font-size: 15px;
  }
  .datacloud__results__item__details__extra a:nth-child(2n) {
    margin-left: 0;
  }
  .datacloud__results__item__details__extra a .icon {
    order: -1;
    padding-right: 10px;
  }
}
.datacloud__results__item__details__organizations {
  margin-top: -10px;
  font-weight: 300;
  font-size: 18px;
  line-height: 135%;
  margin-bottom: 20px;
  color: var(--color-grey-dark);
}
.datacloud__results__item__domains span {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.datacloud__results__item__domains span.inactive {
  background-color: #ddd;
}
.datacloud__results__item__details__subdomains h4 {
  font-weight: 700;
  text-transform: uppercase;
}
.datacloud__results__item__details__subdomains ul {
  padding-bottom: 10px;
  font-size: 13px;
}
.datacloud__results__item__details__subdomains ul li {
  padding: 0 0 0;
}
.datacloud__results__item__details__subdomains .domaindot {
  flex: none;
  position: relative;
  top: 2px;
  display: inline-block;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.datacloud__filters {
  position: relative;
  line-height: 135%;
  margin-top: 30px;
  scroll-margin-top: 150px;
}
@media (max-width: 1279px) {
  .datacloud__filters {
    scroll-margin-top: 10px;
  }
}
.datacloud__filters__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
}
.datacloud__filters__header__title, .datacloud__filters__header__mobiletitle {
  font-weight: 300;
  font-size: 26px;
  line-height: 105%;
  text-transform: uppercase;
  flex: 1 1 auto;
}
.datacloud__filters__header__title b, .datacloud__filters__header__mobiletitle b {
  font-weight: 700;
}
.datacloud__filters__header__title {
  margin-right: 25px;
  font-size: clamp(20px, 2vw, 26px);
}
.datacloud__filters__header__center {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.datacloud__filters__header__mobiletitle {
  display: none;
  color: inherit;
  margin: 0;
  font-size: clamp(18px, 2.6vw, 26px);
}
.datacloud__filters__header__searchbox {
  flex: none;
  height: 44px;
  width: 100%;
  max-width: 324px;
  background-color: #fff;
  border: 1px solid var(--color-grey-lines);
  display: flex;
  transition: max-width 200ms;
}
.datacloud__filters__header__searchbox input, .datacloud__filters__header__searchbox button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 100%;
  border: 0 none;
  border-radius: 0;
  background-color: transparent;
  font-size: 16px;
  display: block;
  color: inherit;
  min-width: 0;
}
.datacloud__filters__header__searchbox input {
  flex: 1 1 auto;
  padding-left: 10px;
}
.datacloud__filters__header__searchbox input:-webkit-autofill,
.datacloud__filters__header__searchbox input:-webkit-autofill:hover,
.datacloud__filters__header__searchbox input:-webkit-autofill:focus {
  border-color: none;
  -webkit-text-fill-color: #979797;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
.datacloud__filters__header__searchbox button {
  padding: 0 10px;
  flex: none;
  cursor: pointer;
}
@media (max-width: 555px) {
  .datacloud__filters__header .datacloud__filters__filterstoggle {
    width: 100%;
    margin-bottom: 15px;
  }
  .datacloud__filters__header .datacloud__filters__header__searchbox {
    flex: none;
    max-width: 100%;
  }
}
.datacloud__filters__filterstoggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  line-height: 22px;
  font-weight: 500;
  margin-right: 20px;
  height: 44px;
  font-size: 16px;
  flex: none;
  min-width: 150px;
  align-items: baseline;
  border-radius: 2px;
  display: block;
  position: relative;
  transform-origin: center;
  transition: transform 500ms;
}
.datacloud__filters__filterstoggle--animate {
  transform: scale(1.2);
}
.datacloud__filters__filterstoggle__show, .datacloud__filters__filterstoggle__hide {
  height: 100%;
  padding: 11px 15px 9px;
  align-items: center;
  justify-content: space-between;
}
.datacloud__filters__filterstoggle__show {
  color: #fff;
  background-color: var(--color-black);
  display: flex;
}
.datacloud__filters__filterstoggle__hide {
  border: 1px solid var(--color-grey-lines);
  color: var(--color-dark-grey);
  background-color: #fff;
  display: none;
}
@media (min-width: 1001px) {
  .datacloud__filters__filterstoggle--active .datacloud__filters__filterstoggle__show {
    display: none;
  }
  .datacloud__filters__filterstoggle--active .datacloud__filters__filterstoggle__hide {
    display: flex;
  }
}
.datacloud__filters__filterstoggle i {
  display: inline-block;
  flex: none;
  padding-left: 30px;
}
.datacloud__filters__instructions {
  cursor: pointer;
  color: var(--color-grey-dark);
  transition: color 200ms;
  flex: none;
  margin-right: 25px;
  text-decoration: none;
}
.datacloud__filters__instructions-mobile {
  display: none;
}
.datacloud__filters__instructions i {
  font-size: 18px;
  position: relative;
  top: 2px;
  display: inline-block;
  padding-right: 10px;
}
.datacloud__filters__instructions span {
  text-decoration: underline;
  text-underline-position: under;
}
.datacloud__filters__instructions:hover {
  color: var(--color-black);
}
@media (min-width: 1001px) {
  .datacloud__filters .datacloud__filters__checkboxescontainer {
    display: block;
    overflow: hidden;
    height: auto;
    transition: max-height 400ms;
  }
  .datacloud__filters .datacloud__filters__checkboxescontainer[hidden] {
    display: block;
    max-height: 0;
  }
}
@media (max-width: 1000px) {
  .datacloud__filters .datacloud__filters__checkboxescontainer {
    display: none;
  }
}
.datacloud__filters__checkboxes {
  background-color: #fff;
  padding: 20px 30px 30px;
  border-radius: 8px;
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: stretch;
}
@media (max-width: 480px) {
  .datacloud__filters__checkboxes {
    padding: 20px;
  }
}
.datacloud__filters__checkboxes > li {
  flex: none;
}
.datacloud__filters__checkboxes[data-domaincount="2"] > li {
  width: calc(100% - 165px);
}
.datacloud__filters__checkboxes[data-domaincount="3"] > li {
  width: calc(50% - 82.5px);
}
.datacloud__filters__checkboxes[data-domaincount="4"] > li {
  width: calc(33.333% - 55px);
}
.datacloud__filters__checkboxes[data-domaincount="5"] > li {
  width: calc(25% - 41.25px);
}
@media (min-width: 1001px) {
  .datacloud__filters__checkboxes--hasfaculties {
    padding-right: 0;
  }
  .datacloud__filters__checkboxes > li.datacloud__filters__domaingroup--additional {
    background-color: rgba(83, 87, 91, 0.8);
    margin-top: -20px;
    margin-bottom: -30px;
    border-radius: 0 8px 8px 0;
    width: 165px;
    color: #fff;
    padding: 20px 20px 30px 0;
  }
  .datacloud__filters__checkboxes > li.datacloud__filters__domaingroup--additional li {
    padding-left: 20px;
  }
  .datacloud__filters__checkboxes > li.datacloud__filters__domaingroup--additional .datacloud__filters__option__count {
    color: inherit !important;
  }
}
@media (max-width: 1000px) {
  .datacloud__filters__checkboxes {
    display: block;
    max-width: 375px;
    margin: 0 auto;
  }
  .datacloud__filters__checkboxes > li {
    max-width: none !important;
    margin: 10px 0 30px;
    width: 100% !important;
  }
}
.datacloud__filters__checkboxes ul {
  list-style-type: none;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
}
.datacloud__filters__checkboxes > li li {
  flex: none;
}
.datacloud__filters__checkboxes > li:not(:last-child) > ul > li {
  padding-right: 20px;
}
.datacloud__filters__domain {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid var(--color-grey-lines);
  padding: 0 0 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.datacloud__filters__subdomain {
  font-size: 13px;
  line-height: 135%;
  padding: 3px 0;
}
.datacloud__filters__option {
  display: flex;
  align-items: baseline;
}
.datacloud__filters__option label {
  cursor: pointer;
}
.datacloud__filters__option input[type=checkbox] {
  flex-shrink: 0;
  position: absolute;
  left: -999em;
}
.datacloud__filters__option__checkbox {
  flex: none;
  width: 15px;
  height: 15px;
  overflow: hidden;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.datacloud__filters__option__checkbox > i {
  opacity: 0;
  display: inline-block;
  font-size: 10px;
}
.datacloud__filters__option__checkbox + label {
  padding-left: 10px;
  position: relative;
  top: 1px;
}
input[type=checkbox]:checked + .datacloud__filters__option__checkbox {
  background-color: var(--color-black);
  border-color: var(--color-black);
}
input[type=checkbox]:checked + .datacloud__filters__option__checkbox > i {
  opacity: 1;
}
.datacloud__filters__option__count {
  color: #999;
}
input[type=checkbox]:checked ~ label .datacloud__filters__option__count, .datacloud__filters__option:hover .datacloud__filters__option__count {
  color: var(--color-black);
}
@media (min-width: 1001px) {
  .datacloud__filters__domaingroup--additional .datacloud__filters__option__checkbox {
    background-color: transparent;
    border: 1px solid #fff;
    color: var(--color-black);
  }
  .datacloud__filters__domaingroup--additional input[type=checkbox]:checked + .datacloud__filters__option__checkbox {
    background-color: var(--color-white);
    border-color: var(--color-white);
  }
  .datacloud__filters__domaingroup--additional .datacloud__filters__option__count {
    color: #fff;
  }
}
.datacloud__filters__itemspacer {
  flex: 1 1 auto !important;
}
.datacloud__filters__reset {
  text-align: right;
  white-space: nowrap;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
  visibility: hidden;
}
.datacloud__filters--active .datacloud__filters__reset {
  visibility: visible;
}
.datacloud__graph-holder {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 50px;
  min-height: 100%;
}
.datacloud-content--graph .datacloud__graph-holder {
  display: block;
}
.datacloud-content--graph .datacloud__results {
  display: none;
}
.datacloud-content--list .datacloud__results {
  display: flex;
}
@media (max-width: 1000px) {
  .datacloud__filters__header {
    flex-wrap: wrap;
  }
  .datacloud__filters__header__title {
    flex: none;
    width: 100%;
    padding-bottom: 20px;
  }
  .datacloud__filters__header__searchbox {
    flex-basis: calc(100% - 180px);
  }
  .datacloud__filters__header__searchbox:focus-within {
    max-width: calc(100% - 180px);
  }
  .datacloud__mobilereset {
    display: block;
  }
  .datacloud__resultscounter {
    padding-top: 0;
    white-space: nowrap;
  }
}
@media (max-width: 555px) {
  .datacloud__filters__header__searchbox:focus-within {
    max-width: inherit;
  }
}
.datacloud-content--list .datacloud__graph-holder {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 1179px) {
  html.datacloudpage--aiexperts .datacloud-header__intro--desktop {
    display: none;
  }
  html.datacloudpage--aiexperts .datacloud-header__intro--nocloud {
    display: block;
  }
  html.datacloudpage--aiexperts .datacloud__filters__instructions, html.datacloudpage--aiexperts .datacloud__tabs,
  html.datacloudpage--aiexperts .datacloud__graph-holder {
    display: none !important;
  }
  html.datacloudpage--aiexperts .datacloud__filters__instructions-mobile {
    display: block;
  }
  html.datacloudpage--aiexperts .datacloud__filters__instructions-mobile .datacloud__filters__instructions {
    display: block !important;
  }
  html.datacloudpage--aiexperts .datacloud-content {
    min-height: 0;
    padding-bottom: 50px;
  }
  html.datacloudpage--aiexperts .datacloud__resultscounter {
    padding-top: 20px;
  }
  html.datacloudpage--aiexperts .datacloud__results {
    display: flex !important;
  }
  html.datacloudpage--aiexperts .datacloud__infoballoon .cloudfilterbtn {
    display: none;
  }
}

@media (max-width: 960px) {
  html:not(.datacloudpage--aiexperts) .datacloud-header__intro--desktop {
    display: none;
  }
  html:not(.datacloudpage--aiexperts) .datacloud-header__intro--nocloud {
    display: block;
  }
  html:not(.datacloudpage--aiexperts) .datacloud__filters__instructions, html:not(.datacloudpage--aiexperts) .datacloud__tabs,
  html:not(.datacloudpage--aiexperts) .datacloud__graph-holder {
    display: none !important;
  }
  html:not(.datacloudpage--aiexperts) .datacloud__filters__instructions-mobile {
    display: block;
  }
  html:not(.datacloudpage--aiexperts) .datacloud__filters__instructions-mobile .datacloud__filters__instructions {
    display: block !important;
  }
  html:not(.datacloudpage--aiexperts) .datacloud-content {
    min-height: 0;
    padding-bottom: 50px;
  }
  html:not(.datacloudpage--aiexperts) .datacloud__resultscounter {
    padding-top: 20px;
  }
  html:not(.datacloudpage--aiexperts) .datacloud__results {
    display: flex !important;
  }
  html:not(.datacloudpage--aiexperts) .datacloud__infoballoon .cloudfilterbtn {
    display: none;
  }
}