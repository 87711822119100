.coursefinder__mycourses {
  padding-top: 25px;
}
.coursefinder__mycourses .wh-form__prefillcontrol {
  display: none;
}
.coursefinder__mycourses--unsubscribedcoursealert .coursefinder__mycourses__unsubscribe,
.coursefinder__mycourses .coursefinder__mycourses__unsubscribed {
  display: none;
}
.coursefinder__mycourses--unsubscribedcoursealert .coursefinder__mycourses__unsubscribed {
  display: block;
}
.coursefinder__mycourses .coursefinder__paymentdialog {
  display: none;
}
.coursefinder__mycourses__coursealertform[hidden] {
  display: none;
}
.coursefinder__mycourses__coursealertform ul.unordered {
  margin-top: 0;
}
.coursefinder__mycourses__coursealertform__cols {
  display: flex;
}
.coursefinder__mycourses__coursealertform__col {
  flex: 1 1 50%;
  max-width: 50%;
}
@media (max-width: 600px) {
  .coursefinder__mycourses__coursealertform__cols {
    display: block;
  }
  .coursefinder__mycourses__coursealertform__col {
    max-width: 100%;
  }
}
.coursefinder__mycourses__coursealertform h3 {
  margin-bottom: 10px;
}
.coursefinder__mycourses--coursealert .coursefinder__registration {
  display: none;
}
.coursefinder__mycourses--coursealert .coursefinder__mycourses__coursealertform {
  display: block;
}
.coursefinder__mycourses ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.coursefinder__mycourses__tabs {
  white-space: nowrap;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.coursefinder__mycourses__tabs li {
  display: inline-block;
  background-color: #aaa;
  transition: background-color 0.2s;
  padding: 0 10px;
  border-radius: 2px;
  cursor: pointer;
}
.coursefinder__mycourses__tabs li + li {
  margin-left: 10px;
}
.coursefinder__mycourses__tabs li:hover, .coursefinder__mycourses__tabs li.active {
  background-color: #1E2328;
}
.coursefinder__mycourses .coursefinder__registration[data-view=history],
.coursefinder__mycourses .coursefinder__registration[data-view=pending] {
  display: none;
}
.coursefinder__mycourses .coursefinder__registration[data-view=history].coursefinder__registration--notaccepted {
  opacity: 0.6;
}
.coursefinder__mycourses--history .coursefinder__registration[data-view=active], .coursefinder__mycourses--history .coursefinder__registration[data-view=pending], .coursefinder__mycourses--pending .coursefinder__registration[data-view=active], .coursefinder__mycourses--pending .coursefinder__registration[data-view=history] {
  display: none;
}
.coursefinder__mycourses--history .coursefinder__mycourses__registrations {
  margin-top: 40px;
}
.coursefinder__mycourses--history .coursefinder__registration[data-view=history], .coursefinder__mycourses--pending .coursefinder__registration[data-view=pending] {
  display: block;
}
.coursefinder__mycourses:not(.coursefinder__mycourses--showcanceled) .coursefinder__registration[hidden] {
  display: none;
}
.coursefinder__mycourses__showcanceled {
  margin-top: 25px;
  display: none;
  cursor: pointer;
}
.coursefinder__mycourses__showcanceled__hide {
  display: none;
}
.coursefinder__mycourses--history .coursefinder__mycourses__showcanceled {
  display: inline-block;
}
.coursefinder__mycourses--showcanceled .coursefinder__mycourses__showcanceled__show {
  display: none;
}
.coursefinder__mycourses--showcanceled .coursefinder__mycourses__showcanceled__hide {
  display: inline-block;
}
.coursefinder__mycourses__registrations h2 {
  margin: 0;
}
.coursefinder__mycourses__registrations > li {
  padding: 35px 0;
  border-bottom: 1px solid #DCDDDE;
}
.coursefinder__mycourses__registrations > li[data-view=history] {
  margin-top: 20px;
  border: 1px solid #DCDDDE;
  padding: 35px 20px;
  background-color: rgba(240, 241, 242, 0.5);
}

/* also used by cancel and payment page */
.coursefinder__registration h2 {
  margin-bottom: 0;
}
.coursefinder__registration ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.coursefinder__registration span.cancel-registration, .coursefinder__registration .courselink {
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  color: #5A5E62;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s;
}
.coursefinder__registration span.cancel-registration:hover, .coursefinder__registration .courselink:hover {
  color: inherit;
}
.coursefinder__registration .pay-registration {
  margin-top: 10px;
  min-width: 100px;
}
.coursefinder__registration .courselink {
  margin-top: 10px;
}
.coursefinder__registration dl {
  font-size: 15px;
  line-height: 25px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 20px 0 0;
}
.coursefinder__registration dl dt, .coursefinder__registration dl dd {
  margin: 0;
  padding: 10px 0 0;
}
.coursefinder__registration dl dt {
  font-weight: 700;
  flex: 1 1 33.3333%;
  min-width: 115px;
}
.coursefinder__registration dl dd {
  flex: 1 1 66.6666%;
}
.coursefinder__registration dl dd, .coursefinder__registration dl li {
  display: flex;
  justify-content: space-between;
}
.coursefinder__registration dl li > span {
  flex: 1 1 33.3333%;
}
.coursefinder__registration dl li > span.time {
  text-align: right;
}
@media (max-width: 600px) {
  .coursefinder__registration dl li > span {
    flex: 1 1 50%;
  }
  .coursefinder__registration dl .weekday {
    display: none;
  }
}
.coursefinder__registration dl ul {
  width: 100%;
}
.coursefinder__registration dl li + li {
  padding-top: 10px;
}