html:not(.opendayv2--allowcancel) .opendayv2__cancellink {
  display: none;
}

.wh-form__fieldgroup--hidden + .openday--hide-with-previous {
  display: none;
}

html.is-opendayv2 .ut-pulldown__item--disabled {
  display: none;
}