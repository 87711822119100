.widget-ws2016events .widget__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget-ws2016events .widget__title .icon {
  margin-left: 10px;
  flex: none;
  font-size: 24px;
  color: var(--color-grey-normal);
}