.widget__issuu {
  background: var(--color-grey-dark);
  border-top: 1px solid transparent;
  color: var(--color-white);
}
.widget__issuu .widget__text {
  color: var(--color-white);
}
.widget__issuu .wh-requireconsent__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 13px 20px;
  font-style: italic;
}
.widget__issuu .widget__issuu_c2 {
  margin-top: 10px;
}
.widget__issuu .wh-form__optionlabel > a {
  color: inherit;
}
.widget__issuu--embedded {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 50px;
  padding-bottom: 20px;
}
@media (max-width: 750px) {
  .widget__issuu--embedded {
    margin-left: -55px;
    margin-right: -55px;
  }
}
@media (max-width: 480px) {
  .widget__issuu--embedded {
    margin-left: -15px;
    margin-right: -15px;
  }
  .widget__issuu--embedded .widget__issuu-embed {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.widget__issuu--embedded .widget__title,
.widget__issuu--embedded .widget__text {
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}
.widget__issuu--embedded .widget__title {
  font-size: 26px;
  line-height: 26px;
}
@media (max-width: 480px) {
  .widget__issuu--embedded .widget__title {
    font-size: 20px;
    line-height: 20px;
  }
}