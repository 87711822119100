/*
https://gitlab.webhare.com/utwente/utwente_base/issues/819

When changing the button styling,
check the following widgets and pages:

- Widgets
  - Inline CTA knop
  - Apply for programme widget knop
  - Programme details widget knoppen ('Take option' en 'Signup' knoppen voor PLD en ITC)
  - Timeline (de fullscreen button)
  - Quiz (open/multiple choice)
- Components
  - Forms file upload knop
  - Toplevel home CTA knop in de header
- Pages
  - Grantfinder ("Detailed information" als een scholarship een link heeft)
  - Publisher forms previous, next, submit knoppen
  - ufonds button ("Versturen" knop op het groen vlak)

Maybe also:

- OpenDagen knop (???, staat los van ws2016)
- filterbar buttons (???)
- faq
- itc search?? (#consiliosearchform button)
*/
/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.wh-form__button + .wh-form__button {
  margin-left: 10px;
}

.cta__button,
.inlinecta__button,
.wh-form__button.wh-form__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  text-transform: uppercase;
  max-width: 100%;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  color: var(--color-white);
  white-space: nowrap;
  transition: background 0.3s, color 0.3s;
  background: var(--theme-color-cta, #E8E8E8);
  height: 40px;
  border-radius: 20px;
  padding: 0 30px;
  font: 700 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
}
html[lang=el] .cta__button,
html[lang=el] .inlinecta__button,
html[lang=el] .wh-form__button.wh-form__button {
  font-family: Roboto Condensed;
}

.header--hascountdown.contentpart__header--photos .header-countdown a.wh-form__button span {
  color: inherit;
}

a.cta__button {
  line-height: 230%;
}

@media (max-width: 480px) {
  .inlinecta__button {
    height: 30px;
    border-radius: 15px;
    padding: 0 25px;
    font-size: 15px;
  }
}

.inlinecta__button.inlinecta__button.inlinecta__button {
  color: var(--color-white);
}

.cta__button:hover,
.inlinecta__button:hover,
.wh-form__button.wh-form__button:hover {
  color: var(--color-white);
}

html.animatedcolorscheme .cta__button:hover,
html.animatedcolorscheme .inlinecta__button:hover,
html.animatedcolorscheme .wh-form__button.wh-form__button:hover {
  animation-name: colorscheme-cta-hover-background !important;
}
html.animatedcolorscheme .cta--transparent .cta__button {
  background-color: var(--color-white);
}
html.animatedcolorscheme .cta--transparent .cta__button:hover {
  animation-name: colorscheme-cta-hover-color !important;
}

#ufonds_donatie .wh-form__button {
  border: 2px solid var(--color-white);
}

.wh-form__button.wh-form__button[disabled] {
  background-color: var(--color-grey-lines) !important;
  color: #787B82;
  animation: none !important;
  cursor: default;
}