/*
1440px - foto 255px
1280px - Zonder datum op titelbalk, foto 210px
1080px - Zonder datum op titelbalk, foto 210px
 750px - Zonder datum op titelbalk, zonder foto - knop naast datums
 320px - Zonder datum op titelbalk, zonder foto - knop onder datums


FIXME:
- $activityitem-padding-sides; should be a CSS variable
- --activityitem-padding-sides should change to the page sidepadding when we jump to mobile fullwidth content area layout

*/
/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.widget--activitieslist input[type=radio] + label {
  height: 22px;
}

/* WS2016 and WS2021 workarounds ********************************/
.widget--activitieslist {
  perspective: none !important;
}

h2 + .embeddedobject.widget--activitieslist {
  margin-top: 50px;
}

/***************************************************************/
.activitieslist__noresults {
  display: none;
  font: 700 18px/20px "Univers Next W02";
  margin-top: 15px;
}

.widget--activitieslist--noresults .activitieslist__noresults {
  display: block;
}
.widget--activitieslist--noresults .activitieslist__resultscount {
  display: none;
}

/* WORKAROUND: need higher specificy because of the ".embeddedobjects > *" rule
               added in WS2021 12-nov-2021
*/
.activitieslist__resultscount.activitieslist__resultscount.activitieslist__resultscount {
  margin-top: 15px;
  margin-bottom: 20px;
  padding-left: var(--whflattabs-tab-sidepadding);
  color: #5A5E62;
  font: 13px/20px "Univers Next W02";
}

h2.activityitem__title {
  margin: 0;
}

.activityitem__title {
  flex: 4 0 0px;
  font: 700 18px/20px "Univers Next W02";
  text-transform: none;
}

.activityitem__title-when.activityitem__title-when {
  flex: 0 0 215px;
  display: flex;
}

.foldablecontent--open .activityitem__title-when {
  display: none;
}

@media (max-width: 640px) {
  .whflattabs__filtertoggle .text {
    display: none;
  }
  /* OVERRIDE the weird ".foldablecontent__title > *" rule which set's display: inline-block; and position: relative;
     on all children in .foldablecontent__title.
  */
  .activityitem__title-when.activityitem__title-when {
    display: none;
  }
}
.activityitem .foldablecontent__title {
  display: flex;
  align-items: center;
}

/*
.activityitem__firstupcomingdate
{
  flex: 0 0 90px; // reserve space so we can align all dates
  text-align: right;
}
*/
.activityitem__firstupcomingdate {
  font: 15px "Univers Next W02";
}

.activityitem__extradatescount {
  font: 13px/20px "Univers Next W02";
  margin-left: 7px;
}

.activityitem {
  /* when the <details> is converted to <div class="foldablecontent"> by WS2016/WS2021 */
}
.activityitem .foldablecontent__title {
  padding-right: 0;
}
.activityitem .foldablecontent__title::after {
  position: static;
  width: 40px;
}
.activityitem .foldablecontent__text {
  background-color: #F7F8F8;
  padding-bottom: 0;
}

.activityitem .foldablecontent__title {
  border-top-width: 0;
}

.activityitem + .activityitem .foldablecontent__title {
  border-top-width: 1px;
}

.activityitem--hidden {
  display: none;
}

.activityitem__panel {
  background-color: #F7F8F8;
}

.activityitem__description > *:first-child {
  margin-top: 0;
}

.activityitem__description > *:last-child {
  margin-bottom: 0;
}

.activityitem__options__label {
  font: 600 14px "Univers Next W02";
  margin-bottom: 10px;
}

.activityitem__options {
  font: 13px/20px "Univers Next W02";
}

.activityitem__option {
  display: flex;
  padding: 7px 0 7px 0;
}

.activityitem__options__singleevent input {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

.activityitem__options__singleevent input + label {
  display: none !important;
}

.activityitem__option label {
  cursor: pointer;
}

.activityitem__option input + label + label {
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
}

.activityitem__option input[disabled] + label + label {
  color: #777777;
}

.activityitem__option + .activityitem__option {
  margin-top: 5px;
}

.activityitem__option__whenday {
  display: inline-block;
  min-width: 112px;
}

.activityitem__option__whentime {
  display: inline;
}

.activityitem__option__subtitle {
  width: 100%;
}

.activityitem__imagewrapper {
  display: grid;
}

.activityitem__image {
  grid-column: 1;
  grid-row: 1;
  display: block;
  width: 100%;
}

.activityitem__image--placeholder {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  padding-top: 50%;
  background-color: #DEDEDE;
}

.activityitem__tags {
  display: flex;
  align-items: start;
}

.activitytag {
  display: inline-flex;
  align-items: center;
  height: 37px;
  background-color: #FFFFFF; /* #F7F8F8; */
  border: 1px solid #F0F1F2;
  border-radius: 3px;
  color: #5A5E62;
  font: 13px/20px "Univers Next W02";
  padding: 0 20px;
}

[data-foroption]:not(.activityitem__ischosenoption) {
  display: none;
}

@media (max-width: 1079px) {
  .activityitem__tags {
    row-gap: 25px;
    column-gap: 10px;
  }
}
@media (max-width: 779px) {
  .widget--activitieslist {
    grid-column: 1/-1 !important;
  }
  .activityitem .foldablecontent__title {
    min-height: 75px;
  }
  .widget--activitieslist .whflattabs,
  .activitieslist__noresults,
  .activitieslist__resultscount {
    margin-left: 30px;
    margin-right: 30px;
  }
  .widget--activitieslist .foldablecontent__title {
    padding-left: 30px;
    padding-right: 30px;
  }
  .activityitem__panel {
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .activityitem__image,
  .activityitem__image--placeholder {
    display: none;
  }
  .activityitem__signupbutton {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .activityitem__description {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media (min-width: 780px) and (max-width: 1079px) {
  .activityitem .foldablecontent__title {
    min-height: 75px;
  }
  .activityitem__panel {
    display: grid;
    grid-template-columns: minmax(0, 1fr) min-content;
    padding-top: 35px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .activityitem__panel__column {
    display: contents;
  }
  .activityitem__options {
    grid-column: 1;
    grid-row: 1;
  }
  .activityitem__signupbutton {
    grid-column: 2;
    grid-row: 1;
  }
  .activityitem__description {
    grid-column: 1/span 2;
    grid-row: 2;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .activityitem__tags {
    grid-column: 1/span 2;
    grid-row: 3;
  }
  .activityitem__image,
  .activityitem__image--placeholder {
    display: none;
  }
}
@media (min-width: 1080px) {
  .activityitem .foldablecontent__title {
    min-height: 79px;
  }
  .activityitem__panel {
    display: flex;
  }
  .activityitem__panel__column:first-child {
    flex: 1 0 0px;
    padding-left: 30px;
    padding-top: 35px;
    padding-bottom: 20px;
  }
  .activityitem__panel__column + .activityitem__panel__column {
    margin-left: 30px;
    padding-bottom: 20px;
    /*
    $activityimage-width:      1vw;
    $activityimage-maxwidth: 255px;

    $minwidth-showimage: 1080px;
    */
    flex: 0 0 255px;
  }
  .activityitem__image + .activityitem__signupbutton,
  .activityitem__image--placeholder + .activityitem__signupbutton {
    display: flex;
    width: min-content;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%);
  }
  .activityitem__tags {
    flex-direction: column;
    padding-right: 30px;
  }
  .activityitem__tags > * + * {
    margin-top: 9px;
  }
  .activityitem__description {
    margin-top: 30px;
  }
}
.activitieslist__showmore {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  font: inherit;
  width: 100%;
  font-weight: 300;
  text-align: center;
  background-color: #f7f8f8;
  text-decoration: underline;
  font-size: 18px;
  line-height: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}

.widget--activitieslist-showall .activitieslist__showmore {
  display: none;
}

/*
.activityitem:nth-child(n+1)
{
  display: none;
}
*/