@charset "UTF-8";
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
/* Hide white ut topbar, just display login menu
html.coursefinderpage .menuheader__header--account
{
  height: 0;
  min-height: 0;
}
*/
html.utwente--ws2021 .coursefinder__coursealertdialog__form,
html.utwente--ws2021 .coursefinder__coursealertdialog__form .wh-form__page.wh-form__page--visible {
  display: block;
}

.coursefinder__coursealertdialog__form.hidecategories .wh-form__fieldgroup--categories {
  display: none;
}

.wh-form__fieldgroup--loginbtn {
  margin-top: 20px;
}
.wh-form__fieldgroup--loginbtn + .wh-form__fieldgroup {
  margin-top: 20px;
}

.coursefinder__coursealert {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
.coursefinder__coursealert > * {
  grid-column: 3/span 10;
}

.coursefinder__logincta {
  margin-bottom: 30px;
  margin-top: 30px;
  color: var(--color-tahiti-gold);
  border-radius: 4px;
  background-color: rgba(236, 122, 8, 0.1);
  border: 1px solid rgba(236, 122, 8, 0.2);
  padding: 15px 20px;
  font-size: 15px;
}
.coursefinder__logincta .ut-login--clicktologin {
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
}

.ut-dialog__holder .coursefinder__subscribedcoursealert {
  display: block;
}

.coursefinder__page {
  width: 100%;
  margin-top: 30px;
  transition: opacity 0.2s;
  opacity: 0;
  --layout: 12col;
}
.coursefinder__page.coursefinder__page--loaded {
  opacity: 1;
}
.coursefinder__page .coursefinder__noresults {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  border-top: 1px solid #DCDDDE;
  display: none;
  padding: 70px 10px 100px;
}
@media (max-width: 990px) {
  .coursefinder__page .coursefinder__noresults {
    padding: 70px 5px 100px;
  }
}
@media (max-width: 768px) {
  .coursefinder__page .coursefinder__noresults {
    padding: 70px 15px 100px;
  }
}
.coursefinder__page--noresults .coursefinder__noresults {
  display: block;
}
.coursefinder__page--noresults.coursefinder__page--calendar .coursefinder__noresults {
  display: none;
}
.coursefinder__page--noresults.coursefinder__page--calendar.coursefinder__page--mobile .coursefinder__noresults { /* mobile view calendar */
  display: block;
}
.coursefinder__page--noresults .coursefinder__detaillist__compactheader {
  display: none !important;
}
.coursefinder__page--detail .coursefinder__detaillist, .coursefinder__page--list .coursefinder__detaillist {
  display: block;
}
.coursefinder__page--detail .coursefinder__calendar, .coursefinder__page--list .coursefinder__calendar {
  display: none;
}
.coursefinder__page--calendar .coursefinder__calendar {
  display: block;
}
.coursefinder__page--calendar .coursefinder__detaillist {
  display: none;
}
.coursefinder__page .coursefinder__views {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 13px;
  padding: 0 10px;
  align-items: flex-start;
}
@media (max-width: 990px) {
  .coursefinder__page .coursefinder__views {
    padding: 0 5px;
  }
}
@media (max-width: 768px) {
  .coursefinder__page .coursefinder__views {
    padding: 0 15px;
  }
}
.coursefinder__page .coursefinder__views .coursefinder__filterresults {
  white-space: nowrap;
  padding-right: 10px;
  margin-bottom: 10px;
}
.coursefinder__page .coursefinder__views .coursefinder__filterresults .count {
  font-weight: 600;
}
.coursefinder__page .coursefinder__views .coursefinder__filterresults .count__courses::after {
  content: " "; /* non breaking space */
  display: inline-block;
}
.coursefinder__page .coursefinder__views .coursefinder__activetags {
  flex: 1 1 100%;
  padding: 0;
  font-size: 13px;
}
.coursefinder__page .coursefinder__views .coursefinder__activetags::after {
  content: "";
  display: block;
  clear: both;
}
.coursefinder__page .coursefinder__views .coursefinder__activetags > span {
  display: inline-block;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  height: 24px;
  float: left;
}
.coursefinder__page .coursefinder__views .coursefinder__activetags > .title::before {
  content: "|";
  padding-right: 10px;
  display: inline-block;
}
.coursefinder__page .coursefinder__views .coursefinder__activetags > .tag {
  position: relative;
  border: 1px solid #AAACAE;
  border-radius: 2px;
  color: #AAACAE;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
  padding: 0 30px 0 10px;
  cursor: pointer;
  font-weight: 700;
}
.coursefinder__page .coursefinder__views .coursefinder__activetags > .tag > span {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 6px;
}
.coursefinder__page .coursefinder__views .coursefinder__viewoptions {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
}
.coursefinder__page .coursefinder__views .coursefinder__viewoptions > li {
  margin-left: 30px;
  white-space: nowrap;
  cursor: pointer;
}
.coursefinder__page .coursefinder__views .coursefinder__viewoptions > li span {
  display: inline-block;
  color: #AAACAE;
  transition: color 0.2s;
}
.coursefinder__page .coursefinder__views .coursefinder__viewoptions > li .text {
  margin-left: 10px;
  color: #5A5E62;
  position: relative;
}
.coursefinder__page .coursefinder__views .coursefinder__viewoptions > li .text .lo {
  display: inline-block;
}
.coursefinder__page .coursefinder__views .coursefinder__viewoptions > li .text .hi {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
}
@media (max-width: 700px) {
  .coursefinder__page {
    margin-top: 10px;
  }
  .coursefinder__page .coursefinder__views {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .coursefinder__page .coursefinder__views .coursefinder__activetags > .title::before {
    display: none;
  }
  .coursefinder__page .coursefinder__views .coursefinder__viewoptions > li {
    margin-left: 0;
    margin-right: 30px;
  }
  .coursefinder__page .coursefinder__views .coursefinder__filterresults, .coursefinder__page .coursefinder__views .coursefinder__activetags {
    flex: 0 0 100%;
    padding-bottom: 10px;
  }
}
.coursefinder__page--calendar .count__domains {
  display: none;
}
.coursefinder__page .coursefinder__views li:hover span, .coursefinder__page--detail .coursefinder__views li.detail span, .coursefinder__page--list .coursefinder__views li.list span, .coursefinder__page--calendar .coursefinder__views li.calendar span {
  color: #1E2328 !important;
}
.coursefinder__page .coursefinder__views li:hover .text .lo, .coursefinder__page--detail .coursefinder__views li.detail .text .lo, .coursefinder__page--list .coursefinder__views li.list .text .lo, .coursefinder__page--calendar .coursefinder__views li.calendar .text .lo {
  visibility: hidden;
}
.coursefinder__page .coursefinder__views li:hover .text .hi, .coursefinder__page--detail .coursefinder__views li.detail .text .hi, .coursefinder__page--list .coursefinder__views li.list .text .hi, .coursefinder__page--calendar .coursefinder__views li.calendar .text .hi {
  display: inline-block;
}
.coursefinder__page--mobile {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  width: 100%;
}
.coursefinder__page--mobile .coursefinder__views {
  padding: 0 15px;
}
.coursefinder__page--mobile .coursefinder__calendar {
  padding: 0;
}
.coursefinder__page--mobile .coursefinder__calendar__nav, .coursefinder__page--mobile .coursefinder__calendar__tablewrapper {
  display: none;
}

.coursefinder-filters {
  display: flex;
  flex-flow: row;
}
.coursefinder-filters .filter__row__item {
  flex: 1 1 25%;
  max-width: 25%;
}
.coursefinder-filters .filter__header {
  display: block;
  width: 100%;
}
.coursefinder-filters .wh-form__fields + .filter__header {
  margin-top: 30px;
}
@media (max-width: 750px) {
  .coursefinder-filters {
    flex-wrap: wrap;
  }
  .coursefinder-filters .filter__row__item {
    flex: 1 1 50%;
    max-width: calc(50% - 20px);
  }
  .coursefinder-filters .filter__row__item:nth-child(3) {
    margin-left: 0;
  }
  .coursefinder-filters .filter__row__item:nth-child(3), .coursefinder-filters .filter__row__item:nth-child(4) {
    padding-top: 15px;
  }
  .coursefinder-filters .wh-form__fields + .filter__header {
    margin-top: 15px;
  }
}
@media (max-width: 480px) {
  .coursefinder-filters {
    display: block;
  }
  .coursefinder-filters .filter__row__item {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .coursefinder-filters .filter__row__item + .filter__row__item {
    margin-left: 0;
    padding-top: 15px;
  }
}