/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
.contentpart {
  flex: 1;
  margin-left: var(--contentpart-margin);
  position: relative;
}

.contentpart__main {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  padding-bottom: var(--main-padding-bottom);
  position: relative;
  z-index: var(--zindex-main);
  overflow-wrap: break-word;
}
.contentpart__main > * {
  grid-column: var(--main-8col-layout);
}

/* Support for snippets preview -------------------------
   We need to cancel the max-width which is set because all
   file widgets are meant to be small (and used in grids).
   The widgets in snippetpreviews are inline and are usually 8 col / 12 col or fullwidth
   and require to be in a grid which has the full width of the page.
*/
html.utwente--ws2021.wh-widgetpreview.ut--snippetpreview {
  max-width: none;
}

.contentpart__deeplinks {
  bottom: 0;
  display: var(--deeplinks-display);
  grid-column: text-right/right;
  pointer-events: none;
  position: absolute;
  right: env(safe-area-inset-right);
  top: 0;
  z-index: var(--zindex-deeplinks);
}