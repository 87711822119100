.component-countdown
{
  display: flex;
  column-gap: var(--countdown-sections-gap);
}

.countdown__section
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* We only show the days if at the time the countdown component started (webpage opened)
   the day count was higher than zero. */
.countdown__section--days.countdown__section--initiallyleadingzero
{
  display: none;
}

/* Hide the leading zero's in the day counter */
.countdown__section--days .countdown__digit--initiallyleadingzero
{
  display: none;
}

/* Container for the two digits for this part (days/hours/minutes/seconds) */
.countdown__section__digits
{
  display: flex;
  justify-content: center;
  column-gap: 1px;

  font: var(--countdown-digit-font);
}

/* The track in which we see the digit */
.countdown__digit
{
  display: flex;
  flex-direction: column;

  width:  var(--countdown-digit-width);
  height: var(--countdown-digit-height);

  overflow: hidden;
}

/* __track is the element which is moved withint the viewport (.countdown__digit).
   The height: 100%; ensures the .countdown__digit__number can inherit/use the % height.
   The element will grow beyond this height though because it's flex with column direction.
*/
.countdown__digit__track
{
  height: 100%;
}

.component-countdown--allowanimation .countdown__digit__track
{
  transition: transform 0.6s ease-in-out;
}

/* The little number card */
  .countdown__digit__number
, .wh-rtd .countdown__digit
{
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--countdown-digit-background);
  color:      var(--countdown-digit-color);

  width:  var(--countdown-digit-width);
  flex:   none;
}

.countdown__digit__number
{
  height: 100%;
}

.countdown__digit__number + .countdown__digit__number
{
  margin-top: 1px;
}

/* leading zeo */
.countdown__digit--leadingzero
{
  /* background: #DCDDDE; */
  opacity: 0.35;
}

.countdown__section__label
{
  font: 400 13px/25px "Univers Next W02", Arial, Helvetica, sans-serif;
}
