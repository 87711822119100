.article {
  position: relative;
}

.article__title small {
  font-weight: 300;
  font-size: 58px;
  line-height: 58px;
}

/*
Styling for previous/next event navigation on detail page
*/
.article__pagenav {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
@media print {
  .article__pagenav {
    display: none;
  }
}

.article__pagenav a {
  position: absolute;
  top: 0;
  display: none;
  white-space: nowrap;
  color: var(--color-grey-normal);
  font-weight: 300;
  font-size: 18px;
}

.article__pagenav a.previous {
  margin-right: 20px;
  right: 100%;
}

.article__pagenav a.next {
  margin-left: 20px;
  left: 100%;
}

.article__pagenav a > span {
  font-size: 12px;
  display: inline-block;
}

.article__pagenav a.active {
  display: inline-block;
}

@media (max-width: 950px) {
  .article__pagenav {
    position: relative;
    width: 100%;
    height: 10px;
  }
  .article__pagenav a {
    top: 15px;
  }
  .article__pagenav a.previous {
    right: auto;
    left: 0;
  }
  .article__pagenav a.next {
    left: auto;
    right: 0;
  }
}
.article__date {
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 15px;
  position: relative;
  padding-left: calc(var(--pageside-padding) + 25px);
}

.article__date > .icon {
  position: absolute;
  top: 4px;
  left: var(--pageside-padding);
  font-size: 16px;
  color: var(--color-grey-normal);
}

.article .soldout {
  float: right;
  font-weight: 700;
}

.article__meta {
  margin-top: 40px;
  border-top: 1px solid #e0e1e1;
  padding-top: 10px;
}

.article__meta__row {
  display: block;
  margin-top: 5px;
  position: relative;
  padding-left: 30px;
}

.article__meta .icon {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 16px;
  color: var(--color-grey-normal);
}

.article + .summary {
  clear: both;
  margin-top: 50px;
}

html.ws2016--iframed .article__pagenav,
html.ws2016--iframed .summary {
  display: none;
}

html.ws2016--iframed .widgetsblock {
  background: var(--color-white);
  border-top: 1px solid var(--color-grey-light);
}