@charset "UTF-8";
/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@import "@mod-utwente_design/scss/config-colors.css";
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.linksgridwidget {
  background-color: #f0f1f2;
  padding: 20px;
}
.linksgridwidget > .widgetsblock {
  padding-left: 20px;
  padding-right: 20px;
}
.linksgridwidget > .widgetsblock > .widgetsblock__contents {
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 0px !important;
}
.linksgridwidget .linksgridwidget__item {
  width: calc((100% - 40px) / 3) !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
@media (max-width: 999px) {
  .linksgridwidget .linksgridwidget__item {
    width: calc((100% - 20px) / 2) !important;
  }
}
@media (max-width: 639px) {
  .linksgridwidget .linksgridwidget__item {
    width: 100% !important;
  }
}

.linksgridwidget.linksgridwidget.linksgridwidget {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
  --layout: 12col;
}

.linksgridwidget.linksgridwidget .linksgridwidget__title,
.linksgridwidget.linksgridwidget .widgetsblock__contents {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
}
@media (max-width: 960px) {
  .linksgridwidget.linksgridwidget .linksgridwidget__title,
  .linksgridwidget.linksgridwidget .widgetsblock__contents {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .linksgridwidget.linksgridwidget .linksgridwidget__title,
  .page--toplevelhome .linksgridwidget.linksgridwidget .widgetsblock__contents {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}

@media (max-width: 639px) {
  .linksgridwidget .widgetsblock__contents .widget {
    margin-left: 0;
  }
  .linksgridwidget .widgetsblock__contents .widget + .widget {
    margin-top: 15px;
  }
}
@media (min-width: 481px) {
  .linksgridwidget .widgetsblock__contents .widget {
    margin-top: 0;
  }
}
.linksgridwidget__title {
  font: 700 15px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

/*
Override specificy of these:
.widgetsblock__contents.widgetsblock__contents--equalheight .widget
.widgetsblock__contents--equalheight .widget
*/
.linksgridwidget__item.linksgridwidget__item.linksgridwidget__item {
  display: flex;
  flex-direction: row;
  background-color: var(--color-white);
  padding: 29px 20px;
}

a.linksgridwidget__item {
  color: var(--color-black);
  text-decoration: none;
}

.linksgridwidget__item__title {
  flex: auto;
  min-width: 0;
  line-height: 24px;
}

.linksgridwidget__item__icon {
  flex: none;
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #afb1b3;
  font-weight: 400;
  font-size: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
}
html.site--itc .linksgridwidget__item__icon {
  color: #00675a;
}

.embeddedobject--downloads .UT-icon-UT_icon_52_arrow-right-round-16px {
  font-size: 16px;
  height: 16px;
  top: calc(50% - 8px);
  text-align: center;
}

.embeddedobject__studyfinder {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}

.sfwidget--collapsedcontent {
  display: none;
}

.sfwidget--expand .sfwidget--collapsedcontent {
  display: block;
}

.sfwidget--expand .sfwidget__showall {
  display: none;
}

.sfwidget__showall {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f0f1f2;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 960px) {
  .sfwidget__showall {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .sfwidget__showall {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (max-width: 960px) {
  .sfwidget__showall {
    width: auto;
  }
}
@media (max-width: 990px) {
  .sfwidget__showall {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* contentlisting */
.embeddedobject__studylist .foldablecontent__text > .summary {
  margin-top: -1px;
}

.embeddedobject__contentlisting .summary__item + .foldablecontent, .embeddedobject__contentlisting > .summary + .summary__item,
.embeddedobject__contentlisting .foldablecontent + .foldablecontent,
.embeddedobject__contentlisting .foldablecontent + .summary__item,
.embeddedobject__contentlisting .foldablecontent__text > .summary,
.embeddedobject__contentlisting .summary + .foldablecontent {
  margin-top: -1px;
}
.embeddedobject__contentlisting .summary__item .summary__link {
  padding-top: 15px;
  padding-bottom: 15px;
}
.embeddedobject__contentlisting .foldablecontent__text {
  padding-bottom: 0;
}
.embeddedobject__contentlisting .foldablecontent__text .summary__title {
  text-transform: none;
  font-size: 15px;
  font-weight: 300;
}
.embeddedobject__contentlisting .summary__description {
  font-size: 15px;
  font-weight: 400;
}
.embeddedobject__contentlisting > .summary__item .summary__title {
  font-weight: 300;
}

/* and projectinfo (gezondheidscampagne) */
.embeddedobject__projectinfo__container {
  border-top: 1px solid #e0e0e1;
  border-bottom: 1px solid #e0e0e1;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
.embeddedobject__projectinfo__container h3 {
  color: #999;
  font-size: 15px;
  background-color: var(--color-white);
  margin-top: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: -10px;
  display: inline-block;
  padding-right: 15px;
}
.embeddedobject__projectinfo__container .line {
  display: table-row;
  font-size: 15px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 480px) {
  .embeddedobject__projectinfo__container .line {
    display: block;
    position: relative;
  }
  .embeddedobject__projectinfo__container .line + .line {
    margin-top: 10px;
  }
}
.embeddedobject__projectinfo__container .line__icon {
  color: var(--color-grey-normal);
  display: table-cell;
  font-size: 16px;
  width: 35px;
}
.embeddedobject__projectinfo__container .line__icon > span {
  display: inline-block;
  position: relative;
  top: 1px;
}
@media (max-width: 480px) {
  .embeddedobject__projectinfo__container .line__icon {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
  }
}
.embeddedobject__projectinfo__container .line__title {
  display: table-cell;
  font-weight: 700;
  padding: 7px 15px 7px 0;
  text-transform: uppercase;
  width: 175px;
}
@media (max-width: 480px) {
  .embeddedobject__projectinfo__container .line__title {
    display: block;
    padding-bottom: 0;
    padding-left: 35px;
    padding-top: 0;
    position: relative;
    width: auto;
  }
}
.embeddedobject__projectinfo__container .line__description {
  display: table-cell;
  padding: 7px 0;
  font-weight: 600;
}
@media (max-width: 480px) {
  .embeddedobject__projectinfo__container .line__description {
    display: block;
    padding-bottom: 0;
    padding-left: 35px;
    padding-top: 0;
    position: relative;
  }
}
@media (max-width: 480px) {
  .embeddedobject__projectinfo__container {
    padding-top: 25px;
    padding-bottom: 15px;
  }
}

.embeddedobject-faq .embeddedobject__projectinfo__container h3 {
  background-color: #f7f8f8;
}

.embeddedobject__placeholder {
  cursor: pointer;
}

.embeddedobject__placeholder:hover .embeddedobject__play {
  opacity: 0.8;
}

.embeddedobject__title {
  color: var(--color-grey-normal);
  display: flex;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.embeddedobject__title:after {
  background: var(--color-grey-lines);
  content: "";
  display: block;
  flex: 1;
  height: 1px;
  margin-left: 10px;
  margin-top: 11px;
}

.embeddedobject__photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  position: relative;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 480px) {
  .embeddedobject__photo {
    background-size: 62px 62px;
    height: 62px;
    width: 62px;
  }
}

div.rtd__anchor {
  padding-top: 5px;
  font-style: italic;
}

a.rtd__anchor {
  position: absolute;
  left: 0;
  content: "";
  line-height: 0;
  display: inline-block;
  padding: 0;
  margin: 0;
  height: 0;
  width: 0;
}

/* foldablecontent */
.embeddedobject.foldablecontent + .embeddedobject.foldablecontent {
  margin-top: -51px;
}

.foldablecontent__title {
  border-top: 1px solid #e0e0e1;
  border-bottom: 1px solid #e0e0e1;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  padding: 16px 40px 15px 0;
  position: relative;
  cursor: pointer;
  z-index: 0;
}

.foldablecontent:not(.foldablecontent--open) + .foldablecontent > .foldablecontent__title {
  border-top: none;
}

.foldablecontent__title:before {
  content: "";
  background-color: var(--color-grey-lighter);
  position: absolute;
  margin-left: -20px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  transition: width 150ms ease;
  z-index: -1;
}

.foldablecontent__title:hover:before {
  width: 100%;
}

.foldablecontent__title > * {
  display: inline-block;
  position: relative;
}

.foldablecontent__title:after {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #afb1b3;
  font-weight: 400;
  font-size: 16px;
  width: 16px;
  height: 16px;
  text-align: center;
  /*
    html.site--itc &
    {
      color: $color-mosque;
    }
  */
  display: block;
  position: absolute;
  width: 40px;
  height: 50px;
  flex: none;
  line-height: 1;
  padding-top: 16px;
  top: 0;
  right: 0;
  content: "\e92f";
  transition: color 300ms;
}

.foldablecontent__title:hover:after {
  color: var(--color-black);
}

.foldablecontent--open > .foldablecontent__title:after {
  color: var(--color-black);
  content: "\e930";
}

.foldablecontent__foldcontainer {
  overflow: hidden;
  height: 0;
}
.foldablecontent__foldcontainer--active {
  transition: height 250ms;
}

.wh-rtd-editor .foldablecontent__foldcontainer,
.foldablecontent--open > .foldablecontent__foldcontainer {
  height: auto;
}

.foldablecontent__text {
  padding: 0 0 10px;
}

.foldablecontent__text:after {
  clear: both;
  content: "";
  display: block;
}

.foldablecontent__text--rte {
  padding: 30px 0;
}

.foldablecontent__text > *:first-child {
  margin-top: 0;
}

.foldablecontent__text > *:last-child {
  margin-bottom: 0;
}

.embeddedobject__studylist .summary__item .summary__link {
  min-height: 0;
  padding-left: 0;
}

/* news/events */
@media (max-width: 480px) {
  .embeddednewsevents .summary__items .tags {
    display: none;
  }
}

.embeddednewsevents--infocus .summary__items {
  display: none;
}
@media (max-width: 960px) {
  .embeddednewsevents--infocus .summary__introblocks {
    display: none;
  }
  .embeddednewsevents--infocus .summary__items {
    display: block;
  }
}

/* person info */
.personinfo__photo {
  float: left;
}

.personinfo.haspeopleplaceholder .personinfo__photo {
  display: none;
}

.personinfo:not(.haspeopleplaceholder) .personinfo__content--photo {
  min-height: 100px;
  padding-left: 120px;
}
@media (max-width: 480px) {
  .personinfo:not(.haspeopleplaceholder) .personinfo__content--photo {
    padding-left: 85px;
  }
}

.personinfo__content-break {
  clear: both;
  margin-left: -20px;
}
.personinfo__content-break.personinfo__content--bottomline:after {
  display: block;
  content: "";
  margin-left: 20px;
  border-bottom: 1px solid var(--color-grey-lines);
}
.personinfo:not(.haspeopleplaceholder) .personinfo__content--photo .personinfo__content-break {
  margin-left: -140px;
}
@media (max-width: 960px) {
  .personinfo__content-break {
    margin-left: 0;
  }
  .personinfo:not(.haspeopleplaceholder) .personinfo__content--photo .personinfo__content-break {
    margin-left: -120px;
  }
  .personinfo__content-break.personinfo__content-break:after {
    display: block;
    content: "";
    margin-left: 0 !important;
    border-bottom: 1px solid var(--color-grey-lines);
  }
}
@media (max-width: 480px) {
  .personinfo__content-break {
    margin-left: 0;
    padding-top: 10px;
  }
  .personinfo:not(.haspeopleplaceholder) .personinfo__content--photo .personinfo__content-break {
    margin-left: -85px;
  }
}

.personinfo__name {
  color: var(--color-black);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  padding-top: 10px;
  text-transform: uppercase;
}

.personinfo__function {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}

.personinfo__bio {
  font-size: 15px;
}
@media (max-width: 480px) {
  .personinfo__bio {
    clear: both;
    padding-top: 20px;
  }
  .personinfo:not(.haspeopleplaceholder) .personinfo__content--photo .personinfo__bio {
    margin-left: -85px;
  }
}
.personinfo__bio p {
  margin: 20px 0 0 0;
}
@media (max-width: 480px) {
  .personinfo__bio p {
    margin-top: 0;
  }
}

.personinfo__sociallink {
  display: block;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 20px;
  text-decoration: none;
}
.personinfo__sociallink-text {
  color: var(--color-black);
  text-decoration: underline;
}
.personinfo__sociallink-icon {
  color: var(--color-grey-normal);
  margin-left: 15px;
  position: relative;
  top: 1px;
  vertical-align: baseline;
}

.personinfo__contactlink {
  border-top: 1px solid var(--color-grey-lines);
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  min-height: 50px;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 20px;
  padding: 14px 0;
  text-decoration: none;
  vertical-align: top;
  width: calc(50% - 20px);
}
.personinfo__contactlink + .personinfo__contactlink + .personinfo__contactlink {
  margin-top: 0;
}
@media (max-width: 750px) {
  .personinfo__contactlink {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }
  .personinfo__contactlink + .personinfo__contactlink {
    margin-top: 0;
  }
}
.personinfo__contactlink-text {
  color: var(--color-black);
}
.personinfo__contactlink-icon {
  color: var(--color-grey-normal);
  float: right;
  margin-left: 15px;
  position: relative;
  vertical-align: top;
}
.personinfo__contactlink.overflow {
  line-height: 50px;
  max-height: 50px;
  padding: 0;
  position: relative;
  z-index: 0;
}
.personinfo__contactlink.overflow .overflow__container {
  overflow: hidden;
  max-height: 50px;
}
.personinfo__contactlink.overflow .softbreak {
  display: block;
  float: left;
  margin-right: -1px;
  width: 1px;
}
.personinfo__contactlink.overflow .personinfo__contactlink-icon {
  float: right;
  line-height: 50px;
}
.personinfo__contactlink.overflow .personinfo__contactlink-text {
  float: left;
  min-width: calc(100% - 40px);
  position: relative;
  white-space: nowrap;
}
.personinfo__contactlink.overflow .personinfo__contactlink-text:before {
  content: attr(data-overflow);
  left: 0;
  margin-top: -50px;
  position: absolute;
  top: 0;
}
.personinfo__contactlink.overflow .personinfo__contactlink-text {
  min-width: calc(100% - 40px);
}

/* quote */
.quote {
  text-align: center;
}

.embeddedobject--rte--themecolor .quote {
  color: var(--color-white);
}

.quote__text {
  font-size: 40px;
  line-height: 40px;
  font-weight: 300;
  text-transform: uppercase;
}

.quote__text:before {
  display: inline;
  content: "“";
  font-size: 58px;
}

.quote__text:after {
  display: inline;
  content: "”";
  font-size: 58px;
}

.quote__from {
  display: inline-block;
  padding-top: 10px;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
}

@media (max-width: 500px) {
  .quote__text {
    font-size: 30px;
    line-height: 30px;
  }
  .quote__text:before {
    font-size: 45px;
  }
  .quote__text:after {
    font-size: 45px;
  }
}
/**/
.embeddedobject, .wh-video {
  position: relative;
  clear: both;
}

.embeddedobject,
.wh-rtd-embeddedobject,
.wh-video,
.twocolumnsrte > div > .embeddedobject + .embeddedobject {
  margin-bottom: 50px;
  margin-top: 50px;
}

h2.foldabledeeplink__title + .foldabledeeplink__foldcontainer .foldabledeeplink__text > .embeddedobject:not(.embeddedobject--margintop):first-child {
  margin-top: 0px;
}

.wh-rtd-embeddedobject .embeddedobject,
html.wh-widgetpreview .wh-video,
html.wh-widgetpreview .embeddedobject,
html.wh-widgetpreview .embeddedobject.foldablecontent + .embeddedobject.foldablecontent,
.twocolumnsrte > div > .embeddedobject {
  margin-top: 0;
  margin-bottom: 0;
}

.embeddedobject[data-scrollvisibleclass=fadein] {
  opacity: 0;
  transition: opacity 0.8s;
}

html.wh-widgetpreview .embeddedobject {
  opacity: 1 !important;
  transition: none !important;
  padding: 0 20px;
}

.wh-rtd-editor .embeddedobject[data-scrollvisibleclass=fadein],
.embeddedobject[data-scrollvisibleclass=fadein].fadein {
  opacity: 1;
}

.embeddedobject__name {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 10px;
  display: block;
}