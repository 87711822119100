@import "@mod-utwente_design/scss/config-colors.css";
.widget-vacancies {
  background-color: #fff;
  color: #1e2328;
}
.widget-vacancies__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget-vacancies__title .icon {
  margin-left: 10px;
  flex: none;
  font-size: 24px;
  color: #aaacae;
}
.widget-vacancies__list:empty {
  display: none;
}
.widget-vacancies .widget__text {
  padding-top: 0;
}
.widget-vacancies__footer {
  padding-top: 20px;
}
.widget-vacancies__footer:empty {
  display: none;
}