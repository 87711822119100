/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.widget-preregistrationwidget .wh-form__introtext {
  margin-top: 15px;
}

.widget-preregistrationwidget .form-success,
.widget-preregistrationwidget .form-error {
  display: none;
}

.widget-preregistrationwidget div.success form,
.widget-preregistrationwidget div.error form,
html.utwente--ws2021 .widget-preregistrationwidget.rpc--success form,
html.utwente--ws2021 .widget-preregistrationwidget.rpc--error form {
  display: none;
}

.widget-preregistrationwidget div.success .form-success,
.widget-preregistrationwidget div.error .form-error,
html.utwente--ws2021 .widget-preregistrationwidget.rpc--success .form-success,
html.utwente--ws2021 .widget-preregistrationwidget.rpc--error .form-error {
  display: block;
}

/* CSS which only applies when used as file widget
   (not when used inline within the page content)
*/
.widget-preregistrationwidget .widget__rawhtml {
  padding: 0 25px 15px;
}

.widget-preregistrationwidget .widget__rawhtml__contents .widget-preregistrationwidget.rpc--success .form-success,
.widget-preregistrationwidget.rpc--fail .form-error {
  display: block;
}

.widget-preregistrationwidget.rpc--success form {
  display: none;
}

.widget-preregistrationwidget h2 {
  margin-top: 15px;
}

.widget-preregistrationwidget h2:before {
  display: none;
}

.ut-dialog .widget-preregistrationwidget .widget__rawhtml {
  padding: 0;
}
.ut-dialog .widget-preregistrationwidget .widget__rawhtml__contents {
  border: none;
}