/* ADDME: after with flex-grow: 1; and use of flex justify instead of margins and negative margins for left/right.. 
   (still needing vertical margins though)
*/
/* 1235px; */
.widgetsblock__contents--equalheight .widget__break {
  width: 100%;
}

/* NO COLUMNS */
html.page--peopleoverview .widgetsblock__contents--equalheight .widget__link {
  margin-top: auto;
}

/* COLUMNS -> use a wrapping flex container */
@media (min-width: 640px) {
  .widgetsblock__contents--equalheight {
    display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
    display: flex;
    -webkit-flex-flow: row wrap; /* flex-wrap: wrap */
    flex-flow: row wrap; /* flex-wrap: wrap */
    width: calc(100% + 20px);
    max-width: 960px; /* ( 940 + 20px ) */
    margin-bottom: -20px;
  }
  .widgetsblock__contents--equalheight .widget,
  .widgetsblock__contents--equalheight .widget .widget__front {
    display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .widgetsblock__contents--equalheight .widget .widget__front {
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}
/* 3 columns */
@media (min-width: 1000px) {
  .widgetsblock__contents.widgetsblock__contents--equalheight .widget {
    flex-basis: auto;
    width: calc(33.33% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
/* 2 columns */
@media (min-width: 640px) and (max-width: 999px) {
  .widgetsblock__contents.widgetsblock__contents--equalheight .widget {
    flex-basis: auto;
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 639px) {
  .widgetsblock__contents.widgetsblock__contents--equalheight .widget {
    flex-basis: auto;
    width: auto;
  }
}