@import "@mod-utwente_design/scss/config-colors.css";
.embeddedobject-insetblock__content {
  position: relative;
  background-color: #f0f1f2;
  border: 1px solid #dcddde;
  padding: 20px 25px 20px 45px;
  min-height: 100px;
}
.embeddedobject-insetblock__content__title {
  font-weight: bold;
  padding-bottom: 5px;
  font-weight: 600;
}
.embeddedobject-insetblock__content__description {
  font-style: italic;
}
.embeddedobject-insetblock__content__description > *:first-child {
  margin-top: 0;
}
.embeddedobject-insetblock__content__description > *:last-child {
  margin-bottom: 0;
}
.embeddedobject-insetblock__content__icon {
  position: absolute;
  left: -23px;
  top: 20px;
  font-size: 64px;
}