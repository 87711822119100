@charset "UTF-8";
.coursefinder__canceldialog h3, .coursefinder__paymentdialog h3 {
  margin: 0 0 2px;
}
.coursefinder__canceldialog .course_date, .coursefinder__paymentdialog .course_date {
  font-style: italic;
  font-size: 13px;
}
.coursefinder__canceldialog .course_price, .coursefinder__paymentdialog .course_price {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 0 15px;
}
.coursefinder__canceldialog .course_price::before, .coursefinder__paymentdialog .course_price::before {
  display: inline-block;
  content: "€";
  padding-right: 5px;
}
.coursefinder__canceldialog .paymentref, .coursefinder__paymentdialog .paymentref {
  font-size: 13px;
  margin-top: 10px;
}
.coursefinder__canceldialog label, .coursefinder__paymentdialog label {
  display: block;
  width: 100%;
}
.coursefinder__canceldialog .buttons, .coursefinder__paymentdialog .buttons {
  text-align: right;
  padding-top: 20px;
}
.coursefinder__canceldialog .buttons button, .coursefinder__paymentdialog .buttons button {
  margin-left: 10px;
  min-width: 100px;
}
.coursefinder__canceldialog .buttons--center, .coursefinder__paymentdialog .buttons--center {
  text-align: center;
}
.coursefinder__canceldialog .buttons--center button, .coursefinder__paymentdialog .buttons--center button {
  margin-right: 10px;
}