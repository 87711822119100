/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
html.utwente--menuhidden .contentpart {
  transition-duration: var(--menu-transition-duration);
  transition-property: transform;
}
html.utwente--menuopen .contentpart {
  transform: translateX(var(--menu-width));
}

.menupart {
  background: var(--color-white);
  bottom: 0;
  display: flex;
  flex: none;
  flex-direction: column;
  height: var(--menu-height);
  left: 0;
  position: fixed;
  top: 0;
  transform: var(--menupart-translation);
  width: var(--menu-width);
  z-index: var(--zindex-menu);
}
html.utwente--menuhidden .menupart {
  transition-duration: var(--menu-transition-duration);
  transition-property: transform;
}
html.utwente--menuopen .menupart {
  transform: translateX(env(safe-area-inset-left));
}

.menupart__menu-container {
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  padding-top: var(--menu-header-spacing);
  width: 100%;
  z-index: 0;
}
.menupart__menu-container .ps__rail-y {
  background-color: transparent !important;
  z-index: 2;
}

.menupart__modalbg {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--zindex-menumodal);
}
html.utwente--menuhidden .menupart__modalbg {
  transition: opacity var(--menu-transition-duration);
}
html.utwente--menuopen .menupart__modalbg {
  opacity: 1;
  pointer-events: auto;
}

.identitypart__site {
  align-items: flex-start;
  display: var(--identity-display);
  flex-wrap: wrap;
  margin-left: var(--identity-margin);
  min-height: var(--navbar-height);
  padding: var(--identity-titles-padding);
  position: relative;
}
.contentpart .identitypart__site {
  background-color: var(--color-white);
}
.contentpart .identitypart__site--branding {
  padding-top: 0;
}

.identitypart__logos {
  flex: none;
  margin: var(--identity-titles-margin);
}
.menupart .identitypart__logos--withtitle {
  margin-bottom: var(--identity-branding-logo-margin-vert);
}
.contentpart .identitypart__logos {
  margin-right: var(--identity-branding-logo-margin-hor);
  margin-top: var(--identity-branding-logo-margin-vert);
}

.identitypart__logo {
  display: var(--logo-display);
  flex: none;
  justify-content: center;
}
.identitypart__logo img {
  display: block;
}
.identitypart__logo--ut {
  background-color: var(--color-black);
  height: var(--navbar-height);
  width: var(--menu-width);
}
html:not(.utwente--sitetitlebar) .contentpart .identitypart__logo--ut {
  margin-left: var(--navbar-height);
}
.identitypart__logo--ut img {
  width: 225px;
}
.identitypart__logo--institute {
  background-image: var(--identity-institute-logo-url);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  line-height: 1px;
}
.identitypart__logo--itc {
  display: inline-block;
  line-height: 0;
  margin-top: var(--identity-itc-logo-top);
}
.identitypart__logo--itc img {
  width: var(--identity-itc-logo-width);
}
.contentpart .identitypart__site--branding .identitypart__logo img {
  max-height: calc(2 * var(--navbar-height) - 2 * var(--identity-branding-logo-margin-vert));
  width: auto;
}
.menupart .identitypart__site--branding .identitypart__logo {
  justify-content: left;
}
.menupart .identitypart__site--branding .identitypart__logo img {
  height: auto;
  max-width: calc(var(--menu-width) - 2 * var(--identity-branding-logo-margin-hor));
}
.contentpart .identitypart__logo--institute-logo, .menupart .identitypart__logo--institute-logo-stacked {
  display: inline-block;
}
.menupart .identitypart__logo--institute-logo, .contentpart .identitypart__logo--institute-logo-stacked {
  display: none;
}

.identitypart__titles {
  align-items: center;
  display: flex;
  flex: none;
  line-height: var(--identity-titles-lineheight);
  margin: var(--identity-titles-margin);
  max-width: 100%;
}
.contentpart .identitypart__titles {
  margin-right: var(--identity-branding-logo-margin-hor);
  margin-top: calc(var(--navbar-height) - var(--identity-branding-logo-margin-vert) - var(--identity-titles-lineheight));
}

.identitypart__title {
  color: var(--color-black);
  font-size: 23px;
  font-weight: var(--weight-bold-condensed);
  text-decoration: none;
  text-transform: uppercase;
}

.menupart__topmenu {
  border-bottom: 1px solid var(--color-grey-lines);
  background-color: var(--color-white);
  display: var(--menu-topmenu-display);
  flex: none;
  flex-direction: row;
  height: calc(var(--navbar-height) + 1px);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}
.menupart__topmenu .contentpart__topmenuitem,
.menupart__topmenu .contentpart__topmenuitem + .contentpart__topmenuitem {
  background-color: var(--color-grey-lighter);
  border-left: 1px solid var(--color-grey-lines);
  margin-left: 0;
  position: static;
}
.menupart__topmenu .contentpart__topmenuitem::after,
.menupart__topmenu .contentpart__topmenuitem + .contentpart__topmenuitem::after {
  display: none;
}
.menupart__topmenu .contentpart__topmenuitem {
  display: var(--menu-topmenu-items-display);
  transition: background-color var(--menu-transition-duration);
  width: calc(var(--navbar-height) + 1px);
}
.menupart__topmenu .contentpart__topmenuitem--menuopen {
  background: var(--color-white);
}
.menupart__topmenu .contentpart__topmenuitem:last-child {
  border-right: 1px solid var(--color-grey-lines);
  width: calc(var(--navbar-height) + 2px);
}
.menupart__topmenu .contentpart__topsubmenu {
  height: calc(100 * var(--vh, 1vh) - var(--navbar-height) - 1px);
  left: 0;
  position: fixed;
  right: 0;
  top: calc(var(--navbar-height) + 1px);
  width: auto;
}

.menupart__title {
  flex: none;
  font-size: 18px;
  font-weight: var(--weight-bold-condensed);
  line-height: var(--navbar-height);
  text-align: center;
  text-transform: uppercase;
  width: calc(var(--menu-width) - 3 * var(--navbar-height) - 4px);
}

.menupart__topmenu-spacer {
  background-color: var(--color-grey-lighter);
  display: var(--menu-topmenu-items-display);
  flex: 1;
  overflow: hidden;
  position: relative;
}
.menupart__topmenu-spacer::before {
  background: var(--color-grey-lines);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.menupart__menu {
  flex: 1;
  padding-top: calc(var(--menu-padding) - var(--menu-item-height) / 2);
  position: relative;
  z-index: 1;
}
.menupart__menu--modal::after {
  bottom: 0;
  content: "";
  cursor: wait;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.menupart__menu--busy::after {
  background: rgba(255, 255, 255, 0.5);
}
.menupart__topmenu--partners ~ .menupart__menu {
  margin-bottom: calc(var(--identity-branding-partner-navbar-height) + var(--menu-padding) / 2);
}

.menupart__submenu {
  background: var(--color-grey-lighter);
  border-bottom: 1px solid var(--color-grey-lines);
  padding-bottom: 10px;
}

.menupart__menuitem {
  --menu-item-fontsize: 16px;
  --submenu-line-width: 7px;
  color: var(--color-black);
  display: flex;
  flex-direction: row;
  font-size: var(--menu-item-fontsize);
  font-weight: var(--weight-normal-condensed);
  line-height: var(--menu-item-fontsize);
  padding: calc((var(--menu-item-height) - var(--menu-item-fontsize)) / 2) var(--menu-padding);
  position: relative;
  text-decoration: none;
  transition: background-color var(--menu-item-transition-duration);
}
.menupart__menuitem--parent {
  border-bottom: 1px solid var(--color-grey-light);
  color: var(--color-grey-dark);
  transition: border-color var(--menu-item-transition-duration);
}
.menupart--submenuopened .menupart__menuitem--parent {
  border-color: var(--color-grey-lines);
}
.menupart__menuitem--selected {
  font-weight: var(--weight-bold-condensed);
}
.menupart__menuitem--current::before {
  background: var(--theme-color);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
}
.menupart__menuitem:hover {
  background-color: var(--color-grey-lighter);
}
.menupart__menuitem--opened {
  background: var(--color-grey-lighter);
  border-top: 1px solid var(--color-grey-lines);
  padding-top: calc((var(--menu-item-height) - var(--menu-item-fontsize)) / 2 - 1px);
}
.menupart__menuitem--opened:hover {
  background-color: var(--color-grey-light);
}
.menupart__submenu .menupart__menuitem {
  padding-left: calc(var(--menu-padding) + var(--menu-indentation));
}
.menupart__submenu .menupart__menuitem:hover {
  background-color: var(--color-grey-light);
}

.menupart__menuitem-icon {
  --submenu-icon-left: 5px;
  color: var(--color-grey-normal);
  display: inline-block;
  flex: none;
  height: var(--menu-item-height);
  left: var(--submenu-icon-left);
  line-height: var(--menu-item-height);
  position: absolute;
  text-align: center;
  top: 0;
  transition: color var(--menu-item-transition-duration);
  width: var(--menu-item-height);
}
.menupart__menuitem-icon--extra {
  padding-top: 4px;
}
.menupart__menuitem-icon--closed {
  display: none;
}
.menupart__menuitem:not(.menupart__menuitem--opened) > .menupart__menuitem-icon--closed {
  display: inline-block;
}
.menupart__menuitem-icon--opened {
  color: var(--color-black);
  display: none;
}
.menupart__menuitem--opened > .menupart__menuitem-icon--opened {
  display: inline-block;
}
.menupart__submenu .menupart__menuitem-icon {
  left: calc(var(--submenu-icon-left) + var(--menu-indentation) - var(--submenu-line-width));
}
.menupart__menuitem-icon[class$="-16px"] {
  font-size: 16px;
}
.menupart__menuitem:hover .menupart__menuitem-icon {
  color: var(--color-black);
}

.menupart__menuitem-text {
  flex: 1;
}

.contentpart__navbar-container {
  pointer-events: none;
  position: var(--navbar-position);
  top: 0;
  width: 100%;
  z-index: var(--zindex-navbar);
}

.contentpart__navbar-block {
  display: flex;
  flex-direction: var(--navbar-flex-direction);
  pointer-events: auto;
  position: sticky;
  top: 0;
}
html.ut--nonbranding .contentpart__navbar-block {
  background: var(--navbar-background-color);
}

.contentpart__navbar {
  background-color: var(--navbar-background-color);
  color: var(--navbar-color);
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  height: var(--navbar-height);
  padding: var(--navbar-padding);
}

.contentpart__hamburger-container {
  left: env(safe-area-inset-left);
  pointer-events: none;
  position: absolute;
  top: var(--hamburger-top);
}

.contentpart__hamburger {
  background-color: var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  display: var(--hamburger-display);
  height: var(--navbar-height);
  pointer-events: auto;
  position: sticky;
  text-align: center;
  top: 0;
  width: var(--navbar-height);
  z-index: var(--zindex-menu);
}
html.utwente--sitetitlebar .contentpart__hamburger {
  background-color: var(--theme-color-cta);
  color: var(--color-white);
  transition: background-color var(--menu-item-transition-duration);
}
html.utwente--sitetitlebar .contentpart__hamburger:hover {
  background-color: var(--theme-color-cta-hover);
}

.contentpart__hamburger-open,
.contentpart__hamburger-close {
  font-size: 16px;
  left: calc((var(--navbar-height) - 16px) / 2);
  position: absolute;
  top: calc((var(--navbar-height) - 16px) / 2);
  transition: opacity var(--menu-transition-duration);
}

.contentpart__hamburger-open {
  opacity: 1;
}
html.utwente--menuopen .contentpart__hamburger-open {
  opacity: 0;
}

.contentpart__hamburger-close {
  opacity: 0;
}
html.utwente--menuopen .contentpart__hamburger-close {
  opacity: 1;
}

.contentpart__navbar-crumbs {
  display: var(--breadcrumbs-display);
  flex: 1;
  position: relative;
}

.contentpart__breadcrumblist {
  align-content: center;
  color: var(--breadcrumbs-color);
  display: var(--breadcrumbs-display);
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 12px;
  height: 100%;
  list-style-type: none;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: var(--breadcrumbs-padding);
  white-space: nowrap;
}
.contentpart__breadcrumblist--hide {
  display: none;
}
.contentpart__breadcrumblist--measure {
  flex-wrap: nowrap;
  max-width: none;
  position: absolute;
}
.contentpart__breadcrumblist a {
  color: inherit;
  font-weight: var(--weight-bold);
  text-decoration: none;
}
.contentpart__breadcrumblist a:hover {
  text-decoration: underline;
}

.contentpart__breadcrumbitem {
  display: inline-block;
  flex: none;
  line-height: 20px;
}
.contentpart__breadcrumblist--shorten .contentpart__breadcrumbitem--hide {
  display: none;
}
.contentpart__breadcrumbitem--ellipsis {
  cursor: pointer;
  display: none;
  order: -1;
}
.contentpart__breadcrumblist--measure .contentpart__breadcrumbitem--ellipsis, .contentpart__breadcrumblist--shorten .contentpart__breadcrumbitem--ellipsis {
  display: inline-block;
}
.contentpart__breadcrumbitem + .contentpart__breadcrumbitem:not(.contentpart__breadcrumbitem--ellipsis)::before {
  content: "/";
  display: inline-block;
  font-weight: var(--weight-normal);
  padding: 0 8px;
}

.contentpart__topmenu {
  display: var(--navbar-menu-display);
  flex: none;
}

.contentpart__topmenuitem {
  cursor: pointer;
  display: inline-block;
  line-height: var(--navbar-height);
  padding-left: calc(var(--navbar-height) / 2);
  padding-right: calc(var(--navbar-height) / 2);
  position: relative;
  text-align: center;
  text-decoration: underline;
  transition: background-color var(--menu-transition-duration), color var(--menu-transition-duration);
}
.contentpart__topmenuitem + .contentpart__topmenuitem {
  margin-left: 1px;
}
.contentpart__topmenuitem + .contentpart__topmenuitem::before {
  background: var(--navbar-menu-border-color);
  bottom: var(--navbar-menu-border-padding);
  content: "";
  display: block;
  left: -1px;
  position: absolute;
  top: var(--navbar-menu-border-padding);
  width: 1px;
}
.contentpart__topmenuitem--menuopen {
  background: var(--color-white);
  color: var(--color-black);
}
.contentpart__topmenuitem--partners {
  background: transparent;
  color: var(--color-grey-dark);
  font-size: 12px;
  line-height: var(--identity-branding-partner-navbar-height);
  vertical-align: top;
}
.menupart__topmenu .contentpart__topmenuitem--partners {
  border-top: 1px solid var(--color-grey-lines);
  left: 0;
  position: fixed;
  top: calc(100 * var(--vh, 1vh) - var(--identity-branding-partner-navbar-height) - 1px);
  width: var(--menu-width);
}

.contentpart a.contentpart__topmenuitem {
  color: var(--navbar-color);
}

.contentpart__topmenuitem--icon {
  font-size: 16px;
  padding: 0;
  text-decoration: none;
  width: var(--navbar-height);
}

.contentpart__topmenuitem--label {
  align-items: flex-end;
  color: var(--color-grey-normal);
  display: flex;
  font-size: 12px;
  height: calc(var(--navbar-height) - 10px);
  justify-content: flex-end;
  line-height: 12px;
  position: absolute;
  text-transform: uppercase;
  transition: opacity var(--menu-transition-duration);
  width: calc(var(--navbar-height) - 10px);
}
.contentpart__topmenuitem--menuopen .contentpart__topmenuitem--label {
  opacity: 0;
}

html.searchsheet--open .contentpart__topmenuitem--search {
  background: var(--color-white);
  color: var(--color-black);
}

.contentpart__topmenuitem--search-open,
.contentpart__topmenuitem--search-close {
  position: relative;
  transition: opacity var(--menu-transition-duration);
}

.contentpart__topmenuitem--search-open {
  left: 8px;
  opacity: 1;
}
html.searchsheet--open .contentpart__topmenuitem--search-open {
  opacity: 0;
}

.contentpart__topmenuitem--search-close {
  opacity: 0;
  left: -8px;
}
html.searchsheet--open .contentpart__topmenuitem--search-close {
  opacity: 1;
}

.contentpart__topsubmenu {
  background: var(--color-white);
  box-shadow: 0 25px 20px -20px rgba(0, 0, 0, 0.3);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  opacity: 0;
  padding: var(--navbar-menu-padding) 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  text-align: left;
  top: var(--navbar-height);
  transition: opacity var(--menu-transition-duration);
  min-width: var(--navbar-menu-width);
  z-index: var(--zindex-popup);
}
.contentpart__topmenuitem--menuopen .contentpart__topsubmenu, .contentpart__topmenuitem--menuopenfortesting .contentpart__topsubmenu {
  opacity: 1;
  pointer-events: auto;
}
.contentpart__topsubmenu-secondary {
  background: var(--color-grey-lighter);
  border-top: 1px solid var(--color-grey-lines);
  flex: 1;
  margin-top: var(--navbar-menu-padding);
  padding: var(--navbar-menu-padding) 0 0;
  position: relative;
  z-index: 0;
}
.contentpart__topsubmenu-secondary::after {
  bottom: calc(0px - var(--navbar-menu-padding));
  background: var(--color-grey-lighter);
  content: "";
  display: block;
  height: var(--navbar-menu-padding);
  left: 0;
  position: absolute;
  right: 0;
  z-index: -2;
}

.contentpart__toptextitem, .contentpart__topsubitem {
  display: block;
  flex: none;
  font-weight: var(--weight-normal-condensed);
  line-height: 100%;
  padding: calc((var(--navbar-menu-item-height) - 15px) / 2) var(--navbar-menu-item-padding);
  position: relative;
  text-decoration: none;
}

.contentpart__toptextitem {
  max-width: var(--navbar-menu-width);
}

.contentpart__topsubitem {
  height: var(--navbar-menu-item-height);
  white-space: nowrap;
}
.contentpart__topsubitem--current {
  color: var(--color-black);
  font-weight: var(--weight-bold-condensed);
}
.contentpart__topsubitem::after {
  background: transparent;
  bottom: 0;
  content: "";
  display: block;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 0;
  transition: background var(--menu-transition-duration);
  z-index: -1;
}
.contentpart__topsubmenu-secondary .contentpart__topsubitem::after {
  left: 0;
  right: 0;
}
.contentpart__topsubitem:hover::after {
  background: var(--color-grey-light);
  color: var(--color-black);
}

.contentpart__topsubitem-icon {
  color: var(--color-grey-normal);
  position: absolute;
  right: var(--navbar-menu-item-padding);
  top: calc((var(--navbar-menu-item-height) - 20px) / 2);
}

.contentpart--topsubmenu__panel {
  border-top: 1px solid var(--color-grey-lines);
  background-color: var(--color-grey-lighter);
}

.contentpart--topsubmenu__info {
  padding: 0 var(--navbar-menu-item-padding);
  font-size: 12px;
  line-height: 21px;
  color: var(--color-black);
}

.partnermenu {
  background: var(--color-grey-light);
  overflow: auto;
  padding: 0;
  top: 0;
  width: var(--menu-width);
}

.partnermenu__navbar {
  background: var(--color-grey-light);
  cursor: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
}
.menupart__topmenu .partnermenu__navbar {
  justify-content: center;
  left: 0;
  position: fixed;
  top: calc(100 * var(--vh, 1vh) - var(--identity-branding-partner-navbar-height));
  width: var(--menu-width);
}

.partnermenu__close-text {
  color: var(--color-grey-dark);
  cursor: pointer;
  display: inline-block;
  flex: none;
  font-size: 12px;
  height: var(--identity-branding-partner-navbar-height);
  line-height: var(--identity-branding-partner-navbar-height);
  padding-left: calc(var(--navbar-height) / 2);
  padding-right: calc(var(--navbar-height) / 2);
  position: relative;
  text-decoration: underline;
}

.partnermenu__close-icon {
  background: var(--color-white);
  cursor: pointer;
  flex: none;
  font-size: 16px;
  height: var(--identity-branding-partner-navbar-height);
  line-height: var(--identity-branding-partner-navbar-height);
  text-align: center;
  width: var(--navbar-height);
}
.menupart__topmenu .partnermenu__close-icon {
  display: none;
}

.partnermenu__partners {
  background: var(--color-white);
  z-index: 1;
}
.menupart__topmenu .partnermenu__partners {
  padding-bottom: var(--identity-branding-partner-navbar-height);
}

.partnermenu__partner {
  padding: 20px;
  position: relative;
  z-index: 0;
}
.partnermenu__partner::after {
  background: var(--color-grey-light);
  bottom: 10px;
  content: "";
  display: block;
  left: 10px;
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: opacity var(--menu-transition-duration);
  z-index: -1;
}
.partnermenu__partner:hover::after {
  opacity: 1;
}
.partnermenu__partner + .partnermenu__partner {
  border-top: 1px solid var(--color-grey-lines);
}
.partnermenu__partner img {
  display: block;
  width: 100%;
}

.activities-loginpanel-email {
  margin-top: 20px;
  position: relative;
  display: flex;
  margin-left: calc(var(--navbar-menu-item-padding) - 10px);
  margin-right: calc(var(--navbar-menu-item-padding) - 10px);
  border: 1px solid var(--color-grey-normal);
  background-color: var(--color-white);
}

.activities-loginpanel-email input {
  width: 100%;
  height: 51px;
  padding: 9px;
  font-weight: var(--weight-normal-condensed);
  font-size: 15px;
  border: none;
  color: var(--color-black);
}
.activities-loginpanel-email input::placeholder {
  color: var(--color-grey-normal);
}

.activities-loginpanel-email button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  font: inherit;
  color: var(--color-grey-normal);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentpart__topmenuitem--custom-activitieslogin .contentpart__topsubmenu {
  padding-bottom: 0;
}
.contentpart__topmenuitem--custom-activitieslogin .contentpart--topsubmenu__panel {
  padding: var(--navbar-menu-padding) 0;
}
.contentpart__topmenuitem--custom-activitieslogin .contentpart--topsubmenu__panel .contentpart__topsubitem {
  font-weight: var(--weight-normal-condensed);
}
.contentpart__topmenuitem--custom-activitieslogin .activities-login-success {
  display: none;
}
.contentpart__topmenuitem--custom-activitieslogin.mail-success .activities-loginpanel {
  display: none;
}
.contentpart__topmenuitem--custom-activitieslogin.mail-success .activities-login-success {
  display: block;
}