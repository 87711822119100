/* New dialog https://projects.invisionapp.com/d/main/#/console/20572278/458190768/inspect

   The box normally needs to be
   - centered
   - stay 30px away from the top/bottom
   - but the dialog bottom should touch the bottom if resized
*/
.ut-dialog {
  padding: 75px;
  overflow: auto;
  position: relative;
}
.ut-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ut-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.ut-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.ut-dialog__modalbg.ut-dialog--expanded {
  align-items: end;
}
.ut-dialog__modalbg.ut-dialog--expanded .ut-dialog__holder {
  height: calc(100vh - 30px);
  max-height: calc(100vh - 30px);
}
.ut-dialog__holder {
  background-color: var(--color-white);
  border: 1px solid #999;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
  min-width: 200px;
  max-width: min(920px, 100vw - 60px);
  max-height: calc(100vh - 60px);
  overflow: visible !important;
  transform: scale(0, 0);
  transition: transform 0.5s;
  display: inline-flex;
  flex-direction: column;
}
@media (max-width: 320px) {
  .ut-dialog__holder {
    max-width: 100vw;
  }
}
.ut-dialog__closeicon {
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgb(170, 172, 174);
  color: var(--color-white);
  text-align: center;
  transition: background 150ms;
}
.ut-dialog__closeicon:hover {
  background: var(--color-grey-dark);
}
.ut-dialog__header:not(:empty) {
  padding: 40px 145px 40px 75px;
  border-bottom: 1px solid var(--color-grey-lines);
  flex: 0 0 0;
}
.ut-dialog__header:not(:empty) h2 {
  margin: 0;
}
@media (max-width: 749px) {
  .ut-dialog__header:not(:empty) {
    padding-left: 50px;
    padding-right: 50px;
  }
  .ut-dialog__header:not(:empty).ps--active-y {
    padding-right: 65px;
  }
}
@media (max-width: 320px) {
  .ut-dialog__header:not(:empty) {
    padding-left: 30px;
    padding-right: 30px;
  }
  .ut-dialog__header:not(:empty).ps--active-y {
    padding-right: 45px;
  }
}
.ut-dialog__header:not(:empty) + .ut-dialog {
  padding-top: 40px;
}
.ut-dialog.ps--active-y {
  padding-right: 110px;
}
.ut-dialog__header:empty + .ut-dialog > *:first-child {
  margin-top: 0;
}
@media (max-width: 749px) {
  .ut-dialog {
    padding-left: 50px;
    padding-right: 50px;
  }
  .ut-dialog.ps--active-y {
    padding-right: 65px;
  }
}
@media (max-width: 320px) {
  .ut-dialog {
    padding-left: 30px;
    padding-right: 30px;
  }
  .ut-dialog.ps--active-y {
    padding-right: 45px;
  }
}
.ut-dialog .wh-form__buttongroup {
  text-align: left;
}
.ut-dialog .wh-form__buttongroup button.wh-form__button {
  margin-left: 0;
  margin-right: 15px;
}
.ut-dialog.ps > .ps__rail-y {
  opacity: 0.6 !important;
  margin: 30px 0;
  left: auto !important;
}
.ut-dialog.ps > .ps__rail-y > .ps__thumb-y {
  background-color: var(--color-grey-dark);
  width: 8px !important;
}
@media (min-width: 751px) {
  .ut-dialog.ps > .ps__rail-y {
    background: rgb(240, 241, 242) !important;
    width: 60px !important;
  }
  .ut-dialog.ps > .ps__rail-y > .ps__thumb-y {
    right: 25px !important;
  }
}

.dialog__modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.5s;
}

.dialog__modal.aftershow {
  opacity: 1;
}

.dialog__modal.withbackground {
  background-color: var(--color-black);
}

@media (max-width: 900px) {
  .dialog__modal.withbackground.closeontop {
    padding: 40px 0 20px;
  }
  .dialog__modal.withbackground.closeontop > .close {
    top: 10px;
  }
}
@media (max-width: 600px) {
  .dialog__modal.withbackground.closeontop {
    padding-bottom: 10px;
  }
}
@media (max-width: 400px) {
  .dialog__modal.withbackground.closeontop {
    padding-bottom: 0;
  }
}
.dialog__modal.withbackground:after { /* extra overlay on top of background image*/
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.dialog__modal:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}

.dialog__body {
  background-color: var(--color-white);
  padding: 25px;
  border: 1px solid #999;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
  min-width: 200px;
  max-width: 600px;
  transform: scale(0, 0);
  transition: transform 0.5s;
}

.dialog__body {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  max-width: calc(100% - 40px);
}

.dialog__modal.withbackground .dialog__body {
  border: 0 none;
}

.dialog__modal.nopadding .dialog__body {
  padding: 0;
}

.dialog__modal.aftershow .dialog__body,
.ut-dialog--aftershow {
  transform: scale(1, 1);
}

@media (max-width: 600px) {
  .dialog__body {
    max-width: calc(100% - 20px);
  }
}
@media (max-width: 400px) {
  .dialog__body {
    max-width: 100%;
    min-width: calc(100% - 30px);
  }
}
.dialog__modal.dialog--small .dialog__body {
  max-width: 350px;
}

.dialog__modal .close {
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--color-white);
  color: var(--color-black);
  text-align: center;
  transition: background 150ms, transform 150ms;
  cursor: pointer;
}
.dialog__modal .close:hover {
  background: #DDDDDD;
}

.dialog__modal .close:hover {
  transform: scale(1.1, 1.1);
}

.dialog__modal .dialog__body .close {
  font-size: 18px;
}

.dialog__body > .title {
  font-size: 18px;
  font-family: inherit;
  font-weight: 700;
  color: #222222;
  text-transform: uppercase;
  margin: 0 0 20px;
  padding-right: 35px; /* space for close btn */
}

.dialog__content {
  padding-top: 15px;
}

.dialog__body > .title + .dialog__content,
.dialog__modal.nopadding .dialog__content {
  padding-top: 0;
}

.dialog__content p {
  margin-top: 15px;
}

.dialog__body h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

/* specific for F&F site: */
.dialog__content .errorlist {
  color: #cd202c;
}

.dialog__content .image {
  margin-top: 4px;
  border: 1px solid #ccc;
  width: 100%;
}

.dialog__content .wh-styledinput label {
  display: block;
  padding-bottom: 4px;
}

.dialog__content .wh-styledinput input[type=email] {
  width: 100%;
}

.dialog__content .wh-form__buttongroup {
  text-align: right;
  margin: 15px 0 0;
}

.dialog__content .busy__message,
.dialog__content .dialog--busy button {
  display: none;
}

.dialog__content .dialog--busy .busy__message {
  display: block;
}

.dialog__content .dialog--busy > * {
  opacity: 0.6;
}

/* WS2021 <dialog>
    https://www.figma.com/design/naS5y6bvlQxYkW4P5vWjA8/Cookie-melding?node-id=5-3321&t=277jY5ZTvpc65EB3-0
*/
dialog {
  width: 920px;
  max-width: 920px;
  border: 0;
  padding: 50px;
}
@media (max-width: 1079px) {
  dialog {
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
  }
}
@media (max-width: 749px) {
  dialog {
    width: 100%;
    max-width: 100%;
    padding: 20px;
  }
}