/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
html.page--blockspage {
  --header-sliderdots-height: calc(var(--tabstrip-height) + 40px);
  --main-padding-bottom: 92px;
}
html.page--blockspage .contentpart__header .whflattabs-background,
html.page--blockspage .contentpart__header .whflattabs {
  margin-top: 10px;
  grid-area: title-below;
  align-self: end;
  z-index: var(--zindex-title);
}
@media (max-width: 480px) {
  html.page--blockspage {
    --header-grid-rows: [top] auto [header-top] 1fr [title-above] calc(min(var(--header-background-height), 50%)) [title-below] min-content [bottom];
  }
}

.contentpart__main > .widget--blockswidget:first-child {
  margin-top: 48px;
}

.contentpart__header--universe .whflattabs-background,
.contentpart__header:not(.contentpart__header--photos) .whflattabs-background {
  display: none;
}

.contentpart__header--photos .whflattabs-background {
  grid-column: left/right !important;
  width: calc(100% - 2 * var(--header-spacing));
  max-width: var(--header-photos-width);
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
  height: var(--tabstrip-height);
}

@media (max-width: 749px) {
  html.page--blockspage .contentpart__title-container,
  html.page--blockspage .contentpart__header .blockswidget__form {
    grid-column: var(--main-12col-layout);
  }
  .widget--blockswidget {
    grid-column: 1/-1 !important;
  }
  .blockswidget__item {
    display: grid;
    width: 100%;
    column-gap: var(--main-grid-gap);
    grid-column-gap: var(--main-grid-gap);
    grid-template-columns: var(--main-grid);
  }
  .blockswidget__item > * {
    grid-column: var(--main-12col-layout);
  }
}
@media (min-width: 750px) and (max-width: 1079px) {
  a.blockswidget__item {
    display: block;
  }
  .widget--blockswidget.widget--blockswidget,
  html.page--blockspage .contentpart__title-container,
  html.page--blockspage .contentpart__header .blockswidget__form {
    grid-column: 3/span 10;
  }
}
@media (min-width: 1080px) {
  a.blockswidget__item {
    display: block;
  }
  .widget--blockswidget.widget--blockswidget,
  html.page--blockspage .contentpart__title-container,
  html.page--blockspage .contentpart__header .blockswidget__form {
    grid-column: var(--main-12col-layout);
  }
}
a.blockswidget__item {
  text-decoration: none;
  color: inherit;
}

.widget--blockswidget {
  padding-bottom: 19px;
  margin-bottom: 92px;
}

.widget--blockswidget .whflattabs {
  margin-bottom: 50px;
}

.blockswidget__item--hidden.blockswidget__item--hidden {
  display: none;
}

.blockswidget__item__image {
  position: relative;
}

.blockswidget__item__image img {
  display: block;
  width: 100%;
  width: calc(100% + 0.1px);
  object-fit: cover;
}

.blockswidget__item__when_label {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  min-height: 36px;
  padding: 13px 15px 10px 15px;
  font: 700 18px/20px "Univers Next W02";
  font-weight: 500;
  text-transform: uppercase;
  background-color: #1E2328;
  color: #FFFFFF;
}

.blockswidget__item__when_label--multiline {
  min-height: 70px;
}

.blockswidget__item__when_label > *:first-child {
  margin-top: 0;
}

.blockswidget__item__when_label > *:last-child {
  margin-bottom: 0;
}

.blockswidget__item__when_label b {
  font-weight: 700;
}

.blockswidget__item__meta {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.blockswidget__item__title {
  margin-top: 16px;
  font: 700 18px/20px "Univers Next W02";
  margin-bottom: 12px;
  text-transform: uppercase;
}

.blockswidget__item__description {
  font: 15px/25px "Univers Next W02";
}

.blockswidget__item__description--long {
  display: none;
}

@media (min-width: 800px) {
  .blockswidget__item:first-child .blockswidget__item__title {
    margin-top: -3px;
  }
}
.blockswidget__item:first-child .blockswidget__item__description {
  display: none;
}
.blockswidget__item:first-child .blockswidget__item__description--long {
  display: block;
}

.blockswidget__item__tags {
  margin-top: auto;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.blockswidget__item__preregistration {
  display: flex;
  align-items: center;
  height: 37px;
  border-radius: 3px;
  font: 13px/20px "Univers Next W02";
  padding: 0 20px;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}

.blockswidget__item__tag {
  display: flex;
  align-items: center;
  height: 37px;
  border-radius: 3px;
  font: 13px/20px "Univers Next W02";
  padding: 0 20px;
  background-color: #F7F8F8;
  border: 1px solid #F0F1F2;
  color: #5A5E62;
}

.blockswidget__item__tag > * {
  margin: 0;
  color: #5A5E62;
  font: 13px/20px "Univers Next W02";
}

.blockswidget__content {
  contain: paint;
}

@media (max-width: 800px-1px) {
  .blockswidget__item {
    position: relative;
  }
  .blockswidget__item::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    border-bottom: 1px solid #D8D8D8;
  }
  .blockswidget__item + .blockswidget__item,
  .blockswidget__bigline + .blockswidget__item {
    margin-top: 61px;
  }
}
@media (min-width: 800px) {
  .blockswidget__content {
    display: grid;
  }
  a.blockswidget__item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .blockswidget__item:first-child {
    grid-column: 1/span 2;
    grid-row: 1;
    margin-bottom: 30px;
  }
  .blockswidget__item:first-child .blockswidget__item__title {
    margin-top: 0;
    font: 700 26px/26px "Univers Next W02";
  }
  .blockswidget__bigline {
    grid-column: 1/span 2;
    grid-row: 1/span 2;
    position: relative;
  }
  .blockswidget__item::before,
  .blockswidget__bigline::before {
    content: "";
    position: absolute;
    height: 100%;
    border-right: 1px solid #D8D8D8;
  }
  .blockswidget__item::after,
  .blockswidget__bigline::after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
  }
  /*
    // Remove the vertical line at the right of the items on the last column
      .blockswidget__content > *:nth-child(1)::before // last item of first row
    , .blockswidget__content > *:nth-child(2n+4)::before // last item of first row
    {
      display: none;
    }
  */
  .blockswidget__content > *:nth-child(2n+1):not(:last-child)::after {
    left: 0;
    right: -49px;
    width: auto;
  }
}
@media (min-width: 800px) and (max-width: 1000px-1px) {
  .blockswidget__item:first-child .blockswidget__item__title {
    margin-top: 25px;
  }
  .blockswidget__bigline {
    display: none;
  }
  .blockswidget__content {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 39px;
    row-gap: 39px;
  }
  .blockswidget__item::before,
  .blockswidget__bigline::before {
    right: -19.5px;
  }
  .blockswidget__item::after,
  .blockswidget__bigline::after {
    bottom: -19.5px;
  }
}
@media (min-width: 1000px) {
  .blockswidget__item:first-child {
    display: contents;
  }
  .blockswidget__item:first-child .blockswidget__item__image {
    grid-column: 1/span 2;
    grid-row: 1;
  }
  .blockswidget__item:first-child .blockswidget__item__image img {
    height: 100%;
  }
  .blockswidget__item:first-child .blockswidget__item__meta {
    grid-column: 1/span 2;
    grid-row: 2;
    z-index: 1; /* allow clickable content to be over the .blockswidget__bigline which serves as anchor/hitarea in this layout mode */
    padding-left: 15px;
  }
  .blockswidget__item:first-child::before,
  .blockswidget__item:first-child::after {
    display: none;
  }
}
@media (min-width: 1000px) and (max-width: 1200px-1px) {
  .blockswidget__content {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 39px;
    row-gap: 39px;
  }
  .blockswidget__item::before,
  .blockswidget__bigline::before {
    right: -19.5px;
  }
  .blockswidget__item::after,
  .blockswidget__bigline::after {
    bottom: -19.5px;
  }
  .blockswidget__content > *:nth-child(3n+5):not(:last-child)::after,
  .blockswidget__content > *:nth-child(3n+6):not(:last-child)::after {
    left: 0;
    right: -49px;
    width: auto;
  }
  /*
    // Remove the vertical line at the right of the items on the last column
      .blockswidget__content > *:nth-child(3)::before // last item of first row
    , .blockswidget__content > *:nth-child(4)::before // last item of first row
    , .blockswidget__content > *:nth-child(3n+7)::before // last item of first row
    {
      display: none;
    }
  */
}
@media (min-width: 1200px) {
  .blockswidget__content {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 49px;
    row-gap: 39px;
  }
  .blockswidget__item::before,
  .blockswidget__bigline::before {
    right: -24.5px;
  }
  .blockswidget__item::after,
  .blockswidget__bigline::after {
    bottom: -19.5px;
  }
  .blockswidget__content > *:nth-child(3):not(:last-child)::after,
  .blockswidget__content > *:nth-child(5):not(:last-child)::after,
  .blockswidget__content > *:nth-child(4n+7):not(:last-child)::after,
  .blockswidget__content > *:nth-child(4n+8):not(:last-child)::after,
  .blockswidget__content > *:nth-child(4n+9):not(:last-child)::after {
    left: 0;
    right: -49px;
    width: auto;
  }
  /*
    // NOTE: in the future we may get an :nth-last-col() pseudoselector which we can use
      .blockswidget__content > *:nth-child(4)::before // last item of first row
    , .blockswidget__content > *:nth-child(6)::before
    , .blockswidget__content > *:nth-child(4n+6)::before
    {
      display: none;
    }
  */
}