@font-face {
  font-family: 'UT-iconfont';
  src: url('ut-iconfont/ut-iconfont.ttf?7177yy') format('truetype'),
       url('ut-iconfont/ut-iconfont.woff?7177yy') format('woff'),
       url('ut-iconfont/ut-iconfont.svg?7177yy#UT-iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="UT-icon-"], [class*=" UT-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'UT-iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.UT-icon-UT_icon_01_bachelor-24px:before {
  content: "\e900";
}
.UT-icon-UT_icon_02_master-24px:before {
  content: "\e901";
}
.UT-icon-UT_icon_03_phd-24px:before {
  content: "\e902";
}
.UT-icon-UT_icon_04_pld-24px:before {
  content: "\e903";
}
.UT-icon-UT_icon_05_executive-education-24px:before {
  content: "\e904";
}
.UT-icon-UT_icon_06_in-company-24px:before {
  content: "\e905";
}
.UT-icon-UT_icon_07_leergangen-24px:before {
  content: "\e906";
}
.UT-icon-UT_icon_08_masterclasses-24px:before {
  content: "\e907";
}
.UT-icon-UT_icon_09_courses-24px:before {
  content: "\e908";
}
.UT-icon-UT_icon_10_symposia-24px:before {
  content: "\e909";
}
.UT-icon-UT_icon_11_events-24px:before {
  content: "\e90a";
}
.UT-icon-UT_icon_12_calendar-24px:before {
  content: "\e90b";
}
.UT-icon-UT_icon_13_phone-24px:before {
  content: "\e90c";
}
.UT-icon-UT_icon_14_fax-24px:before {
  content: "\e90d";
}
.UT-icon-UT_icon_15_mail-24px:before {
  content: "\e90e";
}
.UT-icon-UT_icon_16_pointer-24px:before {
  content: "\e90f";
}
.UT-icon-UT_icon_17_download-24px:before {
  content: "\e910";
}
.UT-icon-UT_icon_18_upload-24px:before {
  content: "\e911";
}
.UT-icon-UT_icon_19_paperclip-24px:before {
  content: "\e912";
}
.UT-icon-UT_icon_20_file-word-24px:before {
  content: "\e913";
}
.UT-icon-UT_icon_21_file-excel-24px:before {
  content: "\e914";
}
.UT-icon-UT_icon_22_file-pdf-24px:before {
  content: "\e915";
}
.UT-icon-UT_icon_23_file-presentatie-24px:before {
  content: "\e916";
}
.UT-icon-UT_icon_24_certificaat-24px:before {
  content: "\e917";
}
.UT-icon-UT_icon_25_diensten-24px:before {
  content: "\e918";
}
.UT-icon-UT_icon_26_studiekeuze-info-centrum-24px:before {
  content: "\e919";
}
.UT-icon-UT_icon_27_nieuws-24px:before {
  content: "\e91a";
}
.UT-icon-UT_icon_28_questionmark-24px:before {
  content: "\e91b";
}
.UT-icon-UT_icon_29_trophy-16px:before {
  content: "\e91c";
}
.UT-icon-UT_icon_30_study-route-24px:before {
  content: "\e91d";
}
.UT-icon-UT_icon_31_binding-study-advice-24px:before {
  content: "\e91e";
}
.UT-icon-UT_icon_32_price-tag-24px:before {
  content: "\e91f";
}
.UT-icon-UT_icon_33_admission-requirements-24px:before {
  content: "\e920";
}
.UT-icon-UT_icon_34_exchange-programs-24px:before {
  content: "\e921";
}
.UT-icon-UT_icon_35_accreditation-24px:before {
  content: "\e922";
}
.UT-icon-UT_icon_36_facebook-24px:before {
  content: "\e923";
}
.UT-icon-UT_icon_37_twitter-24px:before {
  content: "\e924";
}
.UT-icon-UT_icon_38_linkedin-24px:before {
  content: "\e925";
}
.UT-icon-UT_icon_39_high-tea-24px:before {
  content: "\e926";
}
.UT-icon-UT_icon_40_avatar-24px:before {
  content: "\e927";
}
.UT-icon-UT_icon_41_chat-24px:before {
  content: "\e928";
}
.UT-icon-UT_icon_42_cookie-24px:before {
  content: "\e929";
}
.UT-icon-UT_icon_43_arrow-left-16px:before {
  content: "\e92a";
}
.UT-icon-UT_icon_44_arrow-right-16px:before {
  content: "\e92b";
}
.UT-icon-UT_icon_45_arrow-up-16px:before {
  content: "\e92c";
}
.UT-icon-UT_icon_46_arrow-down-16px:before {
  content: "\e92d";
}
.UT-icon-UT_icon_47_cross-16px:before {
  content: "\e92e";
}
.UT-icon-UT_icon_48_plus-16px:before {
  content: "\e92f";
}
.UT-icon-UT_icon_49_min-16px:before {
  content: "\e930";
}
.UT-icon-UT_icon_50_check-16px:before {
  content: "\e931";
}
.UT-icon-UT_icon_51_arrow-left-round-16px:before {
  content: "\e932";
}
.UT-icon-UT_icon_52_arrow-right-round-16px:before {
  content: "\e933";
}
.UT-icon-UT_icon_53_arrow-up-round-16px:before {
  content: "\e934";
}
.UT-icon-UT_icon_54_arrow-down-round-16px:before {
  content: "\e935";
}
.UT-icon-UT_icon_55_cross-round-16px:before {
  content: "\e936";
}
.UT-icon-UT_icon_56_plus-round-16px:before {
  content: "\e937";
}
.UT-icon-UT_icon_57_min-round-16px:before {
  content: "\e938";
}
.UT-icon-UT_icon_58_info-round-16px:before {
  content: "\e939";
}
.UT-icon-UT_icon_59_questionmark-round-16px:before {
  content: "\e93a";
}
.UT-icon-UT_icon_60_current-students-round-16px:before {
  content: "\e93b";
}
.UT-icon-UT_icon_61_price-round-16px:before {
  content: "\e93c";
}
.UT-icon-UT_icon_62_play-round-16px:before {
  content: "\e93d";
}
.UT-icon-UT_icon_63_arrow-left-small-16px:before {
  content: "\e93e";
}
.UT-icon-UT_icon_64_arrow-right-small-16px:before {
  content: "\e93f";
}
.UT-icon-UT_icon_65_arrow-up-small-16px:before {
  content: "\e940";
}
.UT-icon-UT_icon_66_arrow-down-small-16px:before {
  content: "\e941";
}
.UT-icon-UT_icon_67_cross-small-16px:before {
  content: "\e942";
}
.UT-icon-UT_icon_68_plus-small-16px:before {
  content: "\e943";
}
.UT-icon-UT_icon_69_min-small-16px:before {
  content: "\e944";
}
.UT-icon-UT_icon_70_search-16px:before {
  content: "\e945";
}
.UT-icon-UT_icon_71_pointer-16px:before {
  content: "\e946";
}
.UT-icon-UT_icon_72_clock-16px:before {
  content: "\e947";
}
.UT-icon-UT_icon_73_fulltime-16px:before {
  content: "\e948";
}
.UT-icon-UT_icon_74_parttime-16px:before {
  content: "\e949";
}
.UT-icon-UT_icon_75_flag-16px:before {
  content: "\e94a";
}
.UT-icon-UT_icon_76_slider-dot-active-16px:before {
  content: "\e94b";
}
.UT-icon-UT_icon_77_sliderdot-16px:before {
  content: "\e94c";
}
.UT-icon-UT_icon_78_4years-16px:before {
  content: "\e94d";
}
.UT-icon-UT_icon_79_3years-16px:before {
  content: "\e94e";
}
.UT-icon-UT_icon_80_2years-16px:before {
  content: "\e94f";
}
.UT-icon-UT_icon_81_1years-16px:before {
  content: "\e950";
}
.UT-icon-UT_icon_82_play-16px:before {
  content: "\e951";
}
.UT-icon-UT_icon_83_logout-16px:before {
  content: "\e952";
}
.UT-icon-UT_icon_84_calendar-16px:before {
  content: "\e953";
}
.UT-icon-UT_icon_85_download-16px:before {
  content: "\e954";
}
.UT-icon-UT_icon_86_upload-16px:before {
  content: "\e955";
}
.UT-icon-UT_icon_87_event-16px:before {
  content: "\e956";
}
.UT-icon-UT_icon_88_expand-16px:before {
  content: "\e957";
}
.UT-icon-UT_icon_89_shrink-16px:before {
  content: "\e958";
}
.UT-icon-UT_icon_90_asterix-16px:before {
  content: "\e959";
}
.UT-icon-UT_icon_91_bachelor-16px:before {
  content: "\e95a";
}
.UT-icon-UT_icon_92_master-16px:before {
  content: "\e95b";
}
.UT-icon-UT_icon_93_education-16px:before {
  content: "\e95c";
}
.UT-icon-UT_icon_94_read-16px:before {
  content: "\e95d";
}
.UT-icon-UT_icon_95_tag-16px:before {
  content: "\e95e";
}
.UT-icon-UT_icon_96_webcam-16px:before {
  content: "\e95f";
}
.UT-icon-UT_icon_97_alumni-16px:before {
  content: "\e960";
}
.UT-icon-UT_icon_98_teacher-16px:before {
  content: "\e961";
}
.UT-icon-UT_icon_99_double-degree-24px:before {
  content: "\e962";
}
.UT-icon-UT_icon_100_joint-degree-24px:before {
  content: "\e963";
}
.UT-icon-UT_icon_101_special-programme-24px:before {
  content: "\e964";
}
.UT-icon-UT_icon_102_enter-24px:before {
  content: "\e965";
}
.UT-icon-UT_icon_103_menu-16px:before {
  content: "\e966";
}
.UT-icon-UT_icon_104_accreditation-16px:before {
  content: "\e967";
}
.UT-icon-UT_icon_105_admission-16px:before {
  content: "\e968";
}
.UT-icon-UT_icon_106_numerus-fixus-16px:before {
  content: "\e969";
}
.UT-icon-UT_icon_107_binding-study-advice-16px:before {
  content: "\e96a";
}
.UT-icon-UT_icon_108_search-24px:before {
  content: "\e96b";
}
.UT-icon-UT_icon_109_filter-24px:before {
  content: "\e96c";
}
.UT-icon-UT_icon_110_star-16px:before {
  content: "\e96d";
}
.UT-icon-UT_icon_111_heart-16px:before {
  content: "\e96e";
}
.UT-icon-UT_icon_112_settings-24px:before {
  content: "\e96f";
}
.UT-icon-UT_icon_113_honoursprogramme-24px:before {
  content: "\e970";
}
.UT-icon-UT_icon_114_person-16px:before {
  content: "\e971";
}
.UT-icon-UT_icon_115_pathdivider-24px:before {
  content: "\e972";
}
.UT-icon-UT_icon_116_filter-16px:before {
  content: "\e973";
}
.UT-icon-UT_icon_117_paperclip-64px:before {
  content: "\e974";
}
.UT-icon-UT_icon_118_flickr-24px:before {
  content: "\e975";
}
.UT-icon-UT_icon_119_google-24px:before {
  content: "\e976";
}
.UT-icon-UT_icon_120_rss-24px:before {
  content: "\e977";
}
.UT-icon-UT_icon_121_vimeo-24px:before {
  content: "\e978";
}
.UT-icon-UT_icon_122_youtube-24px:before {
  content: "\e979";
}
.UT-icon-UT_icon_123_home-16px:before {
  content: "\e97a";
}
.UT-icon-UT_icon_124_website-24px:before {
  content: "\e97b";
}
.UT-icon-UT_icon_125_check-24px:before {
  content: "\e97c";
}
.UT-icon-UT_icon_126_vcard-24px:before {
  content: "\e97d";
}
.UT-icon-UT_icon_127_thumb-building-64px:before {
  content: "\e981";
}
.UT-icon-UT_icon_128_no-thumb-64px:before {
  content: "\e982";
}
.UT-icon-UT_icon_129_facebook-16px:before {
  content: "\e983";
}
.UT-icon-UT_icon_130_issuu-24px:before {
  content: "\e984";
}
.UT-icon-UT_icon_131_instagram-24px:before {
  content: "\e985";
}
.UT-icon-UT_icons_132_snapchat-24px:before {
  content: "\e986";
}
.UT-icon-UT_icons_133_wechat-24px:before {
  content: "\e987";
}
.UT-icon-UT_icons_134_sina-weibo-24px:before {
  content: "\e988";
}
.UT-icon-UT_icons-135_pinterest-24px:before {
  content: "\e989";
}
.UT-icon-UT_icon_136_diploma-24px:before {
  content: "\e98a";
}
.UT-icon-UT_icon_137_postgraduate-diploma-24px:before {
  content: "\e98b";
}
.UT-icon-UT_icon_138_detail-view-16px:before {
  content: "\e9d2";
}
.UT-icon-UT_icon_139_list-view-16px:before {
  content: "\e9d3";
}
.UT-icon-UT_icon_140_ongoing-16px:before {
  content: "\e9d4";
}
.UT-icon-UT_icon_141_-check-16px:before {
  content: "\e9d5";
}
.UT-icon-UT_icon_142_guest-16px:before {
  content: "\e98c";
}
.UT-icon-UT_icon_143_company-24px:before {
  content: "\e98d";
}
.UT-icon-UT_icons-24px_144_1-years-24px:before {
  content: "\e98e";
}
.UT-icon-UT_icons-24px_145_2-years-24px:before {
  content: "\e98f";
}
.UT-icon-UT_icons-24px_146_3-years-24px:before {
  content: "\e990";
}
.UT-icon-UT_icons-24px_147_4-years-24px:before {
  content: "\e991";
}
.UT-icon-UT_icons-24px_148_fulltime-24px:before {
  content: "\e992";
}
.UT-icon-UT_icons-24px_149_parttime-24px:before {
  content: "\e993";
}
.UT-icon-UT_icons-24px_150_language-24px:before {
  content: "\e994";
}
.UT-icon-UT_icons-24px_151_deadline-24px:before {
  content: "\e995";
}
.UT-icon-UT_icons-24px_152_starttime-24px:before {
  content: "\e996";
}
.UT-icon-UT_icons-24px_153_ects-24px:before {
  content: "\e997";
}
.UT-icon-UT_icons-24px_154_accreditation-24px:before {
  content: "\e998";
}
.UT-icon-UT_icon_155_18months-24px:before {
  content: "\e999";
}
.UT-icon-UT_icons_156_clock-24px:before {
  content: "\e99a";
}
.UT-icon-UT_icons_157_alert-24px:before {
  content: "\e99b";
}
.UT-icon-UT_icons_158_studyfinder-16px:before {
  content: "\e99c";
}
.UT-icon-UT_icons_159_photo-24px:before {
  content: "\e99d";
}
.UT-icon-UT_icons_160_360-video-24px:before {
  content: "\e99e";
}
.UT-icon-UT_icons_161_video-24px:before {
  content: "\e99f";
}
.UT-icon-UT_icons_162_info-24px:before {
  content: "\e9a0";
}
.UT-icon-UT_icons_163_chatter-24px:before {
  content: "\e9a1";
}
.UT-icon-UT_icons_164_navigate-24px:before {
  content: "\e97e";
}
.UT-icon-UT_icons_165_filter-16px:before {
  content: "\e97f";
}
.UT-icon-UT_icons_166_9dot-16px:before {
  content: "\e980";
}
.UT-icon-UT_icons_167_star-16px:before {
  content: "\e9a2";
}
.UT-icon-UT_icons_168_skype-24px:before {
  content: "\e9a3";
}
.UT-icon-UT_icons_169_tiktok-24px:before {
  content: "\e9a4";
}
.UT-icon-UT_icons_170_arrow2-left-16px:before {
  content: "\e9a5";
}
.UT-icon-UT_icons_171_arrow2-right-16px:before {
  content: "\e9a6";
}
.UT-icon-UT_icons_172_arrow2-up-16px:before {
  content: "\e9a7";
}
.UT-icon-UT_icons_173_arrow2-down-16px:before {
  content: "\e9a8";
}
.UT-icon-UT_icons_174_lock-16px:before {
  content: "\e9a9";
}
.UT-icon-UT_icons_175_globe-16px:before {
  content: "\e9aa";
}
.UT-icon-UT_icons_176_grid-view-16px:before {
  content: "\e9ab";
}
.UT-icon-UT_icons_177_summary-view-16px:before {
  content: "\e9ac";
}
.UT-icon-UT_icons_178_questionmark-small-16px:before {
  content: "\e9ad";
}
.UT-icon-UT_icons_179_asterix-small-16px:before {
  content: "\e9ae";
}
.UT-icon-UT_icons_180_alarm-24px:before {
  content: "\e9af";
}
.UT-icon-UT_icons_181_qrcode-24px:before {
  content: "\e9b0";
}
.UT-icon-UT_icons_182_edit-24px:before {
  content: "\e9b1";
}
.UT-icon-UT_icons_183_trash-24px:before {
  content: "\e9b2";
}
.UT-icon-UT_icons_184_eye-24px:before {
  content: "\e9b3";
}
.UT-icon-UT_icons_185_list-24px:before {
  content: "\e9b4";
}
.UT-icon-UT_icons_186_numbered-list-24px:before {
  content: "\e9b5";
}
.UT-icon-UT_icons_187_link-24px:before {
  content: "\e9b6";
}
.UT-icon-UT_icons_188_bold-24px:before {
  content: "\e9b7";
}
.UT-icon-UT_icons_189_italic-24px:before {
  content: "\e9b8";
}
.UT-icon-UT_icons_190_mastodon-24px:before {
  content: "\e9b9";
}
.UT-icon-UT_icons_191_book-24px:before {
  content: "\e9ba";
}
.UT-icon-UT_icons-192_guestlabel-24px:before {
  content: "\e9bb";
}
.UT-icon-UT_icons-193_education-24px:before {
  content: "\e9bc";
}
.UT-icon-UT_icons-194_sport-24px:before {
  content: "\e9bd";
}
.UT-icon-UT_icons-195_mask-24px:before {
  content: "\e9be";
}
.UT-icon-UT_icons-196_backpack-24px:before {
  content: "\e9bf";
}
