/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
:root {
  --tabstrip-height: 52px;
}

.whflattabs--underline {
  --whflattabs-tab-sidepadding: 14px;
}

.whflattabs--tabs {
  --whflattabs-tab-sidepadding: 30px;
}

.whflattabs {
  display: flex;
  height: var(--tabstrip-height);
}

.whflattabs__tabs, .whflattabs__links {
  display: flex;
}

.whflattabs__tabs input {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

.whflattabs__tabs label,
.whflattabs__link {
  padding: 6px var(--whflattabs-tab-sidepadding) 0 var(--whflattabs-tab-sidepadding);
  font: var(--tabstrip-font);
  min-height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.whflattabs__tabs + .whflattabs__links {
  border-left: 1px solid #D8D8D8;
  margin-left: 15px;
  padding-left: 15px;
}

.whflattabs__link:first-child {
  margin-left: calc(var(--whflattabs-tab-sidepadding) * -1);
}

.whflattabs__tabs + .whflattabs__links .whflattabs__link:first-child {
  margin-left: 0;
}

.whflattabs__link {
  padding-bottom: 2px;
}

.whflattabs__tabs label {
  display: grid;
  -webkit-user-select: none;
  user-select: none;
}

.whflattabs__tabs label > * {
  grid-column: 1;
  grid-row: 1;
}

.whflattabs__tabs label:after {
  content: attr(data-title);
  grid-column: 1;
  grid-row: 1;
  font-weight: 600;
  visibility: hidden;
}

.whflattabs__tabs > input:focus-visible + label {
  background-color: #F0F0F0;
}

.whflattabs__tabs input:checked + label {
  font-weight: 600;
}

.widget--activitieslist {
  z-index: 4 !important;
}

.whflattabs--withfilters {
  flex-wrap: wrap;
}

.whflattabs--underline {
  border-bottom: 1px solid #D8D8D8;
}
.whflattabs--underline .whflattabs__tabs label {
  border-bottom: 2px solid transparent;
}
.whflattabs--underline .whflattabs__tabs input:checked + label {
  border-bottom-color: #333333;
}
.whflattabs--underline.whflattabs--withfilters .whflattabs__tabs label {
  margin-bottom: -1px;
}

.whflattabs--tabs {
  border-bottom: 1px solid #333333;
  pointer-events: none;
  /*
    &.whflattabs--withfilters .whflattabs__tabs
    {
      margin-bottom: -1px;
    }
  */
}
.whflattabs--tabs .whflattabs__tabs label,
.whflattabs--tabs .whflattabs__filtertoggle {
  pointer-events: auto;
}
.whflattabs--tabs .whflattabs__tabs label {
  background-color: #FFFFFF;
  border-right: 1px solid #dcddde;
  z-index: -1;
}
.whflattabs--tabs .whflattabs__tabs input:checked + label {
  /*
  border-color: #333333;
  border-bottom-color: transparent; // NOTE: a transparent line will overlap the left and right border, so make it transparent would make it look like there's a border-radius
  */
  border: 1px solid #333333;
  border-bottom: 0px;
  padding: 5px calc(var(--whflattabs-tab-sidepadding) - 1px) 0 calc(var(--whflattabs-tab-sidepadding) - 1px);
  z-index: 0;
}
.whflattabs--tabs .whflattabs__tabs {
  margin-bottom: -1px;
}

.whflattabs__filtertoggle {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  font: inherit;
  margin-left: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr min-content;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
}

.whflattabs__filtertoggle__inactive,
.whflattabs__filtertoggle__active {
  display: contents;
  color: #5A5E62;
}

.whflattabs__filtertoggle__inactive > .text,
.whflattabs__filtertoggle__active > .text {
  grid-column: 1;
  grid-row: 1;
}

.whflattabs__filtertoggle__active {
  color: #5A5E62;
}

.whflattabs__filtertoggle__inactive .icon,
.whflattabs__filtertoggle__active .icon {
  grid-column: 2;
  grid-row: 1;
  margin-left: 10px;
  color: #AAACAE;
}

.whflattabs__filtertoggle__active .icon {
  color: #5A5E62;
  font-size: 12px;
}

.whflattabs__filtertoggle:hover {
  background-color: #F5F5F5;
}

.whflattabs__filtertoggle-checkbox {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

.whflattabs__filtertoggle__active {
  visibility: hidden;
}

.whflattabs__filterpanel--open .whflattabs__filtertoggle__active {
  visibility: visible;
}

.whflattabs__filterpanel--open .whflattabs__filtertoggle__inactive {
  visibility: hidden;
}

.whflattabs__breakline {
  width: 100%;
  height: 0;
}

.whflattabs__filterpanel {
  position: relative;
  margin-left: auto;
  opacity: 0;
  pointer-events: none;
}

.whflattabs__filterpanel::before {
  content: "";
  position: absolute;
  top: 9.5px;
  left: 25px;
  right: 25px;
  bottom: 21.5px;
  box-shadow: 0 15px 25px 0 #1E2328;
}

.whflattabs__filterpanel__inner {
  position: relative;
  z-index: 10;
  background-color: #F7F8F8;
  border: 1px solid #DCDDDE;
  border-bottom: 1px solid #5A5E62;
  display: flex;
  flex-direction: column;
}

/*
.whflattabs__filterpanel__inner
{
  position: relative;
  z-index: 1;
}
*/
.whflattabs__filterpanel > * {
  flex: none;
}

.whflattabs__filterpanel:focus {
  outline: 0;
}

.whflattabs__filterpanel--open .whflattabs__filterpanel {
  opacity: 1;
  pointer-events: auto;
}

@media (min-width: 641px) {
  .whflattabs__filterpanel__inner {
    width: 320px;
    max-width: 100%;
    max-height: 300px;
    max-height: min(500px, 80vh);
  }
  .whflattabs__filterpanel__closebutton,
  .whflattabs__filterpanel__showresults {
    display: none !important;
  }
}
@media (max-width: 640px) {
  html.whflattabs__filterpanel--open {
    --zindex-main: 4;
    overflow: hidden;
  }
  .whflattabs__filterpanel {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .whflattabs__filterpanel__inner {
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .whflattabs__filterpanel__closebutton {
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    color: inherit;
    font: inherit;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  .activities__filterpanel__querywrapper {
    width: calc(100% - 30px - 30px);
  }
  .activities__filterpanel__items.activities__filterpanel__items {
    padding-bottom: 15px;
  }
}