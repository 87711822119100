@charset "UTF-8";
/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.widget {
  font-size: 15px;
  font-weight: 400;
  position: relative;
  perspective: 1000px;
}
.widget:not(.main__contents--overdeeplinks) {
  z-index: 0;
}
.wh-widgetpreview .widget {
  margin: 0 auto;
  width: 300px;
}

.widget__front {
  background: var(--color-white);
  color: var(--color-black);
  opacity: 1;
  position: relative;
  z-index: 2;
}

.embeddedobject-faq .widget__front {
  background-color: transparent;
}

.widgetsblock .widget--fold .widget__front {
  background-color: transparent;
  background-image: linear-gradient(-38.67deg, transparent, transparent 33px, var(--color-white) 33px, var(--color-white));
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.widgetsblock .widget--fold .widget__front:after {
  background-image: url("img/widget--fold.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 53px 50px;
  bottom: 0;
  content: "";
  display: block;
  height: 50px;
  position: absolute;
  right: 0;
  width: 53px;
  z-index: 10;
}

.widget__tabs {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.widget__tabs-titles {
  border-bottom: 1px solid var(--color-grey-lines);
  display: flex;
  margin: 0 20px;
  padding: 0 0 2px 0;
  position: relative;
}
.widget__tabs--pulldown .widget__tabs-titles {
  border: none;
  margin-bottom: 20px;
}

.widget__tabs-title {
  color: var(--color-grey-dark);
  display: inline-block;
  flex: 1;
}

.widget__tabs-titletext {
  cursor: pointer;
  display: inline-block;
  transition: color 250ms;
}
.widget__tabs-titletext--current {
  color: var(--color-black);
}

.widget__tabs-currenttitle {
  background: var(--color-black);
  bottom: -1px;
  height: 1px;
  left: 0;
  position: absolute;
  transition: left 250ms, width 250ms, right 250ms;
  width: 0;
}

.widget__tabs-contents {
  transition: transform 250ms ease;
  transform: translateX(0);
  position: relative;
  white-space: nowrap;
  z-index: 0;
}

.widget__tabs-page {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  width: 100%;
}
.widget__tabs--pulldown .widget__tabs-page {
  opacity: 0;
  transition: opacity 250ms;
}
.widget__tabs--pulldown .widget__tabs-page--init {
  opacity: 1;
  transition: none;
}
.widget__tabs--pulldown .widget__tabs-page--current {
  opacity: 1;
}

.widget__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  padding: 25px 20px;
  position: relative;
  text-transform: uppercase;
}
.widget__title--belowphotos {
  padding: 20px;
}
.widget--embedded .widget__title {
  padding-left: 0;
  padding-right: 0;
}

.widget__title--withicon {
  padding-right: 64px;
}

.widget__title-icon {
  color: var(--color-grey-normal);
  display: block;
  font-size: 24px;
  height: 24px;
  line-height: inherit;
  position: absolute;
  right: 20px;
  top: calc(50% - 12px);
  width: 24px;
}

.widget__title-subtitle {
  display: block;
  font-weight: 300;
  margin-top: 2px;
}
.widget__title--belowphotos .widget__title-subtitle {
  text-transform: none;
}

.widget__photos {
  height: 170px;
  position: relative;
  width: 100%;
  z-index: 0;
}
@media (max-width: 480px) {
  .widget__photos {
    height: auto;
    padding-top: 56.67%; /* 170px / 300px */
    width: 100%;
  }
}
.widget__photos-infobottom {
  font-size: 0;
  height: 0;
  line-height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.widget__photoslide {
  opacity: 1;
  transition: opacity 1s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.widget__photoslide--infoinit {
  display: block;
  visibility: hidden;
}
.widget__photoslide-video:not(.widget__photoslide--poster)::before {
  content: "";
}
.widget__photoslide-video.widget__photoslide--poster {
  cursor: pointer;
  z-index: 0;
  background-size: 100%;
  transition: background-size 300ms;
}
.widget__photoslide-video.widget__photoslide--poster:hover {
  background-size: 110%;
}
.widget__photoslide-video.widget__photoslide--poster::before {
  background: var(--color-white);
  color: var(--color-grey-dark);
  cursor: pointer;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;
  transition: color 300ms;
  z-index: 1;
}
.widget__photoslide-video.widget__photoslide--poster:hover::before {
  color: var(--color-black);
}
.widget__photoslide-video.widget__photoslide--poster::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0126c0;
  opacity: 0.3;
  transition: opacity 300ms;
}
.widget__photoslide-video.widget__photoslide--poster:hover::after {
  opacity: 0.2;
}
.widget__photoslide.slideshow--slidein {
  opacity: 0;
}
.widget__photoslide.slideshow--slideout {
  opacity: 0;
}

.widget__photoinfo {
  background: var(--color-white);
  font-family: "Univers Next W02", Arial, Helvetica, sans-serif;
  padding-bottom: 20px;
  position: absolute;
  top: 100%;
  width: 100%;
}
.widget__photoinfo-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  padding: 25px 20px 5px 20px;
  position: relative;
  text-transform: uppercase;
}
.widget__photoinfo-description {
  color: var(--color-black);
  line-height: 25px;
  padding: 20px 20px 0 20px;
}
.widget__photoinfo-title + .widget__photoinfo-description {
  padding-top: 0;
}
.widget__photoinfo-link {
  display: block;
  padding: 20px 20px 0 20px;
}

.widget__photosliderdots {
  bottom: 0;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  position: absolute;
  width: 60%;
  z-index: 3;
}
html[dir=ltr] .widget__photosliderdots {
  margin-left: 20%;
}

.widget__photosliderdot {
  cursor: pointer;
  display: block;
  flex: none;
  position: relative;
  text-align: center;
  width: 20px;
  height: 20px;
}

.widget__photosliderdotactive {
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 1s ease-out;
  width: 20px;
}
.slideshow--activedot .widget__photosliderdotactive {
  opacity: 1;
}
.slideshow--paused .widget__photosliderdotactive {
  animation: blink-animation 2s linear infinite;
}

.widget__text {
  color: var(--color-black);
  padding: 20px;
}
.widget__title + .widget__text {
  padding-top: 0;
}

.widget__list {
  overflow: hidden;
}
.widget__infoslide--contact .widget__list {
  margin-top: 10px;
}

.widget__listitem {
  border-color: var(--color-grey-lines);
  border-style: solid;
  border-width: 1px 0 0 0;
  color: #333333;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  margin: 0 20px;
  position: relative;
  z-index: 0;
}
html[dir=ltr] .widget__listitem:not(.overflow) {
  padding: 15px 40px 15px 0;
}
.widget__listitem--noborder {
  border-width: 0;
}
.widget--embedded .widget__listitem {
  margin: 0;
}
.widget--embedded .widget__listitem:last-child {
  border-bottom-width: 1px;
}
.widget__photos + .widget__list .widget__listitem:first-child {
  border-top-width: 0;
}
.widget__list:not(:last-child) .widget__listitem:last-child {
  border-bottom-width: 1px;
}
.widget__infoslide--contact .widget__listitem {
  padding-bottom: 10px;
  padding-top: 10px;
}
.widget__listitem--link {
  display: block;
  text-decoration: none;
}
.widget__listitem--link:before {
  transition: transform 150ms ease;
  background: var(--color-grey-lighter);
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  z-index: -2;
}
html[dir=ltr] .widget__listitem--link:before {
  left: -20px;
  right: 20px;
  transform: translateX(-100%);
}
html[dir=ltr] .widget__listitem--link:hover:before {
  transform: translateX(0);
}

.widget__listitem-supertext {
  color: var(--color-black);
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  white-space: pre;
}

.widget__listitem-subtext {
  color: var(--color-black);
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: pre;
}

.widget__listitem-icon {
  color: var(--color-grey-normal);
  display: block;
  font-size: 24px;
  font-weight: 400;
  height: 24px;
  position: absolute;
  text-align: center;
  top: calc(50% - 12px);
  transition: color 150ms;
  width: 24px;
  /* If the list isn't the last widget component, the last item has a bottom border */
}
.widget__listitem-icon--small, .widget__listitem-icon--invisible {
  font-size: 16px;
  height: 16px;
  top: calc(50% - 8px);
}
.widget__listitem-icon--invisible {
  color: transparent;
}
.widget__listitem--link:hover .widget__listitem-icon {
  color: var(--color-black);
}
html[dir=ltr] .widget__listitem-icon {
  right: 8px;
}

.widget__listitem.overflow {
  line-height: 50px;
  max-height: 50px;
  padding: 0;
  position: relative;
  z-index: 0;
  width: calc(100% - 40px);
}
.widget__listitem.overflow .overflow__container {
  overflow: hidden;
  max-height: 50px;
}
.widget__listitem.overflow .softbreak {
  display: block;
  float: left;
  margin-right: -1px;
  width: 1px;
}
.widget__listitem.overflow .widget__listitem-icon {
  float: right;
  line-height: 50px;
}
.widget__listitem.overflow .widget__listitem-text {
  float: left;
  min-width: calc(100% - 40px);
  position: relative;
  white-space: nowrap;
}
.widget__listitem.overflow .widget__listitem-text:before {
  content: attr(data-overflow);
  left: 0;
  margin-top: -50px;
  position: absolute;
  top: 0;
}
.widget--embedded .widget__listitem.overflow {
  width: 100%;
}
.widget__listitem.overflow .widget__listitem-text {
  min-width: calc(100% - 40px);
}
.widget--embedded .widget__listitem.overflow .widget__listitem-text {
  min-width: 100%;
}
.widget__listitem.overflow .widget__listitem-icon {
  display: inline;
  height: auto;
  margin-left: 8px;
  margin-right: 8px;
  position: static;
  vertical-align: top;
}

.widget__infos {
  position: relative;
  z-index: 2;
}
.widget__infos:last-child {
  padding-bottom: 50px;
}
.main__contents .widget__infos:last-child {
  padding-bottom: 0;
}
.widget__infos--slideshow {
  overflow: hidden;
  padding: 0;
  position: relative;
}
.widget__infos--slidequotes {
  height: 420px;
}
.widget__infos--slideawards {
  min-height: 290px;
}
.widget__infos--slidecontacts {
  min-height: 290px;
}
.widget__infos--flip {
  height: 420px;
  overflow: hidden;
}

.widget__infoslide--quote {
  padding: 50px 25px 35px 45px;
}

.widget__infos-info {
  color: var(--color-grey-normal);
  cursor: pointer;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
}

.widget__infos-photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.widget__infoslide--photo .widget__infos-photo {
  height: 100px;
  left: 0;
  position: absolute;
  top: 50px;
  width: 100px;
}
@media (max-width: 480px) {
  .widget__infoslide--photo .widget__infos-photo {
    bottom: 18px;
    height: 62px;
    left: 22px;
    top: auto;
    width: 62px;
  }
}
.widgetsblock .widget__infoslide--quote .widget__infos-photo {
  height: 170px;
  left: -45px;
  position: relative;
  top: -50px;
  width: calc(100% + 70px);
}
.widgetsblock .widget__infoslide--award .widget__infos-photo, .widgetsblock .widget__infoslide--contact .widget__infos-photo {
  height: 150px;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}

.widget__photoslide--roundthumb,
.widget__infos-photo--roundthumb {
  background-size: 100px 100px;
}
@media (max-width: 480px) {
  .widget__infoslide--photo .widget__photoslide--roundthumb,
  .widget__infoslide--photo .widget__infos-photo--roundthumb {
    background-size: 62px 62px;
  }
}
.widgetsblock .widget__photoslide--roundthumb,
.widgetsblock .widget__infos-photo--roundthumb {
  background-size: 100px 100px;
  border-color: var(--color-grey-lines);
  border-style: solid;
  border-width: 0 0 1px 0;
}
.widgetsblock .widget__infoslide--award .widget__photoslide--roundthumb,
.widgetsblock .widget__infoslide--award .widget__infos-photo--roundthumb {
  border-width: 1px 0 0 0;
}
.widgetsblock .widget__infoslide--contact .widget__photoslide--roundthumb,
.widgetsblock .widget__infoslide--contact .widget__infos-photo--roundthumb {
  border: 0;
}

.widgetsblock .widget__photoslide--roundthumb {
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
}

.widgetsblock .widget__infoslide--quote .widget__infos-photo--roundthumb {
  left: -25px;
  width: calc(100% + 30px);
}

.widget__photoslide--roundthumb:after,
.widget__infos-photo--roundthumb:after {
  background-image: url("img/widget--roundthumb.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px 100px;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 480px) {
  .widget__infoslide--photo .widget__photoslide--roundthumb:after,
  .widget__infoslide--photo .widget__infos-photo--roundthumb:after {
    background-size: 62px 62px;
  }
}
.widgetsblock .widget__photoslide--roundthumb:after,
.widgetsblock .widget__infos-photo--roundthumb:after {
  background-size: 100px 100px;
}

.widget__quote-text {
  font: 300 40px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  display: block;
  position: relative;
  text-transform: uppercase;
}
html[lang=el] .widget__quote-text {
  font-family: Roboto Condensed;
}
.widget__infoslide--photo .widget__quote-text {
  font-size: 26px;
  padding-left: 95px;
}
@media (max-width: 480px) {
  .widget__infoslide--photo .widget__quote-text {
    padding-left: 0;
  }
}
.widgetsblock .widget__quote-text {
  font-size: 26px;
  padding-left: 0;
}
@media (max-width: 480px) {
  .widget__quote-text {
    font-size: 26px;
  }
}
.widget__quote-text:before {
  content: "“";
  display: inline;
  font: 500 48px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  left: -23px;
  position: absolute;
}
html[lang=el] .widget__quote-text:before {
  font-family: Roboto Condensed;
  font-weight: 400;
}
.widget__infoslide--photo .widget__quote-text:before {
  left: 72px;
  top: 6px;
}
@media (max-width: 480px) {
  .widget__infoslide--photo .widget__quote-text:before {
    left: -23px;
  }
}
.widgetsblock .widget__quote-text:before {
  left: -23px;
  top: 6px;
}
.widget__quote-text:after {
  content: "”";
  display: inline-block;
  font: 500 48px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  line-height: inherit;
  padding-left: 4px;
  padding-top: 1px;
  vertical-align: top;
}
html[lang=el] .widget__quote-text:after {
  font-family: Roboto Condensed;
  font-weight: 400;
}
.widget__infoslide--photo .widget__quote-text:after {
  padding-top: 6px;
}
.widgetsblock .widget__quote-text:after {
  padding-top: 6px;
}
@media (max-width: 480px) {
  .widget__quote-text:after {
    padding-top: 6px;
  }
}

.widget__quote-from {
  display: block;
  font: 300 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  line-height: 25px;
  padding-top: 10px;
}
html[lang=el] .widget__quote-from {
  font-family: Roboto Condensed;
}
.widget__infoslide--photo .widget__quote-from {
  padding-left: 95px;
}
@media (max-width: 480px) {
  .widget__infoslide--photo .widget__quote-from {
    padding-left: 55px;
    padding-top: 24px;
  }
}
.widgetsblock .widget__quote-from {
  padding-left: 0;
}

.widget__award-text {
  display: block;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.widget__award-from {
  display: block;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

.widget__contact-name {
  display: block;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.widget__contact-title {
  display: block;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

.widget__infoslide {
  opacity: 1;
  transition: opacity 1s;
  background: var(--color-white);
  display: none;
  height: 370px;
  left: 0;
  padding: 50px 25px 50px 45px;
  position: absolute;
  top: 0;
  width: 100%;
}
.widget__infoslide:not(.widget__infoslide--photo) {
  padding-top: 135px;
}
.widget__infoslide--award {
  height: auto;
  padding: 0 20px;
}
.widget__infoslide--contact {
  height: auto;
  padding: 0 20px;
}
.widget__infoslide.slideshow--slidein {
  opacity: 0;
}
.widget__infoslide.slideshow--slideout {
  opacity: 0;
}

.widget__infoslide--photo {
  min-height: 100px;
}
.widgetsblock .widget__infoslide--photo {
  min-height: 0;
}

.widget__infosliderdots {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 3;
  height: 45px;
  color: var(--color-white);
  display: flex;
  justify-content: center;
}

.widget__infosliderdot {
  cursor: pointer;
  display: block;
  flex: none;
  position: relative;
  text-align: center;
  width: 20px;
  color: var(--color-grey-normal);
}

.widget__infosliderdotactive {
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 1s ease-out;
  width: 20px;
  color: var(--color-grey-dark);
}
.slideshow--activedot .widget__infosliderdotactive {
  opacity: 1;
}
.slideshow--paused .widget__infosliderdotactive {
  animation: blink-animation 2s linear infinite;
}

.widget__infos-back {
  height: 100%;
  padding: 50px;
  position: absolute;
  top: 0;
  width: 100%;
}

.widget__infos-close {
  color: var(--color-grey-light);
  cursor: pointer;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.widget__infos-listheader {
  font-weight: 700;
  text-transform: uppercase;
}
.widget__infos-listheader:not(:first-child) {
  margin-top: 10px;
}

.widget__infos-listicon {
  color: var(--color-grey-normal);
  left: 20px;
  margin-top: 3px;
  position: absolute;
}

.widget__infos-listlink {
  color: inherit;
  display: block;
  text-decoration: underline;
}
.widget__infos-listlink:not(:first-child) {
  margin-top: 10px;
}

.widget__link {
  display: block;
  font-size: 18px;
  font-weight: 300;
  margin: 0 20px;
  padding: 15px 0;
}
.widget__title + .widget__link {
  padding-top: 0;
}

.widget__link + .widget__link {
  padding-top: 10px;
  margin-top: -10px;
}

.widget__iconlinks {
  display: flex;
  justify-content: center;
}

.widget__iconlink {
  color: var(--color-grey-normal);
  flex: none;
  font-size: 24px;
  height: 24px;
  margin: 15px 10px;
  text-decoration: none;
  width: 24px;
}
.widget__iconlink:hover {
  color: var(--color-black);
}

.widget__endformholder {
  padding: 0 25px 15px;
}

.widget .wh-form .wh-form__fieldgroup {
  margin-top: 15px;
  display: block;
}

.widget .wh-form .wh-form__label {
  width: 100%;
  max-width: 100%;
  display: block;
  padding-right: 0;
}

.widget .wh-form button,
.widget .wh-form input[type=text],
.widget .wh-form input[type=email],
.widget .wh-form input[type=password],
.widget .wh-form select {
  width: 100%;
}

.widget .wh-form .wh-form__buttongroup {
  margin-top: 20px;
}

.widget__break {
  width: 100% !important;
}
.widget__break--notitle {
  padding: 0;
}

.widget__break__title {
  color: var(--color-black);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  padding-top: 20px;
  text-transform: uppercase;
}

/* For the foldable widget / toggleheader stuff is only used by the peopleoverview page --------- */
.widget--foldable {
  margin-top: -1px; /* overlay previous folderable widget */
}

.widget__toggleheader {
  background-color: var(--color-white);
}

.embeddedobject-faq .widget__toggleheader {
  background-color: transparent;
}

.widget__toggleheader .foldablecontent__title {
  display: flex;
  align-items: center;
  padding: 20px;
}

.widget__toggleheader .foldablecontent__title:after {
  margin-right: -20px;
  position: static;
}

.widget__toggleheader:not(.foldablecontent) .foldablecontent__title:after {
  content: "";
}

.widget__toggleheader.foldablecontent .foldablecontent__title::after {
  content: "\e92f";
}

.widget__toggleheader.foldablecontent.foldablecontent--open .foldablecontent__title::after {
  content: "\e930";
}

.foldablecontent__foldcontainer .widget__listitem:first-child {
  border-top: 0;
}