.widget-ws2016news .widget__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widget-ws2016news .widget__title .icon {
  margin-left: 10px;
  flex: none;
  font-size: 24px;
  color: var(--color-grey-normal);
}
.widget-ws2016news__photo {
  display: block;
  text-decoration: none;
  background-size: cover;
  background-position: 50% 50%;
}
.widget-ws2016news__photo::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}