/* Color definitions for inclusion in CSS files */

:root, .wh-rtd-editor
{
  /*****************************************************************************
  *
  *  Color palette
  */

  /* Cashmere */
  --color-cashmere:           #ebbda9;
  --color-cashmere-hover:     #dd9b83;
  /* Dogwood rose */
  --color-dogwood-rose:       #cf0072;
  --color-dogwood-rose-hover: #b3004b;
  /* Windsor */
  --color-windsor:            #4f2d7f;
  --color-windsor-hover:      #2f1957;
  /* Cool black */
  --color-cool-black:         #002c5f;
  --color-cool-black-hover:   #00183b;
  /* Bondi blue */
  --color-bondi-blue:         #0094b3;
  --color-bondi-blue-hover:   #006c8f;
  /* Malibu blue */
  --color-malibu-blue:        #63b1e5;
  --color-malibu-blue-hover:  #3e8cd4;
  /* Mosque */
  --color-mosque:             #00675a;
  --color-mosque-hover:       #004b48;
  /* Malibu green */
  --color-malibu-green:       #3f9c35;
  --color-malibu-green-hover: #287c31;
  /* Trendy green */
  --color-trendy-green:       #887b1b;
  --color-trendy-green-hover: #685b0f;
  /* Tangerine */
  --color-tangerine:          #fed100;
  --color-tangerine-hover:    #fdbe00;
  /* Tahiti gold */
  --color-tahiti-gold:        #ec7a08;
  --color-tahiti-gold-hover:  #e35a04;
  /* Crimson glory */
  --color-crimson-glory:      #b0061c;
  --color-crimson-glory-hover:#b0061c;
  /* Scarlett */
  --color-scarlett:           #822433;
  --color-scarlett-hover:     #62141e;
  /* Matterhorn */
  --color-matterhorn:         #513c40;
  --color-matterhorn-hover:   #352528;
  /* Dark grey */
  --color-dark-grey:          #5a5d62;
  --color-dark-grey-hover:    #5a5d62;


  /*****************************************************************************
  *
  *  Shades of grey
  */

  /* White */
  --color-white:        #ffffff;
  /* Lighter grey */
  --color-grey-lighter: #f7f8f8;
  /* Light grey */
  --color-grey-light:   #f0f1f2;
  /* Grey lines */
  --color-grey-lines:   #dcddde;
  /* Normal grey */
  --color-grey-normal:  #afb1b3;
  /* Dark grey */
  --color-grey-dark:    #53575b;
  /* Black */
  --color-black:        #1e2328;


  /*****************************************************************************
  *
  * Non-palette colors
  */

  /* TechMed Centre */
  --color-techmed:                           #00abcc;
  /* Digital Society Institute */
  --color-digital:                           #572aba;
  /* Mesa+ Institute */
  --color-mesa:                              #ec1a38;
  /* Mosque (tuned down for ITC coursefinder universum header) */
  --color-mosque-bleached:                   #77aca6;
  /* Grey (admission step inactive) */
  --color-grey-admissionstep-inactive:       #9a9c9e;
  /* Grey (admission step inactive hover) */
  --color-grey-admissionstep-inactive-hover: #484b4f;


  /*****************************************************************************
  *
  * Font weights
  */

  /* Light condensed */
  --weight-light-condensed:  300;
  /* Normal */
  --weight-normal:           400;
  /* Normal condensed */
  --weight-normal-condensed: 500;
  /* Bold */
  --weight-bold:             600;
  /* Bold condensed */
  --weight-bold-condensed:   700;
}
