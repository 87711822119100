/* video standard widget */
.wh-video picture {
  transition: transform 300ms;
  transform: scale(1);
}
.wh-video picture::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0126c0;
  opacity: 0.3;
  transition: opacity 300ms;
}
.wh-video__playbutton::before {
  background: var(--color-white);
  color: var(--color-grey-dark);
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  position: absolute;
  padding-left: 3px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: color 300ms;
}
.wh-video:hover picture {
  transform: scale(1.1);
}
.wh-video:hover picture::after {
  opacity: 0.2;
}
.wh-video:hover .wh-video__playbutton::before {
  color: var(--color-black);
}