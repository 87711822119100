@import "@mod-utwente_design/scss/config-colors.css";
/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * FOOTER
 */
.footer {
  color: var(--color-white);
  flex: none;
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (max-width: 1220px) {
  .footer {
    left: auto;
    width: 100%;
  }
}

.footer__prefooter {
  padding-bottom: 25%;
  position: relative;
  width: 100%;
}
@media (max-width: 959px) {
  .footer__prefooter {
    padding-bottom: 33.3333333333%;
  }
}
@media (max-width: 749px) {
  .footer__prefooter {
    padding: 0;
  }
}

.footer__prefooter-container {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
@media (max-width: 749px) {
  .footer__prefooter-container {
    flex-direction: column;
    height: auto;
    position: relative;
  }
}

.footer__addressblock {
  background: var(--color-white);
  color: #1e2328;
  flex: none;
  font-size: 26px;
  font-weight: 300;
  height: 100%;
  line-height: 26px;
  overflow: hidden;
  padding: 50px 35px;
  width: 25%;
  z-index: 0;
}
@media (max-width: 1439px) {
  .footer__addressblock {
    padding: 45px 30px;
  }
}
@media (max-width: 1279px) {
  .footer__addressblock {
    padding: 25px 30px;
  }
}
@media (max-width: 959px) {
  .footer__addressblock {
    width: 33.3333333333%;
  }
}
@media (max-width: 749px) {
  .footer__addressblock {
    order: 1;
    padding: 45px 30px;
    width: 100%;
  }
}

.footer__address {
  padding: 0;
  text-transform: uppercase;
}
.footer__address > span:first-child {
  font-weight: 700;
}

.footer__contact {
  color: #53575b;
  font-size: 18px;
  line-height: 25px;
  margin-top: 20px;
}

.footer__image {
  background: url("img/footer__image.jpg") center no-repeat;
  background-size: cover;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (max-width: 749px) {
  .footer__image {
    flex: none;
    height: auto;
    padding-top: 42%;
  }
}

.footer__icon {
  bottom: 0;
  color: var(--color-white);
  font-size: 24px;
  height: 50px;
  line-height: 50px !important;
  position: absolute;
  left: 0;
  text-align: center;
  width: 50px;
}
@media (max-width: 749px) {
  .footer__icon {
    opacity: 0.9;
  }
}
@media (min-width: 750px) {
  .footer__icon {
    background-color: transparent !important;
    animation: none !important;
  }
}

.footer__overlay {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 190px;
  position: absolute;
  right: calc(16.6666666667% - 95px);
  top: calc(50% - 95px);
  width: 190px;
}
@media (max-width: 959px) {
  .footer__overlay {
    right: calc(25% - 95px);
  }
}
@media (max-width: 749px) {
  .footer__overlay {
    right: 20px;
  }
}
@media (max-width: 479px) {
  .footer__overlay {
    height: 150px;
    top: calc(50% - 75px);
    width: 150px;
  }
}

.footer__links {
  background: #1f2229;
  padding: 50px 0;
}

html.site--itc .footer__links {
  background: #f0f1f2;
}

.footer__linksblock {
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
  max-width: 960px;
}
html[dir=ltr] .footer__linksblock {
  margin-left: calc(50% - 480px);
}
@media (max-width: 960px) {
  html[dir=ltr] .footer__linksblock {
    margin-left: 30px;
    width: calc(100% - 60px);
  }
}
@media (max-width: 750px) {
  html[dir=ltr] .footer__linksblock {
    margin-left: 55px;
    width: calc(100% - 110px);
  }
}
@media (max-width: 480px) {
  html[dir=ltr] .footer__linksblock {
    margin-left: 15px;
    width: calc(100% - 30px);
  }
}

.footer__linksection {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0 10px;
  width: 25%;
}
html[dir=ltr] .footer__linksection {
  float: left;
}
@media (max-width: 960px) {
  .footer__linksection {
    border-bottom: 1px solid var(--color-grey-dark);
    float: none;
    padding: 0;
    width: 100%;
  }
  .footer__linksection:first-of-type {
    border-top-width: 1px;
  }
}
@media (max-width: 960px) {
  .footer__linksection--logos {
    border: none;
  }
  .footer__linksection--logos.footer__linksection--notitle {
    padding-top: 20px;
  }
}

.footer__sectiontitle {
  color: var(--color-white);
  display: inline;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}
@media (max-width: 960px) {
  .footer__sectiontitle {
    cursor: pointer;
    display: block;
    padding: 15px 0;
  }
}
@media (max-width: 480px) {
  .footer__sectiontitle {
    font-size: 15px;
    padding: 10px 0;
  }
}

html.site--itc .footer__sectiontitle,
html.site--itc .footer__sectionlink {
  color: var(--color-black);
}

.footer__sectionicon {
  display: none;
  line-height: 25px;
}
html[dir=ltr] .footer__sectionicon {
  float: right;
}
.footer__sectionicon--open {
  color: #afb1b3;
}
@media (max-width: 960px) {
  .footer__sectionicon--open {
    display: block;
  }
  .footer__sectiontitle--open .footer__sectionicon--open {
    display: none;
  }
}
.footer__sectionicon--close {
  color: var(--color-white);
}
@media (max-width: 960px) {
  .footer__sectiontitle--open .footer__sectionicon--close {
    display: block;
  }
}

.footer__sectionlinks {
  overflow: visible;
}
@media (max-width: 960px) {
  .footer__sectionlinks {
    border: var(--color-grey-dark) solid 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition-duration: 250ms;
    transition-property: border-width, height, opacity;
  }
  .footer__sectionlinks.footer__sectionlinks--open {
    opacity: 1;
  }
  .footer__sectionlinks.footer__sectionlinks--open:not(.footer__logos) {
    border-top-width: 1px;
  }
  .footer__sectionlinks.footer__sectionlinks--init {
    position: absolute;
    top: 0;
    transition: none;
    visibility: hidden;
  }
  .footer__sectionlinks.footer__sectionlinks--init:not(.footer__logos) {
    height: auto;
  }
}

.footer__sectionlink {
  border-color: #afb1b3;
  color: #afb1b3;
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  margin-top: 15px;
  text-decoration: none;
}
@media (max-width: 960px) {
  .footer__sectionlink:last-child {
    margin-bottom: 15px;
  }
  .footer__sectionlinksall .footer__sectionlink:last-child {
    margin-bottom: 0;
  }
}
.footer__sectionlink:hover {
  border-color: var(--color-white);
  color: var(--color-white);
}
.footer__sectionlink--showall {
  cursor: pointer;
  text-decoration: underline;
}

.footer__sectionlinkicon {
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
  display: inline-block;
  font-size: 12px;
  padding: 5px;
}
html[dir=ltr] .footer__sectionlinkicon {
  margin-right: 12px;
}

.footer__sectionlinksall {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 250ms, opacity 250ms;
}
.footer__sectionlinksall--open {
  opacity: 1;
}
.footer__sectionlinksall--init {
  height: auto;
  position: absolute;
  top: 0;
  transition: none;
  visibility: hidden;
}

.footer__logos {
  height: 240px;
  position: relative;
  width: 220px;
}
@media (max-width: 960px) {
  .footer__logos {
    left: 50%;
    margin-left: -110px;
  }
}

.footer__logoslide {
  opacity: 1;
  transition: opacity 1s;
  display: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 220px;
  left: 0;
  position: absolute;
  top: 0;
  width: 220px;
}
.footer__logoslide.slideshow--slidein {
  opacity: 0;
}
.footer__logoslide.slideshow--slideout {
  opacity: 0;
}

.footer__logosliderdots {
  bottom: 0;
  color: var(--color-white);
  display: flex;
  height: 20px;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.footer__logosliderdot {
  cursor: pointer;
  display: block;
  flex: none;
  position: relative;
  text-align: center;
  width: 20px;
}

.footer__logosliderdotactive {
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 1s ease-out;
  width: 20px;
}
.slideshow--activedot .footer__logosliderdotactive {
  opacity: 1;
}
.slideshow--paused .footer__logosliderdotactive {
  animation: blink-animation 2s linear infinite;
}

html.site--itc .footer__logosliderdots {
  color: #000000;
}

.footer__footer {
  min-height: 60px;
  background: #1f2229;
  font-size: 12px;
  font-weight: 400;
}
@media (max-width: 960px) {
  .footer__footer {
    height: auto;
  }
}
@media (max-width: 750px) {
  .footer__footer {
    height: auto;
  }
}
@media (max-width: 480px) {
  .footer__footer {
    height: auto;
  }
}
html.site--itc .footer__footer {
  background-color: var(--color-white);
}

.footer__footerlinks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid var(--color-grey-dark);
  line-height: 20px;
  margin: 0 calc(50% - 470px);
  padding: 20px 0;
  position: relative;
  width: 940px;
}
html.site--itc .footer__footerlinks {
  border-color: #dcddde;
}
@media (max-width: 960px) {
  .footer__footerlinks {
    margin-left: 30px;
    width: calc(100% - 60px);
  }
  .footer__footerlinks .footer__logo {
    width: 100%;
    order: -1;
  }
}
@media (max-width: 750px) {
  .footer__footerlinks {
    margin-left: 55px;
    width: calc(100% - 110px);
  }
}
@media (max-width: 480px) {
  .footer__footerlinks {
    margin-left: 15px;
    width: calc(100% - 30px);
  }
}

.footer__footerlink {
  color: #afb1b3;
  display: inline-block;
  margin-right: 30px;
  text-decoration: none;
}
@media (max-width: 960px) {
  .footer__footerlink {
    margin-top: 15px;
  }
}
html.site--itc .footer__footerlink {
  color: #00675a;
}

.footer__logo {
  display: inline-flex;
  align-items: center;
}
html[dir=ltr] .footer__logo {
  margin-left: auto;
}

.footer__logo__ut {
  display: block;
}

.footer__logo__ut img {
  display: block;
  max-width: 100%;
  width: 260px;
}

.footer__logo__itc {
  display: block;
  background: url("../../web/img/itc/itc-logo@2x.png") top left/52px 61px no-repeat;
  width: 52px;
  height: 61px;
  margin-left: 40px;
}

.footer__logo__itc::before {
  display: block;
  content: "";
  height: 61px;
  margin-left: -21px;
  border-left: 1px solid #00675A;
}

html.ws2016--iframed .footer__prefooter,
html.ws2016--iframed .footer__image,
html.ws2016--iframed .footer__links,
html.ws2016--iframed .footer__footer {
  display: none;
}

@media print {
  footer {
    display: none;
  }
}