/* fact */

/* Normal widgets get a large margin-top (currently 50px).
  This is more of a inline kind of widget, so use a small margin-top
*/
.embeddedobject--fact
{
  margin-top: 25px; /* half of what a normal widget uses */
}

.embeddedobject--fact > p:last-child
{
  margin-bottom: 0;
}

.embeddedobject--fact + .embeddedobject--fact
{
  margin-top: 20px;
}



  .innumbers h3
, .segmentedcircle h3
, .chart__title /* .chart h3 */
, .progresscircles__title /* .progresscircles h3 */
{
  font-size: 26px;
  line-height: 26px;
  margin-bottom: 25px;
}


.fact__title
{
  text-transform: uppercase;
  font-weight: 700;
  /*
  font-size: 26px;
  line-height: 28px;
  */
  font-size:   18px;
  line-height: 20px;
}
@media (max-width: 500px)
{
  .fact__title
  {
    font-size: 18px;
  }
}
.fact__title--withicon
{
  position: relative;
  padding-left: 40px;
}
.fact__icon
{
  position: absolute;
  top: 0;
  left: 0;
}

.fact__icon
{
  position: absolute;
  top: -20px;
  left: -15px;
  width: 60px;
  height: 60px;
}
.fact__icon--trophy
{
  left: 0;
  top: 1px;
  width: 24px;
  height: 24px;
}

.fact__title + p
{
  margin-top: 5px;
}
.fact__title + p + p
{
  margin-top: inherit;
}


/* segmentedcircle */
.segmentedcircle
{
  min-width: 180px;
  min-height: 180px;
  padding-bottom: 50px;
}
.segmentedcircle:after
{
  clear: both;
  content: '';
  display: block;
}
.segmentedcircle-circle
{
  position: relative;
  top: 0;
  left: -15px;
  width: 180px;
  height: 180px;
  float: left;
}
.segmentedcircle .path
{
  cursor: pointer;
  transition: all 0.3s;
}
.segmentedcircle .path.hover
{
  stroke-width: 22px;
  transform: scale(1.1, 1.1);
}
.segmentedcircle__legend
{
  float: right;
  border-collapse: collapse;
  margin: 20px 0 0 50px;
  width: 380px;
}
.segmentedcircle__legend .label
{
  cursor: pointer;
  display: table-row;
}
.segmentedcircle__legend .label > *
{
  padding-bottom: 10px;
  vertical-align: top;
  text-align: left;
  font-size: 15px;
  line-height: 20px;
}
.segmentedcircle__legend .bullet
{
  width: 12px;
  height: 12px;
  border-radius: 6px;
  display: block;
  margin-top: 4px;
  margin-right: 12px;
}
.segmentedcircle__legend .title
{
  width: 100%;
}
.segmentedcircle__legend .value
{
  text-align: right;
  padding-left: 12px;
}
  .segmentedcircle__legend .label.hover
, .segmentedcircle__legend .total
{
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 750px)
{
  .segmentedcircle-circle
  {
    left: auto;
    float: none;
    margin: 0 auto;
  }
  .segmentedcircle__legend
  {
    width: 100%;
    float: none;
    margin-left: 0;
  }
}


/* groupedprogresscircles */
.groupedprogresscircles__title
{
  margin: 70px 0 -6px;
}

/* progresscircle */
.progresscircles
{
  padding-bottom: 60px;
}
.groupedprogresscircles .progresscircles__title
{
  font-size: 26px;
  font-weight: 300;
}
.progresscircles__items
{
  width: 100%;
  text-align: justify;
}
.progresscircle
{
  display: inline-block;
  position: relative;
  width: 180px;
  min-height: 150px;
  margin-bottom: 12px;
  overflow: hidden;
}
.progresscircle + .progresscircle
{
  margin-left: 8px;
}
.progresscircle__title
{
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  font-size: 18px;
  padding-left: 30px;
  padding-bottom: 5px;
}
.progresscircle__icon
{
  position: absolute;
  bottom: 0;
  left: -16px;
  width: 60px;
  height: 60px;
}
.progresscircle__icon--trophy
{
  left: -3px;
  width: 24px;
  height: 24px;
}
.progresscircle__graphholder
{
  position: relative;
  width: 180px;
  height: 150px;
}
  .progresscircle__track
, .progresscircle__fill
{
  position: absolute;
  width: 150px;
  height: 150px;
  bottom: 0;
  right: 0;
}
.embeddedobject--rte--themecolor .progresscircle__track
{
  opacity: 0.2;
}
.progresscircle__fill
{
  stroke-dasharray: 427; /* 2*Pi*radius (r=68) */
  stroke-dashoffset: 427;
}
.progresscircle__digits
{
  position: absolute;
  width: 150px;
  height: 150x;
  right: 0;
  top: 0;
  border-color: var(--color-white);
  color: var(--color-white);
  text-align: center;
}
.progresscircle__digits .percentage
{
  position: absolute;
  top: 47px;
  left: 35px;
  font-size: 40px;
  line-height: 40px;
  white-space: nowrap;
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 80px;
}
.progresscircle__digits .percentage:after
{
  display: inline-block;
  content: '%';
}
.progresscircle__digits .value
{
  position: absolute;
  top: 84px;
  left: 35px;
  font-size: 13px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  width: 80px;
}
.progresscircles__total
{
  display: inline-block;
  width: 100%;
}

@media (max-width: 570px)
{
  .progresscircles:not(.progresscircles--paired) .progresscircle__graphholder
  {
    margin: 0 auto;
  }
    .progresscircles:not(.progresscircles--paired) .progresscircle
  , .progresscircles:not(.progresscircles--paired) .progresscircle + .progresscircle
  {
    margin-left: -15px;
    display: block;
    width: auto;
    margin-bottom: 20px;
  }

  .progresscircles--paired .progresscircle__graphholder
  {
    width: 140px;
    height: 120px;
  }
  .progresscircles--paired .progresscircle
  {
    width: 140px;
    margin-left: 0;
    min-height: 120px;
  }
  .progresscircles--paired .progresscircle__graphholder > svg
  {
    transform: scale(0.75, 0.75) translate(25px, 26px);
  }
  .progresscircles--paired .progresscircle__digits
  {
    width: 120px;
    height: 120px;
  }
  .progresscircles--paired .progresscircle__digits .percentage
  {
    top: 40px;
    left: 35px;
    font-size: 27px;
    line-height: 27px;
    width: 60px;
  }
  .progresscircles--paired .progresscircle__digits .value
  {
    top: 65px;
    left: 35px;
    width: 60px;
  }

}
@media (max-width: 900px)
{
  .twocolumnsrte .progresscircles:not(.progresscircles--paired) .progresscircle__graphholder
  {
    margin: 0 auto;
  }
    .twocolumnsrte .progresscircles:not(.progresscircles--paired) .progresscircle
  , .twocolumnsrte .progresscircles:not(.progresscircles--paired) .progresscircle + .progresscircle
  {
    margin-left: -15px;
    display: block;
    width: auto;
    margin-bottom: 20px;
  }

  .twocolumnsrte .progresscircles--paired .progresscircle__graphholder
  {
    width: 140px;
    height: 120px;
  }
  .twocolumnsrte .progresscircles--paired .progresscircle
  {
    width: 140px;
    min-height: 120px;
  }
  .twocolumnsrte .progresscircles--paired .progresscircle__graphholder > svg
  {
    transform: scale(0.75, 0.75) translate(25px, 26px);
  }
  .twocolumnsrte .progresscircles--paired .progresscircle__digits
  {
    width: 120px;
    height: 120px;
  }
  .twocolumnsrte .progresscircles--paired .progresscircle__digits .percentage
  {
    top: 40px;
    left: 35px;
    font-size: 27px;
    line-height: 27px;
    width: 60px;
  }
  .twocolumnsrte .progresscircles--paired .progresscircle__digits .value
  {
    top: 65px;
    left: 35px;
    width: 60px;
  }

}


/* progressbars */
.progressbars
{
  padding-bottom: 10px;
}
.progressbars .embeddedobject__share
{
  margin-bottom: 20px;
}
.progressbar
{
  margin: 60px 0 70px 50px;
  height: 12px;
  position: relative;
  background-color: var(--color-grey-lines);
  border-radius: 6px;
}
.embeddedobject--rte--themecolor .progressbar
{
  background-color: rgba(0, 108, 143, 0.3);
}
.progressbar__icon
{
  position: absolute;
  bottom: -22px;
  left: -26px;
  margin-left: -40px;
  width: 60px;
  height: 60px;
}
.progressbar__icon--trophy
{
  left: -3px;
  width: 40px;
  height: 40px;
}
.progressbar__title
{
  display: block;
  position: absolute;
  top: 19px;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}
.progressbar__progress
{
  position: absolute;
  border-radius: 6px 0 0 6px;
  left: 0;
  top: 0;
  width: 0;
  height: 12px;
  transition: width 2s linear;
}
html.wh-widgetpreview .progressbar__progress
{
  transition: none;
}
.progressbar__progress .percentage
{
  position: absolute;
  display: inline-block;
  bottom: 20px;
  right: -30px;
  text-align: center;
  width: 60px;
  height: 30px;
  color: var(--color-white);
  line-height: 30px;
  font-size: 18px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  border-radius: 2px;
}
.progressbar__progress .percentage:before
{
  display: block;
  position: absolute;
  content: '';
  top: 100%;
  left: 50%;
  margin-left: -6px;
  width: 0;
  height: 0;
  border-color: inherit;
  border-top-width: 8px;
  border-top-style: solid;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.progressbar__progress .percentage:after
{
  display: inline-block;
  content: '%';
}




/* bar / linechart */
.main__contents > .chart
{
  padding: 0;
}
.chart .embeddedobject__share
{
  margin-right: 20px;
}
.chart__scrollarea
{
  width: 100%;
  min-height: 380px;
  overflow: auto;
  overflow-y: visible;
}
html.wh-widgetpreview .chart__scrollarea
{
  overflow: visible;
  width: 610px;
}
.chart__scrollarea__content
{
  position: relative;
  width: 610px;
  height: 100%;
  padding: 50px 5px 20px 60px;
}
.chart__area
{
  height: 200px;
  width: 100%;
  position: relative;
}
.chart__area > svg
{
  position: relative;
  width: 100%;
  height: 100%;
}
.chart__area .hline
{
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  left: 0;
  z-index: 0;
}
.chart__area .hline--zero
{
  background-color: #222;
}
.chart__area .hline .label
{
  display: inline-block;
  position: absolute;
  right: 100%;
  line-height: 15px;
  margin-top: -8px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 700;
}
.chart__area .vlabel
{
  display: inline-block;
  line-height: 15px;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  top: 100%;
  margin-top: 5px;
  text-transform: uppercase;
  text-align: center;
}
.chart--negativevalues .chart__area .vlabel
{
  margin-top: 15px;
}
  .chart__area .htitle
, .chart__area .vtitle
{
  line-height: 15px;
  font-size: 12px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  color: #ccc;
  font-size: 12px;
  text-transform: uppercase;
}
.chart__area .vtitle
{
  top: -25px;
  left: 0;
}
.chart__area .htitle
{
  right: 0;
  top: 100%;
  margin-top: 25px;
}
.chart--negativevalues .chart__area .htitle
{
  margin-top: 35px;
}
.chart__area .bar
{
  position: absolute;
  bottom: 0;
  width: 12px;
  margin-left: -6px;
  border-radius: 6px;
  transition: height 1s;
  cursor: pointer;
}
html.wh-widgetpreview .chart__area .bar
{
  transition: none;
}
.chart__area .point
{
  position: absolute;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border-radius: 100%;
  background-color: var(--color-white);
  border-width: 2px;
  border-style: solid;
  z-index: 2;
  cursor: pointer;
}
.chart .value
{
  position: absolute;
  display: inline-block;
  text-align: center;
  color: var(--color-white);
  bottom: 100%;
  left: 50%;
  min-width: 40px;
  border-radius: 2px;
  box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.8);
  padding: 7px 5px;
  margin-bottom: 10px;
  margin-left: -20px;
  opacity: 0;
  transition: opacity 0.4s;
}
.chart--linechart .value
{
  margin-bottom: 0;
  height: 0; /* so mouseover will only trigger on point */
  overflow: hidden;
}
.chart__area .value:before
{
  display: block;
  position: absolute;
  content: '';
  top: 100%;
  left: 50%;
  margin-left: -6px;
  width: 0;
  height: 0;
  border-color: inherit;
  border-top-width: 8px;
  border-top-style: solid;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
  .chart__area .bar:hover
, .chart__area .point:hover
{
  z-index: 3;
}
  .chart__area .bar:hover .value
, .chart__area .point:hover .value
{
  opacity: 1;
}
.chart--linechart .point:hover .value
{
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
}
.chart__legend
{
  margin-top: 50px;
}
.chart__legend .label
{
  line-height: 16px;
  font-size: 15px;
  padding-left: 25px;
  position: relative;
  padding-bottom: 10px;
}
.chart__legend .label .bullet
{
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  top: 2px;
  left: 0;
}
.chart__legend .label--hover
{
  font-weight: 600;
}
