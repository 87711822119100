/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.wh-autocomplete-values {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  border-radius: 2px;
  border: 1px solid #dcddde;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.wh-autocomplete-values > li {
  padding: 3px 15px;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  cursor: pointer;
  transition: background-color 0.15s;
  outline: none;
  color: #999;
}
.wh-autocomplete-values > li.noresults {
  font-weight: normal;
  font-style: italic;
  color: #999;
  cursor: default;
}
.wh-autocomplete-values > li.suggestion:focus, .wh-autocomplete-values > li.suggestion:hover {
  text-decoration: underline;
}
.wh-autocomplete-values > li .match {
  color: var(--color-black);
}

.searchpage {
  margin-left: calc(50% - 480px);
  padding: 30px 15px 10px;
  max-width: 960px;
}
.searchpage .feedback {
  font-weight: bold;
  font-size: 13px;
}
.searchpage .feedback h2 {
  margin-bottom: 5px;
  margin-top: 20px;
}
.searchpage .moreresults + .feedback, .searchpage .consilioresults + .feedback {
  padding-top: 60px;
}
@media (max-width: 960px) {
  .searchpage {
    margin-left: 0;
  }
}

#consiliosearchform {
  position: relative;
  white-space: nowrap;
  width: 100%;
}
#consiliosearchform input {
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-lines);
  border-radius: 2px;
  height: 40px;
  outline: none;
  -webkit-appearance: none;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 32px 10px 15px;
  display: inline-block;
  transition: max-width 0.2s, border-color 0.2s;
  width: 100%;
  max-width: 300px;
}
#consiliosearchform input:focus {
  max-width: 100%;
  border-color: #53575b;
}
#consiliosearchform input:hover + button, #consiliosearchform input:focus + button {
  opacity: 1;
}
#consiliosearchform button {
  vertical-align: top;
  cursor: pointer;
  border: 0 none;
  font-size: 16px;
  background-color: transparent;
  position: relative;
  width: 32px;
  height: 40px;
  opacity: 0.3;
  margin-left: -32px;
  display: inline-block;
  transition: opacity 0.2s;
}
#consiliosearchform button > span {
  display: inline-block;
  padding-top: 4px;
}

.consilioresults {
  list-style-type: none;
  margin: 0;
  padding: 20px 0 0;
  border-bottom: 1px solid #dcddde;
}
.consilioresults > li {
  display: block;
  border-top: 1px solid #dcddde;
  padding: 15px 0;
  opacity: 1;
  transition: opacity 500ms;
}
.consilioresults > li.beforeshow {
  opacity: 0;
}
.consilioresults a {
  display: block;
  text-decoration: none;
  color: inherit;
  position: relative;
  padding: 0 270px 0 0;
}
.consilioresults a.withimage {
  min-height: 124px;
}
.consilioresults a > * {
  display: block;
  max-width: 100%;
  overflow: hidden;
}
.consilioresults .title {
  font-size: 18px;
  text-transform: uppercase;
}
.consilioresults .title > .filesize {
  font-weight: 300;
}
.consilioresults .link {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 6px;
  color: #00675a;
}
.consilioresults .match {
  font-weight: bold;
}
.consilioresults .image {
  position: absolute;
  top: 0;
  right: 0;
  width: 220px;
  height: 124px;
  overflow: hidden;
}
.consilioresults .image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.consilioresults .image:after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
@media (max-width: 650px) {
  .consilioresults a {
    padding-right: 0;
  }
  .consilioresults .image {
    display: none;
  }
}

.searchpage #consiliomore,
.searchpage .moreresults {
  background-color: #f0f1f2;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font: 300 17px/24px "Univers Next W02", Arial, Helvetica, sans-serif;
  color: #000;
  height: 58px;
  padding: 17px 10px;
}