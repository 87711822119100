/*
Implementation notes:

- Seperate background layer
  The header must correctly size to fit the countdown text and padding,
  also when a F&F inset or activities overpage has an title-inset over the header.
  A seperate background layer will ensure the color goes the full height of the header photo area while the content part stays in the upper part of the header to have it stretch there.t to  text+padding

- The content part needs to also have the background color to ensure accessibility
  checking tools are able to correctly detect the contrast between background and text.
*/
.header--hascountdown {
  --headercountdown-background: #FFFFFF;
  --headercountdown-panel-padding-v: 45px;
  --headercountdown-panel-padding-h: 25px;
  --headercountdown-countdown-margin-h: 10px;
  --countdown-sections-gap: 25px;
  --countdown-digit-width: 37px;
  --countdown-digit-height: 50px;
  --countdown-digit-background: var(--color-black);
  --countdown-digit-color: #FFFFFF;
  --countdown-digit-font: bold 40px "Univers Next W02", Arial, Helvetica, sans-serif;
}
@media (max-width: 425px) {
  .header--hascountdown {
    --headercountdown-panel-padding-v: 40px;
    --headercountdown-panel-padding-h: 15px;
    --countdown-digit-width: 30px;
    --countdown-digit-height: 44px;
    --countdown-digit-font: bold 33px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
  .header--hascountdown .component-countdown[data-initiallyvisibledigits="9"] {
    --countdown-sections-gap: 19px;
    --countdown-digit-width: 30px;
    --countdown-digit-height: 43px;
    --countdown-digit-font: bold 31px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
}
@media (max-width: 350px) {
  .header--hascountdown {
    --countdown-sections-gap: 20px;
    --countdown-digit-width: 27px;
    --countdown-digit-height: 40px;
    --countdown-digit-font: bold 31px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
}
@media (max-width: 320px) {
  .header--hascountdown .component-countdown[data-initiallyvisibledigits="9"] {
    outline: 1px solid #00A;
    --countdown-digit-width: 24px;
    --countdown-digit-height: 42px;
    --countdown-digit-font: bold 29px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
}

.header-countdown {
  display: grid;
}

@media (max-width: 799px) {
  .contentpart__header-sliderdots {
    display: none;
  }
}
.header--hascountdown .contentpart__header > .contentpart__header-messagesarea {
  z-index: 6;
}

@media (min-width: 800px) {
  .contentpart__header--photos.header--hascountdown.contentpart__header--photos {
    --countdown-digit-width: 30px;
    --countdown-digit-height: 44px;
    --countdown-digit-font: bold 33px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
  .contentpart__header--photos.header--hascountdown .contentpart__header-photos {
    grid-row: 1/span 4;
    width: calc((100% - var(--header-spacing) * 2) / 2);
    min-height: 100%; /* stretch with content of countdown */
    justify-self: start;
  }
  .contentpart__header--photos.header--hascountdown .header-countdown-background, .contentpart__header--photos.header--hascountdown .header-countdown {
    grid-row: 1/span 4;
    width: calc((100% - var(--header-spacing) * 2) / 2);
    min-width: min-content;
    justify-self: end;
  }
  .contentpart__header--photos.header--hascountdown.header--withfilterbar .header-countdown {
    grid-row: 1/span 3;
  }
}
@media (min-width: 751px) {
  html.ut--factsfigures .contentpart__header--photos.header--hascountdown .header-countdown,
  html.page--activitiesoverview .contentpart__header--photos.header--hascountdown .header-countdown {
    grid-row: 2/span 2;
    z-index: 0;
  }
}
html:not(.ut--factsfigures) .contentpart__header--photos.header--hascountdown .contentpart__title-container {
  grid-row: 5;
}
html:not(.ut--factsfigures) .contentpart__header--photos.header--hascountdown .contentpart__title {
  color: #000;
  margin-top: 15px;
}

html.ut--factsfigures .contentpart__header--universe.header--hascountdown {
  --header-background-height: 0px;
}

@media (max-width: 799px) {
  html.ut--factsfigures .contentpart__header--photos.header--hascountdown {
    --header-background-height: 0px;
  }
}
@media (max-width: 750px) {
  html.ut--factsfigures .contentpart__header--photos.header--hascountdown .contentpart__header-photoscontainer {
    min-height: 100%;
  }
  html.ut--factsfigures .contentpart__header--photos.header--hascountdown .header-countdown {
    grid-row: 2/span 3;
  }
}
@media (min-width: 401px) {
  .header--hascountdown.contentpart__header--universe .header-countdown-background, .header--hascountdown.contentpart__header--universe .header-countdown {
    margin: 25px 25px 0 25px;
  }
}
.header--hascountdown.contentpart__header--universe .header-countdown-inprogress, .header--hascountdown.contentpart__header--universe .header-countdown-expired {
  border: 1px solid #979797;
}

.header--hascountdown.contentpart__header--photos {
  --headercountdown-background: var(--theme-color);
  --countdown-digit-background: #FFFFFF;
  --countdown-digit-color: var(--theme-color);
}
.header--hascountdown.contentpart__header--photos .header-countdown {
  margin-top: 0;
  color: #FFFFFF;
}
.header--hascountdown.contentpart__header--photos .header-countdown .wh-form__button {
  background: #FFFFFF;
  color: var(--theme-color);
}
.header--hascountdown.contentpart__header--photos .component-addtocalendarlink {
  color: #FFFFFF;
}

.header-countdown-background {
  background-color: var(--headercountdown-background);
  z-index: 0;
}

.header-countdown-inprogress,
.header-countdown-expired {
  grid-column: 1;
  grid-row: 1;
  /* Setting a z-index ensures the background not being drawn behind all other cells in this grid.
     Please ignore Chrome saying "The position: static property prevents z-index from having an effect"! */
  z-index: 0;
  background-color: var(--headercountdown-background);
  padding: var(--headercountdown-panel-padding-v) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* vertically align content */
}

.header-countdown__title,
.header-countdown__subtitle,
.header-countdown__button {
  margin: 0 var(--headercountdown-panel-padding-h);
}

.component-countdown {
  margin: 0 var(--headercountdown-countdown-margin-h);
}

.header-countdown:not(.has-expired-countdown) .header-countdown-expired {
  display: none;
}

/*
FIXME: Only use animation if the countdown was visible before.
       Having the animation fire at the moment the page is loaded isn't nice.

.header-countdown.has-expired-countdown .header-countdown-expired
{
  animation: headercountdown-fadein 5s;
}
*/
.header-countdown__title,
.header-countdown__expired__title {
  font: var(--rtd-h1-font);
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
}

.header-countdown__subtitle {
  margin-top: 34px;
  /*
  also used for:
  - .newseventsrecent__event__title
  - (preview) .newseventsrecent__news__meta
  - .slideshowwidget__slideinfo__title
  - .slideshowwidget__slideinfo__description > *
  */
  font: 700 18px/25px "Univers Next W02", Arial, Helvetica, sans-serif;
}

.header-countdown .component-countdown {
  margin-top: 25px;
}

.header-countdown__button,
.header-countdown__expired__button {
  margin-top: 12px;
}
.header-countdown__button .wh-form__button,
.header-countdown__expired__button .wh-form__button {
  /* FIXME: the generic .wh-form__button in utwente_base
            should do this...
  */
  white-space: normal;
  padding-top: 7px;
  padding-bottom: 7px;
  height: auto;
  min-height: 40px;
}

.header-countdown .component-addtocalendarlink {
  margin-top: 14px;
}

/* FIXME: we need --rtd-text-font */
.header-countdown-expired p,
.header-countdown-expired ul,
.header-countdown-expired ol {
  font: 700 18px/25px "Univers Next W02", Arial, Helvetica, sans-serif;
}

/*
@keyframes headercountdown-fadein
{
    0% { opacity: 0; }
  100% { opacity: 1; }
}
*/