:root {
  --tabstrip-font: 400 14px/25px "Univers Next W02";
  --tabstrip-filterpanel-options-font: 400 13px/20px "Univers Next W02";
}

.contentpart-activities__intro {
  margin-top: 20px;
  font: 400 18px/30px "Univers Next W02";
}

/*
Our filterpanel popout cannot escape the z-index stack because .contentpart__main prevents anything within
from escaping it's z-index. It seems safer to change the footer's z-index than to mess with the z-index of the
content container.
(other option is using JS to make the panel to another place in the DOM, but then we would need
to handle any repositioning due to reflow and resizing)
*/
html.whflattabs__filterpanel--open .footer {
  z-index: 0;
}

@media (min-width: 751px) {
  html.page--activitiesoverview {
    --header-sliderdots-height: 163px;
  }
  html.page--activitiesoverview .contentpart__title {
    display: none !important;
  }
  .contentpart-activities-background {
    background-color: #FFFFFF;
    grid-row: 4/span 2;
    grid-column: 3/span 10;
    z-index: 5; /* overlay a countdown */
  }
  .contentpart-activities {
    background-color: #FFFFFF;
    grid-row: 4/span 2;
    grid-column: var(--main-8col-layout);
    z-index: 5; /* overlay a countdown */
  }
  .contentpart-activities .contentpart__title.contentpart__title {
    color: var(--color-black);
  }
}
@media (max-width: 750px) {
  .contentpart-activities {
    background-color: #FFFFFF;
    grid-row: 5;
    grid-column: var(--main-8col-layout);
  }
  .contentpart-activities-background {
    display: none;
  }
  .contentpart-activities__title {
    display: none;
  }
  html.page--activitiesoverview .header--hascountdown:before {
    display: none;
  }
  html.page--activitiesoverview .header--hascountdown .contentpart__title, html.page--activitiesoverview .header--hascountdown .contentpart__header--photos::before {
    display: none !important;
  }
  html.page--activitiesoverview .header--hascountdown .contentpart-activities__title {
    display: block;
  }
}