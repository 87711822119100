.osiris__maxpoints:after {
  content: " EC";
}
.osiris__description {
  max-height: 200px;
  overflow: hidden;
  position: relative;
}
.osiris__description p:first-child {
  margin-top: 0;
}

.ut-dialog .osiris__description {
  max-height: none;
  overflow: visible;
}