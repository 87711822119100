/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
@media screen and (min-width: 961px) {
  /* These are widgets which are fullwidth and have their edges distinctly
     contrast their surroundings. They need extra margin above and below
     to not have the textual content above and below them feel stuck to them.

     Some future improvement might be to have those widget set a CSS variable
     which flags them as having this state in the current layout/styling.
     This means code would upon resizing have to recheck the items and flag them
  */
  html.ut--factsfigures .embeddedobject--rte--themecolor,
  html.ut--factsfigures .smartlayout--rtdtext[data-contentabove*=embeddedobject--rte--themecolor],
  html.ut--factsfigures .embeddedobject--carrousel,
  html.ut--factsfigures .smartlayout--rtdtext[data-contentabove*=embeddedobject--carrousel],
  html.ut--factsfigures .embeddedobject--imageblock,
  html.ut--factsfigures .smartlayout--rtdtext[data-contentabove*=embeddedobject--imageblock] {
    margin-top: 100px;
  }
}