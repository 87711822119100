/*
.embeddedobject__course_overview
{
  margin-top: 0 !important;
}
*/
.embeddedobject__course_overview ul
{
  border-radius: 2px;
  border: 1px solid var(--color-grey-lines);
  background-color: rgba(240,241,242,0.5);
  list-style-type: none;
  margin: 0;
  padding: 50px 25px;
}
.embeddedobject__course_overview li
{
  display: flex;
  flex-flow: row;
  font-size: 15px;
}
.embeddedobject__course_overview li + li
{
  padding-top: 20px;
}
.embeddedobject__course_overview li > span
{
  display: inline-block;
}
.embeddedobject__course_overview .icon
{
  color: var(--color-grey-normal);
  font-size: 24px;
  flex: 0 0 35px;
}
.embeddedobject__course_overview .title
{
  font-weight: 700;
  padding-right: 15px;
  text-transform: uppercase;
  flex: 0 0 190px;
}
.embeddedobject__course_overview .description
{
  flex: 1 1 auto;
}
.embeddedobject__course_overview .description > p
{
  padding: 0 !important;
  margin: 0 !important;
}
@media(max-width: 650px)
{
  .embeddedobject__course_overview li
  {
     flex-wrap: wrap;
     justify-content: flex-end;
  }
    .embeddedobject__course_overview .title
  , .embeddedobject__course_overview .description
  {
    flex: 0 0 calc(100% - 35px);
  }
}
