/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@import "@mod-utwente_design/scss/config-colors.css";
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*

Filteredoverview is a generic setup for pages with filters, feedback and results.
See the readme.md on instructions and tips on how to use it.


NOTE:
- One reason we enforce using the .filter__row__item wrapper is so
  replaced components can inherit their width.
  If we target an <select> directly we will fail to style an replacement (.ut-pulldown).
  If we let the <select> and .ut-pulldown both just use width: 100%; we don't need to care anymore what kind of component is used inside.


Elements:
- .header__container::after // header slideshow
- .header__universe::after  // animated universie SVG thingy

*/
.filteredoverview__toggleandsearchbar input[type=text],
.filteredoverview__toggleandsearchbar input[type=search],
.filters__expandedcontent input[type=text],
.filters__expandedcontent input[type=search] {
  border-radius: 0 !important;
}

/*
Users of .filteredoverview__widgetheader:
- Studyfinder widget
- Used for dislaying a list of partners in "ITC Major project"-detail page
- Used for dislaying a list of partners in "ITC PHD project"-detail page
*/
.filteredoverview__widgetheader__content {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
  padding-left: 10px;
  padding-right: 10px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
}
@media (max-width: 960px) {
  .filteredoverview__widgetheader__content {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .filteredoverview__widgetheader__content {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (max-width: 960px) {
  .filteredoverview__widgetheader__content {
    width: auto;
  }
}
@media (max-width: 990px) {
  .filteredoverview__widgetheader__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.filteredoverview__widgetheader__content > h2 {
  margin: 0;
  min-width: 0;
  flex: auto;
}
.filteredoverview__widgetheader__content > a {
  display: inline-block;
  margin-left: auto;
  flex: none;
  font-size: 18px;
  font-weight: 300;
}

.embeddedobject__studyfinder .filteredoverview__widgetheader {
  border-top: 1px solid #dcddde;
  padding-top: 73px;
}
.main__contents > .embeddedobject__studyfinder:first-child .filteredoverview__widgetheader {
  border-top: 0;
}
.embeddedobject__studyfinder .filteredoverview__widgetheader__content {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.widget--itcphdprojectpartners {
  margin-top: 75px;
}

.main__contents > .filteredoverview__results:first-child {
  margin-top: 30px;
}

.filters__expandedcontent label {
  cursor: pointer;
}

.filters__expandedcontent {
  font-size: 13px;
}

header .filteredoverview__filterholder {
  margin-top: 28px;
  padding-bottom: 28px;
}

@media (max-width: 479px) {
  html.showfilters header .filteredoverview__filterholder {
    padding-bottom: 70px;
  }
}
@media (min-width: 480px) {
  html.showfilters .header__universe .filteredoverview__filterholder {
    padding-bottom: 40px;
  }
}
.filteredoverview__freesearch.filteredoverview__freesearch input[name=words],
.filteredoverview__freesearch.filteredoverview__freesearch input[name=query] {
  padding-right: 32px;
  transition: max-width 0.2s;
}

.header--content8col .filteredoverview__freesearch input[name=words],
.header--content8col .filteredoverview__freesearch input[name=query] {
  width: 100%;
}
@media (min-width: 480px) {
  .header--content8col .filteredoverview__togglefiltersbutton + .filteredoverview__freesearch input[name=words],
  .header--content8col .filteredoverview__togglefiltersbutton + .filteredoverview__freesearch input[name=query] {
    max-width: calc(100% - 20px);
  }
}

.header--content12col .filteredoverview__freesearch input[name=words],
.header--content12col .filteredoverview__freesearch input[name=query] {
  width: 100%;
  max-width: calc(0% + 300px);
  height: 40px;
}
.header--content12col .filteredoverview__freesearch input[name=words]:focus,
.header--content12col .filteredoverview__freesearch input[name=query]:focus {
  max-width: calc(100% + 0px);
}
@media (max-width: 750px) {
  .header--content12col .filteredoverview__freesearch input[name=words],
  .header--content12col .filteredoverview__freesearch input[name=query] {
    max-width: 100%;
  }
}

@media (max-width: 479px) {
  .filteredoverview__freesearch {
    margin-top: 30px;
  }
}
@media (min-width: 480px) {
  .filteredoverview__toggleandsearchbar {
    display: flex;
    width: 100%;
  }
  .filteredoverview__freesearch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.filteredoverview__togglefiltersbutton {
  font-family: inherit;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  height: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: 18px;
  font-weight: 300;
  padding: 0 20px;
  height: 40px;
  background-color: var(--color-white);
  color: #53575b;
  border: 1px solid #afb1b3;
  cursor: pointer;
}

.filteredoverview__togglefiltersbutton span {
  color: var(--color-grey-dark);
}

html.showfilters .filteredoverview__togglefiltersbutton {
  background-color: var(--color-grey-dark);
  color: var(--color-white);
}

html.showfilters .filteredoverview__togglefiltersbutton span {
  color: var(--color-white);
}

.filteredoverview__togglefiltersbutton__icon {
  font-size: 16px;
  margin-left: 20px;
}

.filteredoverview__togglefiltersbutton {
  flex: none;
  margin-right: 40px;
}

.filteredoverview__freesearch {
  position: relative;
  margin-left: auto;
  position: relative;
  flex: 1 1 0px;
  text-align: right;
}

.filteredoverview__freesearch button {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: auto;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  font-size: 16px;
  color: #afb1b3;
  cursor: pointer;
}

.filteredoverview__freesearch:hover input,
.filteredoverview__freesearch:hover button {
  color: #1e2328;
}

/* In image/slideshow header */
.header__container .filteredoverview__togglefiltersbutton {
  background-color: #1e2328;
  color: var(--color-white);
  border: 0;
}
html.showfilters .header__container .filteredoverview__togglefiltersbutton {
  background-color: var(--color-white);
  color: #1e2328;
}
html.showfilters .header__container .filteredoverview__togglefiltersbutton span {
  color: #1e2328;
}
.header__container .filteredoverview__freesearch input[type=search] {
  background-color: #1e2328;
  color: #f0f1f2;
  border: 0;
  padding-left: 20px;
  transition: max-width 0.2s, background-color 0.2s, color 0.2s;
}
.header__container .filteredoverview__freesearch input[type=search]::placeholder {
  color: #afb1b3;
  font-size: 13px;
  opacity: 1;
}
.header__container .filteredoverview__freesearch input[type=search]:focus,
.header__container .filteredoverview__freesearch:hover input[type=search] {
  background-color: var(--color-white);
  color: #1e2328;
}
.header__container .filteredoverview__freesearch:hover + button:hover,
.header__container .filteredoverview__freesearch input[type=search]:focus + button:hover {
  color: #1e2328 !important;
}

.filters__expandedcontent .filter__row + .filter__row {
  margin-top: 30px;
}

.filters__expandedcontent .filter__row__item + .filter__row__item {
  margin-left: 20px;
}

.filter__row--flex {
  display: flex;
  width: 100%;
}

.filter__row--flex .filter__row__item {
  flex: auto;
}

.filter__row__item input[text=text] {
  width: 100%;
  min-width: 0;
}

.filter__row__item--flex {
  display: flex;
  align-items: baseline;
}

.filter__row__item--flex .ut-pulldown {
  width: auto;
}

.filters__expandedcontent .filter__header {
  font-size: 15px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.filters__expandedcontent .checkboxrow {
  margin-top: 11px;
  line-height: 20px;
}

.filters__expandedcontent .ut-pulldown {
  line-height: 25px;
}

.filters__expandedcontent .checkboxrow:first-child {
  margin-top: 0;
}

.filters__expandedcontent .checkboxrow label::before {
  margin-right: 7px;
}

.filters__expandedcontent .checkboxrow label {
  display: flex;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}

.filters__expandedcontent .checkboxrow label::before {
  flex: none;
}

header .filters__expandedcontent__content {
  transition: opacity 0.2s;
  transition-delay: 0.2s;
  opacity: 0;
}

html:not(.showfilters) header .filters__expandedcontent {
  height: 0;
  overflow: hidden;
  margin-top: 0;
}

html.showfilters header .filters__expandedcontent__content {
  opacity: 1;
}

.header__container--withfilters .header__titleholder {
  bottom: 0;
}

.filters__expandedcontent__content {
  padding-top: 30px;
}

/* image / slideshow */
.header__container + .filters__expandedcontent > .filters__expandedcontent__content {
  padding-top: 30px;
  padding-bottom: 30px;
}

html.site--itc .header__container + .filters__expandedcontent > .filters__expandedcontent__content {
  padding-bottom: 55px;
}

html:not(.filteredoverview--noresults) .filteredoverview__noresultsmessage {
  display: none !important;
}

html.filteredoverview--noresults .filteredoverview__detailview,
html.filteredoverview--noresults .filteredoverview__listview {
  display: none;
}

.filteredoverview__noresultsmessage {
  margin-top: 70px;
  margin-bottom: 100px;
}

.filteredoverview__teaser {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  width: 100%;
  height: 49px;
  background-color: var(--color-white);
  color: #00675a;
  line-height: 49px;
  text-decoration: underline;
  font-size: 13px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}

.filteredoverview__teaser--aboveresults {
  opacity: 1;
  pointer-events: auto;
}

html.site--itc .filteredoverview__teaser::before {
  position: absolute;
  bottom: 49px;
  width: 100%;
  display: block;
  content: "";
  padding-top: 9%;
  background: url("../../web/img/itc/header-paintstroke.png"), linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgb(255, 255, 255) 100%);
  background-size: cover;
  pointer-events: none;
}