/***************************************************************
*
*  UT Search Icons font
*
*/
@font-face {
  font-family: 'UT-Search-iconfont';
  src:  url('ut-search-iconfont.ttf?jeg5m8') format('truetype'),
        url('ut-search-iconfont.woff?jeg5m8') format('woff'),
        url('ut-search-iconfont.svg?jeg5m8#UT-Search-iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ut-searchicon_"], [class*=" ut-searchicon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'UT-Search-iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ut-searchicon_01-ut-14px:before {
  content: "\e900";
}
.ut-searchicon_02-people-12px:before {
  content: "\e901";
}
.ut-searchicon_03-news-12px:before {
  content: "\e902";
}
.ut-searchicon_04-lock-12px:before {
  content: "\e903";
}
.ut-searchicon_05-play-12px:before {
  content: "\e904";
}
.ut-searchicon_06-info-12px:before {
  content: "\e905";
}
.ut-searchicon_07-calendar-12px:before {
  content: "\e906";
}
.ut-searchicon_08-book-12px:before {
  content: "\e907";
}
