html.eligibilitycheck {
  background-color: var(--color-grey-light);
}

.echeck2018__form {
  grid-column: var(--main-12col-layout) !important;
  display: block;
  box-shadow: 0 50px 50px -30px rgba(30, 35, 40, 0.3);
  padding-top: 60px;
}
html.utwente--menuhidden .echeck2018__form {
  margin-top: 60px;
}
@media (max-width: 959px) {
  .echeck2018__form {
    margin-top: 0 !important;
  }
}
@media (max-width: 750px) {
  .echeck2018__form {
    grid-column: var(--main-full-layout) !important;
  }
}
.echeck2018__form .wh-form__prefillarea {
  position: absolute;
}
.echeck2018__form .wh-form__fieldgroup > .wh-form__label {
  font-weight: 600;
  margin-bottom: 5px;
}
.echeck2018__form .wh-form__fieldgroup + h2 {
  margin-bottom: 20px;
}
.echeck2018__form__pages > div, .echeck2018__form__footer > div, .echeck2018__form__header__title {
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
}
.echeck2018__form__header {
  background-color: #fff;
  padding: 50px 30px;
}
.echeck2018__form__header h1 {
  margin: 0;
  font: 700 40px/100% var(--rtd-heading-fontfamily);
}
.echeck2018__form__header h1 small {
  text-transform: none;
  display: block;
  font-size: 18px;
  font-weight: 400;
  color: #01675B;
}
.echeck2018__form__progress {
  margin-top: 45px;
  background-color: #8E9495;
  height: 1px;
  position: relative;
  z-index: 0;
}
@media (max-width: 750px) {
  .echeck2018__form__progress {
    margin-left: -30px;
    margin-right: -30px;
  }
}
.echeck2018__form__progress__indicator {
  position: absolute;
  left: 0;
  top: 0;
  height: 2px;
  background-color: #409C35;
}
.echeck2018__form__progress__inner {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.echeck2018__form__progress__page--withlabel {
  position: relative;
  top: -7px;
  border: 1px solid var(--color-grey-normal);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
}
.echeck2018__form__progress__label {
  position: absolute;
  top: -14px;
  left: -14px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: #fff;
  background-color: #409C35;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.echeck2018__form__progress__label::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.echeck2018__form__progress__label::after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  border: 3px solid #fff;
}
.echeck2018__form__progress__label[hidden] {
  display: none;
}
.echeck2018__form__progress__page--checked .echeck2018__form__progress__label, .echeck2018__form__progress__page--failed .echeck2018__form__progress__label {
  top: -9px;
  left: -9px;
  width: 28px;
  height: 28px;
  background-color: #fff;
  color: #409C35;
  border: 1px solid #409C35;
}
.echeck2018__form__progress__page--checked .echeck2018__form__progress__label__nr, .echeck2018__form__progress__page--failed .echeck2018__form__progress__label__nr {
  display: none;
}
.echeck2018__form__progress__page--checked .echeck2018__form__progress__label::after, .echeck2018__form__progress__page--failed .echeck2018__form__progress__label::after {
  display: none;
}
.echeck2018__form__progress__page--checked .echeck2018__form__progress__label::before {
  content: "\e931";
}
.echeck2018__form__progress__page--failed ~ .echeck2018__form__progress__page {
  visibility: hidden;
}
.echeck2018__form__progress__page--failed .echeck2018__form__progress__label {
  color: #C60C31;
  border-color: #C60C31;
}
.echeck2018__form__progress__page--failed .echeck2018__form__progress__label::before {
  content: "\e92e";
}
.echeck2018__form--success .echeck2018__form__progress__page {
  background-color: transparent;
  border: 0 none;
}
.echeck2018__form--success .echeck2018__form__progress__page:not(.echeck2018__form__progress__page--checked) .echeck2018__form__progress__label {
  display: none;
}
.echeck2018__form--success .echeck2018__form__progress__indicator {
  width: 100% !important;
}
.echeck2018__form--failed .echeck2018__form__progress::after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  background-color: #C60C31;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.echeck2018__form__pages {
  background-color: #fff;
  border-bottom: 1px solid var(--color-grey-normal);
  padding: 0 30px 50px;
}
.echeck2018__form__terms {
  border: 1px solid var(--color-grey-normal);
  padding: clamp(20px, 2.5vw, 25px);
  position: relative;
  margin-top: 40px;
  padding-left: clamp(40px, 5vw, 50px);
}
.echeck2018__form__terms__icon {
  color: var(--theme-color-cta);
  font-size: 64px;
  position: absolute;
  left: -23px;
  top: 20px;
}
.echeck2018__form__terms p:first-child {
  margin-top: 0;
}
.echeck2018__form__terms[hidden] {
  display: none;
}
.echeck2018__form__footer {
  background-color: var(--color-grey-lighter);
  padding: 40px 30px;
}
.echeck2018__form__footer__buttongroup {
  text-align: right !important;
  display: block !important;
}
.echeck2018__form__footer__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.echeck2018__form__footer__buttongroup .echeck2018__prevbutton {
  float: left;
  display: inline-flex;
  align-items: center;
  text-transform: none;
  font-weight: normal;
  background-color: transparent !important;
  animation: none !important;
  border-radius: 0;
  text-decoration: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
}
.echeck2018__form__footer__buttongroup .echeck2018__prevbutton .wh-form__buttonlabel {
  font-size: 15px;
  color: var(--color-grey-dark);
}
.echeck2018__form__footer__buttongroup .echeck2018__prevbutton::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e92a";
  display: inline-block;
  color: var(--color-grey-normal);
  transition: color 0.3s;
  margin-right: 6px;
}
.echeck2018__form__footer__buttongroup .echeck2018__prevbutton:hover:not([disabled]) {
  background-color: transparent !important;
  color: #333 !important;
}
.echeck2018__form__footer__buttongroup .echeck2018__prevbutton:hover:not([disabled])::before {
  color: #333;
}
.echeck2018__form__footer__buttongroup .echeck2018__nextbutton {
  float: right;
}
.echeck2018__form--failed .echeck2018__nextbutton, .echeck2018__form--success .echeck2018__nextbutton, .echeck2018__form--success .echeck2018__prevbutton {
  display: none;
}
.echeck2018__form > .wh-form__requirednote {
  display: none;
}
.echeck2018__form__groupcolumns .wh-form__fields {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: none;
  position: relative;
}
.echeck2018__form__groupcolumns .wh-form__fields .wh-anchor {
  flex: none;
  position: absolute;
}
.echeck2018__form__groupcolumns .wh-form__fields .wh-form__error {
  width: 100%;
}
.echeck2018__form__groupcolumns__column {
  flex: none;
  width: calc(50% - 30px);
}
.echeck2018__form__groupcolumns__column h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.echeck2018__form__groupcolumns__column .wh-form__fieldline + h4 {
  margin-top: 15px;
}
@media (max-width: 550px) {
  .echeck2018__form__groupcolumns .wh-form__fields {
    display: block;
  }
  .echeck2018__form__groupcolumns__column {
    width: auto;
  }
  .echeck2018__form__groupcolumns__column + .echeck2018__form__groupcolumns__column {
    margin-top: 15px;
  }
}

.echeck2018__page__question {
  margin-bottom: 30px;
  display: flex;
  align-items: baseline;
}
.echeck2018__page__question__title {
  flex: 1 1 auto;
}
.echeck2018__page__question__title h2 {
  margin: 0;
}
.echeck2018__page__question__icon {
  flex: none;
  margin-left: 20px;
  font-size: clamp(32px, 4.8vw, 48px);
}

.echeck2018__page {
  display: none;
}
.echeck2018__page > p:first-child {
  margin-top: 0;
}
.echeck2018__page--selected {
  display: block;
}