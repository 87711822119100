.widget-stayuptodate {
  /*
  foldablecontent__text has 10px v padding
  foldablecontent__text--rte has 30px v padding
  for inline stayuptodate widget we want 50px v padding
  */
}
.widget-stayuptodate .foldablecontent__foldcontainer {
  background-color: var(--color-grey-lighter);
}
.widget-stayuptodate .foldablecontent__text {
  position: relative;
}
.widget-stayuptodate .foldablecontent__title {
  border-top: 0 none;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-stayuptodate .foldablecontent__title::before, .widget-stayuptodate .foldablecontent__title::after {
  display: none;
}
.widget-stayuptodate .foldablecontent__title .icon {
  font-size: 48px;
  flex: none;
}
.widget-stayuptodate .foldablecontent__title .title {
  flex: 1 1 auto;
  font-size: 18px;
  line-height: 140%;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-stayuptodate .foldablecontent__title .title small {
  font-size: 100%;
  font-weight: 300;
  color: #5A5E62;
  text-decoration: underline;
}
.widget-stayuptodate .foldablecontent__text {
  margin-right: 20px;
  margin-left: 20px;
}
.widget-stayuptodate .foldtoggle {
  flex: none;
  cursor: pointer;
}
.widget-stayuptodate .foldtoggle > span {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.widget-stayuptodate .foldtoggle__open {
  display: flex;
  position: relative;
}
.widget-stayuptodate .foldtoggle__open::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 12px;
  background-color: #fff;
}
.widget-stayuptodate .foldtoggle__open::after {
  content: "";
  transform: rotate(45deg);
  position: absolute;
  top: 13px;
  left: 12px;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.widget-stayuptodate .foldtoggle__close {
  display: none;
  background-color: #AAACAE;
}
.widget-stayuptodate .foldtoggle__close::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 2px;
  background-color: #fff;
}
.widget-stayuptodate .foldablecontent--open .foldtoggle__open {
  display: none;
}
.widget-stayuptodate .foldablecontent--open .foldtoggle__close {
  display: flex;
}
.widget-stayuptodate .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}
@media (max-width: var(--breakpoint-tablet)) {
  .widget-stayuptodate .wh-form__buttongroup .wh-form__button + .wh-form__button {
    margin-left: 0;
    margin-top: 15px;
  }
}
.widget-stayuptodate p {
  margin-top: 0;
  margin-bottom: 15px;
}
.widget-stayuptodate .wh-form__page {
  margin-top: 0;
}
.widget-stayuptodate .wh-form__buttongroup {
  margin-top: 30px;
}
.widget-stayuptodate .wh-form__richtext {
  display: block !important;
}
.widget-stayuptodate .foldablecontent__text {
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--color-grey-lines);
}