@import "@mod-utwente_design/scss/config-colors.css";
/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
:root {
  --zindex-modaldialog: 12;
  --zindex-popup: 11;
  --zindex-cobrowser: 10;
  --zindex-searchsheet: 9;
  --zindex-hamburger: 8;
  --zindex-menu: 7;
  --zindex-menumodal: 6;
  --zindex-messagesarea: 5;
  --zindex-navbar: 4;
  --zindex-above-deeplinks: 3;
  --zindex-deeplinks: 2;
  --zindex-main: 1;
  --zindex-header-cta: 6;
  --zindex-title: 5;
  --zindex-itc-swoosh: 4;
  --zindex-header: 2;
  --zindex-headerbg: -1;
  --breakpoint-flexgrid: 1430px;
  --breakpoint-menuhidden: 1279px;
  --breakpoint-deeplinks: 959px;
  --breakpoint-tablet: 749px;
  --breakpoint-phone: 480px;
  --menupart-translation: none;
  --menu-width: 260px;
  --menu-height: auto;
  --menu-transition-duration: 250ms;
  --menu-topmenu-display: none;
  --menu-topmenu-items-display: none;
  --menu-topmenu-title-border: none;
  --menu-padding: 40px;
  --menu-header-spacing: 0;
  --menu-indentation: 20px;
  --menu-item-height: 40px;
  --menu-item-transition-duration: 150ms;
  --contentpart-margin: var(--menu-width);
  --hamburger-display: none;
  --hamburger-top: 0px;
  --header-navbar-height: 0;
  --header-margin: 0;
  --header-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --header-background-height: 260px;
  --header-grid-rows: [top] auto [header-top] 1fr [title-above] calc(var(--header-background-height) - 2 * var(--header-sliderdots-height)) [title-below] var(--header-sliderdots-height) [bottom];
  --header-spacing: 30px;
  --header-photos-width: 1600px;
  --header-photos-height: 640px;
  --header-photos-max-height: 640px;
  --header-photos-aspect-padding: 0;
  --header-videos-display: block;
  --header-videomobile-display: none;
  --header-videos-width: 1280px;
  --header-videos-height: 352px;
  --header-videos-aspect-padding: 0;
  --header-photos-fixed-height: 320px;
  --header-sliderdots-height: 45px;
  --header-sliderdots-display: flex;
  --header-slideshow-duration: 1s;
  --header-author-height: 16px;
  --header-author-bottom: calc((var(--header-sliderdots-height) - var(--header-author-height)) / 2);
  --header-author-right: calc((var(--header-sliderdots-height) - var(--header-author-height)) / 2);
  --header-filters-height: 100px;
  --header-filters-margin: 0;
  --header-title-margin: 0;
  --universe-rotation-duration: 180s;
  --notification-grid-area: grid-left / grid-right;
  --notification-cookiebar-separator: none;
  --notification-padding: 20px 64px 16px;
  --header-cta-width: 320px;
  --header-cta-padding-vert: 20px;
  --header-cta-padding-hor: 20px;
  --header-cta-flex-direction: column;
  --header-title-cta-padding: 0;
  --header-overlay-width: 125px;
  --header-overlay-position: absolute;
  --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2) + 20px);
  --header-overlay-transform: translateY(calc(100% - 100px));
  --main-grid-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --main-grid-column: 65px;
  --main-grid-gap: 30px;
  --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
  --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
  --main-grid-margin: 1fr;
  /*
  [left] [grid-left] [text-left]                                 [text-right] [grid-right] [right]
    |        |           |                                               |           |        |
    | margin | 2 columns |                   8 columns                   | 2 columns | margin |
             |     |     |     |     |     |     |     |     |     |     |     |     |
             |                              12 columns                               |

    By default, all content columns have a fixed width and the margins have a width of 1fr. If the width is too small to
    contain the fixed-width columns, the columns get a width of 1fr and the margins are set to 0. There will always be at
    least a gutter-sized padding left and right.
  */
  --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left] repeat(2, var(--main-grid-column)) [text-left] repeat(8, var(--main-grid-column)) [text-right] repeat(2, var(--main-grid-column)) [grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
  --main-8col-layout: text-left / text-right;
  --main-12col-layout: grid-left / grid-right;
  --main-full-layout: left / right;
  --main-embed-title-layout: grid-left / grid-right;
  --main-padding-bottom: 50px;
  --widget-equalheight-width: calc((var(--main-grid-width) - 2 * var(--main-grid-gap) - 1px) / 3);
  --newseventsrecent-news-columns: grid-left / span 8;
  --newseventsrecent-sidebar-columns: span 4 / grid-right;
  --newseventsrecent-sidebar-padding: 25px;
  --slideshow-height: 450px;
  --slideshow-flex-direction: row;
  --slideshow-panel-flex: 0 0 50%;
  --slideshow-panelleft-order: 1;
  --slideshow-panelleft-padding: 0 var(--main-grid-gap) 0 0;
  --slideshow-panelleft-width: auto;
  --slideshow-panelright-order: 2;
  --slideshow-panelright-height: auto;
  --banner-slide-width: var(--main-grid-width);
  --banner-slide-padding: 0 100px 0 0;
  --banner-overlay-width: var(--main-grid-width);
  --people-overiew-list-columns: grid-left / span 8;
  --footer-padding-right: env(safe-area-inset-right);
  --footer-padding-bottom: env(safe-area-inset-bottom);
  --footer-padding-left: env(safe-area-inset-left);
  --footer-prefooter-display: grid;
  --footer-prefooter-height: 300px;
  --footer-address-columns: grid-left / span 4;
  --footer-address-padding: 0px;
  --footer-address-width: auto;
  --footer-image-columns: span 9 / right;
  --footer-share-column: span 3 / grid-right;
  --footer-links-column-1: 2 / span 3;
  --footer-links-column-2: 5 / span 3;
  --footer-links-column-3: 8 / span 3;
  --footer-links-column-4: 11 / span 3;
  --footer-footerlinks-column: var(--main-12col-layout);
  --deeplinks-display: block;
  --deeplinks-width: 160px;
  --deeplinks-margin: 50px;
  --deeplinks-padding: 10px;
  --deeplinks-top: calc(var(--navbar-height) + var(--deeplinks-margin));
  --cta-font-size: 40px;
  --cta-line-height: 40px;
  --searsia-sheet-top: var(--navbar-height);
  --searsia-form-width: 920px;
  --searsia-form-margin: calc(50% - var(--searsia-form-width) / 2);
  --searsia-close-display: none;
  --searsia-close-color: var(--color-white);
  --searsia-close-background-color: var(--color-black);
}

html.ut--nonbranding {
  --searsia-sheet-top: 0px;
  --searsia-close-display: block;
  --searsia-close-color: var(--color-black);
  --searsia-close-background-color: var(--color-white);
}

:root {
  --navbar-position: sticky;
  --navbar-flex-direction: column;
  --navbar-top: 0;
  --navbar-height: 60px;
  --navbar-padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  --navbar-background-color: var(--color-black);
  --navbar-color: var(--color-white);
  --navbar-menu-display: block;
  --navbar-menu-width: 240px;
  --navbar-menu-padding: 10px;
  --navbar-menu-item-height: 40px;
  --navbar-menu-item-padding: 20px;
  --navbar-menu-border-color: var(--color-grey-dark);
  --navbar-menu-border-padding: 0;
}

html.ut--nonbranding {
  --navbar-background-color: var(--color-grey-light);
  --navbar-color: var(--color-black);
  --navbar-menu-border-color: var(--color-grey-lines);
  --navbar-menu-border-padding: 10px;
}

.contentpart__navbar-crumbs {
  --breadcrumbs-padding: 0 30px;
  --breadcrumbs-color: var(--color-white);
  --breadcrumbs-display: block;
}
.contentpart__navbar-crumbs .contentpart__breadcrumblist {
  --breadcrumbs-display: flex;
}
html.ut--nonbranding .contentpart__navbar-crumbs {
  --breadcrumbs-color: var(--color-black);
}

.contentpart__header-crumbs {
  --breadcrumbs-padding: 10px calc(20px + env(safe-area-inset-right)) 10px calc(20px + env(safe-area-inset-left));
  --breadcrumbs-color: var(--color-black);
  --breadcrumbs-display: none;
}
.contentpart__header-crumbs .contentpart__breadcrumblist {
  --breadcrumbs-display: flex;
}

.menupart {
  --logo-display: flex;
  --identity-display: block;
  --identity-margin: 0;
  --identity-titles-margin: 0 20px 2px 0;
  --identity-titles-padding: 30px env(safe-area-inset-right) 10px calc(20px + env(safe-area-inset-left));
  --identity-titles-lineheight: 22px;
  --identity-branding-logo-margin-hor: calc(20px + max(env(safe-area-inset-left), env(safe-area-inset-right)));
  --identity-branding-logo-margin-vert: 20px;
  --identity-branding-partner-navbar-height: 40px;
  --identity-itc-logo-width: 52px;
  --identity-itc-logo-top: 0px;
  /* Tweak some settings for ITC */
  /* Tweak some settings for non-branding logo sites */
}
.menupart .identitypart__site--itc {
  --identity-titles-padding: 15px calc(25px + env(safe-area-inset-right)) 0 calc(25px + env(safe-area-inset-left));
}
.menupart .identitypart__site--branding {
  --identity-titles-margin: 0;
  --identity-titles-padding: var(--identity-branding-logo-margin-vert) var(--identity-branding-logo-margin-hor) 0;
}

.contentpart {
  --logo-display: none;
  --identity-display: none;
  --identity-margin: calc(var(--navbar-height) + env(safe-area-inset-left));
  --identity-titles-margin: 0 20px 2px 0;
  --identity-titles-padding: 0 env(safe-area-inset-right) calc(((var(--navbar-height) - var(--identity-titles-lineheight)) / 2) - 2px) 20px;
  --identity-titles-lineheight: 22px;
  --identity-branding-logo-margin-hor: 20px;
  --identity-branding-logo-margin-vert: 20px;
  --identity-branding-partner-navbar-height: var(--navbar-height);
  --identity-itc-logo-width: 52px;
  --identity-itc-logo-top: -11px;
  /* Tweak some settings for ITC */
  /* Tweak some settings for non-branding logo sites */
}
.contentpart .identitypart__site--itc {
  --identity-titles-padding: 0 20px;
}
.contentpart .identitypart__site--branding {
  --identity-titles-margin: 0;
  --identity-titles-padding: 0 var(--identity-branding-logo-margin-hor) var(--identity-branding-logo-margin-vert);
}

html.site--itc {
  --header-sliderdots-height: 90px;
  --header-author-bottom: 0;
  --header-author-right: 0;
  --header-filters-margin: 60px; /* push content at the bottom of the page header upwards so it's not fully on the ITC swoosh */
}

.cta__button__title--default {
  --header-cta-button-display: inline;
}

.cta__button__title--mobile {
  --header-cta-button-display: none;
}

html.ws2016--iframed {
  --menu-width: 0px;
}

@media (max-width: 1820px) {
  :root {
    /* Scale header photo/video proportionally */
    --header-photos-width: auto;
    --header-photos-height: auto;
    --header-photos-aspect-padding: calc(320 / 750 * 100%);
    --header-videos-aspect-padding: calc(320 / 750 * 100%);
  }
}
@media (max-width: 1690px) {
  :root {
    /* Update header title CTA padding */
    --header-title-cta-padding: 130px;
  }
}
@media (max-width: 1490px) {
  :root {
    /* Prevent title from sticking to left side of screen */
    --header-title-margin: calc((var(--main-grid-width) + 2 * var(--main-grid-gap) - (100vw - var(--menu-width) - 2 * var(--header-spacing))) / 2);
  }
}
@media (max-width: 1430px) {
  :root {
    /* Make the content columns flexible, hide the margins left and right */
    --main-grid-column: 1fr;
    --main-grid-margin: 0px;
    --main-content-width: calc((100vw - var(--menu-width) - 13 * var(--main-grid-gap)) * 8 / 12 + 7 * var(--main-grid-gap));
    --main-grid-width: calc(100vw - var(--menu-width) - 2 * var(--main-grid-gap));
    /* Prevent title from sticking to left side of screen */
    --header-title-margin: var(--header-spacing);
    /* Update header title CTA padding */
    --header-title-cta-padding: 190px;
  }
}
/*
Options for having the option to hide the menu by default:
1. we dont use a media query (@media (max-width: 1279px)) but set a class
   (using the same code which uses the --breakpoint-menuhidden to open/close the menu based on the viewport size)
   - If the JS code fails, the layout will be messed up
2. use a mixins and publish the overrides in both the media query AND
3. have JS find this media query in the sheet and update it from 1279px to a very high number if .ut--menuhiddenbydefault is set
*/
@media (max-width: 1279px) {
  :root {
    /* Make room for the navbar */
    --header-margin: var(--header-navbar-height);
    /* Remove spacing around header photo */
    --header-spacing: 0px;
    /* Reposition the menu */
    --menupart-translation: translateX(calc(0px - var(--menu-width)));
    --menu-height: 100vh;
    --menu-header-spacing: calc(var(--navbar-height) + 1px);
    --contentpart-margin: 0;
    /* Make notification area take up all width, add separator between cookiebar and notification */
    --notification-grid-area: left / right;
    --notification-cookiebar-separator: 1px solid var(--color-grey-lines);
    /* Scroll navbar with site */
    --navbar-position: absolute;
    /* Show the hamburger and menu top menu title */
    --hamburger-display: block;
    --menu-topmenu-display: flex;
    /* Update header title CTA padding */
    --header-title-cta-padding: 165px;
    /* Remove extra space between photo edge and overlay */
    --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2));
    /* Make the content columns static again */
    --main-grid-column: 65px;
    --main-grid-margin: 1fr;
    --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
    --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
    /* Update deeplinks top position */
    --deeplinks-top: var(--deeplinks-margin);
  }
  /* Make room for site title bar in navbar */
  html.utwente--sitetitlebar {
    --hamburger-top: var(--navbar-height);
  }
  /* Author shouldn't stick to the right side of the screen */
  html.site--itc {
    --header-author-right: 30px;
  }
  /* Navbar and site title are combined */
  html.ut--nonbranding {
    --navbar-flex-direction: row-reverse;
    --navbar-background-color: var(--color-white);
    --hamburger-top: 0;
  }
  /* Hide the menu logo and site titles, show the navbar logo and site titles */
  /* Hide the menu site titles, show the navbar site titles */
  .menupart {
    --logo-display: none;
    --identity-display: none;
  }
  .contentpart {
    --logo-display: flex;
    --identity-display: flex;
  }
}
html.ut--menuhiddenbydefault {
  --menupart-translation: translateX(calc(0px - var(--menu-width))) !important;
  --hamburger-display: block;
  /* Make room for site title bar in navbar */
  /* Navbar and site title are combined */
}
html.ut--menuhiddenbydefault.utwente--sitetitlebar {
  --hamburger-top: var(--navbar-height);
}
html.ut--menuhiddenbydefault.ut--nonbranding {
  --hamburger-top: 0;
}

html.ut--menuhiddenbydefault.utwente--menuhidden {
  /* Make room for site title bar in navbar */
  /* Author shouldn't stick to the right side of the screen */
  /* Navbar and site title are combined */
  /* Hide the menu logo and site titles, show the navbar logo and site titles */
  /* Hide the menu site titles, show the navbar site titles */
  --breakpoint-menuhidden: 13371337px; /* Set Javascript trigger for showing menu very high */
  --contentpart-margin: 0;
}
html.ut--menuhiddenbydefault.utwente--menuhidden :root {
  /* Make room for the navbar */
  --header-margin: var(--header-navbar-height);
  /* Remove spacing around header photo */
  --header-spacing: 0px;
  /* Reposition the menu */
  --menupart-translation: translateX(calc(0px - var(--menu-width)));
  --menu-height: 100vh;
  --menu-header-spacing: calc(var(--navbar-height) + 1px);
  --contentpart-margin: 0;
  /* Make notification area take up all width, add separator between cookiebar and notification */
  --notification-grid-area: left / right;
  --notification-cookiebar-separator: 1px solid var(--color-grey-lines);
  /* Scroll navbar with site */
  --navbar-position: absolute;
  /* Show the hamburger and menu top menu title */
  --hamburger-display: block;
  --menu-topmenu-display: flex;
  /* Update header title CTA padding */
  --header-title-cta-padding: 165px;
  /* Remove extra space between photo edge and overlay */
  --header-overlay-left: calc(((100% - var(--main-grid-width)) / 2));
  /* Make the content columns static again */
  --main-grid-column: 65px;
  --main-grid-margin: 1fr;
  --main-content-width: calc(8 * var(--main-grid-column) + 7 * var(--main-grid-gap));
  --main-grid-width: calc(12 * var(--main-grid-column) + 11 * var(--main-grid-gap));
  /* Update deeplinks top position */
  --deeplinks-top: var(--deeplinks-margin);
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.utwente--sitetitlebar {
  --hamburger-top: var(--navbar-height);
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.site--itc {
  --header-author-right: 30px;
}
html.ut--menuhiddenbydefault.utwente--menuhidden html.ut--nonbranding {
  --navbar-flex-direction: row-reverse;
  --navbar-background-color: var(--color-white);
  --hamburger-top: 0;
}
html.ut--menuhiddenbydefault.utwente--menuhidden .menupart {
  --logo-display: none;
  --identity-display: none;
}
html.ut--menuhiddenbydefault.utwente--menuhidden .contentpart {
  --logo-display: flex;
  --identity-display: flex;
}

@media (max-width: 1170px) {
  :root {
    /* Make the content columns flexible, hide the margins left and right */
    --main-grid-column: 1fr;
    --main-grid-margin: 0px;
    --main-content-width: calc((100vw - 13 * var(--main-grid-gap)) * 8 / 12 + 7 * var(--main-grid-gap));
    --main-grid-width: calc(100vw - 2 * var(--main-grid-gap));
  }
}
@media (max-width: 959px) {
  :root {
    /* Update header title CTA padding */
    --header-title-cta-padding: 200px;
    /* Hide homepage video */
    --header-videos-display: none;
    --header-videomobile-display: block;
    /* Position the overlay statically below the CTA */
    --header-overlay-position: static;
    --header-overlay-width: 90px;
    --header-overlay-transform: none;
    /* Display columns stacked instead of next to each other */
    --newseventsrecent-news-columns: grid-left / grid-right;
    --newseventsrecent-sidebar-columns: grid-left / grid-right;
    /* Update footer prefooter layout*/
    --footer-prefooter-display: flex;
    --footer-prefooter-height: 250px;
    --footer-address-padding: var(--main-grid-gap);
    --footer-address-width: 33.3333333333333333%;
    /* Footer links are made foldable */
    --footer-links-column-1: grid-left / grid-right;
    --footer-links-column-2: grid-left / grid-right;
    --footer-links-column-3: grid-left / grid-right;
    --footer-links-column-4: grid-left / grid-right;
    /* Hide the deeplinks */
    --deeplinks-display: none;
    /* Reduce full-width embedded component title width */
    --main-embed-title-layout: text-left / text-right;
    /* Resize widgets to fit 2 on a row instead of 3 */
    --widget-equalheight-width: calc((var(--main-grid-width) - var(--main-grid-gap) - 1px) / 2);
    /* Adjust search form width */
    --searsia-form-margin: 90px;
    --searsia-form-width: calc(100vw - 2 * var(--searsia-form-margin));
  }
  /* Hide the navbar breadcrumbs, show the header breadcrumbs */
  .contentpart__navbar-crumbs .contentpart__breadcrumblist {
    --breadcrumbs-display: none;
  }
  .contentpart__header-crumbs {
    --breadcrumbs-display: block;
  }
  .contentpart__header-crumbs + .contentpart__header {
    --header-margin: 0;
  }
  html.site--itc {
    --header-sliderdots-height: 60px;
  }
}
@media (max-width: 749px) {
  :root {
    /* Make the main content 10 columns wide */
    --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left] var(--main-grid-column) [text-left] repeat(10, var(--main-grid-column)) [text-right] var(--main-grid-column) [grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
    --main-content-width: calc((100vw - 13 * var(--main-grid-gap)) * 10 / 12 + 9 * var(--main-grid-gap));
    /* Hide the navbar menu, show the menu top menu items*/
    --navbar-menu-display: none;
    --menu-topmenu-items-display: block;
    --menu-topmenu-title-border: 1px solid var(--color-grey-lines);
    /* Crop header photo/video horizontally instead of scaling proportionally */
    --header-photos-height: var(--header-photos-fixed-height);
    --header-photos-aspect-padding: 0;
    --header-videos-aspect-padding: 0;
    /* Make the CTA text smaller */
    --cta-font-size: 26px;
    --cta-line-height: 26px;
    /* Position header CTA on full top row, reduce padding, place button next to title */
    --header-cta-width: 100%;
    --header-cta-padding-vert: 15px;
    --header-cta-flex-direction: row;
    --header-cta-button-margin: 0px;
    --header-title-cta-padding: 0;
    /* Change slideshow layout */
    --slideshow-height: auto;
    --slideshow-flex-direction: column;
    --slideshow-panel-flex: 1 0 auto;
    --slideshow-panelleft-order: 2;
    --slideshow-panelleft-padding: 0 var(--main-grid-gap) 40px 0;
    --slideshow-panelleft-width: calc(100vw - var(--main-grid-gap));
    --slideshow-panelright-order: 1;
    --slideshow-panelright-height: 320px;
    /* Make sidebar full width */
    --newseventsrecent-sidebar-columns: left / right;
    --newseventsrecent-sidebar-padding: var(--main-grid-gap);
    /* Change banner layout */
    --banner-slide-width: 100%;
    --banner-slide-padding: 25px var(--main-grid-gap) 65px var(--main-grid-gap);
    /* Make people folder list wider */
    --people-overiew-list-columns: grid-left / span 10;
    /* Update the prefooter layout again */
    --footer-prefooter-display: grid;
    --footer-prefooter-height: auto;
    --footer-address-columns: var(--main-8col-layout);
    --footer-address-padding: 0;
    --footer-image-columns: left / right;
    /* Share page takes up full width */
    --footer-share-column: grid-left / grid-right;
    /* Update footer prefooter layout*/
    --footer-address-width: 100%;
    /* Resize widgets to fit within the content width instead of the grid width */
    --widget-equalheight-width: calc((var(--main-content-width) - var(--main-grid-gap) - 1px) / 2);
    /* Search sheet takes up the whole page */
    --searsia-sheet-top: 0px;
    --searsia-close-display: block;
    /* Adjust search form width */
    --searsia-form-margin: 28px;
    --searsia-form-width: calc(100vw - 2 * var(--searsia-form-margin) - var(--navbar-height));
  }
  :root .header--hascta {
    --header-grid-rows: [top] auto [header-top] 1fr [title-above] calc(var(--header-background-height) - 2 * var(--header-sliderdots-height)) [title-below] var(--header-sliderdots-height) [bottom];
  }
}
@media (max-width: 480px) {
  :root {
    /* Reduce gutter */
    --main-grid-gap: 20px;
    /* Make the grid 6 columns wide */
    --main-grid: [left] minmax(env(safe-area-inset-left), var(--main-grid-margin)) [grid-left text-left] repeat(6, var(--main-grid-column)) [text-right grid-right] minmax(env(safe-area-inset-right), var(--main-grid-margin)) [right];
    --main-content-width: calc(100vw - 2 * var(--main-grid-gap));
    /* Hide slider dots on smaller displays */
    --header-sliderdots-display: none;
    /* Position notification contents below icons */
    --notification-padding: 56px 20px 16px;
    /* Make people folder list wider */
    --people-overiew-list-columns: grid-left / grid-right;
    /* Give footer links some margin */
    --footer-share-column: text-left / text-right;
    --footer-links-column-1: text-left / text-right;
    --footer-links-column-2: text-left / text-right;
    --footer-links-column-3: text-left / text-right;
    --footer-links-column-4: text-left / text-right;
    --footer-footerlinks-column: text-left / text-right;
    /* Make the CTA text smaller */
    --cta-font-size: 18px;
    --cta-line-height: 20px;
    /* Reduce header CTA padding */
    --header-cta-padding-vert: 10px;
    --header-cta-padding-hor: 15px;
  }
  /* Show mobile CTA button instead of desktop button, if it's available */
  .cta__button__title--mobile + .cta__button__title--default {
    --header-cta-button-display: none;
  }
  .cta__button__title--mobile {
    --header-cta-button-display: inline;
  }
}
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*

This SCSS file is for forms layout ONLY!
Components belong in other files.


.wh-form
  - Any form adhiring to the standard as set for Publisher forms

.wh-styledinput
  - Opt-in to ws2016 custom styled components.
    This requires any <input type="checkbox" /> or <input type="radio" /> to be followed by a <label for="id_of_input"></label> as placeholder.

.wh-form--justify
  - Make textual input fields stretch (meant to be used within .wh-form)

.wh-form--webform
  - Any form within an element using this (or having it itself)
    will have a stacked (one column) layout on mobile
    (using the "form_onecolumn" mixin would increase CSS bundle size,
    so it's better to use the .wh-form--webform class instead)



Stretch a field within a .wh-form

    #myformid input[name="email"] { flex: 1; }

Disable stretching from .wh-form--justify in a single field:

    #myformid input[name="email"] { flex: 0; }

Stretch all textual fields within a .wh-form

    opt-in to .wh-form--justify

*/
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.contentpart__main > .wh-wrdauth-form {
  grid-column: var(--main-full-layout);
}

/*
  Note: if changes to label/fields widths, then also alter 'embeddedobject__infotip';
*/
html:not(.ut-form--singlecolumn) .wh-form__fieldgroup--imgedit .wh-form__infotextwrapper {
  max-width: 400px;
}

.wh-form ::-webkit-input-placeholder {
  opacity: 1;
  color: #686b6e;
}
.wh-form ::-moz-placeholder {
  opacity: 1;
  color: #686b6e;
}
.wh-form :-ms-input-placeholder {
  opacity: 1;
  color: #686b6e;
}
.wh-form input:-moz-placeholder {
  opacity: 1;
  color: #686b6e;
}

/*

Main layout for the form and it's fields and richtext content
- handling visibility of pages
- applying the grid (including the 8/12/full columns layout for content)

*/
.wh-form {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
.contentpart__main > .wh-form {
  grid-column: var(--main-full-layout);
}
html.utwente--ws2021 .foldabledeeplink__text > .wh-form {
  grid-column: var(--main-full-layout);
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
}
.wh-form > * {
  pointer-events: auto;
  grid-column: var(--main-8col-layout);
}
.wh-form .wh-form__page--visible {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  grid-column: var(--main-full-layout);
}
.wh-form .wh-form__page--visible > * {
  grid-column: var(--main-8col-layout);
}
.wh-form .wh-form__questiongroup, .wh-form .ut--legacyformgridfix {
  margin-left: unset;
  margin-right: unset;
  width: unset;
  grid-column: var(--main-8col-layout);
}

@media (min-width: 1280px) {
  .wh-form__page--visible .wh-form__fieldgroup--richtext {
    grid-column: var(--main-full-layout);
  }
  .wh-form__page--visible .wh-form__fieldgroup--richtext .wh-form__richtext {
    display: grid;
    width: 100%;
    column-gap: var(--main-grid-gap);
    grid-column-gap: var(--main-grid-gap);
    grid-template-columns: var(--main-grid);
  }
  .wh-form__fieldgroup--richtext .wh-form__richtext > * {
    grid-column: var(--main-8col-layout);
  }
  .wh-form__page--visible .wh-form__questiongroup__fieldgroups > .wh-form__fieldgroup--richtext .wh-form__richtext {
    display: block;
  }
}
/* NOTE: Since we treat *all* wh-form's as being in the main contentarea here,
   there are ("Switch to full width within widgets") overrides at the bottom of this stylesheet.
   This override set .wh-form to display: block;
   so the grid isn't applied. (used for widgets, dialogs/popups)
*/
.widget .wh-form,
.embeddedobject .wh-form,
.dialog__content .wh-form,
.widget .wh-form .wh-form__page--visible,
.embeddedobject .wh-form .wh-form__page--visible,
.dialog__content .wh-form .wh-form__page--visible {
  display: block;
}

.wh-form {
  /* infotext below input */
}
.wh-form h2.wh-form__grouptitle {
  margin-top: 20px;
  margin-bottom: 0;
}
.wh-form__infotextwrapper {
  width: 100%;
}
.wh-form .wh-form__optiondata + .wh-form__infotextwrapper {
  margin-top: 5px;
  margin-bottom: 15px;
}
.wh-form__infotext {
  position: relative;
  border-radius: 0 0 2px 2px;
  border: 1px solid var(--color-grey-lines);
  border-top-width: 0;
  background-color: var(--color-grey-light);
  padding: 15px 50px 15px 20px;
  font-style: italic;
  font-size: 13px;
  line-height: 20px;
}
.wh-form__infotext::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  content: "\e939"; /* UT-icon-UT_icon_58_info-round-16px */
  position: absolute;
  top: 13px;
  right: 15px;
  color: var(--color-grey-normal);
}
.wh-form__fieldgroup.utforms--noinfotexticon .wh-form__infotext::before {
  content: none;
}
.wh-form__infotext > *:first-child {
  margin-top: 0;
}
.wh-form .wh-form__optiondata + .wh-form__infotextwrapper .wh-form__infotext {
  border-top-width: 1px;
}
.wh-form .wh-form__page--visible[data-wh-form-pagerole=thankyou] ~ .wh-form__requirednote {
  display: none;
}
.wh-form .wh-form__fieldgroup--aligncontentleft {
  justify-content: flex-start;
}
.wh-form .wh-form__fieldgroup--aligncontentleft .wh-form__label {
  flex: none;
}
.wh-form .wh-form__fieldgroup--aligncontentleft .wh-form__fields {
  flex: 1;
  max-width: none;
}
.wh-form__label {
  font-weight: 400;
  padding-right: 10px;
  line-height: 20px;
}
.wh-form__label:empty {
  display: none;
}
.wh-form__optionlabel {
  margin-right: 10px; /* space incase of __subfield */
}
.wh-form__richtext {
  width: 100%;
}
.wh-form__richtext > *:first-child {
  margin-top: 0;
}
.wh-form__rtd {
  height: 250px;
  width: 100%;
}
.wh-form__page {
  margin-top: 25px;
}
.wh-form__fieldgroup--pulldown .wh-form__subfield { /* pulldown uses fullwidth so set margin above subfield */
  margin-top: 5px;
}
.wh-form wh-fileedit {
  --wh-button-border: none;
  --wh-button-border-radius: 15px;
  --wh-button-background-color: var(--theme-color-cta);
  --wh-button-color: #ffffff;
}
.wh-form wh-fileedit::part(filename) {
  color: var(--color-black);
  background-color: var(--color-grey-light);
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  height: 30px;
  padding: 4px 10px;
}
.wh-form wh-fileedit::part(button selectbutton) {
  font-weight: var(--weight-bold-condensed);
  text-transform: uppercase;
}
.wh-form wh-fileedit::part(button deletebutton) {
  background-color: var(--theme-color-cta);
}
.wh-form__uploadfield {
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.wh-form__uploadfieldinputholder {
  flex: 1 1 100%;
  padding-right: 10px;
  display: block;
}
.wh-form__uploadfieldfilename {
  width: 100%;
  padding-right: 25px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wh-form__uploadfieldselect {
  flex: none;
}
.wh-form__uploadfielddelete {
  color: var(--color-grey-normal);
  transition: color 0.2s;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 7px;
  display: none;
}
.wh-form__uploadfielddelete::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e936";
}
.wh-form__uploadfielddelete:hover {
  color: #333;
}
.wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: block;
}
.wh-form .wh-form__button:not(.wh-form__button--previous) {
  background: var(--theme-color-cta);
}
.wh-form .wh-form__button:not(.wh-form__button--previous):hover {
  background: var(--theme-color-cta-hover);
}

.widget-wh-form .wh-form__page.wh-form__page--visible .wh-form__fieldgroup--richtext .wh-form__richtext {
  display: block;
}

.wh-form__buttongroup {
  position: relative;
}
.wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 0;
  margin-right: 15px;
}
.wh-form__buttongroup .wh-form__button--previous, .wh-form__buttongroup .ut-button--previous-styling {
  display: inline-flex;
  align-items: center;
  text-transform: none;
  font-weight: normal;
  background-color: transparent !important;
  animation: none !important;
  border-radius: 0;
  text-decoration: none;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: 0;
  right: calc(100% - 240px + 20px);
  font-size: 15px;
  line-height: 25px;
}
.wh-form__buttongroup .wh-form__button--previous .wh-form__buttonlabel, .wh-form__buttongroup .ut-button--previous-styling .wh-form__buttonlabel {
  text-decoration: underline;
  color: #53575b;
}
.wh-form__buttongroup .wh-form__button--previous::before, .wh-form__buttongroup .ut-button--previous-styling::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e92a";
  display: inline-block;
  color: var(--color-grey-normal);
  transition: color 0.3s;
  margin-right: 8px;
}
.wh-form__buttongroup .wh-form__button--previous:hover:not([disabled]), .wh-form__buttongroup .ut-button--previous-styling:hover:not([disabled]) {
  background-color: transparent !important;
  color: #333 !important;
}
.wh-form__buttongroup .wh-form__button--previous:hover:not([disabled])::before, .wh-form__buttongroup .ut-button--previous-styling:hover:not([disabled])::before {
  color: #333;
}
@media (max-width: 1279px) {
  .wh-form__buttongroup .wh-form__button--previous, .wh-form__buttongroup .ut-button--previous-styling {
    position: static;
  }
}
.wh-form__buttongroup a.ut-button--previous-styling {
  text-decoration: underline;
}

.ut-dialog .wh-form__buttongroup {
  margin-top: 15px;
}
.ut-dialog .wh-form__buttongroup .wh-form__button, .ut-dialog .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin: 15px 0 0 15px;
}

/* align group titles left */
.wh-form__fieldgroup--group > .wh-form__fields {
  max-width: 100%;
}

.wh-form__fieldgroup--checkbox::before {
  content: "";
  display: block;
}

.wh-form__fieldgroup {
  justify-content: flex-end;
}

.wh-form__fieldgroup--checkbox::before,
.wh-form__label {
  flex: 1 1 100%;
  max-width: calc(100% - 380px);
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}

.wh-form__fieldgroup > .wh-form__label {
  text-align: right;
  padding-right: 20px;
}

.wh-form__buttongroup {
  text-align: left;
  padding-left: calc(100% - 380px);
}

.wh-form__fields {
  min-width: 380px;
  max-width: 380px;
}

.ut--fields-fullwidth .wh-form__fields {
  max-width: none;
}

.wh-form__fieldgroup--richtext .wh-form__fields {
  max-width: none;
}

.wh-styledinput .wh-form--uploading .wh-form__uploadfieldfilename {
  background-color: transparent !important;
  background-image: linear-gradient(135deg, var(--color-grey-light) 16.67%, transparent 16.67%, transparent 50%, var(--color-grey-light) 50%, var(--color-grey-light) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--richtext .wh-form__fields {
  flex-basis: 0px; /* IE fix - without this, RTDs inside forms don't properly word wrap
*/
}

/* Stretch textual fields
     - when opting-in to justify form content using .wh-form--justify
*/
.wh-form--justify input[type=text]:not(.wh-date),
.wh-form--justify input[type=url],
.wh-form--justify input[type=email],
.wh-form--justify input[type=password],
.wh-form--justify input[type=number],
.wh-form--justify input[type=date],
.wh-form--justify input[type=time],
.wh-form--justify input[type=search],
.wh-form--justify input[type=tel],
.wh-form--justify textarea {
  flex: 1;
  max-width: 100%;
}

.wh-form__questiongroup + .wh-form__questiongroup {
  margin-top: 50px;
}

.wh-form__questiongroup__title {
  margin-top: 0; /* in case we use H3 for the title element */
}

.wh-form__fieldgroup {
  margin-top: 5px;
}

.wh-form__fieldgroup.wh-form__fieldgroup--textarea,
.wh-form__fieldgroup.wh-form__fieldgroup--imgedit {
  align-items: inherit;
}

.wh-form__fieldgroup + .wh-form__fieldgroup {
  margin-top: 10px;
}

.wh-form__radiooption,
.wh-form__checkboxoption {
  margin-bottom: 10px;
}

.wh-form__optiondata {
  margin-left: 10px;
}

/* some space between grouped checkboxes/radioubuttons or any other
   controls grouped in .wh-form__fields or .wh-form__optiondata
*/
.wh-form__fieldline + .wh-form__fieldline {
  margin-top: 5px;
}

.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  margin-top: 5px;
}
.wh-form__fieldgroup--horizontal .wh-form__fields {
  margin-top: -5px;
}

.wh-form__fieldline--disabled {
  opacity: 0.4;
}

.wh-form__prefix + * {
  margin-left: 10px;
}

* .wh-form__suffix {
  margin-left: 10px;
}

.wh-form__addendumlabel {
  margin-left: 10px;
  margin-right: 10px;
}

/* add required marker but not in label wrappers for radio/checkbox */
.wh-styledinput .wh-form__fieldgroup.wh-form__fieldgroup--required > label::after {
  content: "*";
}

.wh-form__buttongroup {
  margin-top: 50px;
}

.wh-form__buttongroup::after {
  clear: both;
  content: "";
  display: block;
}

.wh-form__buttongroup .wh-form__button--previous,
.wh-form__buttongroup .wh-form__button--legacyprevious {
  float: left;
}

/* FIXME: It's better to use .wh-form__buttongroup
          This is only used in header.witty ?
 */
.wh-form .cta__buttonholder {
  margin-top: 10px;
  margin-bottom: 0;
}

.wh-form__questiongroup .title {
  font-size: 15px; /*1.5rem*/
  font-weight: 700;
  line-height: 25px; /*2.5rem*/
  margin-top: 50px; /*5.0rem*/
  text-transform: uppercase;
}

.wh-styledinput,
.wh-formcontrol-styling {
  --wh-field-border-color: #cbcbcb;
  --wh-field-border-color--hover: #808080;
  --wh-field-border-color--focus: #808080;
}
.wh-styledinput input[type=text],
.wh-styledinput input[type=time],
.wh-styledinput input[type=url],
.wh-styledinput input[type=tel],
.wh-styledinput input[type=email],
.wh-styledinput input[type=password],
.wh-styledinput input[type=number],
.wh-styledinput input[type=date],
.wh-styledinput input[type=time],
.wh-styledinput input[type=search],
.wh-styledinput textarea,
.wh-formcontrol-styling input[type=text],
.wh-formcontrol-styling input[type=time],
.wh-formcontrol-styling input[type=url],
.wh-formcontrol-styling input[type=tel],
.wh-formcontrol-styling input[type=email],
.wh-formcontrol-styling input[type=password],
.wh-formcontrol-styling input[type=number],
.wh-formcontrol-styling input[type=date],
.wh-formcontrol-styling input[type=time],
.wh-formcontrol-styling input[type=search],
.wh-formcontrol-styling textarea {
  padding: 9px 10px 7px;
  height: 38px;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  background-color: var(--color-white);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
  color: var(--color-black);
  outline: none;
  flex-grow: 1;
}
.wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-formcontrol-styling select:not(.wh-rtd__toolbarstyle) {
  width: 100%;
  padding: 9px 10px 7px;
  height: 38px;
  border: 1px solid var(--color-grey-lines);
  border-radius: 2px;
  background-color: var(--color-white);
  font: inherit;
  color: var(--color-black);
  outline: none;
}
.wh-styledinput textarea,
.wh-formcontrol-styling textarea {
  resize: vertical;
  height: 132px;
  flex-grow: 1;
}
.wh-styledinput input[type=date],
.wh-styledinput input.wh-date,
.wh-formcontrol-styling input[type=date],
.wh-formcontrol-styling input.wh-date {
  width: 140px;
  padding-left: 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAARklEQVR4AWNAB6vXrPsPwlAuUfIUGoIQFADi9yAJMvF7sCEUGIBw1eAzhIFEMPgNgfEd8GFChhAV0HQxhP4BS/3ERmkGBACSnuCCFXKWdQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: 10px 50%;
}
.wh-styledinput input[type=text]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=text]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput input[type=url]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=url]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput input[type=tel]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=tel]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput input[type=email]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=email]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput input[type=password]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=password]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput input[type=number]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=number]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput input[type=date]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=date]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput input[type=time]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=time]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput input[type=search]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput input[type=search]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput textarea:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput textarea:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-styledinput select:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-styledinput select:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=text]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=text]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=url]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=url]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=tel]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=tel]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=email]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=email]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=password]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=password]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=number]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=number]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=date]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=date]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=time]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=time]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling input[type=search]:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling input[type=search]:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling textarea:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling textarea:not([disabled]):not([readonly]):not(.wh-form__field--error):focus,
.wh-formcontrol-styling select:not([disabled]):not([readonly]):not(.wh-form__field--error):hover,
.wh-formcontrol-styling select:not([disabled]):not([readonly]):not(.wh-form__field--error):focus {
  border-color: #808080;
}
.wh-styledinput input[type=text][disabled], .wh-styledinput input[type=text][readonly],
.wh-styledinput input[type=url][disabled], .wh-styledinput input[type=url][readonly],
.wh-styledinput input[type=tel][disabled], .wh-styledinput input[type=tel][readonly],
.wh-styledinput input[type=email][disabled], .wh-styledinput input[type=email][readonly],
.wh-styledinput input[type=password][disabled], .wh-styledinput input[type=password][readonly],
.wh-styledinput input[type=number][disabled], .wh-styledinput input[type=number][readonly],
.wh-styledinput input[type=date][disabled], .wh-styledinput input[type=date][readonly],
.wh-styledinput input[type=time][disabled], .wh-styledinput input[type=time][readonly],
.wh-styledinput input[type=search][disabled], .wh-styledinput input[type=search][readonly],
.wh-styledinput textarea[disabled], .wh-styledinput .wh-styledinput textarea[readonly],
.wh-styledinput select[disabled],
.wh-formcontrol-styling input[type=text][disabled],
.wh-formcontrol-styling input[type=text][readonly],
.wh-formcontrol-styling input[type=url][disabled],
.wh-formcontrol-styling input[type=url][readonly],
.wh-formcontrol-styling input[type=tel][disabled],
.wh-formcontrol-styling input[type=tel][readonly],
.wh-formcontrol-styling input[type=email][disabled],
.wh-formcontrol-styling input[type=email][readonly],
.wh-formcontrol-styling input[type=password][disabled],
.wh-formcontrol-styling input[type=password][readonly],
.wh-formcontrol-styling input[type=number][disabled],
.wh-formcontrol-styling input[type=number][readonly],
.wh-formcontrol-styling input[type=date][disabled],
.wh-formcontrol-styling input[type=date][readonly],
.wh-formcontrol-styling input[type=time][disabled],
.wh-formcontrol-styling input[type=time][readonly],
.wh-formcontrol-styling input[type=search][disabled],
.wh-formcontrol-styling input[type=search][readonly],
.wh-formcontrol-styling textarea[disabled],
.wh-formcontrol-styling .wh-styledinput textarea[readonly],
.wh-formcontrol-styling select[disabled] {
  background-color: var(--color-grey-light);
  color: #686b6e;
}
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=text],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=url],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=tel],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=email],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=password],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=number],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=date],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=time],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) input[type=search],
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) textarea,
.wh-styledinput .ut-prefilled:not(.ut-prefilled--changed) select,
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=text],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=url],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=tel],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=email],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=password],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=number],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=date],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=time],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) input[type=search],
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) textarea,
.wh-formcontrol-styling .ut-prefilled:not(.ut-prefilled--changed) select {
  background-color: var(--color-grey-light);
}

.wh-form__errorlist,
.wh-form__errors {
  padding: 0;
  margin: 0;
  font: inherit;
  list-style-type: none;
}

.wh-form__error,
.wh-form__suggestion {
  margin: 5px 0 0 11px;
}

.wh-form__error {
  margin: 5px 0 0 11px;
  color: #cd202c;
}

.fieldgroup.error .optionsgroup {
  padding-bottom: 16px; /* create some space for errors */
  white-space: nowrap;
}

.wh-styledinput input.wh-form__field--error[type=text],
.wh-styledinput input.wh-form__field--error[type=url],
.wh-styledinput input.wh-form__field--error[type=tel],
.wh-styledinput input.wh-form__field--error[type=email],
.wh-styledinput input.wh-form__field--error[type=password],
.wh-styledinput input.wh-form__field--error[type=number],
.wh-styledinput input.wh-form__field--error[type=date],
.wh-styledinput input.wh-form__field--error[type=search],
.wh-styledinput select.wh-form__field--error,
.wh-styledinput .wh-form__fieldgroup--error input,
.wh-styledinput .wh-form__fieldgroup--error textarea {
  border-color: #cd202c;
  color: #cd202c;
  background-color: rgba(205, 32, 44, 0.05);
}

.wh-styledinput .fieldnote {
  margin: 5px 0 0 135px;
}

/* paged forms */
.wh-form .wh-page {
  display: none;
}

.wh-form .wh-page--active {
  display: block;
}

/* overlay when form rpc is busy */
.rpc__overlay {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10001;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
}

.rpc__overlaywait {
  flex: none;
  padding: 30px;
  background: var(--color-white);
  border-radius: 5px;
}

.rpc__spinner {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 20px;
  top: -14px;
  vertical-align: middle;
}

/* search fields */
.ut-form__searchcontrol {
  display: flex;
  height: 38px;
}

.ut-form__searchcontrol__field {
  flex-grow: 1;
}

.ut-form__searchcontrol__imgbutton {
  background: transparent;
  border: none;
  color: var(--color-grey-dark);
  flex-grow: 0;
  font-size: 24px;
  font-weight: normal;
  margin-left: 14px;
  margin-right: 10px;
  transition: color 100ms;
}

.ut-form__searchcontrol__imgbutton:hover {
  color: #222;
}

.dialog--small form.wh-form,
.ut-dialog form.wh-form,
.ut-form--singlecolumn {
  /* this broke mobile layout forms - https://gitlab.webhare.com/utwente/utwente_base/-/issues/1853
    .wh-form__page.wh-form__page--visible .wh-form__fieldgroup--richtext .wh-form__richtext
    {
      display: block;
    }
  */
}
.dialog--small form.wh-form .wh-form__fieldgroup--checkbox::before,
.ut-dialog form.wh-form .wh-form__fieldgroup--checkbox::before,
.ut-form--singlecolumn .wh-form__fieldgroup--checkbox::before {
  display: none;
}
.dialog--small form.wh-form .wh-form__label,
.ut-dialog form.wh-form .wh-form__label,
.ut-form--singlecolumn .wh-form__label {
  flex: 1 1 auto;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}
.dialog--small form.wh-form .wh-form__fieldgroup > .wh-form__label,
.ut-dialog form.wh-form .wh-form__fieldgroup > .wh-form__label,
.ut-form--singlecolumn .wh-form__fieldgroup > .wh-form__label {
  text-align: left;
  padding-right: 0px;
  padding-bottom: 4px;
}
.dialog--small form.wh-form .wh-form__fieldgroup,
.ut-dialog form.wh-form .wh-form__fieldgroup,
.ut-form--singlecolumn .wh-form__fieldgroup {
  flex-direction: column;
}
.dialog--small form.wh-form .wh-form__fields,
.ut-dialog form.wh-form .wh-form__fields,
.ut-form--singlecolumn .wh-form__fields {
  width: 100%;
  max-width: 100%;
  min-width: 0;
  flex: 1 0 auto;
}
.dialog--small form.wh-form .wh-form__fieldgroup + .wh-form__fieldgroup,
.dialog--small form.wh-form .openday__round + .openday__round,
.ut-dialog form.wh-form .wh-form__fieldgroup + .wh-form__fieldgroup,
.ut-dialog form.wh-form .openday__round + .openday__round,
.ut-form--singlecolumn .wh-form__fieldgroup + .wh-form__fieldgroup,
.ut-form--singlecolumn .openday__round + .openday__round {
  margin-top: 20px;
}
.dialog--small form.wh-form .wh-form__buttongroup,
.ut-dialog form.wh-form .wh-form__buttongroup,
.ut-form--singlecolumn .wh-form__buttongroup {
  text-align: left;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-left: 0;
}
.dialog--small form.wh-form .wh-form__buttongroup .wh-form__button,
.ut-dialog form.wh-form .wh-form__buttongroup .wh-form__button,
.ut-form--singlecolumn .wh-form__buttongroup .wh-form__button {
  /* why was this minwidth needed? it's also to wide for the prereg widget eg on https://www.utwente.nl/en/education/study-choice-calendar/online-open-days/#register-open-days
  min-width: 270px;
  */
  margin-right: 0;
}
.dialog--small form.wh-form .wh-form__buttongroup .wh-form__button + .wh-form__button,
.ut-dialog form.wh-form .wh-form__buttongroup .wh-form__button + .wh-form__button,
.ut-form--singlecolumn .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 0;
  margin-bottom: 10px;
}
.dialog--small form.wh-form .wh-form__buttongroup .wh-form__button--previous,
.ut-dialog form.wh-form .wh-form__buttongroup .wh-form__button--previous,
.ut-form--singlecolumn .wh-form__buttongroup .wh-form__button--previous {
  position: relative;
  right: auto;
  float: none;
  order: 3;
  margin-top: 0;
  text-align: left;
}

@media (max-width: 1279px) {
  form.wh-form {
    /* this broke mobile layout forms - https://gitlab.webhare.com/utwente/utwente_base/-/issues/1853
      .wh-form__page.wh-form__page--visible .wh-form__fieldgroup--richtext .wh-form__richtext
      {
        display: block;
      }
    */
  }
  form.wh-form .wh-form__fieldgroup--checkbox::before {
    display: none;
  }
  form.wh-form .wh-form__label {
    flex: 1 1 auto;
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }
  form.wh-form .wh-form__fieldgroup > .wh-form__label {
    text-align: left;
    padding-right: 0px;
    padding-bottom: 4px;
  }
  form.wh-form .wh-form__fieldgroup {
    flex-direction: column;
  }
  form.wh-form .wh-form__fields {
    width: 100%;
    max-width: 100%;
    min-width: 0;
    flex: 1 0 auto;
  }
  form.wh-form .wh-form__fieldgroup + .wh-form__fieldgroup,
  form.wh-form .openday__round + .openday__round {
    margin-top: 20px;
  }
  form.wh-form .wh-form__buttongroup {
    text-align: left;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding-left: 0;
  }
  form.wh-form .wh-form__buttongroup .wh-form__button {
    /* why was this minwidth needed? it's also to wide for the prereg widget eg on https://www.utwente.nl/en/education/study-choice-calendar/online-open-days/#register-open-days
    min-width: 270px;
    */
    margin-right: 0;
  }
  form.wh-form .wh-form__buttongroup .wh-form__button + .wh-form__button {
    margin-left: 0;
    margin-bottom: 10px;
  }
  form.wh-form .wh-form__buttongroup .wh-form__button--previous {
    position: relative;
    right: auto;
    float: none;
    order: 3;
    margin-top: 0;
    text-align: left;
  }
}
@media (max-width: 480px) {
  .wh-form__buttongroup .wh-form__button {
    align-self: stretch;
  }
  .wh-form__buttongroup .wh-form__button {
    min-width: 0;
  }
  .wh-form__buttongroup .wh-form__button--previous {
    position: relative;
    right: auto;
  }
}
.widget-wh-form .widget__rawhtml__contents {
  padding: 0 25px 15px;
}
.widget-wh-form .wh-form__button + .wh-form__button {
  margin-left: 0;
}