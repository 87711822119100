@charset "UTF-8";
/*

SPC native checkboxes 2021-02

Native checkbox styling browser support:
- Webkit browsers (en Opera) already allowed styling of checkboxes/radiobuttons in 2012
- Since Firefox 54 (juni 2017) also supports this
- Internet Explorer doesn't support this but isn't relevant anymore

Use of focus-visible:
- Chrome     (oct 2020)
- Firefox 85 (jan 2021)
- iOS 15.4   (mar 2022 ?)

Also see:
- https://gitlab.webhare.com/webharebv/accessibility/-/issues/7
  ("Afstappen van gebruik <label> als weergave voor checkbox/radiobuttons?"")

*/
.wh-formcontrol-styling {
  /* We directly add the symbol so we can do things like
     - also fainly show the checkmark on hover
     - have a fade in/out effect
     - we have content, making sure we have a baseline so we can align with context next to us
       (if we don't use a symbol, use content: '\200B'; (zero-width space)
  */
}
.wh-formcontrol-styling input[type=radio],
.wh-formcontrol-styling input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--formcontrol-checkradio-size);
  max-width: var(--formcontrol-checkradio-size);
  flex: 0 0 var(--formcontrol-checkradio-size);
  height: var(--formcontrol-checkradio-size);
  vertical-align: baseline;
  cursor: pointer;
  border: var(--formcontrol-checkradio-borderwidth) solid var(--formcontrol-checkradio-bordercolor);
  background-color: #FFFFFF;
}
@supports not (*:focus-visible) {
  .wh-formcontrol-styling input[type=radio]:focus,
  .wh-formcontrol-styling input[type=checkbox]:focus {
    outline: 0;
    box-shadow: var(--formcontrol-checkradio-focus-boxshadow);
    border-color: var(--formcontrol-checkradio-focus-bordercolor);
  }
}
.wh-formcontrol-styling input[type=checkbox] {
  position: relative;
  top: var(--formcontrol-check-shift);
  border-radius: var(--formcontrol-checkradio-borderradius);
}
.wh-formcontrol-styling input[type=checkbox]:checked {
  border-color: var(--formcontrol-checkradio-checked-bordercolor);
}
.wh-formcontrol-styling input[type=checkbox][disabled] {
  background-color: var(--formcontrol-checkradio-disabled-bgcolor);
  border-color: var(--formcontrol-checkradio-disabled-bordercolor);
  cursor: default;
}
.wh-formcontrol-styling input[type=checkbox][disabled]:checked {
  background-color: var(--formcontrol-checkradio-disabled-checked-bgcolor);
}
.wh-formcontrol-styling input[type=checkbox][disabled]:checked::after {
  color: var(--formcontrol-checkradio-disabled-color);
}
.wh-formcontrol-styling input[type=checkbox]::after {
  color: transparent;
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e931"; /* UT-icon-UT_icon_50_check-16px */
  font-size: var(--font-awesome-size);
  margin-left: var(--font-awesome-offsetx);
  margin-top: var(--font-awesome-offsety);
}
.wh-formcontrol-styling input[type=checkbox]:checked {
  background-color: var(--formcontrol-checkradio-checked-bgcolor);
  border-color: var(--formcontrol-checkradio-checked-bordercolor);
  color: var(--formcontrol-checkradio-checked-color);
}
.wh-formcontrol-styling input[type=checkbox]:hover::after {
  color: var(--formcontrol-checkradio-hover-color);
}
.wh-formcontrol-styling input[type=checkbox]:checked::after {
  color: var(--formcontrol-checkradio-checked-color);
}
.wh-formcontrol-styling input[type=radio] {
  position: relative;
  top: var(--formcontrol-radio-shift);
  border-radius: 50%;
}
.wh-formcontrol-styling input[type=radio]::after {
  position: relative;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.wh-formcontrol-styling input[type=radio][disabled] {
  border-color: var(--controls_outline_color-disabled);
  cursor: default;
}
.wh-formcontrol-styling input[type=radio][disabled]::after {
  background-color: var(--formcontrol-checkradio-disabled-bgcolor);
  border-color: var(--formcontrol-checkradio-disabled-bgcolor);
}
.wh-formcontrol-styling input[type=radio][disabled]:checked::after {
  background-color: var(--controls_outline_color-disabled);
  color: var(--formcontrol-checkradio-disabled-color);
}
.wh-formcontrol-styling input[type=radio]::after {
  content: "​";
  width: var(--formcontrol-radio-innersize);
  height: var(--formcontrol-radio-innersize);
}
.wh-formcontrol-styling input[type=radio]:checked {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--formcontrol-checkradio-checked-bordercolor);
}
.wh-formcontrol-styling input[type=radio]:hover::after {
  background-color: var(--formcontrol-checkradio-hover-color);
}
.wh-formcontrol-styling input[type=radio]:checked::after {
  color: var(--formcontrol-checkradio-checked-color);
  border-radius: 50%;
  background-color: var(--formcontrol-radio-checked-color);
}
.wh-formcontrol-styling input[type=radio]:focus-visible,
.wh-formcontrol-styling input[type=checkbox]:focus-visible {
  outline: 0;
  box-shadow: var(--formcontrol-checkradio-focus-boxshadow);
  border-color: var(--formcontrol-checkradio-focus-bordercolor);
}