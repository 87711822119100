.carrousel__viewport {
  -webkit-tap-highlight-color: transparent; /* iOS Safari */
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: -webkit-grab; /* Safari < 9 */
  cursor: grab;
  /* prevent selection.
     We cannot use preventDefault because touch devices must be able to
     pick up the touch events to initiate a vertical scroll.
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* when the carrousel is foccused it has keyboard navigation */
.carrousel__viewport:focus {
  outline: 0;
}

.carrousel.livemode .carrousel__viewport {
  overflow: hidden;
}

.carrousel.livemode .carrousel__cell {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-backface-visibility: hidden;
}