.widget__edunumbers .embeddedobject {
  margin: 0;
}
.widget__edunumbers .progresscircles {
  padding-bottom: 20px;
}
.widget__edunumbers .progresscircle {
  margin-left: calc(50% - 105px);
}
.widget__edunumbers .progresscircles__total {
  margin-left: calc(50% - 105px);
  margin-right: calc(50% - 105px);
}