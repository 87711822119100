.widget-programmetags {
  margin-top: 50px;
}
.widget-programmetags__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.widget-programmetags__list > li {
  display: inline-block;
}
.widget-programmetags__tag {
  display: inline-block;
  font-size: 12px;
  color: var(--color-grey-normal);
  background-color: var(--color-white);
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid var(--color-grey-normal);
  border-radius: 2px;
  padding: 2px 7px 1px;
  line-height: 15px;
  text-decoration: none;
  margin-right: 4px;
  transition: color 0.3s;
}
.widget-programmetags__tag:hover {
  color: var(--color-grey-dark);
  border-color: var(--color-grey-dark);
}
.widget-programmetags__tag:active {
  color: var(--color-black);
  border-color: var(--color-black);
}