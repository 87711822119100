.cobrowserchatcontrol {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: var(--color-black);
  cursor: pointer;
  padding: 13px;
  color: var(--color-grey-normal);
  border-radius: 25px;
  transition: width 0.2s;
}
.cobrowserchatcontrol:not(.cobrowserchatcontrol--activated) .wh-requireconsent {
  display: none;
}
.cobrowserchatcontrol .wh-requireconsent__overlay {
  text-align: left;
  white-space: normal;
  position: absolute;
  background: var(--color-white);
  z-index: 1;
  font: 300 15px/25px Arial, Helvetica, sans-serif;
  bottom: -16px;
  right: -10px;
}
.cobrowserchatcontrol__consent1 {
  padding: 13px 20px 9px;
  color: var(--color-white);
  font-style: italic;
  background-color: var(--color-grey-dark);
  width: 320px;
}
.cobrowserchatcontrol__consent2 {
  color: var(--color-black);
  padding: 20px 20px 20px;
  display: flex;
}
@media print {
  .cobrowserchatcontrol {
    display: none;
  }
}

.cobrowserchatcontrol__icon {
  font-size: 24px;
  display: inline-block;
  width: 24px;
}
.cobrowserchatcontrol__icon--animated {
  display: inline-block;
  position: absolute;
  top: 11px;
  left: 11px;
  width: 28px;
  height: 28px;
  background: transparent url(chat-icon-56px.gif) no-repeat 50% 50%;
  background-size: 28px 28px;
}

.cobrowserchatcontrol object {
  display: none;
}

.cobrowserchatcontrol .cobrowserchatcontrol__text {
  display: none;
  text-transform: uppercase;
  vertical-align: top;
  line-height: 22px;
  padding-left: 36px;
}

.cobrowserchatcontrol:hover,
.cobrowserchatcontrol--opencontrol {
  width: 300px;
}

.cobrowserchatcontrol:hover .cobrowserchatcontrol__text,
.cobrowserchatcontrol--opencontrol .cobrowserchatcontrol__text {
  display: inline-block;
}