@charset "UTF-8";
@import "@mod-utwente_design/scss/config-colors.css";
.embeddedobject-quotes__wrapper {
  width: 100%;
  display: inline-flex;
  position: relative;
  justify-content: flex-start;
}
.embeddedobject-quotes__wrapper .widget-quote {
  position: relative;
  flex: none;
  width: 100%;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s;
}
.embeddedobject-quotes__wrapper .widget-quote + .widget-quote {
  margin-left: -100%;
}
.embeddedobject-quotes__wrapper .widget-quote.active {
  opacity: 1;
  z-index: 1;
  pointer-events: inherit;
}

.embeddedobject-quotes__nav {
  display: flex;
  margin-left: -4px;
  margin-top: 20px;
  padding-left: 75px;
}
.embeddedobject-quotes__nav--spaceleft {
  padding-left: 210px;
}
@media (max-width: 640px) {
  .embeddedobject-quotes__nav {
    padding-left: 45px;
  }
}
.embeddedobject-quotes__nav__button {
  margin-left: 4px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  flex: none;
  border: 2px solid transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 0;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  transition: border-color 300ms;
}
.embeddedobject-quotes__nav__button::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: var(--color-grey-normal);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: background-color 300ms;
}
.embeddedobject-quotes__nav__button.active {
  border-color: var(--color-black);
}
.embeddedobject-quotes__nav__button.active::before {
  background-color: var(--color-black);
}

.embeddedobject-quotes__wrapper:hover + .embeddedobject-quotes__nav .embeddedobject-quotes__nav__button.active {
  animation: widget-quotes-hover-animation 2s linear infinite;
}

.widget-quotes {
  position: relative;
  z-index: 0;
  max-width: 100%;
}
.widget-quotes__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  perspective: 1000px;
}
.widget-quotes__slide.fadeout {
  z-index: 1;
}
.widget-quotes__slide.active {
  opacity: 1;
  z-index: 2;
}
.widget-quotes.showslidedetails .widget-quotes__slide.active .widget-quote {
  transform: rotateY(180deg);
}
.widget-quotes__jumpbuttons {
  white-space: nowrap;
  position: absolute;
  left: 20px;
  bottom: 15px;
}
.widget-quotes__jumpbuttons > span {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
}
.widget-quotes__jumpbuttons > span.active {
  border-color: #afb1b3;
}
.widget-quotes__jumpbuttons > span::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #afb1b3;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.widget-quotes:hover .widget-quotes__jumpbuttons > span.active {
  animation: widget-quotes-hover-animation 2s linear infinite;
}
.widget-quotes .widget-quote {
  height: 100%;
  margin-bottom: 0;
  transition: transform 500ms;
  transform-style: preserve-3d;
  position: relative;
}
.widget-quotes .widget-quote__front {
  flex-flow: column;
  align-items: center;
  height: 100%;
  position: relative;
}
.widget-quotes .widget-quote__textwrapper {
  margin-left: 0 !important;
  width: 100%;
  padding: 20px;
}
.widget-quotes .widget-quote__photo {
  margin-top: 35px;
}
.widget-quotes .widget-quote__content {
  flex: 1 1 auto;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  display: flex;
}
.widget-quotes .widget-quote .quote {
  text-align: left;
}
.widget-quotes .widget-quote .from {
  display: block;
  padding-top: 10px;
}
.widget-quotes .widget-quote__footer {
  margin-top: 15px;
}
.widget-quotes .widget-quote--photofullwidth .widget-quote__photo {
  margin-top: 0;
}
.widget-quotes .widget-quote__front, .widget-quotes .widget-quote__persondetails {
  background-color: var(--color-white);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.widget-quotes .widget-quote__persondetails {
  background-color: #1e2328;
  color: var(--color-white);
  position: absolute;
  margin-top: 0;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 2;
  transform: rotateY(180deg);
}
.widget-quotes .widget-quote__persondetails a::before {
  background-color: rgba(255, 255, 255, 0.1);
  left: -30px;
}
.widget-quotes .widget-quote__persondetails .widget-quote__togglemore {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}
.widget-quotes .widget-quote__persondetails .widget-quote__togglemore .icon--close {
  display: inline-block;
  color: var(--color-white);
}
.widget-quotes .widget-quote__persondetails__line {
  flex: none;
  border-top-color: transparent;
}
.widget-quotes .widget-quote__persondetails__line--jobdescription {
  flex: 1 1 auto;
  border-top: 0 none;
  padding-top: 40px;
  padding-bottom: 20px;
}
.widget-quotes .widget-quote__persondetails__line--jobdescription b {
  font-weight: 700;
  font-size: 18px;
}
.widget-quotes .widget-quote__persondetails a {
  padding-left: 20px;
  padding-right: 10px;
  overflow: hidden;
}
.widget-quotes .widget-quote__persondetails a + a {
  border-top: 1px solid #53575b;
}
.widget-quotes .widget-quote__persondetails__line .icon {
  color: var(--color-white);
}
.widget-quotes .widget-quote__persondetails__content {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 100%;
  background-color: transparent;
}
.widget-quotes .widget-quote__persondetails__line:first-child {
  border-top: 0 none;
}
.widget-quotes .widget-quote__persondetails__line:last-child {
  border-bottom: 0 none;
}

.widget-quote {
  margin-bottom: 25px;
  color: #1e2328;
  max-width: 100%;
  /* grey background hover fx, left to right */
}
.widget-quote__title {
  text-transform: initial;
  font-size: 118%;
}
.widget-quote__text > *:first-child {
  margin-top: 0;
}
.widget-quote__text p + ul.unordered, .widget-quote__text p + ol.ordered {
  margin-top: 10px;
}
.widget-quote__front {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: 30px;
}
.widget-quote--withphoto .widget-quote__front {
  margin-left: 0;
}
.widget-quote--photofullwidth .widget-quote__front {
  display: flex;
}
.widget-quote__photo {
  width: 130px;
  flex: none;
  margin-top: 20px;
}
.widget-quote__photo > img {
  width: 100%;
  vertical-align: bottom;
  height: auto;
  border-radius: 50%;
}
.widget-quote--photofullwidth .widget-quote__photo {
  width: 100%;
}
.widget-quote--photofullwidth .widget-quote__photo > img {
  border-radius: 0;
}
.widget-quote__photo + .widget-quote__textwrapper {
  margin-left: 35px;
}
.widget-quote--photofullwidth .widget-quote__textwrapper {
  padding: 20px;
}
.widget-quote__textwrapper {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: #fff;
  border: 1px solid var(--color-grey-lines);
  padding: 40px 40px 30px;
  position: relative;
}
.widget-quote__textwrapper::before {
  background-color: var(--color-grey-light);
  z-index: -1;
  height: 10px;
  position: absolute;
  content: "";
  display: block;
  left: 40px;
  right: 40px;
  top: 100%;
}
.widget-quote__textwrapper__beacon {
  position: absolute;
  top: calc(100% - 60px);
  right: 100%;
}
.widget-quote__textwrapper__beacon::after, .widget-quote__textwrapper__beacon::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 30px solid transparent;
}
.widget-quote__textwrapper__beacon::before {
  border-right: 30px solid var(--color-grey-lines);
}
.widget-quote__textwrapper__beacon::after {
  margin-right: -2px;
  margin-top: 1px;
  border-right: 30px solid #fff;
}
@media (min-width: 641px) {
  .widget-quote--embeddedobject .widget-quote__photo ~ .widget-quote__textwrapper {
    min-height: 185px; /* min-height tekstarea if has photo at left side */
  }
  .widget-quote--embeddedobject .widget-quote__photo ~ .widget-quote__textwrapper .widget-quote__textwrapper__beacon {
    top: 140px; /* only if has photo, align beacon with photo */
  }
}
.widget-quote__content {
  margin: 0;
}
.widget-quote__text {
  line-height: 135%;
  font-size: 22px;
  font-style: italic;
  margin: 0;
}
.widget-quote__text--rtd {
  font-style: initial;
}
.widget-quote__from {
  color: #1e2328;
  margin-top: 10px;
  line-height: 135%;
  font-size: 18px;
  font-weight: 700;
}
.widget-quote__from:before {
  content: "- ";
}
.widget-quote__from:after {
  position: absolute;
  content: " -";
}
.widget-quote__footer {
  flex: none;
  font-weight: 300;
  margin-top: 30px;
  font-size: 18px;
  display: flex;
  justify-content: flex-end;
  color: #53575b;
  min-height: 25px;
}
.widget-quote__footerlink {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
}
.widget-quote__more {
  color: inherit;
  display: inline-block;
}
.widget-quote__togglemore {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.widget-quote__togglemore .from {
  display: inline-block;
  position: relative;
}
.widget-quote__togglemore .icon {
  margin-left: 10px;
  padding-top: 3px;
  color: #afb1b3;
  display: inline-block;
}
.widget-quote__togglemore .icon--close {
  display: none;
}
.widget-quote__togglemore.opendetails .icon--open {
  display: none;
}
.widget-quote__togglemore.opendetails .icon--close {
  display: inline-block;
}
.widget-quote__more {
  flex: none;
  white-space: nowrap;
  position: relative;
  text-decoration: underline;
}
.widget-quote__persondetails {
  margin: -1px 0 0 0 30px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 200ms;
}
.widget-quote__persondetails.opendetails {
  margin-top: -1px;
  max-height: none;
}
.widget-quote--embeddedobject .widget-quote__persondetails {
  margin-left: 30px; /* space for beacon */
}
.widget-quote--withphoto.widget-quote--embeddedobject .widget-quote__persondetails {
  margin-left: 165px; /* quote has photo left */
}
.widget-quote__persondetails__content {
  background-color: #f7f8f8;
}
.widget-quote__persondetails__line {
  border-top: 1px solid #dcddde;
  padding: 11px 20px 9px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  position: relative;
}
.widget-quote__persondetails__line--jobdescription {
  display: block;
}
.widget-quote__persondetails__line .icon {
  color: #afb1b3;
  flex: none;
  font-size: 24px;
  width: 40px;
  text-align: center;
}
.widget-quote__persondetails__line:last-child {
  border-bottom: 1px solid #dcddde;
}
.widget-quote__persondetails a {
  z-index: 0;
}
.widget-quote__persondetails a::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -20px;
  bottom: 0;
  width: 0;
  background-color: #f0f1f2;
  transition: width 300ms;
  z-index: -1;
}
.widget-quote__persondetails a:hover::before {
  width: 100%;
}
.widget-quote__persondetails__line--jobdescription + .widget-quote__persondetails__line {
  border-top: 0 none;
}
@media (max-width: 640px) {
  .widget-quote__front {
    margin-left: 0;
    flex-flow: column;
    align-items: center;
  }
  .widget-quote--withphoto .widget-quote__front {
    margin-top: 0;
  }
  .widget-quote__persondetails {
    margin-left: 0 !important;
  }
  .widget-quote .widget-quote__textwrapper {
    width: 100%;
  }
  .widget-quote__textwrapper__beacon {
    transform: rotate(90deg) scale(1, -1) translate(0, 60px);
    right: 50%;
    top: auto;
    bottom: 100%;
  }
  .widget-quote--embeddedobject .widget-quote__photo {
    margin-bottom: 30px;
  }
  .widget-quote__photo + .widget-quote__textwrapper {
    margin-left: 0;
  }
  .widget-quote__footer {
    margin-top: 20px;
  }
}

.widget-quotes .widget-quote__front {
  background-color: transparent;
  margin-left: 0;
}
.widget-quotes .widget-quote__photo + .widget-quote__textwrapper {
  margin-top: 30px;
}
.widget-quotes .widget-quote .widget-quote__front {
  padding-top: 30px;
}
.widget-quotes .widget-quote--withphoto .widget-quote__front {
  padding-top: 0;
}
.widget-quotes .widget-quote--photofullwidth .widget-quote__photo + .widget-quote__textwrapper {
  margin-top: 0;
}
.widget-quotes .widget-quote__textwrapper__beacon {
  transform: rotate(90deg) scale(1, -1) translate(0, 60px);
  right: 50%;
  top: auto;
  bottom: 100%;
}

@keyframes widget-quotes-hover-animation {
  50% {
    border-color: var(--color-white);
  }
}