/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.embeddedobject__transfer__header {
  position: relative;
  padding: 0 0 25px 55px;
}
.embeddedobject__transfer__header .icon {
  font-size: 48px;
  position: absolute;
  top: 0;
  left: -7px;
  color: #5A5E62;
}
.embeddedobject__transfer__header .title {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 26px;
  line-height: 100%;
}
.embeddedobject__transfer__header .title b {
  font-weight: 700;
}
.embeddedobject__transfer[data-edutype] .embeddedobject__transfer__form__fields {
  display: block;
}
.embeddedobject__transfer[data-edutype] .embeddedobject__transfer__form__fields > div {
  max-width: 100%;
}
.embeddedobject__transfer__form__fields {
  border: 1px solid #dcddde;
  background-color: #f7f8f8;
  border-radius: 2px;
  padding: 20px;
  display: flex;
  position: relative;
}
.embeddedobject__transfer__form__fields > div {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 10px;
}
.embeddedobject__transfer__form__fields > div + div {
  padding-right: 0px;
  padding-left: 10px;
}
.embeddedobject__transfer__form__fields label {
  display: block;
  padding-bottom: 2px;
}
@media (max-width: 750px) {
  .embeddedobject__transfer__form__fields {
    display: block;
  }
  .embeddedobject__transfer__form__fields > div {
    max-width: 100%;
    padding-right: 0;
  }
  .embeddedobject__transfer__form__fields > div + div {
    padding-top: 10px;
    padding-left: 0;
  }
}
.embeddedobject__transfer__form__fields select:not(.active),
.embeddedobject__transfer__form__fields select:not(.active) + .ut-pulldown {
  display: none;
}
.embeddedobject__transfer__form__fields::after {
  content: "";
  position: absolute;
  display: none;
  top: 100%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #dcddde;
  border-right: 1px solid #dcddde;
  background-color: #f7f8f8;
}
.embeddedobject__transfer__header + .embeddedobject__transfer__results > .studyroute__route:first-child {
  padding-top: 20px;
}
.embeddedobject__transfer.hasresults .embeddedobject__transfer__form__fields::after {
  display: block;
}
.embeddedobject__transfer.loading .embeddedobject__transfer__results {
  position: relative;
}
.embeddedobject__transfer.loading .embeddedobject__transfer__results::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  content: "Loading...";
}
html[lang=nl] .embeddedobject__transfer.loading .embeddedobject__transfer__results::before {
  content: "Laden...";
}
.embeddedobject__transfer.loading .embeddedobject__transfer__results > div {
  opacity: 0.6;
}
.embeddedobject__transfer .noresults {
  padding-top: 30px;
}

.foldableprog {
  margin-top: 30px;
  position: relative;
}
.foldableprog + .foldableprog {
  margin-top: 30px;
}
.foldableprog:not(.foldableprog--open) + .foldableprog {
  margin-top: -1px;
}

.foldableprog__title {
  border-top: 1px solid #e0e0e1;
  border-bottom: 1px solid #e0e0e1;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  margin-top: 0;
  padding: 16px 40px 16px 0;
  position: relative;
  width: 620px;
  z-index: 0;
}
@media (max-width: 750px) {
  .foldableprog__title {
    width: 100%;
  }
}
.foldableprog__title::before {
  background-color: #f7f8f8;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  margin: 0 0 0 -20px;
  position: absolute;
  top: 0;
  transition: width 150ms ease;
  width: 0;
  z-index: -1;
}
.foldableprog__title:hover::before {
  width: 100%;
}
.foldableprog__title::after {
  color: #afb1b3;
  content: "\e92f";
  display: block;
  font-size: 16px;
  height: 50px;
  padding-top: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 40px;
  font-family: "ut-iconfont" !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}
.foldableprog--open .foldableprog__title::after {
  content: "\e930";
}

.foldableprog__foldcontainer {
  height: 0;
  overflow: hidden;
}
.foldableprog__foldcontainer--active {
  transition: height 250ms;
}