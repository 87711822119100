/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*

Recent News & Events widget:

          Page width    - News        News
Design                    Highlight   Normal
1440      -             - 350 x 196 - 160 x 90    - FIXED
1280/1080 - 960+        - 290 x 162 - 130 x 73.13 - FIXED
750       - 750 t/m 959 - 270 x 150 - 150 x 84.38 - FIXED
320       -     t/m 749 - 280 x 150 -  60 x 60    - Fluid


Traits

Sidebar
-  960+        - News & events side-by-side
-  750 t/m 959 - Events below news but it's still the sidebar
- <750         - Event tabs stick to the sides

Other layout
- <749px       - hide description

-  751+        - 12col layout gets large padding

*/
.newseventsrecent__sidebar__maincontent {
  background-color: var(--color-white);
  border: 1px solid #afb1b3;
}

.newseventsrecent__tabstrip {
  display: flex;
  height: 60px;
}

.newseventsrecent__sidebar .tabstrip__panel {
  display: none;
}
.newseventsrecent__sidebar .tabstrip__panel--selected {
  display: block;
}

.newseventsrecent__tabstrip__item {
  flex: 1 1 0px;
  font: 700 18px/60px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  padding: 0 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newseventsrecent__tabstrip .tabstrip__tab {
  color: var(--color-white);
  background-color: #53575b;
}
.newseventsrecent__tabstrip .tabstrip__tab:not(.tabstrip__tab--selected) {
  cursor: pointer;
}
.newseventsrecent__tabstrip .tabstrip__tab--selected {
  color: #1e2328;
  background-color: var(--color-white);
}

a.newseventsrecent__readmore {
  display: inline-block;
  font: 300 18px/35px "Univers Next W02", Arial, Helvetica, sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.newseventsrecent__news__item {
  display: flex;
  align-items: flex-start;
}

a.newseventsrecent__news__item {
  text-decoration: none;
}

/*
disabled by request of Maarten

  a.newseventsrecent__news__item:hover .newseventsrecent__news__title
, a.newseventsrecent__news__item:hover .newseventsrecent__news__subtitle
{
  text-decoration: underline;
}
*/
.newseventsrecent__news__item + .newseventsrecent__news__item {
  margin-top: 30px;
}

.newseventsrecent__news__title {
  color: #1e2328;
}

.newseventsrecent__news__subtitle {
  color: #53575b;
}

.newseventsrecent__news__description {
  color: #53575b;
  font-weight: 300;
  overflow: hidden;
}

html.manualellipsis .newseventsrecent__news__description {
  height: 100px; /* 4 * 25px */
}

.newseventsrecent__news__imageholder {
  flex: none;
}

.newseventsrecent__news__item--highlight .newseventsrecent__news__imageholder {
  flex: none;
}

.newseventsrecent__news__item--highlight:hover .newseventsrecent__news__subtitle {
  color: #1e2328;
}

.newseventsrecent__news__imageholder img {
  width: 100%;
}

.newseventsrecent__news__meta {
  flex: 1 1 0;
  font: 700 18px/25px "Univers Next W02", Arial, Helvetica, sans-serif;
}

.newseventsrecent__news__item:not(.newseventsrecent__news__item--highlight) .newseventsrecent__news__meta {
  /*
  design:
  - 4 lines (text + description) of text
  - ellipsis (add using JS, but use sane breaking points if possible)
  - 20px lineheight. Use 4 lines * 20px = 80px as height to prevent height changes
  */
  /*
    max-height: 100px; // cut off to 4 lines of 25 px line-height
    overflow: hidden;
    text-overflow: ellipsis;
  */
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

a.newseventsrecent__event {
  display: block;
  text-decoration: none;
}

a.newseventsrecent__event:hover {
  text-decoration: none;
}

.newseventsrecent__event__when {
  color: #53575b;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.newseventsrecent__event:hover .newseventsrecent__event__when {
  color: #1e2328;
}

.newseventsrecent__event__day {
  font-weight: 700;
}

.newseventsrecent__event__month {
  margin-left: 1px;
}

.newseventsrecent__event__title {
  font: 300 18px/25px "Univers Next W02", Arial, Helvetica, sans-serif;
  color: #1e2328;
  overflow: hidden;
}

.newseventsrecent__sidebar .newseventsrecent__readmore {
  display: none;
}

.newseventsrecent__sidebar .newseventsrecent__sidebar__maincontent + .newseventsrecent__readmore {
  display: block;
}

@media (max-width: 959px) {
  .newseventsrecent__news + .newseventrecent__sidebar {
    margin-top: 40px;
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .newseventsrecent__news + .newseventrecent__sidebar {
    margin-top: 60px;
  }
}
@media (min-width: 1440px) {
  .newseventsrecent__news__imageholder {
    flex: none;
    width: 160px;
    height: 90px;
  }
  .newseventsrecent__news__image {
    background-size: cover;
    width: 160px;
    height: 90px;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__imageholder {
    width: 350px;
    height: 196px;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__image {
    flex: none;
    width: 350px;
    height: 196px;
  }
}
@media (min-width: 960px) and (max-width: 1439px) {
  .newseventsrecent__news__imageholder {
    flex: none;
    width: 130px;
    height: 73px;
  }
  .newseventsrecent__news__image {
    background-size: cover;
    width: 130px;
    height: 73px;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__imageholder {
    width: 290px;
    height: 162px;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__image {
    width: 290px;
    height: 162px;
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .newseventsrecent__news__imageholder {
    flex: none;
    width: 150px;
    height: 84px;
  }
  .newseventsrecent__news__image {
    background-size: cover;
    width: 150px;
    height: 84px;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__imageholder {
    width: 270px;
    height: 150px;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__image {
    width: 270px;
    height: 150px;
  }
}
@media (max-width: 749px) {
  .newseventsrecent__news__imageholder {
    width: 60px;
    height: 60px;
    width: 18.75vw;
    height: 18.75vw;
    max-width: 100px;
    max-height: 100px;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__imageholder {
    width: 100%;
    height: auto;
    max-width: none;
    max-height: none;
  }
  .newseventsrecent__news__image {
    background-size: cover;
    padding-top: 100%;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__image {
    padding-top: 53%;
  }
}
@media (max-width: 479px) {
  .newseventsrecent__news__meta {
    margin-left: 20px;
  }
  .newseventsrecent__news__description {
    display: none;
  }
  .newseventsrecent__event__when {
    font: 300 18px / 20px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
  html[lang=el] .newseventsrecent__event__when {
    font-family: Roboto Condensed;
  }
}
@media (max-width: 749px) {
  .newseventsrecent__news__meta {
    margin-left: 20px;
  }
  .newseventsrecent__news__item--highlight {
    flex-direction: column;
    align-items: stretch;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__meta {
    flex: auto;
    padding: 25px;
    margin-left: 0;
    border: 1px solid #dcddde;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__title {
    font: 700 18px/20px "Univers Next W02", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__subtitle {
    font: 300 18px / 20px "Univers Next W02", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
  }
  html[lang=el] .newseventsrecent__news__item--highlight .newseventsrecent__news__subtitle {
    font-family: Roboto Condensed;
  }
}
@media (min-width: 750px) {
  .newseventsrecent__news__meta {
    margin-left: 30px;
  }
  .newseventsrecent__news__title {
    margin-top: -4px;
  }
  .newseventsrecent__news__item--highlight {
    align-items: center;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__title {
    font: 700 26px/26px "Univers Next W02", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__subtitle {
    font: 300 26px/26px "Univers Next W02", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
  }
  .newseventsrecent__news__item--highlight .newseventsrecent__news__title,
  .newseventsrecent__news__item--highlight .newseventsrecent__news__subtitle {
    display: inline;
  }
  .newseventsrecent__event__when {
    font: 300 26px/26px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
}
@media (max-width: 960px) {
  .newseventsrecent__news + .newseventsrecent__sidebar {
    margin-top: 50px;
  }
  .newseventsrecent__event + .newseventsrecent__event {
    margin-top: 40px;
  }
  a.newseventsrecent__readmore {
    margin-top: 20px;
  }
}
.embeddedobject--newseventsrecent {
  margin-top: 30px;
}

@media (max-width: 479px) {
  .embeddedobject--newseventsrecent__title {
    margin-top: 65px;
  }
}
@media (min-width: 480px) {
  .embeddedobject--newseventsrecent__title {
    margin-top: 65px;
  }
}
@media (min-width: 1080px) {
  .embeddedobject--newseventsrecent__title {
    margin-top: 95px;
  }
}
@media (min-width: 1600px) {
  .embeddedobject--newseventsrecent__title {
    margin-top: 100px;
  }
}

@media (min-width: 961px) {
  h2.embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title {
    --layout: 12col;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 960px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 961px) and (max-width: 960px) {
  h2.embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 961px) and (min-width: 1440px) {
  .page--toplevelhome h2.embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 960px) {
  /*
  Because of the foldable stuff (page content divided into parts when there are multiple H2's)
  we need to be sure to override the width with high specificy

  - We need 2 classes to override .main__contents--content8col > *
  - We need 3 classes to override .main__contents--content8col .foldabledeeplink__text > *
  - In side-by-side view we *always* want 12 column layout
  */
  .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent {
    --layout: 12col;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 960px;
    width: var(--page-contentwidth-12col);
    contain: layout paint style;
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 960px) and (min-width: 1440px) {
  .page--toplevelhome .embeddedobject--newseventsrecent.embeddedobject--newseventsrecent.embeddedobject--newseventsrecent {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 960px) {
  .newseventsrecent__columns {
    display: flex;
    min-height: 200px;
  }
  .newseventsrecent__news {
    flex: 0 0 auto;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .newseventsrecent__news {
    width: calc((100% - 20px - 11 * 30px) / 12 * 8 + 7 * 30px);
    width: calc((100% - var(--pageside-padding) * 2 - 11 * 30px) / 12 * 8 + 7 * 30px);
  }
}
@media (min-width: 960px) and (min-width: 961px) {
  .newseventsrecent__news {
    width: 610px;
  }
}
@media (min-width: 960px) and (min-width: 1440px) {
  .page--toplevelhome .newseventsrecent__news {
    width: 730px;
  }
}
@media (min-width: 960px) {
  .newseventsrecent__sidebar {
    flex: 0 0 auto;
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .newseventsrecent__sidebar {
    width: calc((100% - 20px - 11 * 30px) / 12 * 4 + 3 * 30px);
    width: calc((100% - var(--pageside-padding) * 2 - 11 * 30px) / 12 * 4 + 3 * 30px);
  }
}
@media (min-width: 960px) and (min-width: 961px) {
  .newseventsrecent__sidebar {
    width: 290px;
  }
}
@media (min-width: 960px) and (min-width: 1440px) {
  .page--toplevelhome .newseventsrecent__sidebar {
    width: 350px;
  }
}
@media (min-width: 960px) {
  .newseventsrecent__news__items {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .newseventsrecent__sidebar {
    display: flex;
    flex-direction: column;
  }
  a.newseventsrecent__readmore {
    margin-top: 20px;
  }
  .newseventsrecent__sidebar__maincontent {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 450px;
  }
  .newseventsrecent__tabstrip {
    flex: none;
  }
  .newseventsrecent__sidebar .tabstrip__panel--selected {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: hidden;
    flex-wrap: wrap;
  }
  .newseventsrecent__event {
    padding: 0 25px;
  }
  .newseventsrecent__sidebar__events:not(.newseventsrecent__sidebar__events--spread) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .newseventsrecent__sidebar__events:not(.newseventsrecent__sidebar__events--spread) .newseventsrecent__event + .newseventsrecent__event {
    margin-top: 40px;
  }
  .newseventsrecent__sidebar__events--spread .newseventsrecent__event.newseventsrecent__event {
    display: flex;
    flex-direction: column;
    flex: 2 0 auto;
    width: 100%;
  }
  .newseventsrecent__sidebar__events--spread .newseventsrecent__event.newseventsrecent__event:first-child {
    flex: 1 0 auto;
  }
  .newseventsrecent__sidebar__events--spread .newseventsrecent__event::before,
  .newseventsrecent__sidebar__events--spread .newseventsrecent__event::after {
    content: "";
    display: block;
    flex: 1 0 20px;
    max-height: 30px;
  }
  .newseventsrecent__sidebar__events--spread .newseventsrecent__event:first-child::before {
    flex: 0 0 25px;
  }
  .newseventsrecent__sidebar__events--spread .newseventsrecent__event:last-child::after {
    flex: 0 0 25px;
  }
}
@media (max-width: 959px) {
  .newseventsrecent__tabstrip__panel {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .newseventsrecent__event:nth-child(1n+6) {
    display: none;
  }
}
@media (min-width: 750px) and (max-width: 959px) {
  .newseventsrecent__event {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 749px) {
  .embeddedobject--newseventsrecent {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }
  .embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title,
  .newseventsrecent__news.newseventsrecent__news,
  .newseventsrecent__tabstrip__panel,
  .newseventsrecent__sidebar .newseventsrecent__readmore {
    --layout: 8col;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 640px;
    /*
    Activate when 1440+ design becomes available for all pages
      @media (min-width: $responsive-xxmedium-devices)
      {
        width: $largescreen-width-8col;
      }
    */
  }
}
@media (max-width: 749px) and (max-width: 750px) {
  .embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title,
  .newseventsrecent__news.newseventsrecent__news,
  .newseventsrecent__tabstrip__panel,
  .newseventsrecent__sidebar .newseventsrecent__readmore {
    margin-left: 0;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%;
  }
}
@media (max-width: 749px) and (max-width: 480px) {
  .embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title,
  .newseventsrecent__news.newseventsrecent__news,
  .newseventsrecent__tabstrip__panel,
  .newseventsrecent__sidebar .newseventsrecent__readmore {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 749px) and (min-width: 0) {
  .embeddedobject--newseventsrecent__title.embeddedobject--newseventsrecent__title,
  .newseventsrecent__news.newseventsrecent__news,
  .newseventsrecent__tabstrip__panel,
  .newseventsrecent__sidebar .newseventsrecent__readmore {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}
@media (max-width: 749px) {
  .newseventsrecent__tabstrip__item:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 749px) and (max-width: 750px) {
  .newseventsrecent__tabstrip__item:first-child {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (max-width: 749px) and (max-width: 480px) {
  .newseventsrecent__tabstrip__item:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 749px) and (min-width: 0) {
  .newseventsrecent__tabstrip__item:first-child {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}
@media (max-width: 749px) {
  .newseventsrecent__sidebar__maincontent {
    border-width: 0px;
    border-top-width: 1px;
  }
}