/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*
New style videocontainer and playbuttons (already in use by slideshow).

ADDME: Beside timeline also use in slideshow and carrousel embeddedobjects
*/
.generic_videocontainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: flex;
  align-items: center;
  /* make a kind of theater (a place to focus on the video,
     rather than having the video sticking/blending to the page
  padding: 10px 80px;
  */
  background-color: #000000;
}

.generic_videocontainer,
.generic_videocontainer .video-close {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s;
}

.genericvideoplayeractive .generic_videocontainer, .genericvideoplayeractive .generic_videocontainer .generic_videocontainer__closevideo {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}

.generic_videocontainer__closevideo {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 18px;
  height: 18px;
  background-image: url("img/close-white.svg");
  background-size: cover;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}

.genericvideoplayeractive .generic_videocontainer__closevideo {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 1.5s;
}

.generic_videoplaybutton {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  line-height: 56px;
  text-align: center;
  transition: background-color 0.25s, opacity 0.25s;
  cursor: pointer;
}

.generic_videoplaybutton__icon::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e951";
  font-size: 16px;
}

.generic_videoplaybutton {
  opacity: 1;
}

.genericvideoplayeractive .generic_videoplaybutton {
  opacity: 0;
  pointer-events: none;
}

/* ITC uses fixed colors for the buttons */
html.site--itc .generic_videoplaybutton {
  background-color: #00675a;
}
html.site--itc .generic_videoplaybutton:hover {
  background-color: var(--color-white);
}
html.site--itc .generic_videoplaybutton__icon.generic_videoplaybutton__icon {
  color: var(--color-white);
}
html.site--itc .generic_videoplaybutton:hover .generic_videoplaybutton__icon.generic_videoplaybutton__icon {
  color: #00675a;
}

/* Use the animating themecolor for buttons */
html:not(.site--itc) .generic_videoplaybutton:hover {
  animation: none !important;
  background-color: var(--color-white);
}
html:not(.site--itc) .generic_videoplaybutton:not(:hover) .generic_videoplaybutton__icon {
  animation: none !important;
  color: var(--color-white);
}