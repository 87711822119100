/* Detail page */
.coursefinder-noseriesavailablecta {
  position: relative;
}
.coursefinder-noseriesavailablecta > .inlinecta--withicon {
  margin-left: -10px;
}
.coursefinder-noseriesavailablecta > .inlinecta--withicon .inlinecta__content {
  border-left: 0 none;
}
.coursefinder-noseriesavailablecta::before {
  content: "";
  display: block;
  border-left: 1px solid #dcddde;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
}
@media (max-width: 750px) {
  .coursefinder-noseriesavailablecta::before {
    left: 55px;
  }
  .coursefinder-noseriesavailablecta .inlinecta--withicon {
    margin-left: -15px;
  }
  .coursefinder-noseriesavailablecta .inlinecta--withicon .inlinecta__icon {
    left: -5px;
  }
}
@media (max-width: 480px) {
  .coursefinder-noseriesavailablecta::before {
    left: 15px;
  }
}

.coursepage__form {
  position: relative;
}
.coursepage__form .noavailableoptions {
  padding-top: 20px;
  padding-left: 47px;
}
.coursepage__form > .wh-form__page > .wh-form__fieldgroup.fieldgroup-serie {
  display: block !important;
}
.coursepage__form .serie-title {
  font-size: 13px;
  margin-top: -5px;
  margin-bottom: 5px;
}
.coursepage__form::before {
  content: "";
  position: absolute;
  border-left: 1px solid #dcddde;
  left: 10px;
  top: 0;
  height: 100%;
}
.coursepage__form .inlinecta--withicon {
  margin-left: -10px;
}
.coursepage__form .inlinecta--withicon .inlinecta__content {
  border-left: 0 none;
  padding-bottom: 0;
}
.coursepage__form .inlinecta--withicon .inlinecta__description {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
}
@media (max-width: 750px) {
  .coursepage__form::before {
    left: 55px;
  }
  .coursepage__form .inlinecta--withicon {
    margin-left: -15px;
  }
  .coursepage__form .inlinecta--withicon .inlinecta__icon {
    left: -5px;
  }
}
@media (max-width: 480px) {
  .coursepage__form::before {
    left: 15px;
  }
}
.coursepage__form.wh-form--disabledsubmit .wh-form__button--submit {
  cursor: default;
  opacity: 0;
  visibility: hidden;
}
.coursepage__form.wh-form--disabledsubmit .wh-form__button--submit:hover {
  color: inherit;
  background-color: inherit;
}
.coursepage__form.wh-form--disabledsubmit .wh-form__page--naw .questionsgroup {
  display: none;
  /*
  opacity: 0.3;
  */
}
.coursepage__form .fieldgroup-serie {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.coursepage__form .fieldgroup-serie .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 0;
}
.coursepage__form .fieldgroup-serie__month {
  font-size: 18px;
  height: 50px;
  line-height: 26px;
  font-weight: 700;
  padding: 13px 15px 11px 47px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding-right: 60px;
  position: relative;
  border-bottom: 1px solid #DCDDDE;
  margin-top: -1px;
}
.coursepage__form .fieldgroup-serie__monthtoggle {
  color: #AAACAE;
  display: inline-block;
  height: 16px;
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -12px;
}
.coursepage__form .fieldgroup-serie__monthtoggle .less {
  display: none;
}
.coursepage__form .fieldgroup-serie__monthtoggle .more {
  display: inline-block;
}
.coursepage__form .fieldgroup-serie__month.active .fieldgroup-serie__monthtoggle .less {
  display: inline-block;
}
.coursepage__form .fieldgroup-serie__month.active .fieldgroup-serie__monthtoggle .more {
  display: none;
}
.coursepage__form .fieldgroup-serie__monthitems {
  transition: height 0.3s;
  overflow: hidden;
  position: relative;
  height: 0;
}
.coursepage__form .fieldgroup-serie__month.active + .fieldgroup-serie__monthitems {
  height: auto;
}
.coursepage__form .fieldgroup-serie .wh-form__fieldline {
  border-bottom: 1px solid #DCDDDE;
  padding-left: 15px;
  min-height: 45px;
  padding-top: 11px;
}
.coursepage__form .fieldgroup-serie .wh-form__optionlabel {
  display: flex;
  flex-flow: row;
  font-size: 13px;
  align-content: center;
  white-space: nowrap;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .coursepage__form .fieldgroup-serie .wh-form__optionlabel {
    flex-wrap: wrap;
  }
}
.coursepage__form .fieldgroup-serie .wh-form__optionlabel .sessions {
  flex: 0 1 350px;
  min-width: 190px;
  overflow-y: hidden;
  max-height: 30px;
  transition: max-height 0.3s;
}
.coursepage__form .fieldgroup-serie .wh-form__optionlabel .session {
  display: flex;
}
.coursepage__form .fieldgroup-serie .wh-form__optionlabel .session:last-child {
  padding-bottom: 20px;
}
.coursepage__form .fieldgroup-serie .wh-form__optionlabel .session--first {
  display: none;
}
@media (max-width: 450px) {
  .coursepage__form .fieldgroup-serie .wh-form__optionlabel .session--first {
    display: flex;
  }
}
.coursepage__form .fieldgroup-serie .wh-form__optionlabel .session + .session {
  padding-top: 5px;
}
.coursepage__form .fieldgroup-serie .weekday, .coursepage__form .fieldgroup-serie .time, .coursepage__form .fieldgroup-serie .duration {
  flex: 0 0 85px;
}
.coursepage__form .fieldgroup-serie .time {
  flex: 0 1 135px;
  min-width: 95px;
}
.coursepage__form .fieldgroup-serie .note {
  flex: 1 1 95px;
  min-width: 95px;
}
.coursepage__form .fieldgroup-serie .date {
  flex: 0 1 130px;
  min-width: 95px;
}
.coursepage__form .fieldgroup-serie .duration {
  position: relative;
  color: #5A5E62;
  font-size: 15px;
  font-weight: 300;
}
.coursepage__form .fieldgroup-serie .multiplesessions .duration {
  cursor: pointer;
  text-decoration: underline;
}
@media (max-width: 620px) {
  .coursepage__form .fieldgroup-serie .weekday {
    display: none;
  }
}
@media (max-width: 450px) {
  .coursepage__form .fieldgroup-serie .duration {
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 10px;
  }
}
.coursepage__form .wh-form__page--naw {
  border-top: 1px solid #DCDDDE;
}
.coursepage__form .loginbar {
  display: flex;
  flex-flow: row;
  padding: 20px 40px;
  background-color: #F0F1F2;
  border-bottom: 1px solid #DCDDDE;
  align-items: center;
  justify-content: space-between;
}
.coursepage__form .loginbar > * {
  display: inline-block;
}
.coursepage__form .loginbar > button {
  margin-left: 20px;
}
.coursepage__form .questionsgroup {
  padding-left: 40px; /* align with CTA text */
}
.coursepage__form .wh-form__buttongroup {
  padding-bottom: 30px;
}
.coursepage__form .resulttext {
  padding: 0 40px 20px;
}
.coursepage__form .resulttext .errortitle {
  padding-bottom: 20px;
  font-weight: bold;
}
.coursepage__form .wh-form__textblock {
  padding: 20px 0 20px 40px;
}
.coursepage__form .wh-form__textblock.nocredentials {
  font-weight: bold;
  color: #cd202c;
}
.coursepage__form .wh-form__textblock ul.unordered {
  padding-left: 35px;
}
@media (max-width: 750px) {
  .coursepage__form .wh-form__buttongroup {
    margin-left: 40px;
    padding-left: 0;
  }
  .coursepage__form .wh-form__buttongroup .wh-form__button--previous {
    right: auto;
  }
  .coursepage__form .wh-form__textblock {
    padding-left: 30px;
  }
}
@media (max-width: 500px) {
  .coursepage__form .loginbar {
    padding: 10px 25px;
  }
  .coursepage__form .questionsgroup {
    padding-left: 25px; /* align with CTA text */
  }
  .coursepage__form .wh-form__buttongroup {
    margin-left: 25px;
  }
  .coursepage__form .wh-form__textblock {
    padding-left: 25px;
  }
}

.coursefinder__footnote > div {
  padding: 20px 40px;
  font-style: inherit;
  font-size: 13px;
}

form.hidefootnote + .coursefinder__footnote {
  display: none;
}