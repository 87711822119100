/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*
Used by:
- Filteredoverview feedback bar
- LinksPerCategory_Tabs widget
- tags.scss
*/
/*
Used in:
- default .tagslist__tag (used by linkspercategory_colors and linkspercategory_tabs widgets)
- slideshow widget slide tag
*/
/*
Used by
- linkspercategory_tabs embeddedobject
- linkspercategory_color embeddedobject
*/
.tagslist__container {
  display: -webkit-flex;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: -8px;
}

/*
LinksPerCategory_tabs (categoriekeuze widget)
    line-height:   30px;
    border:        1px solid $color-grey-normal;
    border-radius: 2px;
    color:         $color-grey-dark;

Studiefinder tags
    line-height:   24px;
    border:        1px solid $color-grey-normal;
    border-radius: 3px;
    color:         $color-grey-normal;
*/
.tagslist__tag {
  display: -webkit-inline-flex;
  display: inline-flex;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: 700 15px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 2px;
  background-color: var(--color-white);
  border: 1px solid #afb1b3;
  color: #53575b;
  cursor: pointer;
}
html[lang=el] .tagslist__tag {
  font-family: Roboto Condensed;
}

.tagslist__container .tagslist__tag {
  margin-right: 10px;
  margin-bottom: 8px;
}

.tagslist__tag:hover {
  color: #1e2328;
  border-color: #1e2328;
}

.tagslist__tag--grey {
  background-color: #53575b;
  border: 1px solid #53575b;
  color: var(--color-white);
}

.tagslist__tag--grey:hover {
  background-color: #1e2328;
  color: var(--color-white);
}

.pagetags a {
  display: inline-block;
  border-radius: 2px;
  text-decoration: none;
  white-space: nowrap;
  line-height: 18px;
  padding: 3px 7px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  background-color: var(--color-white);
  transition: background-color 0.15s;
}
.pagetags a:hover {
  background-color: #f8f8f8;
}
.pagetags a + a {
  margin-left: 5px;
}

.embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs {
  contain: layout paint style;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.lpct__title + .embeddedobject--linkspercategory_tabs,
.lpct__title + .foldabledeeplink__foldcontainer .embeddedobject--linkspercategory_tabs {
  margin-top: 0;
}

@media (min-width: 961px) {
  .embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs {
    --layout: 12col;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 960px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 961px) and (max-width: 960px) {
  .embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 961px) and (min-width: 1440px) {
  .page--toplevelhome .embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs.embeddedobject--linkspercategory_tabs {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 961px) {
  h2.lpct__title.lpct__title {
    --layout: 12col;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 960px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 961px) and (max-width: 960px) {
  h2.lpct__title.lpct__title {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 961px) and (min-width: 1440px) {
  .page--toplevelhome h2.lpct__title.lpct__title {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
.lpct__categorylist__item {
  display: flex;
  padding: 0 20px;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  align-items: center;
  font: 700 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
html[lang=el] .lpct__categorylist__item {
  font-family: Roboto Condensed;
}

.lpct__category > *:first-child {
  margin-top: 0;
}

.lpct__category > *:last-child {
  margin-bottom: 0;
}

.lpct__category__content > *:first-child {
  margin-top: 0;
}

.lpct__category__content > *:last-child {
  margin-bottom: 0;
}

@media (max-width: 959px) {
  .lpct__categorylist__item {
    background-color: var(--color-white);
    border: 1px solid #afb1b3;
    color: #53575b;
  }
  .lpct__categorylist__item:hover,
  .lpct__categorylist__item--active,
  .lpct__categorylist__item--activeinitial {
    background-color: #53575b;
    border-color: #53575b;
    color: var(--color-white);
  }
}
@media (min-width: 960px) {
  /*
  Because of the foldable stuff (page content divided into parts when there are multiple H2's)
  we need to be sure to override the width with high specificy

  We MUST override .main__contents--content8col .foldabledeeplink__text>*
  */
  .foldabledeeplink__foldcontainer > .foldabledeeplink__text > .lpct__content {
    --layout: 12col;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 960px;
    width: var(--page-contentwidth-12col);
    contain: layout paint style;
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .foldabledeeplink__foldcontainer > .foldabledeeplink__text > .lpct__content {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 960px) and (min-width: 1440px) {
  .page--toplevelhome .foldabledeeplink__foldcontainer > .foldabledeeplink__text > .lpct__content {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 960px) {
  .lpct__categorylist__item {
    background-color: #53575b;
    color: var(--color-white);
  }
  .lpct__categorylist__item:hover,
  .lpct__categorylist__item--active,
  .lpct__categorylist__item--activeinitial {
    background-color: var(--color-white);
    border: 1px solid #53575b;
    color: #1e2328;
  }
}
.lpct__categoriespanel p {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 479px) {
  .lpct__title {
    margin-bottom: 15px;
  }
  .lpct__categorylist__item a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .lpct__categorylist__item + .lpct__categorylist__item {
    margin-top: 10px;
  }
  .lpct__category.lpct__category--hasmobilelink {
    display: none;
  }
  .lpct__category {
    overflow: hidden;
    max-height: 100vh;
    transition: max-height 0.25s, opacity 0.25s;
    opacity: 1;
  }
  .lpct__category:not(.lpct__category--active):not(.lpct__category--activeinitial) {
    opacity: 0;
    max-height: 0;
  }
}
@media (min-width: 480px) {
  .lpct__categorylist__item a {
    display: none;
  }
}
@media (min-width: 480px) and (max-width: 959px) {
  .lpct__title {
    margin-bottom: 20px;
  }
  .lpct__categorylist,
  .lpct__categorylist__leftside {
    display: -webkit-flex;
    display: flex;
  }
  .lpct__categorylist__item + .lpct__categorylist__item {
    margin-left: 10px;
  }
  .lpct__category:not(.lpct__category--active):not(.lpct__category--activeinitial) {
    display: none;
  }
}
@media (max-width: 959px) {
  .lpct__categorylist__item {
    position: relative;
  }
  .lpct__content {
    padding-top: 20px;
  }
  .lpct__pointer__container {
    display: none;
  }
  .lpct__categoriespanel {
    margin-top: 45px;
  }
}
@media (min-width: 960px) {
  .lpct__title {
    margin-bottom: 25px;
  }
  .lpct__content {
    display: -webkit-flex;
    display: flex;
  }
  .lpct__leftside {
    display: -webkit-flex;
    display: flex;
  }
  /* NOTE: Due to Edge and IE we need an extra wrapper, because align-items: stretch; doesn't work on SVG there.
           We apply the size to .lpct__pointer__container and then set the SVG to width and height 100%.
  */
  /* equal-height trick by Mark */
}
@media (min-width: 960px) and (max-width: 960px) {
  .lpct__leftside {
    width: calc((100% - 20px - 11 * 30px) / 12 * 4 + 3 * 30px);
    width: calc((100% - var(--pageside-padding) * 2 - 11 * 30px) / 12 * 4 + 3 * 30px);
  }
}
@media (min-width: 960px) and (min-width: 961px) {
  .lpct__leftside {
    width: 290px;
  }
}
@media (min-width: 960px) and (min-width: 1440px) {
  .page--toplevelhome .lpct__leftside {
    width: 350px;
  }
}
@media (min-width: 960px) {
  .lpct__categorylist {
    flex: 1 1 0px;
  }
  .lpct__categorylist__item + .lpct__categorylist__item {
    margin-top: 10px;
  }
  .lpct__categorylist__item a {
    display: none;
  }
  .lpct__pointer__container {
    width: 60px;
    flex: none;
    border-right: 1px solid #afb1b3;
  }
  .lpct__pointer {
    width: 100%;
    height: 100%;
  }
  .lpct__pointer__curve {
    stroke-width: 1px;
    stroke: #afb1b3;
    stroke-linecap: round;
    fill: none;
  }
  .lpct__categoriespanel {
    margin-left: 30px;
    flex: 1 0 0px;
    color: #1e2328;
    font: 300 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  }
  html[lang=el] .lpct__categoriespanel {
    font-family: Roboto Condensed;
  }
  .lpct__category__content b {
    font: 700 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  }
  html[lang=el] .lpct__category__content b {
    font-family: Roboto Condensed;
  }
  .lpct__categoriespanel {
    display: flex;
    overflow: hidden;
  }
  .lpct__category {
    flex: 0 0 100%;
  }
  .lpct__category--active,
  .lpct__category--activeinitial {
    order: -1;
  }
}
/******************************/
.lpct__categorylist__item .tagslist__container {
  margin-top: 20px;
}
.lpct__categorylist__item .tagslist__container + * {
  margin-top: 20px;
}
.lpct__categorylist__item .lpct__category :first-child {
  margin-top: 0;
}