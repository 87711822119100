@import "@mod-utwente_design/scss/config-colors.css";
.widget-multilanguagedownloads {
  color: var(--color-black);
  margin-top: 25px;
}
.widget-multilanguagedownloads__header {
  padding: 20px;
  background-color: var(--color-white);
}
.widget-multilanguagedownloads__header .widget__title {
  padding: 0;
}
.widget-multilanguagedownloads__header h3 {
  text-transform: uppercase;
}
.widget-multilanguagedownloads__header p {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 0;
}
.widget-multilanguagedownloads ul {
  list-style-type: none;
  margin: 0;
  background-color: var(--color-white);
  padding: 0 20px;
  position: relative;
  z-index: 0;
}
.widget-multilanguagedownloads li {
  border-bottom: 1px solid var(--color-grey-lines);
}
.widget-multilanguagedownloads li a {
  text-decoration: none;
  display: flex;
}
.widget-multilanguagedownloads__item {
  padding: 15px 0;
  position: relative;
  border-top: 0 none;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
  line-height: 165%;
}
.widget-multilanguagedownloads__item > * {
  display: block;
}
.widget-multilanguagedownloads__item__text {
  flex: 1 1 auto;
  padding-right: 15px;
}
.widget-multilanguagedownloads__item__text > span {
  display: block;
}
.widget-multilanguagedownloads__item__title {
  font-weight: bold;
}
.widget-multilanguagedownloads__item__description {
  color: var(--color-grey-normal);
}
.widget-multilanguagedownloads__item__icon {
  font-size: 24px;
  flex: none;
  width: 50px;
  line-height: 1;
  color: var(--color-grey-normal);
  transition: color 0.3s;
  text-align: center;
  padding-left: 10px;
  padding-top: 5px;
}
.widget-multilanguagedownloads__item__icon[class$="16px"] {
  padding-top: 8px;
  font-size: 16px;
}
.widget-multilanguagedownloads__item__link {
  flex: none;
  width: 50px;
  font-size: 15px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 6px;
  position: relative;
  text-underline-position: under;
}
.widget-multilanguagedownloads__item__link--en {
  font-weight: bold;
}
.widget-multilanguagedownloads__item__link--nl:hover, .widget-multilanguagedownloads__item__link--en:hover {
  text-decoration: underline;
}
.widget-multilanguagedownloads__item__link--nl {
  font-weight: 500;
}
.widget-multilanguagedownloads__item__link--nl::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -6px;
  background-color: var(--color-grey-lines);
  height: 45px;
  width: 1px;
}
.widget-multilanguagedownloads__header, .widget-multilanguagedownloads ul {
  padding: 0;
}

.widget-section .widget-downloads ul {
  background-color: inherit; /* inside serviceportal section */
}

.article__columns__right { /* inside serviceportal right column  */ }
.article__columns__right .widget-downloads__header, .article__columns__right .widget-downloads ul {
  padding-right: 0px;
  padding-left: 0px;
}

/* Note: widget-downloads styling is also used by publications widget */
.widget-downloads {
  color: var(--color-black);
}
.widget-downloads--embobj {
  margin-top: 25px;
}
.widget-downloads--embobj .widget-downloads__header {
  border: 1px solid var(--color-grey-lines);
  border-bottom: 0 none;
}
.widget-downloads__header {
  padding: 20px;
  background-color: var(--color-white);
}
.widget-downloads__header .widget__title {
  padding: 0;
}
.widget-downloads__header h3 {
  text-transform: uppercase;
}
.widget-downloads__header p {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 0;
}
.widget-downloads ul {
  list-style-type: none;
  margin: 0;
  background-color: var(--color-white);
  padding: 0 20px;
  position: relative;
  z-index: 0;
}
.widget-downloads--embobj ul {
  border: 1px solid var(--color-grey-lines);
  border-top: 0 none;
}
.widget-downloads--embobj.widget-downloads--noheader ul {
  border-left: 0 none;
  border-right: 0 none;
  padding: 0;
}
.widget-downloads li a {
  text-decoration: none;
  display: block;
}
.widget-downloads__item {
  font-weight: 300;
  padding: 15px 50px 15px 0;
  position: relative;
  border-top: 1px solid var(--color-grey-lines);
  font-size: 18px;
}
.widget-downloads__item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -20px;
  bottom: 0;
  width: 0;
  background-color: var(--color-grey-lighter);
  transition: all 300ms;
  z-index: -1;
}
.widget-downloads__item:focus::before {
  width: 100%;
  opacity: 0.3;
}
.widget-downloads__item:hover::before {
  width: 100%;
  opacity: 1;
}
.widget-downloads__item > * {
  display: block;
}
.widget-downloads__item__title {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 300;
}
.widget-downloads__item__icon {
  position: absolute;
  font-size: 24px;
  top: 50%;
  margin-top: -12px;
  right: 10px;
  line-height: 1;
  color: var(--color-grey-normal);
  transition: color 0.3s;
}
.widget-downloads__item__icon[class$="16px"] {
  margin-right: 4px;
  font-size: 16px;
  margin-top: -8px;
}
.widget-downloads__item:hover .widget-downloads__item__icon {
  color: inherit;
}
.widget-downloads--embobj .widget-downloads__item__title {
  font-weight: bold;
}

/* exceptions when inside faq */
.embeddedobject-faq .widget-downloads__header,
.embeddedobject-faq .widget-downloads ul,
.embeddedobject-faq .widget-multilanguagedownloads__header,
.embeddedobject-faq .widget-multilanguagedownloads ul {
  background-color: transparent;
}

.widget-section--personalized,
.widget-section--loggedin { /* Inside Serviceportal personalized section (has Tahiti background color with opacity) */ }
.widget-section--personalized .widget-downloads__item::before,
.widget-section--loggedin .widget-downloads__item::before {
  display: none !important;
}
.widget-section--personalized .widget-downloads__header,
.widget-section--loggedin .widget-downloads__header {
  background-color: transparent;
}