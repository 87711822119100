@import "@mod-utwente_design/scss/config-colors.css";
.widget-phonedirectory {
  background-color: var(--color-white);
}
.widget-phonedirectory__form {
  background-color: #f0f1f2;
  margin: 0;
  padding: 20px 10px 20px 20px;
  display: flex;
}
.widget-phonedirectory__form > * {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
}
.widget-phonedirectory__form > *:focus {
  outline: none;
}
.widget-phonedirectory__form input {
  border: 1px solid #dcddde;
  flex: 1 1 100%;
  padding: 9px 10px;
  line-height: 20px;
  font-size: 15px;
  transition: border-color 0.3s;
}
.widget-phonedirectory__form input:focus {
  border-color: #5a5e62;
}
.widget-phonedirectory__form button {
  flex: none;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  opacity: 0.5;
  transition: opacity 0.3s;
  background-color: transparent;
  border: 0 none;
  padding: 0 10px;
  cursor: pointer;
}
.widget-phonedirectory__form button:hover, .widget-phonedirectory__form button:focus {
  opacity: 1;
}
.widget-phonedirectory__footer {
  padding: 20px;
  font-size: 13px;
  font-style: italic;
  color: #53575b;
}