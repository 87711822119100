body .datacloud-instructions__dialog {
  max-width: 1040px;
  width: 100%;
  padding: 0;
  border: 0 none;
  background-color: transparent;
  box-shadow: none !important;
}
body .datacloud-instructions__dialog .widget {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.widget-instructions {
  max-width: 1020px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 450px;
  overflow: hidden;
  border-radius: 2px;
}
.widget-instructions .slide__scrollwrapper {
  max-height: 100%;
  overflow-y: auto;
  position: relative;
}
.widget-instructions .slide__scrollwrapper.ps {
  padding-right: 20px;
  margin-right: -20px;
}
.widget-instructions .slide__scrollwrapper.ps:not(.ps--active-y) > .ps__rail-y {
  display: none;
}
.widget-instructions .slide__scrollwrapper.ps > .ps__rail-y {
  opacity: 0.7;
  display: block;
  background-color: transparent !important;
}
.widget-instructions .slide__scrollwrapper.ps > .ps__rail-y::before {
  content: "";
  background-color: #DCDDDE;
  border-radius: 2px;
  width: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.widget-instructions .slide__scrollwrapper.ps > .ps__rail-y > .ps__thumb-y {
  width: 4px !important;
  background-color: #5a5e62;
  right: 0;
}
.widget-instructions .title {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}
.widget-instructions .subtitle {
  font-size: 26px;
  font-weight: 300;
  line-height: 32px;
}
.widget-instructions .text {
  margin-top: 20px;
}
.widget-instructions .text p + p {
  margin-top: 15px;
}
.widget-instructions .text a {
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}
.widget-instructions .contentarea {
  height: 450px;
  display: flex;
  flex-flow: row;
}
.widget-instructions .contentarea > div {
  flex: 0 0 50%;
  max-width: 50%;
}
.widget-instructions .slides__description {
  z-index: 0;
  background-color: #fff;
}
.widget-instructions .slides__description .slides {
  display: block;
  height: 100%;
  position: relative;
}
.widget-instructions .slides__description .slides > .slide {
  position: absolute;
  padding: 45px;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.5s;
  transform: translate3d(0, 0, 0);
}
.widget-instructions .slides__description .slides > .slide.active {
  z-index: 1;
  opacity: 1;
}
.widget-instructions .slides__image {
  overflow: hidden;
  position: relative;
}
.widget-instructions .slides__image .slides {
  display: block;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  transition: transform 0.5s;
  transform: translate3d(0, 0, 0);
}
.widget-instructions .slides__image .slides > .slide {
  display: inline-block;
  height: 100%;
  width: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.widget-instructions .slides__image .slides > .slide.active {
  z-index: 1;
}
.widget-instructions .slides__image::after {
  content: "";
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -7px;
  transform: rotate(2deg);
  width: 20px;
  background-color: #0039A6;
  opacity: 0.1;
}
.widget-instructions .slideshow__btns {
  position: absolute;
  left: 45px;
  bottom: 18px;
  white-space: nowrap;
}
.widget-instructions .slideshow__btns > span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 2px #fff;
  transition: border-color 0.3s;
  margin-right: 2px;
}
.widget-instructions .slideshow__btns > span::before {
  content: "";
  background-color: #dcddde;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transition: background-color 0.3s;
}
.widget-instructions .slideshow__btns > span.active {
  border-color: #1d2328;
}
.widget-instructions .slideshow__btns > span.active::before {
  background-color: #1d2328;
}
.widget-instructions .slideshow__nav {
  position: absolute;
  left: 50%;
  margin-left: -60px;
  bottom: 0;
  width: 120px;
  height: 60px;
  white-space: nowrap;
  font-size: 16px;
  color: #AAACAE;
}
.widget-instructions .slideshow__nav > div {
  display: inline-block;
  width: 60px;
  height: 100%;
  text-align: center;
  padding-top: 20px;
  cursor: pointer;
}
.widget-instructions .slideshow__nav > div + div {
  background-color: var(--theme-color-cta);
  color: #fff;
}
@media (max-width: 750px) {
  .widget-instructions {
    min-height: 0;
  }
  .widget-instructions .contentarea {
    flex-flow: column;
    height: auto;
  }
  .widget-instructions .contentarea > div {
    max-width: 100%;
    width: 100%;
    flex: 0 0 auto;
  }
  .widget-instructions .contentarea > .slides__description {
    order: 1;
    height: 100%;
    padding-bottom: 40px;
  }
  .widget-instructions .contentarea > .slides__description > .slides > .slide {
    height: auto;
  }
  .widget-instructions .contentarea > .slides__image > .slides {
    height: 320px;
    max-height: 50vw;
  }
  .widget-instructions .slideshow__nav {
    left: auto;
    right: 0;
    margin-left: 0;
  }
}