/* imageblock */
.imageblock.imageblock.main__contents--fullwidth {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  min-height: var(--header-background-height);
  height: var(--header-photos-height);
  /*
  .contentpart__header:not(.contentpart__header--fixedheight) .contentpart__header-photos::before, .contentpart__header:not(.contentpart__header--fixedheight) .contentpart__header-videos::before
    */
  width: 100% !important; /* otherwise the use of aspect-ratio will base the width on the specified height.. but if we specify both it'll have the minimum height be a certain aspect-ratio..  */
  aspect-ratio: 750/320;
  min-height: var(--header-photos-height); /* fallback */
  min-height: max(var(--header-photos-height), var(--header-background-height));
}

/*
.imageblock.main__contents--fullwidth:after
{
  content: "";
  display: block;
  padding-top: 25%;
}
*/
.imageblock img {
  width: 100%;
  height: auto;
}

.imageblock.main__contents--fullwidth img {
  margin: auto 0;
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px; /* center image */
  bottom: -9999px;
}

.embeddedobject--rte + .embeddedobject--rte,
.embeddedobject--rte + .rtd__anchor + .embeddedobject--rte,
.embeddedobject.carrouselwidget + .embeddedobject--rte,
.embeddedobject.imageblock + .embeddedobject--rte,
.embeddedobject.carrouselwidget + .rtd__anchor + .embeddedobject--rte,
.embeddedobject.imageblock + .rtd__anchor + .embeddedobject--rte,
.embeddedobject--rte + .embeddedobject.carrouselwidget,
.embeddedobject--rte + .embeddedobject.imageblock,
.embeddedobject--rte + .rtd__anchor + .embeddedobject.carrouselwidget,
.embeddedobject--rte + .rtd__anchor + .embeddedobject.imageblock {
  margin-top: 0px; /* compensate bottom margin from element before */
}