.widget__relatedprogrammes .programme + .programme {
  margin-top: -1px;
}

.widget__relatedprogrammes {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.widget__relatedprogrammes .foldabledeeplink__title, .widget__relatedprogrammes .foldabledeeplink__text > * {
  width: auto;
}