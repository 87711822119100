.utforms-recipientsfield__row {
  display: block;
  position: relative;
}
.utforms-recipientsfield__row + .utforms-recipientsfield__row {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--color-grey-lines);
}

.utforms-recipientsfield__row:first-child .utforms-recipientsfield__deleterow {
  display: none;
}

.utforms-recipientsfield__deleterow {
  position: absolute;
  left: 100%;
  bottom: -5px;
  margin-left: 10px;
  cursor: pointer;
}

.utforms-recipientsfield__button {
  cursor: pointer;
  margin-top: 10px;
  display: inline-flex;
  align-items: baseline;
  justify-content: flex-start;
}
.utforms-recipientsfield__button__icon {
  margin-right: 7px;
}

.utforms-recipientsfield__name,
.utforms-recipientsfield__email {
  width: 100%;
}

.utforms-recipientsfield__name {
  margin-bottom: 10px;
}