.embeddedobject-imagevideowithtext__inner {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  /* overwrite fixed size image widget */
}
.embeddedobject-imagevideowithtext__inner .widget__image {
  height: 100%;
}
.embeddedobject-imagevideowithtext__inner .widget__image-photo {
  position: relative;
}
.embeddedobject-imagevideowithtext__inner > div {
  flex: 1 1 50%;
  max-width: calc(50% - 15px);
}
.embeddedobject-imagevideowithtext__inner > div > *:first-child {
  margin-top: 0;
}
.embeddedobject-imagevideowithtext__inner > div > *:last-child {
  margin-bottom: 0;
}
.embeddedobject-imagevideowithtext__title {
  font-size: 26px;
  font-weight: 700;
}
.embeddedobject-imagevideowithtext__title + p {
  margin-top: 10px;
}
.embeddedobject-imagevideowithtext__video .wh-video {
  margin-top: 0;
}
.embeddedobject-imagevideowithtext__image {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.embeddedobject-imagevideowithtext__right {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;
}
.embeddedobject-imagevideowithtext__linkholder {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 10px;
}
.embeddedobject-imagevideowithtext__linkholder:empty {
  padding-top: 0;
}
.embeddedobject-imagevideowithtext__linkholder a {
  color: #53575b;
  font-weight: 300;
  font-size: 18px;
  text-underline-position: under;
}
@media (max-width: 600px) {
  .embeddedobject-imagevideowithtext__inner {
    display: block;
  }
  .embeddedobject-imagevideowithtext__inner > div {
    max-width: 100%;
  }
  .embeddedobject-imagevideowithtext__inner > div + div {
    margin-top: 20px;
  }
}