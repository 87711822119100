/*
These are the actual font faces. Legally you are not allowed to use this file unless you ensure
that https://cdn.fonts.net/t/1.css?apiType=css&projectid=7c052a08-29f5-4c10-ab60-eeb1623773d6 is
hit during page load. The above URL may change! (but should be stable until aug 2029)

Paths here need to match fonts.witty preload-univers-next
*/

@font-face
{ /* UNIVERS® NEXT W01 230 BASIC THIN */
  font-family: "Univers Next W02";
  src: url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/c70bd903-dd6a-4b66-85af-fc3b07e97100.woff2") format("woff2"),
       url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/a1c4330f-ba73-4f1f-aa05-bd237c58ba51.woff") format("woff");
  font-weight: 275;
  font-style: normal;
}

@font-face
{ /* UNIVERS® NEXT W01 430 BASIC REGULAR */
  font-family: "Univers Next W02";
  src: url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/3de47ce5-a747-444b-b44b-20e0f8736ac8.woff2") format("woff2"),
       url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/c6e5b0e0-ee60-4f51-975c-6e2f4bdd6225.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face
{ /* UNIVERS® NEXT W01 431 BASIC ITALIC */
  font-family: "Univers Next W02";
  src: url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/c286361c-8fad-42e9-a699-52cf25e755ae.woff2") format("woff2"),
       url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/e1560e38-2ee2-4502-8c26-3a8c9013b031.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face
{ /* UNIVERS® NEXT W01 630 BASIC BOLD */
  font-family: "Univers Next W02";
  src: url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/086dc2f3-0872-4cae-aa0f-f66b64139049.woff2") format("woff2"),
       url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/5726bf63-b50c-4eb5-8615-36daaa1b3f99.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face
{ /* UNIVERS® NEXT W05 631 BASIC BOLD ITALIC */
  /* note - this was W05 because W02 is no longer available, but for compatibility we'll stick with the W02 name */
  font-family: "Univers Next W02";
  src: url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/a9284361-ca85-4595-b67e-a0e9fe95c565.woff2") format("woff2"),
       url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/2137c79a-6e11-432a-af1a-8f6ce17927c8.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face
{ /* UNIVERS® NEXT W01 320 CONDENSED LIGHT */
  font-family: "Univers Next W02";
  src: url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/00c26472-7693-44a7-b100-b1a1fe462d3b.woff2") format("woff2"),
       url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/57e60dd5-084b-448a-a471-b674908c0f46.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face
{ /* UNIVERS® NEXT W01 420 CONDENSED REGULAR */
  font-family: "Univers Next W02";
  src: url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/c0898c8c-0b78-43ee-9041-92bf98faf813.woff2") format("woff2"),
       url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/4a632485-93d8-495e-a524-a72ad23346ce.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face
{ /* UNIVERS® NEXT W01 720 CONDENSED HEAVY */
  font-family: "Univers Next W02";
  src: url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/d8bbf6a4-3b12-4429-bdf6-3d0124de945d.woff2") format("woff2"),
       url("https://1348661504.rsc.cdn77.org/.wh/ea/mod/utwente_design/fonts/univers-next/71ec3f58-e6ba-4af5-95be-5f69ed783c50.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
