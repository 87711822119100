/* This file may not be SCSS, it's used in RTD's */

.carrouselwidget
{
  background-color: #EEEEEE;
  width: 100%;
  -webkit-tap-highlight-color: transparent; /* iOS Safari */
}

.carrouselwidget-title
{
  color: var(--color-black);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.carrouselwidget--small
{
  padding: 20px 0 !important; /* override main__contents > * rule */
}
.carrouselwidget--small .carrousel__previous
{
  position: absolute;
  right: 81px;
  bottom: 20px;
}
.carrouselwidget--small .carrousel__next
{
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.carrouselwidget--large
{
  padding: 50px 0 !important; /* override main__contents > * rule */
}

.carrouselwidget .carrousel__cell
{
  max-width: calc(100vw - 67px);
}

.carrousel__cell .carrousel__cell-image
{
  display: block; /* set explicitely, it might be an anchor */
  position: relative; /* to position the title within */

  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.carrousel__cell-title
{
  position: absolute;
  left: 0px;
  bottom: 0px;
  max-width: 100%;

  color: var(--color-white);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  /*font: bold 18px "Univers W01";  UniversLTStd BoldCn; */

  padding: 0 20px 18px 20px;
}

.carrousel__cell-description
{
  max-width: 100%;
  padding-top: 18px;
}

.carrousel__cell-description > p
{
  padding-left: 20px;
  padding-right: 20px;
}

.carrousel__cell-description p:first-child
{
  margin-top: 0;
}

.carrouselwidget.livemode .carrousel__cell .carrousel__cell-description
{
  opacity: 0;

  transition: opacity 0.25s;
}
.carrouselwidget.livemode .carrousel__cell--aligned .carrousel__cell-description
{
  opacity: 1;

  transition: opacity 0.25s;
}

.carrousel__cell-playbutton
{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;

  width: 40px;
  height: 40px;

  background-color: var(--color-white);
  line-height: 56px;
  text-align: center;

  transform: scale(1);

  transition: transform 0.25s, opacity 0.25s;
}
.carrousel__cell-playbutton > svg
{
  color: var(--color-grey-normal);
  width: 26px;
  height: 26px;
}
.carrousel__cell-playbutton:hover > svg
{
  color: #444444;
}

.carrousel__cell .videocontainer
{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;

  /* make a kind of theater (a place to focus on the video,
     rather than having the video sticking/blending to the page
  padding: 10px 80px;
  */
  background-color: #000000;
}

  .carrousel__cell .videocontainer
, .carrousel__cell .video-close
{
  visibility: hidden;
  z-index: -1;
  pointer-events: none;

  opacity: 0;
}
