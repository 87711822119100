/*
.widget-countdown.widget-countdown--withcta
.widget-countdown.widget-countdown--isplain
.widget-countdown.widget-countdown--withevents

.component-ctalinks__item
.component-ctalinks__item.widget-countdown-expireditem

.widget-countdown
.widget-countdown.has-expired-countdown
*/

.widget-countdown
{
  --countdown-sections-gap:     25px;
  --countdown-digit-width:      37px;
  --countdown-digit-height:     50px;
  --countdown-digit-background: var(--color-black);
  --countdown-digit-color:      #FFFFFF;
  --countdown-digit-font:       bold 40px/40px "Univers Next W02", Arial, Helvetica, sans-serif;

  /* NOTE: same as utwente_voorlichting $activities-font-smalltitle
  --list-events-title-font: 700 18px/20px 'Univers Next W02'; / * Univers LT Std 67 Bold Condensed * /
  */
}

/* Tweak countdowns with 3 day digits to have the same width as a countdown with 2 day digits */
.widget-countdown .component-countdown[data-initiallyvisibledigits="9"]
{
  --countdown-sections-gap:     24px;
  --countdown-digit-width:      33px;
  --countdown-digit-height:     48px;
  --countdown-digit-font:       bold 38px/40px "Univers Next W02", Arial, Helvetica, sans-serif;
}

/* Switch to smaller digits while the contentarea is still centered but getting quite narrow. */
@media (max-width: 1400px)
{
  .widget-countdown .component-countdown
  {
    --countdown-sections-gap:     24px;
    --countdown-digit-width:      33px;
    --countdown-digit-height:     48px;
    --countdown-digit-font:       bold 38px/40px "Univers Next W02", Arial, Helvetica, sans-serif;
  }

  /* Tweak countdowns with 3 day digits to have the same width as a countdown with 2 day digits */
  .widget-countdown .component-countdown[data-initiallyvisibledigits="9"]
  {
    --countdown-sections-gap:     21px;
    --countdown-digit-width:      30px;
    --countdown-digit-height:     47px;
    --countdown-digit-font:       bold 36px/40px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
}

.widget-countdown__expiredtext
{
  display: none;
}
.widget-countdown.has-expired-countdown .widget-countdown__countdown
{
  display: none;
}
.widget-countdown.has-expired-countdown .widget-countdown__expiredtext
{
  display: block;
}

.wh-rtd.wh-rtd .widget-countdown__expiredtext
{
  display: block;
}




/*
NOTE: The countdown with events won't have it's own box (with padding, border, centering content)
      because the events already establish some compositional flow.
*/
@media (max-width: 600px)
{
  .widget-countdown .component-countdown
  {
/*    --countdown-digit-width:      32px;*/
    --countdown-digit-height:     45px;
  }

    .widget-countdown--withcta .widget-countdown__countdown
  , .widget-countdown--isplain .widget-countdown__countdown
  , .widget-countdown__expiredtext
  {
    padding: 30px 0;
    border-top:    1px solid #DCDDDE;
    border-bottom: 1px solid #DCDDDE;
  }

  .widget-countdown__expiredtext
  {
    text-align: center;
    padding: 50px 0;
  }

  /*
  For mobile we make the counter stretch the digits over the whole available width.

  How to calculate the digit width:
  - Use the 100vw
  - Account for gaps between days/hours/minutes/seconds: 3 * var(--countdown-sections-gap)
  - Account for sidepadding: 20px * 2
  - Account for whitespace line betwen each digit: 4px
  - Divide the space amongst the 8 digits

  FIXME: let digit-width grow up till 37px, but also have the digit-font font-size scale using clamp
  */

  .widget-countdown .component-countdown[data-initiallyvisibledigits="7"] /* 1 day digit */
  {
    --countdown-digit-width:      min(34px, calc((100vw - 3 * var(--countdown-sections-gap) - 3px - 20px * 2) / 7));
    --countdown-digit-font:       bold 36px/100% "Univers Next W02", Arial, Helvetica, sans-serif;
  }

  .widget-countdown .component-countdown[data-initiallyvisibledigits="8"] /* 2 day digits */
  {
    --countdown-digit-width:      min(34px, calc((100vw - 3 * var(--countdown-sections-gap) - 4px - 20px * 2) / 8));
    --countdown-digit-font:       bold 34px/100% "Univers Next W02", Arial, Helvetica, sans-serif;
  }

  .widget-countdown .component-countdown[data-initiallyvisibledigits="9"] /* 3 day digits */
  {
    --countdown-digit-width:      min(34px, calc((100vw - 3 * var(--countdown-sections-gap) - 5px - 20px * 2) / 9));
    --countdown-digit-font:       bold 32px/100% "Univers Next W02", Arial, Helvetica, sans-serif;
  }
}


@media (min-width: 601px)
{
  /* have the countdown and expired panels in a grid,
     so they keep the same size when switching over to the expired message.

     FIXME: but in case of long expired texts this might be annoying.

    .widget-countdown--withcta
  , .widget-countdown--isplain
  {
    display: grid;
  }
  */

    .widget-countdown--withcta .widget-countdown__countdown
  , .widget-countdown--isplain .widget-countdown__countdown
  , .widget-countdown__expiredtext
  {
    border: 1px solid #DCDDDE;
    padding: 45px 20px;

    /*
    grid-column: 1;
    grid-row: 1;
    */
  }

  .widget-countdown__expiredtext
  {
    text-align: center;
    padding: 48px 30px 50px 30px;
  }
}


.widget-countdown__expiredtext h2 + p
{
  margin-top: 6px; /* 25px normally */
}
.widget-countdown__expiredtext__button
{
  margin-top: 15px;
}






/* Extra paddings and borders within a widget preview block look messy,
   so we disable them in the richdocument editor. */
.wh-rtd__body .widget-countdown
{
  padding: 15px 0;
  border: 0;
}

.widget-countdown.widget-countdown h2
{
  margin: 0;
}

/* Situation when there is "no CTA + no events" */
.widget-countdown--isplain .widget-countdown__countdown
{
  text-align: center;
}
.widget-countdown--isplain .widget-countdown__countdown .component-countdown
{
  width: max-content; /* other method to shrinkwrap would be to have the container be flex with direction column */
  margin-left: auto;
  margin-right: auto;
}



.widget-countdown__withevents.widget-countdown--withevents
{
  justify-content: start;
}

/*
div.wh-rtd--margins-compact>.wh-rtd>.wh-rtd-embeddedobject--block .wh-rtd-embeddedobject__preview {
    padding: 2px 8px;
*/


.widget-countdown__when
{
  margin-top: 8px;
}


/* CTA ***********************************************/
  .widget-countdown--withcta .widget-countdown__countdown
, .widget-countdown--withevents .widget-countdown__countdown
{
  display: flex;
  flex-wrap: wrap;
  column-gap: 33px;
  row-gap: 33px;
}

.widget-countdown__cta
{
  flex: 1 1 0;
  min-width: 220px;
  max-width: max-content; /* shrink-wrap, because if we used flex-base: auto; the browser will wrap our __cta just to get *all* text on a single line */
}

  .widget-countdown__signupcontainer
  {
    margin-top: 7px;

    display: flex;
    column-gap: 11px;
    row-gap: 5px;
    flex-wrap: wrap;

    align-items: baseline;
  }

  .widget-countdown__signupcontainer .wh-form__button
  {
    /* allow wrapping */
    white-space: normal;

    min-height: 40px;
    height: auto;
    padding-top: 7px;
    padding-bottom: 7px;
  }

.widget-countdown__button-caption
{
  color: #5A5E62;
  font-size: 13px;

  min-width: 150px;
}


/* CTA ***********************************************/
  .widget-countdown__addtocalendar
, .component-addtocalendarlink
{
  position: relative; /* so AddEvent popup is positioned correctly */

  align-items: center;

  font: var(--ctalink-font);
  text-decoration: underline;
  text-underline-offset: 3px;
  color: var(--color-grey-dark); /* #53575B; */
}

  .widget-countdown__addtocalendar .icon
, .component-addtocalendarlink .icon
{
  vertical-align: middle;

  display: inline-block;
  margin-right: 15px;
  font-size: 24px;
  font-weight: bold;
}

/*
  .widget-countdown__addtocalendar::before
, .component-addtocalendarlink::before
{
  display: inline-block;
  font:    var(--uticon-large-font);
  content: var(--uticon-large-events);

  speak: none;

  font-size: 24px;
  margin-right: 16px;
}
*/



.widget-countdown--withevents .widget-countdown__cta
{
  align-self: center;
}

.widget-countdown .component-ctalinks
{
  width: 100%;
}

/*
Styling which keeps passed events visible but:
- expired item will fade out
- "sign up" text will disappear
- arrow will be shown before the event which is being counted down to

.widget-countdown-expireditem
{
  opacity: 0.5;
}

  .widget-countdown-expireditem .widget--ctalinks__link
, .widget-countdown-expireditem .widget--ctalinks__icon
{
  display: none;
}

.component-ctalinks__item.widget-countdown-countingdownto::before
{
  font: var(--uticon-font);
  content: var(--uticon-arrow-right-16px);

  display: block;
  position: absolute;
  left: -15px;
  transform: translateX(-100%);
}
*/

.widget-countdown-expireditem.widget-countdown-expireditem
{
  display: none;
}
