/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
:root {
  --pageside-padding: 10px;
}
@media (max-width: 960px) {
  :root {
    --page-contentwidth-12col: 100%;
  }
}
@media (min-width: 961px) {
  :root {
    --page-contentwidth-12col: calc(930px + var(--pageside-padding) * 2);
    --page-contentwidth-10col: calc(770px + var(--pageside-padding) * 2);
    --page-contentwidth-8col: calc(610px + var(--pageside-padding) * 2);
  }
}
@media (max-width: 750px) {
  :root {
    --pageside-padding: 55px;
  }
}
@media (max-width: 480px) {
  :root {
    --pageside-padding: 15px;
  }
}

html.page--toplevelhome {
  --pageside-padding: 20px;
}
@media (min-width: 1440px) {
  html.page--toplevelhome {
    --page-contentwidth-12col: calc(1110px + var(--pageside-padding) * 2);
    --page-contentwidth-10col: calc(920px + var(--pageside-padding) * 2);
    --page-contentwidth-8col: calc(730px + var(--pageside-padding) * 2);
  }
}
@media (max-width: 960px) {
  html.page--toplevelhome {
    --pageside-padding: 90px;
  }
}
@media (max-width: 750px) {
  html.page--toplevelhome {
    --pageside-padding: 20px;
  }
}

/*******************************************************************************
 * MAIN
 */
.main {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (max-width: 1220px) {
  .main {
    left: auto;
    width: 100%;
  }
}

.main__contents {
  background-color: var(--color-white);
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  margin: -1px 0;
}
.main__contents .main__contents {
  background-color: transparent;
}
.main__contents__pageprevnext {
  clear: both;
  padding-top: 30px;
}
.main__contents__pageprevnext:after {
  content: "";
  display: block;
  clear: both;
}
.main__contents__pageprevnext > .previouspage {
  float: left;
}
.main__contents__pageprevnext > .nextpage {
  float: right;
}

#main__measure-element {
  clear: both;
}

#main__measure-element:last-child {
  margin-bottom: 50px;
}

#main__measure-element:first-child {
  margin-bottom: 0;
}

.main__contents--overdeeplinks {
  position: relative;
  z-index: 2;
}

.main__contents--content12col > *,
.main__contents--content12col .foldabledeeplink__text > *,
.main__contents--content12col .foldabledeeplink__title {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  /*
    @at-root .main__contents--fullwidth.main__contents--fullwidth
    {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  */
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
}
@media (max-width: 750px) {
  .main__contents--content12col > *,
  .main__contents--content12col .foldabledeeplink__text > *,
  .main__contents--content12col .foldabledeeplink__title {
    margin-left: 0;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .main__contents--content12col > *,
  .main__contents--content12col .foldabledeeplink__text > *,
  .main__contents--content12col .foldabledeeplink__title {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 0) {
  .main__contents--content12col > *,
  .main__contents--content12col .foldabledeeplink__text > *,
  .main__contents--content12col .foldabledeeplink__title {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}
.main__contents--content12col > *.foldabledeeplink,
.main__contents--content12col .foldabledeeplink__text > *.foldabledeeplink,
.main__contents--content12col .foldabledeeplink__title.foldabledeeplink {
  --layout: ;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
@media (max-width: 960px) {
  .main__contents--content12col > *,
  .main__contents--content12col .foldabledeeplink__text > *,
  .main__contents--content12col .foldabledeeplink__title {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .main__contents--content12col > *,
  .page--toplevelhome .main__contents--content12col .foldabledeeplink__text > *,
  .page--toplevelhome .main__contents--content12col .foldabledeeplink__title {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}

.main__contents--content8col > *,
.main__contents--content8col .foldabledeeplink__text > *,
.main__contents--content8col .foldabledeeplink__title {
  --layout: 8col;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  /*
    @at-root .main__contents--fullwidth.main__contents--fullwidth
    {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  */
  width: 640px;
}
@media (max-width: 750px) {
  .main__contents--content8col > *,
  .main__contents--content8col .foldabledeeplink__text > *,
  .main__contents--content8col .foldabledeeplink__title {
    margin-left: 0;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .main__contents--content8col > *,
  .main__contents--content8col .foldabledeeplink__text > *,
  .main__contents--content8col .foldabledeeplink__title {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 0) {
  .main__contents--content8col > *,
  .main__contents--content8col .foldabledeeplink__text > *,
  .main__contents--content8col .foldabledeeplink__title {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}
.main__contents--content8col > *.foldabledeeplink,
.main__contents--content8col .foldabledeeplink__text > *.foldabledeeplink,
.main__contents--content8col .foldabledeeplink__title.foldabledeeplink {
  --layout: ;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

/* We define these with low specificy so media queries can easily override
   these for mobile/tablet/desktop
*/
.main__contents--default {
  --layout: 8col;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 640px;
  /*
  Activate when 1440+ design becomes available for all pages
    @media (min-width: $responsive-xxmedium-devices)
    {
      width: $largescreen-width-8col;
    }
  */
}
@media (max-width: 750px) {
  .main__contents--default {
    margin-left: 0;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .main__contents--default {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 0) {
  .main__contents--default {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}

.main__contents--12col {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
}
@media (max-width: 960px) {
  .main__contents--12col {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .main__contents--12col {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}

div.widezebra.widezebra, div.wh-rtd__tablewrap.widewithlines {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
}
@media (max-width: 960px) {
  div.widezebra.widezebra, div.wh-rtd__tablewrap.widewithlines {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome div.widezebra.widezebra, .page--toplevelhome div.wh-rtd__tablewrap.widewithlines {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}

.main__contents--fullwidth.main__contents--fullwidth {
  /*
  NOTE: Don't add --layout: fullwidth; because a widget must be able to set
  the layout it uses without without a high specificy!
  */
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.main__contents--fullwidth-nopadding.main__contents--fullwidth-nopadding {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

/*******************************************************************************
 * DEEPLINKS
 */
.main__deeplinks {
  right: 0;
  padding: 10px 10px 10px 20px;
  text-align: right;
  border-radius: 5px 0 0 5px;
  background-color: var(--color-white);
  width: 170px;
  position: absolute;
  top: 50px;
  z-index: 1;
}
.main__deeplinks--stickymain {
  position: fixed;
}
.main__deeplinks--stickyfooter {
  bottom: 50px;
  position: absolute;
  top: auto;
}

.main__deeplinklist,
.main__deeplinkitem {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main__deeplinkitem {
  max-height: 36px; /* 2x15px lineheigh + 6px padding */
  overflow: hidden;
}

.main__deeplink {
  color: var(--color-grey-normal);
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  min-height: 20px;
  line-height: 15px;
  margin-bottom: 5px;
  padding: 3px 20px 3px 0;
  text-decoration: none;
  position: relative;
}
.main__deeplink::after {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 3px;
  right: 0;
  content: "\e941";
  display: inline-block;
}
.main__deeplink.isvisible::after {
  content: "\e944";
}
.main__deeplink.isbefore::after {
  content: "\e940";
}
.main__deeplink--active {
  color: #162731;
}
.main__deeplink:hover, .main__deeplink.isvisible:not(.isvisible--hide) {
  color: #162731;
}
html.site--itc .main__deeplink.isvisible:not(.isvisible--hide) {
  color: #ec7a08;
}

@media screen and (max-width: 960px) {
  .foldabledeeplink {
    --layout: 8col;
    margin-top: 50px;
  }
  .foldabledeeplink--nocontent {
    margin-top: 50px;
  }
  .foldabledeeplink:not(.foldabledeeplink--open) + .foldabledeeplink, .foldabledeeplink:not(.foldabledeeplink--open) + .foldabledeeplink--nocontent, .foldabledeeplink--nocontent + .foldabledeeplink, .foldabledeeplink--nocontent + .foldabledeeplink--nocontent {
    margin-top: -1px;
  }
  .foldabledeeplink > h2 {
    margin-bottom: 0;
  }
  .foldabledeeplink + .hasfoldabledeeplink,
  .foldabledeeplink--nocontent + .hasfoldabledeeplink,
  .hasfoldabledeeplink + .hasfoldabledeeplink {
    margin-top: 0;
  }
  .hasfoldabledeeplink {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .hasfoldabledeeplink .foldabledeeplink, .hasfoldabledeeplink + .foldabledeeplink {
    margin-top: -1px;
  }
  .foldabledeeplink__title {
    border-top: 1px solid #e0e0e1;
    border-bottom: 1px solid #e0e0e1;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 0;
    padding: 16px 40px 16px 0;
    position: relative;
    text-transform: uppercase;
    width: 620px;
    z-index: 0;
  }
}
@media screen and (max-width: 960px) and (max-width: 750px) {
  .foldabledeeplink__title {
    margin-left: 55px;
    margin-right: 55px;
    width: calc(100% - 110px);
  }
}
@media screen and (max-width: 960px) and (max-width: 480px) {
  .foldabledeeplink__title {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
  }
}
@media screen and (max-width: 960px) and (min-width: 0) {
  .foldabledeeplink__title {
    margin-left: var(--pageside-padding);
    margin-right: var(--pageside-padding);
  }
}
@media screen and (max-width: 960px) {
  .foldabledeeplink__title:before {
    background-color: var(--color-grey-lighter);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    margin: 0 0 0 -20px;
    position: absolute;
    top: 0;
    transition: width 150ms ease;
    width: 0;
    z-index: -1;
  }
  .foldabledeeplink__title:hover:before {
    width: 100%;
  }
  .foldabledeeplink__title:after {
    font-family: "UT-iconfont" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #afb1b3;
    font-weight: 400;
    font-size: 16px;
    width: 16px;
    height: 16px;
    text-align: center;
    /*
      html.site--itc &
      {
        color: $color-mosque;
      }
    */
    content: "\e92f";
    display: block;
    padding-top: 16px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
  .foldabledeeplink__title--nocontent {
    border-top: 1px solid #e0e0e1;
    border-bottom: 1px solid #e0e0e1;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    padding: 16px 0;
    position: relative;
    text-transform: uppercase;
  }
  .foldabledeeplink__title--nocontent:before, .foldabledeeplink__title--nocontent:after {
    display: none;
  }
  .foldabledeeplink--open .foldabledeeplink__title:after {
    content: "\e930";
  }
  .foldabledeeplink__foldcontainer {
    height: 0;
    overflow: hidden;
  }
  .foldabledeeplink__foldcontainer--active {
    transition: height 250ms;
  }
}