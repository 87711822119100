/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@import "@mod-utwente_design/scss/config-colors.css";
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/* FIXME: the max-width should maybe only be the filters aren't expanded */
.page--scholarshipfinder .contentpart__header--withfilters.contentpart__header--withfiltersexpanding .filteredoverview__freesearch input {
  max-width: none;
}

.scholarshipfinder__textsearch-explain {
  margin-top: 40px;
  font-weight: 700;
  font-size: 18px;
  text-transform: none;
  padding-left: 10px;
  padding-right: 10px;
  display: none;
}

.scholarshipfinder__textsearch-explain.textsearchactive {
  display: block;
}

.scholarshipfinder__textsearch-explain.textsearchactive + .filteredoverview__feedbackandviewbar {
  margin-top: 15px;
}

@media (min-width: 480px) {
  .filterbar--scholarshipfinder .filter__row {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 479px) {
  .filterbar--scholarshipfinder .filter__row__item + .filter__row__item {
    margin-left: 0;
    margin-top: 30px;
  }
}
@media (min-width: 480px) and (max-width: 749px) {
  .filterbar--scholarshipfinder .filter__row--line1 .filter__row__item {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 30px;
  }
  .filterbar--scholarshipfinder .scholarshipfinder__selectprogramme.scholarshipfinder__selectprogramme {
    flex: 0 0 100%;
    margin-left: 0;
  }
}
@media (min-width: 750px) {
  .filterbar--scholarshipfinder .filter__row--line1 .filter__row__item {
    flex: 0 0 calc(33.3333333333% - 40px / 3);
    overflow: hidden;
  }
}

.scholarshipfinder__selectprogramme {
  opacity: 0;
  transition: opacity 0.25s;
}

.scholarshipfinder__selectprogramme--visible {
  opacity: 1;
}

.scholarshipfinder__items {
  padding-left: 0;
  padding-right: 0;
  opacity: 0;
  transition: opacity 0.5s;
}

.scholarshipfinder__items.scholarshipfinder--show {
  opacity: 1;
}

.scholarship__info {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: right;
}

.scholarship__status {
  flex: 1 0 0;
  font-size: 16px;
  font-weight: bold;
  color: #C60C30;
  margin-right: 15px;
}
.scholarship__status.scholarship__status--open {
  color: #00675A;
}

.scholarship__statusicon {
  font-size: 150%;
  display: inline-block;
  position: relative;
  top: 4px;
  margin-right: 6px;
}

.grantfinder__scholarship {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  /*
    border: 1px solid $color-greylines;

    // Browsers can round down the border-width to 0 when zooming out.
    // When we use "thin" Chrome will always render a border of at least one physical pixel wide.
    // (so 0.5px virtual on a retina display)
    border: thin solid $color-greylines;
  */
}

.grantfinder__scholarship.grantfinder--hide,
.grantfinder__scholarship .grantfinder--hide,
.grantfinder__scholarship__programmes .programme.grantfinder--hide {
  display: none;
}

.grantfinder__scholarship .scholarship__priority {
  position: absolute;
  width: 30px;
  height: 60px;
  background-color: #1e2328;
  color: #fed100;
  font-size: 16px;
  top: 0;
  right: 10px;
  text-align: center;
  padding-top: 20px;
}

.grantfinder__scholarship--priority .scholarship__text {
  padding-right: 40px;
}

.grantfinder__scholarship .scholarship__title {
  display: block;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.grantfinder__scholarship .scholarship__title--nolink {
  cursor: default;
}

.grantfinder__scholarship .scholarship__specs {
  margin: 10px 0;
  padding: 0;
  border-collapse: collapse;
}

.grantfinder__scholarship .scholarship__specs th,
.grantfinder__scholarship .scholarship__specs td {
  text-align: left;
  vertical-align: top;
  padding: 3px 10px 3px 0;
}

.grantfinder__scholarship .scholarship__link {
  text-align: right;
  margin-top: 10px;
}

.grantfinder__scholarship--priority .scholarship__link {
  margin-right: -40px;
}

/*
WARNING: There are widget which uses the following .grantfinder__scholarship__programmes styling:
*/
.grantfinder__scholarship__programmes {
  margin-top: 20px;
}

.grantfinder__scholarship__programmes + .grantfinder__scholarship__programmes {
  margin-top: -1px;
}

.grantfinder__scholarship__programmes .programme {
  text-decoration: none;
  display: block;
  position: relative;
  padding-right: 25px;
  border-bottom: 1px solid #e0e0e1;
}

.grantfinder__scholarship__programmes .programme--nolink {
  cursor: default;
}

.grantfinder__scholarship__programmes .programme__title {
  font-size: 18px;
  font-weight: 300;
  padding: 10px 0;
}

.grantfinder__scholarship__programmes .programme__icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 11px;
  font-size: 16px;
}

.grantfinder__scholarship__programmes .programme__description {
  display: none;
  position: absolute;
  bottom: 100%;
  margin-bottom: -3px;
  right: -90px;
  width: 220px;
  padding-right: 15px;
}

.grantfinder__scholarship__programmes .programme__description:before {
  content: "";
  border-top: 8px solid #5a5e62;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  display: block;
  top: 100%;
  right: 50%;
  margin-right: -8px;
}

.grantfinder__scholarship__programmes .programme__description > p {
  padding: 15px 0 15px 15px;
  margin: 0;
  background-color: #5a5e62;
  display: block;
  color: #fff;
  font-style: italic;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
}

.grantfinder__scholarship__programmes .programme__description > .corner {
  display: block;
  position: absolute;
  width: 15px;
  right: 0;
  top: 15px;
  bottom: 0;
  background-color: #5a5e62;
}

.grantfinder__scholarship__programmes .programme__description > .corner:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  right: 0;
  border-top: 15px solid transparent;
  border-left: 15px solid #aaacae;
}

.grantfinder__scholarship__programmes .programme__description > .corner:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #1e2328;
}

.grantfinder__scholarship__programmes .programme:hover > .programme__description {
  display: block;
}

@media (max-width: 800px) {
  .grantfinder__scholarship__programmes .programme__description:before {
    right: 30px;
  }
  .grantfinder__scholarship__programmes .programme__description {
    right: -10px;
  }
}