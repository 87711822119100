/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@import "@mod-utwente_design/scss/config-colors.css";
@import "@mod-utwente_design/vendor/perfect-scrollbar-wh/css/perfect-scrollbar.css";
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * MENU
 */
.menu {
  background: #1f2229;
  bottom: 0;
  color: var(--color-white);
  font-size: 18px;
  font-weight: 300;
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 260px;
  z-index: 2;
}
@media (max-width: 1220px) {
  html[dir=ltr] .menu {
    left: -260px;
    position: absolute;
  }
}
html[dir=ltr].hidesidemenu .menu {
  left: -260px;
  position: absolute;
}
.menu--nativescroll {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}
.menu--animate {
  transition: top 250ms;
}

.menu__parent {
  color: var(--color-white);
  display: none;
  font-size: 26px;
  font-weight: 300;
  height: 60px;
  padding: 19px;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
}
.menu__parent + .menu__parent {
  padding-top: 0;
}
@media (max-width: 480px) {
  .menu__parent {
    display: block;
    font-size: 20px;
  }
}
@media (max-width: 320px) {
  .menu__parent {
    height: 50px;
  }
}

.menu__logoarea,
.menu__logo {
  display: block;
  height: 80px;
  width: 100%;
}
html.hidesidemenu .menu__logoarea,
html.hidesidemenu .menu__logo {
  display: none;
}
@media (max-width: 1220px) {
  .menu__logoarea,
  .menu__logo {
    display: none !important;
  }
}

@media (min-width: 1221px) {
  .sitetitle__container__logo--itc {
    display: none !important;
  }
}
.menu__logo {
  background: url("img/menu__logo.png") 24px / 140px 45px no-repeat;
}
@media (min-width: 1221px) {
  .menu__logo {
    background: url("img/menu__logo--htht.png") 24px / 221px 45px no-repeat;
  }
}

.menu__logoarea {
  display: flex;
  align-items: center;
  padding: 0 24px;
  background-color: #f0f1f2;
}

.menu__logoarea__divider {
  width: 1px;
  height: 42px;
  background-color: #00675a;
  margin: 0 auto;
}

.menu__logoarea a {
  display: block;
}

.menu__logo__ut > img {
  display: block;
  width: 125px;
}

.menu__logo__itc > img {
  display: block;
  width: 52px;
  height: 61px;
  position: relative;
  top: -9px;
}

.menu__branding {
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding: 0 20px;
  width: 100%;
}
html.site--ut .menu__branding {
  background-color: var(--color-white);
}
.menu__branding a {
  text-decoration: none;
}
.menu__branding-partners {
  color: #1e2328;
  font-weight: 300;
  font-size: 15px;
  border-bottom: 1px solid #1e2328;
}
.menu__branding-partners__hide, .menu__branding-partners__show {
  cursor: pointer;
  padding: 2px 0;
}
.menu__branding-partners__hide, .menu__branding-partners.active .menu__branding-partners__show {
  display: none;
}
.menu__branding-partners__show, .menu__branding-partners.active .menu__branding-partners__hide {
  display: block;
}
.menu__branding-partners ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #1e2328;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s;
  height: 0;
  overflow: hidden;
}
.menu__branding-partners ul > li {
  padding-bottom: 15px;
  text-align: center;
}
.menu__branding-partners.active ul {
  height: auto;
  opacity: 1;
}
.menu__branding-logo {
  display: block;
  flex: none;
  padding: 20px 0;
  color: #1e2328;
  font-weight: 700;
  font-size: 26px;
  text-transform: uppercase;
}
.menu__branding-logo + .menu__branding-logo--text {
  border-top: 1px solid var(--color-black);
}
.menu__branding-logo--text {
  padding: 15px 0 12px;
}
@media (max-width: 1220px) {
  .menu__branding .menu__branding-partners, .menu__branding-logo + .menu__branding-logo--text {
    display: none;
  }
}
.menu__branding img {
  max-width: 100%;
  vertical-align: bottom;
}

.menu__searchbutton {
  opacity: 1;
  margin-top: 0;
  transition-property: opacity, margin-top;
  color: #afb1b3;
  cursor: pointer;
  display: block;
  padding: 0 24px;
  position: relative;
}
html.searchsheet--open .menu__searchbutton {
  opacity: 0;
  margin-top: -100%;
}

html.searchsheet--opening:not(.searchsheet--fixed) .menu__searchbutton {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

html.searchsheet--closing:not(.searchsheet--fixed) .menu__searchbutton {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.645, 0, 0.785, 0.39);
}

html.site--ut .menu__searchbutton:hover {
  color: var(--color-white);
}
html.searchsheet--open .menu__searchbutton {
  margin-top: -60px;
  pointer-events: none;
}
.menu__searchbutton:after {
  background-color: #53575b;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 24px;
  position: absolute;
  right: 24px;
}
html.site--itc .menu__searchbutton:after {
  background-color: #dcddde;
}
.menu__searchbutton:after:hover {
  background-color: var(--color-white);
}
html.site--itc .menu__searchbutton:after:hover {
  background-color: #00675a;
}
@media (max-width: 750px) {
  .menu__searchbutton.menu__searchbutton--account {
    margin-left: 68px;
  }
  .menu__searchbutton.menu__searchbutton--account::after {
    left: 0;
  }
}
@media (max-width: 320px) {
  .menu__searchbutton.menu__searchbutton--account {
    margin-left: 58px;
  }
}

.menu__searchbutton-icon {
  /*@include searchsheet-visibility((
    color: (from: $color-grey-normal, to: $color-grey-dark),
    opacity: (from: 1, to: 0)), button);*/
  font-size: 16px;
  left: 24px;
  line-height: 16px;
  position: absolute;
  top: 25px;
  /*html.searchsheet--open &
  {
    @include searchsheet-visibility((opacity: (from: 0, to: 1)));
  }*/
}
@media (max-width: 320px) {
  .menu__searchbutton-icon {
    left: 20px;
    top: 19px;
  }
}

.menu__searchbutton-placeholder {
  /*@include searchsheet-visibility((opacity: (from: 1, to: 0)), button);*/
  color: #afb1b3;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  height: 60px;
  line-height: 37px;
  margin: 0;
  padding: 14px 0 9px 26px;
  width: 100%;
}
.menu__searchbutton-placeholder:hover {
  color: var(--color-white);
}
html.site--itc .menu__searchbutton-placeholder:hover {
  color: #000000;
}
@media (max-width: 320px) {
  .menu__searchbutton-placeholder {
    height: 50px;
  }
}

.menu__searchform {
  position: relative; /* needed for positioning suggestions */
}
html:not(.site--itc) .menu__searchform {
  display: none;
}
@media (max-width: 750px) {
  .menu__searchform.menu__search--account {
    margin-left: 60px;
  }
}
@media (max-width: 320px) {
  .menu__searchform.menu__search--account {
    margin-left: 50px;
  }
}

.menu__search {
  color: #afb1b3;
  cursor: pointer;
  display: block;
  padding: 0 24px;
  position: relative;
}
html.site--ut .menu__search:hover {
  color: var(--color-white);
}
html.site--ut .menu--search-focus .menu__search, html.site--ut .menu--search-focus .menu__search:hover {
  background: var(--color-white) linear-gradient(to right, var(--color-white), var(--color-white) 80%, var(--color-grey-light));
  border-right: 1px solid #dcddde;
  color: #1f2229;
}
.menu__search html.site--itc .menu__search:hover {
  color: #555555;
}
html.site--itc .menu--search-focus .menu__search, html.site--itc .menu--search-focus .menu__search:hover {
  background-color: #00675a;
  color: var(--color-white);
  /*
      border-right: 1px solid $color-greylines;
      color: #1f2229;
  */
}
.menu__search:after {
  background-color: #53575b;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 24px;
  position: absolute;
  right: 24px;
}
html.site--itc .menu__search:after {
  background-color: #dcddde;
}
.menu__search:after:hover {
  background-color: var(--color-white);
}
html.site--itc .menu__search:after:hover, html.site--itc .menu--search-focus .menu__search:after, html.site--itc .menu--search-focus .menu__search:after:hover {
  background-color: #00675a;
}
@media (max-width: 750px) {
  .menu__search--account .menu__search::after {
    left: 0;
  }
}

.menu__searchicon {
  position: absolute;
  left: 24px;
  top: 25px;
  font-size: 16px;
  line-height: 16px;
}
@media (max-width: 320px) {
  .menu__searchicon {
    left: 20px;
    top: 19px;
  }
}

.menu__searchfield {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  font-weight: 300;
  height: 60px;
  margin: 0;
  padding: 14px 0 9px 26px;
  width: 100%;
}
.menu__searchfield:focus {
  border-color: transparent;
  cursor: text;
  outline: none;
}
@media (max-width: 320px) {
  .menu__searchfield {
    height: 50px;
  }
}

.menu__searchfield--placeholder {
  color: #afb1b3;
}
.menu__searchfield--placeholder:hover {
  color: var(--color-white);
}
html.site--itc .menu__searchfield--placeholder:hover {
  color: #000000;
}

.menu__quicklinksicon {
  position: absolute;
  right: 24px;
  top: 25px;
  font-size: 16px;
  line-height: 16px;
}
@media (max-width: 750px) {
  .menu__quicklinksicon {
    display: none;
  }
}

.menu__eventrelay {
  height: auto;
  overflow: hidden;
  position: relative;
  transition: height 250ms ease-in-out;
}
.menu__eventrelay--loading:after {
  background: transparent;
  bottom: 0;
  content: "";
  cursor: wait;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.menu__branding + .menu__eventrelay {
  border-top: 1px solid #dcddde;
}

.menu__language {
  color: #afb1b3;
  font-size: 15px;
  margin-top: 50px;
}

/*******************************************************************************
 * PAGE WITHOUT MENU
 */
.page {
  height: 100%;
  overflow: visible;
  position: relative;
  z-index: 0;
}
@media (max-width: 1220px) {
  .page {
    transition: transform 250ms ease-in-out;
  }
  html.menu--open .page {
    height: 100%;
  }
  html[dir=ltr].menu--open .page {
    transform: translateX(260px);
  }
}

html.hidesidemenu .page {
  transition: transform 250ms ease-in-out;
}

html.hidesidemenu.menu--open .page {
  height: 100%;
}

html[dir=ltr].hidesidemenu.menu--open .page {
  transform: translateX(260px);
}

.page__withoutmenu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}
html[dir=ltr] .page__withoutmenu {
  margin-left: 260px;
}
@media (max-width: 1220px) {
  html[dir=ltr] .page__withoutmenu {
    margin-left: 0;
  }
}
html[dir=ltr].hidesidemenu .page__withoutmenu {
  margin-left: 0;
}

.page__menumodal {
  background: transparent;
  cursor: pointer;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}
html.menu--open .page__menumodal {
  display: block;
}

/*******************************************************************************
 * MENU HEADER WITH LOGO
 */
.menuheader {
  display: none;
  flex: none;
  min-height: 60px;
  position: relative;
  width: 100%;
  pointer-events: none;
}
.menuheader > * {
  pointer-events: all;
}
@media (max-width: 1220px) {
  .menuheader {
    display: block;
  }
}
@media (max-width: 320px) {
  .menuheader {
    min-height: 50px;
  }
}
.menuheader--alwaysshow {
  display: block;
}
html.noheader .menuheader {
  min-height: 60px;
}
html.noheader .menuheader .menuheader__header {
  border-bottom: 1px solid #dcddde;
}
.menuheader--sticky {
  position: fixed;
  top: 0;
  z-index: 4;
}
@media (min-width: 1221px) {
  html:not(.hidesidemenu) .menuheader--sticky {
    max-width: calc(100% - 260px);
  }
}

.menuheader--sticky.menuheader--show {
  max-height: 60px;
}

.menuheader__hamburger {
  align-items: center;
  background-color: #1e2328;
  color: var(--color-white);
  cursor: pointer;
  display: none;
  font-size: 16px;
  height: 100%;
  justify-content: center;
  min-height: 60px;
  padding: 0;
  position: absolute;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 60px;
  z-index: 4;
}
html.site--itc .menuheader__hamburger {
  background-color: #00675a;
}
@media (max-width: 320px) {
  .menuheader__hamburger {
    min-height: 50px;
    width: 50px;
  }
}
@media (max-width: 1220px) {
  .menuheader__hamburger {
    display: flex;
  }
}
.menuheader--sticky .menuheader__hamburger {
  transition: transform 250ms ease-in-out;
  transform: translateY(-100%);
}
.menuheader--sticky.menuheader--show .menuheader__hamburger {
  transform: translateY(0);
}
.menuheader__hamburger--open {
  position: absolute;
  left: 0;
  transition: opacity 250ms;
}
@media (max-width: 1220px) {
  html.menu--open .menuheader__hamburger--open {
    opacity: 0;
  }
}
.menuheader__hamburger--close {
  position: absolute;
  left: 0;
  opacity: 0;
  transition: opacity 250ms;
}
@media (max-width: 1220px) {
  html.menu--open .menuheader__hamburger--close {
    opacity: 1;
  }
}

html.hidesidemenu .menuheader__hamburger {
  display: flex;
}

.menuheader__hamburger__content {
  position: absolute;
  top: 22px;
  left: 22px;
  width: 16px;
  height: 16px;
}
@media (max-width: 320px) {
  .menuheader__hamburger__content {
    top: 17px;
    left: 17px;
  }
}

html.hidesidemenu.menu--open .menuheader__hamburger--open {
  opacity: 0;
}

html.hidesidemenu.menu--open .menuheader__hamburger--close {
  opacity: 1;
}

.menuheader__header {
  background: var(--color-white);
  min-height: 60px;
  padding: 0 20px 0 20px;
  position: relative;
  z-index: 4;
  max-width: 100%;
  /*
    html.site--itc &
    {
      //$color-lightgrey: #f0f1f2;
      background: $color-lightgrey;
    }
  */
}
@media (max-width: 1220px) {
  .menuheader__header.haspartners {
    transition: margin-top 0.3s; /* same speed as for height partnerlogos */
    overflow: visible !important;
  }
  html:not(.html__scrolled) .menuheader__header.haspartners.showpartners {
    margin-top: 80px !important;
  }
}
@media (max-width: 320px) {
  .menuheader__header {
    min-height: 50px;
  }
}
.menuheader__header:after {
  background-color: transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  transition: background-color 250ms ease-in-out;
}
header .menuheader--sticky .menuheader__header, html.hidesidemenu header .menuheader--sticky .menuheader__header {
  transition: transform 250ms ease-in-out;
  transform: translateY(-100%);
  margin-top: 0;
}
header .menuheader--sticky .menuheader__header:after, html.hidesidemenu header .menuheader--sticky .menuheader__header:after {
  background-color: #dcddde;
}
header .menuheader--logotitle.menuheader--sticky.menuheader--show .menuheader__header, html.hidesidemenu header .menuheader--logotitle.menuheader--sticky.menuheader--show .menuheader__header, header .menuheader--sticky.menuheader--showlogo .menuheader__header, html.hidesidemenu header .menuheader--sticky.menuheader--showlogo .menuheader__header {
  transform: translateY(0);
}
header .menuheaderplaceholder {
  display: none;
}

header.header--menuheaderissticky .menuheaderplaceholder {
  display: block;
}

.menuheader__header--account {
  padding-right: 80px;
}
@media (max-width: 320px) {
  .menuheader__header--account {
    padding-right: 60px;
  }
}
html[dir=ltr].hidesidemenu .menuheader__header {
  padding-left: 80px;
}
@media (max-width: 320px) {
  html[dir=ltr].hidesidemenu .menuheader__header {
    padding-left: 60px;
  }
}
@media (max-width: 1220px) {
  html[dir=ltr] .menuheader__header {
    padding-left: 80px;
  }
}
@media (max-width: 320px) {
  html[dir=ltr] .menuheader__header {
    padding-left: 60px;
  }
}

.sitetitle__container {
  min-height: 60px;
}
@media (max-width: 320px) {
  .sitetitle__container {
    min-height: 50px;
  }
}

.sitetitle__container {
  display: flex;
  align-items: center;
}

.sitetitle__container__logo--itc {
  flex: none;
  margin-right: 15px;
  display: block;
  display: inline-grid;
}

.sitetitle__container__logo--itc > img {
  width: 45px;
}

.sitetitle__container__titles {
  flex: auto;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sitetitle__break {
  display: inline-block;
}

.sitetitle__link {
  color: #1e2328;
  display: inline-block;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  max-width: 100%;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: top;
}
@media (max-width: 320px) {
  .sitetitle__link {
    font-size: 20px;
  }
}

.sitetitle__logo-container {
  display: none;
  margin-top: 10px;
  vertical-align: top;
}
@media (max-width: 1220px) {
  .menuheader--logotitle .sitetitle__logo-container {
    background-color: #f0f1f2;
    background-position: 20px 20px;
    min-height: 60px;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    position: absolute !important;
    right: 0;
    transform: translateY(-100%);
    width: 100%;
  }
}
@media (max-width: 1220px) and (max-width: 320px) {
  .menuheader--logotitle .sitetitle__logo-container {
    background-position: 15px 15px;
    min-height: 50px;
  }
}
html.hidesidemenu .menuheader--logotitle .sitetitle__logo-container {
  background-color: #f0f1f2;
  background-position: 20px 20px;
  min-height: 60px;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  position: absolute !important;
  right: 0;
  transform: translateY(-100%);
  width: 100%;
}
@media (max-width: 320px) {
  html.hidesidemenu .menuheader--logotitle .sitetitle__logo-container {
    background-position: 15px 15px;
    min-height: 50px;
  }
}
@media (max-width: 1220px) {
  .sitetitle__logo-container {
    display: inline-block;
    margin-top: 10px;
  }
}
html.hidesidemenu .sitetitle__logo-container {
  display: inline-block;
  margin-top: 10px;
}
@media (max-width: 320px) {
  .sitetitle__logo-container {
    margin-top: 5px;
  }
}

.sitetitle__logo {
  background: url("img/sitetitle__logo.png") left/contain no-repeat;
  display: block;
  height: 40px;
  max-width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  width: 248px;
}
@media (max-width: 320px) {
  .sitetitle__logo {
    height: 30px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
.menuheader--logotitle .sitetitle__logo {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  max-width: calc(100% - 40px);
}

.sitetitle__branding-partners {
  height: auto;
  overflow: hidden;
  max-height: 80px;
  overflow-x: auto;
  margin: 0 0;
  text-align: center;
  height: 0;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s;
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}
@media (max-width: 1220px) {
  .sitetitle__branding-partners {
    display: block;
  }
}
.sitetitle__branding-partners.ps > .ps__rail-x {
  opacity: 0.6;
  display: block;
  background-color: transparent !important;
  margin-left: 15px;
  margin-right: 15px;
  visibility: hidden;
}
.sitetitle__branding-partners.ps > .ps__rail-x::before {
  content: "";
  background-color: #eee;
  border-radius: 2px;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.sitetitle__branding-partners.ps > .ps__rail-x > .ps__thumb-x {
  height: 4px !important;
  background-color: #ccc;
  bottom: 0;
}
.sitetitle__branding-partners.ps.scrollable > .ps__rail-x {
  visibility: visible;
}
.sitetitle__branding-partners.ps:hover > .ps__rail-x, .sitetitle__branding-partners.ps:active > .ps__rail-x {
  opacity: 1;
}
.sitetitle__branding-partners.active {
  opacity: 1;
}
.sitetitle__branding-partners ul {
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}
.sitetitle__branding-partners ul > li {
  display: inline-flex;
  height: 60px;
  padding: 0 15px;
  align-items: center;
}
.sitetitle__branding-partners ul > li a {
  display: inline-block;
  max-height: 100%;
}
.sitetitle__branding-partners ul > li img {
  width: auto;
  max-height: 60px;
}

.sitetitle__branding {
  background-color: var(--color-white);
  display: flex;
  flex-flow: row;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.sitetitle__branding a {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
}
.sitetitle__branding a img {
  display: none;
}
.sitetitle__branding-logo {
  display: block;
  flex: none;
  height: 100%;
  max-width: 100%;
  color: #1e2328;
  font-weight: 700;
  font-size: 26px;
  text-transform: uppercase;
}
.sitetitle__branding-partnerstoggle {
  text-align: right;
  text-decoration: underline;
  display: none;
}
.sitetitle__branding-partnerstoggle > span {
  cursor: pointer;
}
.sitetitle__branding-partnerstoggle__hide, .sitetitle__branding-partnerstoggle.active .sitetitle__branding-partnerstoggle__show {
  display: none;
}
.sitetitle__branding-partnerstoggle__show, .sitetitle__branding-partnerstoggle.active .sitetitle__branding-partnerstoggle__hide {
  display: block;
}
@media (max-width: 1220px) {
  .sitetitle__branding a img {
    display: inline-block;
    margin-right: 10px;
  }
  .sitetitle__branding-partnerstoggle {
    display: inline-block;
  }
}

.accountmenu {
  transition-property: transform, opacity, height;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  background: #53575b;
  height: 0;
  opacity: 0;
  pointer-events: auto;
}
.menu .accountmenu {
  transform: translateY(-100%);
}
.menuheader .accountmenu {
  transform: translateX(100%);
}
@media (max-width: 750px) {
  .menuheader .accountmenu {
    display: none;
  }
}
.accountmenu__container {
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}
.menu .accountmenu__container--empty {
  display: none;
}
.menuheader .accountmenu__container {
  position: absolute;
  right: 0;
  top: 0;
}
.accountmenu--init {
  height: auto;
  visibility: hidden;
}
.accountmenu--visible {
  opacity: 1;
}
.menu .accountmenu--visible {
  transform: translateY(0);
}
.menuheader .accountmenu--visible {
  transform: translateX(0);
}
.accountmenu__toggle {
  align-items: center;
  background: #53575b;
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  text-decoration: none;
  width: 60px;
}
.accountmenu__toggle:hover {
  color: var(--color-white);
}
@media (max-width: 320px) {
  .menu .accountmenu__toggle {
    height: 50px;
    width: 50px;
  }
}
.menuheader .accountmenu__toggle {
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
@media (max-width: 750px) {
  .menuheader .accountmenu__toggle {
    display: none;
  }
}
.accountmenu__title {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  min-height: 60px;
  line-height: 20px;
  text-decoration: none;
}
.menu .accountmenu__title {
  color: #dcddde;
  font-weight: 700;
  padding: 20px 60px 20px 20px;
}
.menu .accountmenu__title:hover {
  color: #dcddde;
}
@media (max-width: 320px) {
  .menu .accountmenu__title {
    min-height: 50px;
    padding: 15px 60px 15px 20px;
  }
}
.menu .accountmenu__container--empty .accountmenu__title {
  display: inline-block;
  font-weight: 300;
  margin-left: -10px;
  padding: 20px 20px 20px 0;
  text-decoration: none;
  text-transform: none;
}
@media (max-width: 320px) {
  .menu .accountmenu__container--empty .accountmenu__title {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
.menu .accountmenu__container--empty .accountmenu__title:hover {
  color: var(--color-white);
}
.menuheader .accountmenu__title {
  color: var(--color-white);
  font-size: 18px;
  font-weight: 500;
  line-height: 60px;
  padding-left: 18px;
  padding-right: 60px;
}
.accountmenu__link {
  color: var(--color-white);
  display: block;
  margin: 0 20px;
  position: relative;
  text-decoration: none;
}
.accountmenu__link:hover {
  color: var(--color-white);
}
.menu .accountmenu__link {
  padding: 15px 0;
}
.menu .accountmenu__link:first-of-type {
  margin-top: 60px;
}
@media (max-width: 320px) {
  .menu .accountmenu__link:first-of-type {
    margin-top: 50px;
  }
}
.menu .accountmenu__link:last-child {
  margin-bottom: 30px;
}
.menuheader .accountmenu__link {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  z-index: 0;
}
html[dir=ltr] .menuheader .accountmenu__link:not(.overflow) {
  padding: 15px 40px 15px 0;
}
.menuheader .accountmenu__link:before {
  transition: transform 150ms ease;
  background: #4f5156;
  bottom: 0;
  content: "";
  display: block;
  left: -20px;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: -2;
}
html[dir=ltr] .menuheader .accountmenu__link:before {
  transform: translateX(-100%);
}
html[dir=ltr] .menuheader .accountmenu__link:hover:before {
  transform: translateX(0);
}
.accountmenu__link[data-num]:not([data-num="0"]):after {
  background: #1e2328;
  border-radius: 10px;
  color: var(--color-white);
  content: attr(data-num);
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
  margin: 10px;
  padding: 0 3px;
  position: absolute;
  top: calc(50% - 20px);
  text-align: center;
  min-width: 20px;
}
.menu .accountmenu__link[data-num]:not([data-num="0"]):after {
  right: -15px;
}
.menuheader .accountmenu__link[data-num]:not([data-num="0"]):after {
  right: -10px;
}

.menu__account {
  display: none;
  font-size: 18px;
  margin-top: -60px;
  min-height: 60px;
}
@media (max-width: 750px) {
  .menu__account {
    display: block;
  }
}
@media (max-width: 320px) {
  .menu__account {
    margin-top: -50px;
    min-height: 50px;
  }
}

.quicklinks {
  background: transparent;
  bottom: 0;
  box-sizing: content-box;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  pointer-events: none;
  transition-property: background, width;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 260px;
  z-index: 20;
}
.quicklinks::after {
  background: #1f2229;
  bottom: 0;
  content: "";
  display: block;
  left: 260px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 21;
}
.quicklinks--init {
  transition: none;
  width: 260px;
}
.quicklinks--show {
  background: #1f2229;
  pointer-events: auto;
  width: 100%;
}

.quicklinks__content {
  height: 100%;
  left: calc(50% - 720px);
  max-width: 1440px;
  opacity: 0;
  overflow: auto;
  padding: 22px;
  position: absolute;
  transform: translateX(-50px);
  transition-property: transform, opacity;
  transition-duration: 375ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0;
  width: 100vw;
  z-index: 22;
}
.quicklinks__content--init {
  opacity: 0;
  transform: translateX(-50px);
  transition: none;
}
@media (max-width: 1440px) {
  .quicklinks__content {
    left: auto;
    right: 0;
  }
}
.quicklinks--show .quicklinks__content {
  transform: translateX(0);
  transition-delay: 125ms;
  opacity: 1;
}

.quicklinks__close {
  color: var(--color-white);
  float: right;
  font-size: 18px;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  text-decoration: none;
}
.quicklinks__close-link {
  text-decoration: underline;
}
.quicklinks__close-icon {
  padding-left: 16px;
}
.quicklinks__close:hover {
  color: var(--color-white);
}

.quicklinks__logo {
  background: url("img/quicklinks__logo.png") left/contain no-repeat;
  display: block;
  height: 50px;
  width: 248px;
}

.quicklinks__searchform {
  float: right;
  position: relative;
  width: 40%;
}
.quicklinks__searchform .menu__search {
  background: var(--color-white);
  border: 1px solid #dcddde;
  border-radius: 2px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  width: 100%;
}
.quicklinks__searchform .menu__search::after {
  display: none;
}
.quicklinks__searchform .menu__searchicon {
  color: #afb1b3;
  left: auto;
  right: 17px;
  top: 17px;
}
.quicklinks__searchform .menu__searchfield {
  color: #1e2328;
  font-weight: 400;
  height: 50px;
  padding: 11px 33px 11px 17px;
}
.quicklinks__searchform .menu__searchfield--placeholder {
  font-weight: 400;
}

.quicklinks__title {
  color: var(--color-white);
  display: block;
  font-size: 58px;
  font-weight: 700;
  line-height: 58px;
  text-transform: uppercase;
}

.quicklinks__container {
  display: flex;
  margin-top: 110px;
  width: 100%;
}
@media (max-width: 960px) {
  .quicklinks__container {
    display: block;
  }
}

.quicklinks__section {
  width: 20%;
}
.quicklinks__section + .quicklinks__section {
  padding-left: 5px;
}
.quicklinks__section:not(:last-child) {
  padding-right: 5px;
}
@media (max-width: 960px) {
  .quicklinks__section {
    width: 100%;
  }
  .quicklinks__section + .quicklinks__section, .quicklinks__section:not(:last-child) {
    padding: 0;
  }
}
@media (max-width: 960px) {
  .quicklinks__section {
    border-color: var(--color-grey-dark);
    border-style: solid;
    border-width: 0 0 1px 0;
  }
  .quicklinks__section:first-of-type {
    border-top-width: 1px;
  }
}
.quicklinks__section-title {
  color: var(--color-white);
  display: inline;
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
}
@media (max-width: 960px) {
  .quicklinks__section-title {
    cursor: pointer;
    display: block;
    font-size: 18px;
    padding: 15px 0;
  }
}
@media (max-width: 480px) {
  .quicklinks__section-title {
    font-size: 15px;
    padding: 10px 0;
  }
}
.quicklinks__section-icon {
  display: none;
  line-height: 25px;
  float: right;
}
.quicklinks__section-icon--open {
  color: var(--color-grey-normal);
}
@media (max-width: 960px) {
  .quicklinks__section-icon--open {
    display: block;
  }
  .quicklinks__section-title--open .quicklinks__section-icon--open {
    display: none;
  }
}
.quicklinks__section-icon--close {
  color: var(--color-white);
}
@media (max-width: 960px) {
  .quicklinks__section-title--open .quicklinks__section-icon--close {
    display: block;
  }
}
.quicklinks__section-links {
  overflow: visible;
}
@media (max-width: 960px) {
  .quicklinks__section-links {
    border: var(--color-grey-dark) solid 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition-duration: 250ms;
    transition-property: border-width, height, opacity;
  }
  .quicklinks__section-links.quicklinks__section-links--open {
    border-top-width: 1px;
    opacity: 1;
  }
  .quicklinks__section-links.quicklinks__section-links--init {
    height: auto;
    position: absolute;
    top: 0;
    transition: none;
    visibility: hidden;
  }
}
.quicklinks__section-link {
  color: var(--color-white);
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: 300;
  line-height: 50px;
  text-decoration: none;
}
.quicklinks__section-link-icon {
  color: #afb1b3;
  font-size: 14px;
  padding-right: 13px;
}
.quicklinks__section-link:hover {
  color: var(--color-white);
}
.quicklinks__section-alllinks {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 250ms, opacity 250ms;
}
.quicklinks__section-alllinks--open {
  opacity: 1;
}
.quicklinks__section-alllinks--init {
  height: auto;
  position: absolute;
  top: 0;
  transition: none;
  visibility: hidden;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.9;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #aaa;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
}

.ps__thumb-y {
  background-color: #aaa;
}