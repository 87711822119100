/** The photoalbum widget consists of
    - a Justified Image Grid
    - a fullscreen slideshow

    FIXME: maybe not use fixed, but replace the contents... or place ourselves at the top with height: 100vh; and disable scrolling
*/

.photoalbumwidget__item
{
  cursor: pointer;
}

.photoalbumwidget__item-title
{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 10px;

  background-color: rgba(0, 0, 0, 0.75);
  color: var(--color-white);

  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.25s, opacity 0.25s;

  cursor: pointer;
}

html.allowhover .photoalbumwidget__item:hover .photoalbumwidget__item-title
{
  opacity: 1;
  transform: translateY(0%);
}





.photoalbumwidget__slideshow
{
  display: none;

  -webkit-tap-highlight-color: transparent; /* iOS Safari */

/*  position: fixed;*/
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-black); /* rgba(0, 0, 0, 0.75); */
  z-index: 1337;
}

.photoalbumwidget__slideshow.prepare
{
  display: block;
  visibility: hidden;
}

.photoalbumwidget__slideshow.visible
{
  visibility: visible;
}

.photoalbumwidget__slideshow .carrousel__viewport
{
}



.fsslideshow--singleslide.prepare
{
  display: flex;
  flex-direction: column;
}

.fsslideshow--singleslide .carrousel__cell
{
  display: table; /* shrinkwrap without using position: absolute; or float */
  margin: auto;
}



.photoalbumwidget__slideshow .carrousel
{
}

.photoalbumwidget__slideshow .carrousel__cell
{
  box-shadow: 7px 12px 45px rgba(0, 0, 0, 0.75);
}





/* FullScreen Slideshow */
/*
.fsslideshow .carrousel__cell-image
{
  background-image: none; / * !! prevent browsers from directly loading * /
}
*/

/*
.fsslideshow .carrousel__viewport
{
  transform: scale(0);
  transition: transform 10.25s, opacity 10.25s;
}
.fsslideshow.visible .carrousel__viewport
{
  transform: scale(1);
}
*/


/* make the normal content of the page use no space anymore,
   so it appears all content is gone but without killing it's state
   (running plugins, iframe content, eventlisteners etc).
*/
.html--onlyfullscreenelement
.html--onlyfullscreenelement body
{
  overflow: hidden;
}
.html--onlyfullscreenelement .page
{
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden;
}




.fsslideshow .sitetitle__logo
{
  position: absolute;
  left: 20px;
  top: 0;

  height: 60px;
  line-height: 60px;

  color: var(--color-white);

  font-size: 30px; /* instead of 26 */
}

/*
.fsslideshow .photoalbumwidget__sitetitle
{
  position: absolute;
  left: 20px;
  bottom: 0;
}
*/





.fsslideshow__title
{
  position: absolute;
  bottom: 10px;
  width: 100%;

  color: var(--color-white);

  font-size: 18px;
  font-weight: normal;

  text-align: center;

  letter-spacing: 0.2px;

  color: var(--color-grey-normal);
}

  .fsslideshow__topbuttons
, .fsslideshow__navbuttons
{
  display: flex;
}

.fsslideshow__topbuttons
{
  position: absolute;
  top: 0;
  right: 0;
}

.fsslideshow__navbuttons
{
  position: absolute;
  right: 0;
  bottom: 0;
}

.fsslideshow__button
{
  width: 60px;
  height: 60px;

  text-align: center;
  line-height: 60px;

  cursor: pointer;
}

  .fsslideshow__button
, .fsslideshow__title
{
  -webkit-transform: translate3D(0,0,0);
  -webkit-backface-visibility: hidden;
}

  .fsslideshow__button > .UT-icon
, .fsslideshow__button > .UT-icon
{
  transition: fill 0.25s;

  vertical-align: middle;

  font-size: 24px;
  color: #999999;
}

  html.allowhover .fsslideshow .fsslideshow__button:hover > .UT-icon
, .fsslideshow .fsslideshow__button:active > .UT-icon
{
  color: var(--color-white);
}

.fsslideshow__download > .UT-icon
{
  font-size: 26px;
}

  .fsslideshow__close > .UT-icon
, .carrousel__previous
, .carrousel__next
{
  font-size: 22px;
}

/*
.fsslideshow__download
{
}
.fsslideshow__close
{
}
.carrousel__previous
{
}
.carrousel__next
{
}

*/
