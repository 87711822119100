/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*
.listitem__item advantages compared to summary.scss:
- (at the moment) for simplo flat rows (not nested or morphing into a block)
- row height is flexible
- icon will automatically use themecolor
*/
.listitem__item {
  position: relative;
  display: block;
  text-decoration: none;
  color: #1e2328;
  border-top: 1px solid #dcddde;
}
.listitem__item:before {
  background-color: #f7f8f8;
  bottom: 0;
  content: "";
  display: block;
  left: -20px;
  position: absolute;
  top: 0;
  transition: width 150ms ease;
  width: 0;
  z-index: -1;
}
.listitem__item:hover:before {
  width: 100%;
}

.listitem__item::before {
  z-index: 0;
}

.listitem__item:last-child {
  border-bottom: 1px solid #dcddde;
}

.listitem__item__content {
  display: flex;
  padding: 15px 8px 14px 0;
  position: relative;
}

.listitem__item__content > * + * {
  margin-left: 20px;
}

.listitem__meta {
  min-width: 0;
  flex: 1 0 0px;
}

.listitem__title {
  text-transform: none;
  font-size: 15px;
  font-weight: 700;
}

.listitem__title.listitem__title.listitem__title {
  color: #1e2328;
}

.listitem__description {
  color: #5a5e62;
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.listitem__icon {
  flex: none;
  width: 24px;
  height: 24px;
  font-size: 24px;
  align-self: center;
  text-align: center;
}

/* UT and institutes */
.listitem__icon {
  color: #afb1b3;
}

/*
.widget--featuredpages
{
  .listitem__title
  {
    @include font-summary-title;
  }
  .listitem__description
  {
    color: $color-black;
    @include font-summary-description;
  }
}
*/
.widget--featuredpages .listitem__icon {
  color: #53575b;
}

.widget--featuredpages .listitem__arrow {
  color: #afb1b3;
}

.listitem__arrow {
  align-self: center;
  color: #afb1b3;
}
.listitem__arrow::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e933";
  height: 16px;
  font-size: 16px;
}

.listitem__item:hover .listitem__icon,
.listitem__item:hover .listitem__arrow {
  color: #1e2328;
}

.listitem__icon .UT-icon-UT_icon_52_arrow-right-round-16px {
  height: 16px;
  font-size: 16px;
}

.widget--progress .listitem__icon {
  width: 48px;
  height: 48px;
}