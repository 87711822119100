/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*

By using the following DOM you can make checkboxes/radiobuttons which look like tabs or toggle buttons.

<div class="comp-togglebuttonbar">
  <input id="" type="checkbox" name="..." />
  <label class="comp-togglebutton" for=""></label>
</div>

*/
.comp-togglebuttonbar {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.comp-togglebutton {
  flex: none;
  cursor: pointer;
}

.comp-togglebuttonbar--stretch {
  flex-grow: 1;
  flex-shrink: 1;
}

.comp-togglebuttonbar input[type=checkbox],
.comp-togglebuttonbar input[type=radio] {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}

.comp-togglebutton::before {
  display: none !important;
}

input:checked + .comp-togglebutton {
  color: var(--color-white);
  border-color: transparent;
  height: 100%;
  background-color: #00675a;
}

input:checked + .comp-togglebutton + input:checked + .comp-togglebutton.selected {
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}

.comp-togglebuttonbar .spacer {
  width: 10px;
  min-width: 5px;
  max-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;
}

.comp-togglebutton {
  /* [IEFIX] We need to use <label> to have a natively stylable, interactable replacement for the checkbox.
             <label> however is inline and IE10 doesn't see inline elements as normal flex child */
  display: inline-block;
  text-align: center;
  padding: 0 10px;
  line-height: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 3px;
  color: var(--color-white);
  margin-bottom: 5px;
}

.comp-togglebutton + input + label {
  margin-left: 10px;
}

html.site--itc .comp-togglebutton {
  background-color: #1e2328;
}

html.site--itc input:checked + .comp-togglebutton {
  background-color: #ec7a08;
}

.comp-togglebutton {
  background-color: #afb1b3;
}

html.site--ut input:checked + .comp-togglebutton {
  background-color: #1e2328;
}