@import "@mod-utwente_design/scss/config-colors.css";
/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * HEADER
 * Header is styled at >1500 pixels, for smaller screens the media queries will
 * update the header
 */
/*
.header__container - container for the photo/slideshow header
.header__universe  - container for the header with animating SVG




.header__sliderdots  - 10
.header__titleholder -  6 - To be over the swoosh in case the overlay must overlap/overflow the bottom of header(swoosh)
header swoosh        -  5 - Over the background-color of .filters__expandedcontent (which is used to cover the animation universe element)
.header__author      -  1 (UT)
                     -  5 (ITC)

*/
.header {
  flex: none;
  position: relative;
  width: 100%;
  z-index: 3;
}
@media (max-width: 1220px) {
  .header {
    left: auto;
    width: 100%;
  }
}
@media print {
  .header {
    display: none;
  }
}

.header__container {
  background-color: var(--color-grey-light);
}

/* NOTE: we could also use .header__content for this? */
.header__container,
.header__universe {
  position: relative;
  width: 100%;
}

.header__universe {
  z-index: 0;
  height: 320px;
}
@media (max-width: 480px) {
  .header__universe {
    height: 220px;
  }
}

html.site--itc .header__universe::after,
html.site--itc:not(.showfilters) .header__container .header__slide::before,
html.site--itc.showfilters .header__container + .filters__expandedcontent::after {
  pointer-events: none;
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  top: auto;
  width: 100%;
  display: block;
  content: "";
  padding-top: 9%;
  background-image: url("../../web/img/itc/header-paintstroke.png");
  background-size: cover;
  background-color: transparent;
}
@media (min-width: 1221px) {
  html:not(.hidesidemenu) html.site--itc .header__universe::after,
  html:not(.hidesidemenu) html.site--itc:not(.showfilters) .header__container .header__slide::before,
  html:not(.hidesidemenu) html.site--itc.showfilters .header__container + .filters__expandedcontent::after {
    padding-top: calc((100vw - 260px) * 0.09);
  }
}

html.peopleoverview--showasgrid.site--itc .header__universe::after,
html.peopleoverview--showasgrid.site--itc:not(.showfilters) .header__container .header__slide::before,
html.peopleoverview--showasgrid.site--itc.showfilters .header__container + .filters__expandedcontent::after {
  background-image: url("../../web/img/itc/header-paintstroke-grey.png");
}

html.site--itc .header__universe::before {
  background-size: cover;
}

html.site--itc .header__heroimage::after,
html.site--itc .header__photo::after {
  background: linear-gradient(to bottom, transparent, transparent calc(25% - 10px), rgba(0, 0, 0, 0.3));
}

html.site--itc:not(.showfilters) .header__container::after {
  background: var(--color-white);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

html.showfilters .header__universe {
  background: linear-gradient(to top, rgb(240, 241, 242) 0px, rgb(240, 241, 242) 130px, rgba(240, 241, 242, 0) 51px);
}

.header__container--reducedheight {
  height: 320px;
  padding: 0;
}

.header__container--large {
  display: inline-block;
  vertical-align: top;
  height: 640px;
}
@media (max-width: 1760px) {
  .header__container--large {
    height: auto;
  }
  .header__container--large:before {
    content: "";
    float: left;
    padding-top: 42.67%;
  }
}
@media (max-width: 1760px) and (min-width: 1500px) {
  html.hidesidemenu .header__container--large:not(.header__container--large--cta) {
    height: 640px;
  }
}
@media (max-width: 1500px) {
  .header__container--large {
    height: auto;
  }
  .header__container--large:before {
    content: "";
    float: left;
    padding-top: 42.67%;
  }
}
@media (max-width: 750px) {
  .header__container--large {
    height: 320px;
    padding: 0;
  }
}

.header__universe.header__container--withfilters .header__titleholder {
  bottom: 0;
}

.header__universe.header__container--withfiltersexpanding {
  height: auto;
  padding-top: 0 !important;
}
.header__universe.header__container--withfiltersexpanding::before {
  display: none;
}
.header__universe.header__container--withfiltersexpanding .header__titleholder {
  position: relative;
}
@media (min-width: 1221px) {
  .header__universe.header__container--withfiltersexpanding .header__titleholder {
    padding-top: 77px;
  }
}
@media (max-width: 1220px) {
  .header__universe.header__container--withfiltersexpanding .header__titleholder {
    padding-top: 90px;
  }
}

.header__heroimage {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.header__heroimage--background {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.header__heroimage--background::after {
  background: rgba(0, 0, 0, 0.25);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.header__slide--photo {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.header__photo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.header__heroimage::after,
.header__photo::after {
  background: linear-gradient(to bottom, transparent, transparent 25%, rgba(0, 0, 0, 0.3));
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.header__author {
  bottom: 20px;
  color: #dcddde;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  position: absolute;
  right: 20px;
  z-index: 1;
}
.filteredoverview__form .header__author {
  bottom: 8px;
}
html.site--itc .header__author {
  color: #afb1b3;
  z-index: 5;
}
@media (max-width: 960px) {
  html.site--itc .header__author {
    bottom: 0;
  }
}

.header__slide {
  opacity: 1;
  transition: opacity 1s;
}
.header__slide + .header__slide {
  display: none;
}

.header__slide.slideshow--slidein {
  opacity: 0;
}

.header__slide.slideshow--slideout {
  opacity: 0;
}

.header__sliderdots {
  bottom: 16px;
  color: var(--color-white);
  position: absolute;
  width: 100%;
  z-index: 10;
}
.header__container--withfilters .header__sliderdots {
  bottom: 6px;
}
html.site--itc .header__sliderdots {
  bottom: 34px;
}
@media (max-width: 480px) {
  .header__sliderdots {
    display: none;
  }
}

.header__sliderdots-contents {
  display: flex;
}

.header__sliderdot {
  cursor: pointer;
  display: block;
  flex: none;
  position: relative;
  text-align: center;
  width: 20px;
}

.header__sliderdotactive {
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 1s ease-out;
  width: 20px;
}
.slideshow--activedot .header__sliderdotactive {
  opacity: 1;
}
.slideshow--paused .header__sliderdotactive {
  animation: blink-animation 2s linear infinite;
}

.header__backgrounduniverse {
  background-color: var(--color-grey-light);
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 1;
}
html[dir=ltr] .header__backgrounduniverse {
  left: 260px;
  right: 0;
}
@media (max-width: 1220px) {
  html[dir=ltr] .header__backgrounduniverse {
    left: 0;
  }
}
html[dir=ltr].hidesidemenu .header__backgrounduniverse {
  left: 0;
}

.header__universe-container {
  height: 100%;
  position: relative;
  width: 100%;
}
.header__universe-container--clockwise {
  transition: transform 150s linear;
  transform: rotate(-25deg);
}
.header__universe-container--clockwise.header__universe-container--rotate {
  transform: rotate(25deg);
}
.header__universe-container--counterclockwise {
  transition: transform 150s linear;
  transform: rotate(25deg);
}
.header__universe-container--counterclockwise.header__universe-container--rotate {
  transform: rotate(-25deg);
}
@media (prefers-reduced-motion) {
  .header__universe-container {
    transform: none !important;
    transition: none !important;
  }
}
@media print {
  .header__universe-container {
    display: none;
  }
}

.header__universe-svg {
  display: block;
  left: 50%;
  position: absolute;
}

.header__universe-grey {
  fill: var(--color-grey-lines);
}

/*
We want to prevent the titleholder from blocking
clicks to the CTA button label

!!! Be carefull: IE & Edge ignore pointer-events settings on elements which are only inline.
    Making them positioned or inline-block, block, inline-flex etc makes them accept the pointer-events.

    Also see caniuse (which isn't 100% correct in listing triggers to get pointer-events working):
    https://caniuse.com/#feat=pointer-events
*/
.header__titleholder {
  pointer-events: none;
}
.header__titleholder a, .header__titleholder .filteredoverview__filterholder {
  pointer-events: auto;
}
.header__titleholder .header__breadcrumb li {
  pointer-events: auto;
}
.header__titleholder .header__prevtitle a {
  display: inline-block;
}

.header__titleholder {
  position: absolute;
  bottom: 43px;
  width: 100%;
  z-index: 6;
}
html.site--itc .header__titleholder {
  bottom: 60px;
}

.filters__expandedcontent {
  background-color: var(--color-grey-light);
}

.header--content8col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
.header--content8col .filteredoverview__toggleandsearchbar,
.header--content8col .filters__expandedcontent__content,
.header--content8col .header__sliderdots-contents,
.header--content8col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
  --layout: 8col;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 640px;
  /*
  Activate when 1440+ design becomes available for all pages
    @media (min-width: $responsive-xxmedium-devices)
    {
      width: $largescreen-width-8col;
    }
  */
}
@media (max-width: 750px) {
  .header--content8col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
  .header--content8col .filteredoverview__toggleandsearchbar,
  .header--content8col .filters__expandedcontent__content,
  .header--content8col .header__sliderdots-contents,
  .header--content8col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
    margin-left: 0;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .header--content8col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
  .header--content8col .filteredoverview__toggleandsearchbar,
  .header--content8col .filters__expandedcontent__content,
  .header--content8col .header__sliderdots-contents,
  .header--content8col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 0) {
  .header--content8col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
  .header--content8col .filteredoverview__toggleandsearchbar,
  .header--content8col .filters__expandedcontent__content,
  .header--content8col .header__sliderdots-contents,
  .header--content8col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}

.header--content12col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
.header--content12col .filteredoverview__toggleandsearchbar,
.header--content12col .filters__expandedcontent__content,
.header--content12col .header__sliderdots-contents,
.header--content12col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 960px) {
  .header--content12col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
  .header--content12col .filteredoverview__toggleandsearchbar,
  .header--content12col .filters__expandedcontent__content,
  .header--content12col .header__sliderdots-contents,
  .header--content12col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .header--content12col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
  .page--toplevelhome .header--content12col .filteredoverview__toggleandsearchbar,
  .page--toplevelhome .header--content12col .filters__expandedcontent__content,
  .page--toplevelhome .header--content12col .header__sliderdots-contents,
  .page--toplevelhome .header--content12col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (max-width: 750px) {
  .header--content12col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
  .header--content12col .filteredoverview__toggleandsearchbar,
  .header--content12col .filters__expandedcontent__content,
  .header--content12col .header__sliderdots-contents,
  .header--content12col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (max-width: 480px) {
  .header--content12col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
  .header--content12col .filteredoverview__toggleandsearchbar,
  .header--content12col .filters__expandedcontent__content,
  .header--content12col .header__sliderdots-contents,
  .header--content12col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 0) {
  .header--content12col .header__titleholder__contents > *:not(.filteredoverview__filterholder),
  .header--content12col .filteredoverview__toggleandsearchbar,
  .header--content12col .filters__expandedcontent__content,
  .header--content12col .header__sliderdots-contents,
  .header--content12col .header__container + .filters__expandedcontent .filters__expandedcontent__content {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}

.header__titleprefix {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}
@media (max-width: 750px) {
  .header__titleprefix {
    margin-bottom: 10px;
  }
}
.header__titleprefix--photos {
  color: var(--color-white);
}

.header__title {
  margin-top: 0;
  text-transform: uppercase;
}
.header__title--photos {
  color: var(--color-white);
}
.header__title .subtitle {
  font-weight: 300;
}

.header__container__clipper,
.header__container__zoom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header__container__clipper {
  overflow: hidden;
}

/*
Facts & Figures bar directly below the normal header
*/
.header__bottomtextblock {
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 58px;
  line-height: 58px;
  font-weight: 700;
  background-color: var(--color-black);
  padding: 50px 0;
}
@media (max-width: 750px) {
  .header__bottomtextblock {
    padding: 35px 0;
  }
}

.header__bottomtextblock > div {
  width: 740px;
  padding: 0 0 0 60px;
  margin: 0 auto;
}
@media (max-width: 750px) {
  .header__bottomtextblock > div {
    margin-left: 0;
    padding: 0 55px;
    width: 100%;
    font-size: 8vw;
    line-height: 8vw;
  }
}
@media (max-width: 480px) {
  .header__bottomtextblock > div {
    padding: 0 15px;
  }
}

.header__bottomtextblock__slogan,
.header__bottomtextblock__sitetitle small {
  font-weight: 300;
  font-size: 58px;
}
@media (max-width: 750px) {
  .header__bottomtextblock__slogan,
  .header__bottomtextblock__sitetitle small {
    font-size: 8vw;
    line-height: 8vw;
  }
}

html.ws2016--iframed .header,
html.ws2016--iframed .header__backgrounduniverse {
  display: none;
}