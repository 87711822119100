wh-imgedit {
  --wh-imgedit-height: 190px;
  --wh-imgedit-width: 140px;
}
wh-imgedit::part(button deletebutton) {
  background-color: var(--theme-color-cta);
}

.wh-form__imgedit {
  position: relative;
  vertical-align: bottom;
  height: 190px;
  max-height: 190px;
  max-width: 140px;
  width: 140px;
}
.wh-form__imgedit .wh-form__imgeditholder {
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.wh-form__imgedit.parsley-error .wh-form__imgeditholder {
  border-color: #cd202c;
}
.wh-form__imgedit:not(.wh-form__imgedit--hasimage) .wh-form__imgeditholder {
  background: var(--color-grey-light);
  cursor: pointer;
}
.wh-form__imgedit:not(.wh-form__imgedit--hasimage) .wh-form__imgeditholder:before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  bottom: calc(50% + 10px);
  color: #abacae;
  display: block;
  font-size: 50px;
  position: absolute;
  text-align: center;
  width: 100%;
  content: "\e911";
}
.wh-form__imgedit:not(.wh-form__imgedit--hasimage) .wh-form__imgeditholder:after {
  color: var(--color-grey-dark);
  content: "drop or upload your image";
  display: block;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  position: absolute;
  text-align: center;
  text-decoration: underline;
  top: 50%;
  width: 100%;
}
.wh-form__imgedit .wh-form__imgeditimg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.wh-form__imgedit .wh-form__imgeditdelete {
  background: var(--color-white);
  border-radius: 100%;
  color: #abacae;
  cursor: pointer;
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 5px;
  width: 20px;
}
.wh-form__imgedit .wh-form__imgeditdelete:before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e936";
}

.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder {
  cursor: default;
}
.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder:before {
  opacity: 0.2;
}
.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder:after {
  content: "Loading...";
  text-decoration: none;
}

.utwente-imgedit__userpicture .wh-form__imgedit {
  height: 100px;
  max-height: 100px;
  max-width: 100px;
  width: 100px;
}
.utwente-imgedit__userpicture .wh-form__imgedit .wh-form__imgeditholder {
  border-radius: 100%;
}
.utwente-imgedit__userpicture .wh-form__imgedit:not(.wh-form__imgedit--hasimage) .wh-form__imgeditholder:before {
  bottom: calc(50% - 40px);
  content: "\e927";
  font-size: 80px;
}
.utwente-imgedit__userpicture .wh-form__imgedit:not(.wh-form__imgedit--hasimage) .wh-form__imgeditholder:after {
  display: none;
}
.utwente-imgedit__userpicture .wh-form__imgedit .wh-form__imgeditdelete {
  right: -8px;
  top: 0;
}