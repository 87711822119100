.universe {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity var(--header-slideshow-duration);
  width: 100%;
}
.universe ~ * {
  position: relative;
}
.universe--loaded {
  opacity: 1;
}

.universe__container {
  min-height: 100%;
  min-width: 100%;
  position: relative;
}
.universe__container--animate {
  animation: spinuniverse var(--universe-rotation-duration) linear infinite alternate running;
}
.universe__container--reverse {
  animation-name: spinuniverse--reverse;
}
.universe__container svg {
  display: block;
  left: 80%;
  position: absolute;
}
.universe__container .header__universe-color {
  fill: var(--theme-color);
}

@keyframes spinuniverse {
  0% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(25deg);
  }
}
@keyframes spinuniverse--reverse {
  0% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(-25deg);
  }
}