html
{
  color: var(--color-black);
  font: 10px "Univers Next W02", Arial, Helvetica, sans-serif;


  --rtd-link-color:         var(--color-grey-dark);
  --rtd-link-color-hover:   currentColor; /* inherit.. but if we use inherit the CSS variable inherits */

  --rtd-text-color:         var(--color-black);
  --rtd-text-fontfamily:    "Univers Next W02", Arial, Helvetica, sans-serif;
  --rtd-text-font:          15px/25px var(--rtd-text-fontfamily);

  --rtd-heading-color:      var(--color-black);
  --rtd-heading-fontfamily: "Univers Next W02", Arial, Helvetica, sans-serif;

  --rtd-intro-font:         300 26px/26px var(--rtd-text-fontfamily);


  --color-error:           #EE0000; /* lightest accessible red, 4.53:1 contrast on white */
  --color-grey-border:     #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-text-small: #767677; /* WCAG 4.53:1 contrastratio */
  --color-input-placeholder: var(--color-grey-text-small);
  --color-input-disabled-placeholder: var(--color-grey-text-small);


  --formcontrols-themecolor: #222222;

  /************************************************************
   *
   *  Form controls (checkbox and radio buttons)
   */

  /*--font-awesome:        "Font Awesome 5 Pro";*/
  /*--font-awesome-weight:  600;*/
  --font-awesome-size:    16px;
  --font-awesome-offsetx: 0px;
  --font-awesome-offsety: 0px;

  /* Checkboxes and radiobuttons */
  --formcontrol-checkradio-size:                22px;
  --formcontrol-checkradio-bordercolor:         #CBCBCB;
  --formcontrol-checkradio-borderwidth:         1px;
  --formcontrol-checkradio-borderradius:        2px;
  --formcontrol-radio-innersize:                8px;

  --formcontrol-check-shift:                    3px;
  --formcontrol-radio-shift:                    0px;

  /* Checked radio button (white background) */
  --formcontrol-checkradio-checked-color:       #FFFFFF;
  --formcontrol-checkradio-checked-bgcolor:     var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-radio-checked-color:            var(--formcontrols-themecolor);

  /*
  Checked radiobutton (solid background)
  --formcontrol-checkradio-checked-color:       var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-bgcolor:     #FFFFFF;
  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-radio-checked-color:            var(--formcontrols-themecolor);
  */

  /* Color for subtly showing the checkmark/bullet symbol upon hover */
  --formcontrol-checkradio-hover-color:         #F0F1F2;

  /* Disabled checkbox */
  --formcontrol-checkradio-disabled-color:           #555555;
  --formcontrol-checkradio-disabled-bgcolor:         #F2F2F2;
  --formcontrol-checkradio-disabled-bordercolor:     #F2F2F2;
  --formcontrol-checkradio-disabled-checked-bgcolor: #F2F2F2;

  /* Focus */
  --formcontrol-checkradio-focus-bordercolor:   #FFFFFF; /* create contrast to the box shadow */
  --formcontrol-checkradio-focus-boxshadow:     0 0 2px 3px rgb(44, 108, 214);
}


html[lang=el] /*greek - www.utwente.edu/greece */
{
  font-family: Roboto, Arial, Helvetica, sans-serif;
  --rtd-text-fontfamily: Roboto, Arial, Helvetica, sans-serif;
}


/* rules using fontfamily variables are placed here so
   they can inherit any fontfamily overrides set on html
*/
body
{
  --rtd-h1-font:            700 58px/58px var(--rtd-heading-fontfamily);
  --rtd-h2-font:            700 26px/26px var(--rtd-heading-fontfamily);
  --rtd-h3-font:            700 18px/20px var(--rtd-heading-fontfamily);
  --rtd-h4-font:            600 15px/25px var(--rtd-heading-fontfamily);

  --ctalink-font: 300 18px var(--rtd-heading-fontfamily);
}

/*
   Workaround so underline is directly below super/sub. See issue #2107
   Note: sup > * has no problems, other way around, like a > sup, underline is to low
*/
u > sup, p > a > sup
{
  display: inline-block;
  text-decoration: underline;
}

.wh-form__fieldgroup--error
{
  --formcontrol-checkradio-checked-bordercolor: #CD202C;
}

/* F&F has a selection of 9 colors from the 15 WS2012 colors as theme colors*/
html.ut-fftheme-mosque
{
  --theme-color-cta: var(--color-mosque);
}
html.ut-fftheme-windsor
{
  --theme-color-cta: var(--color-windsor);
}
html.ut-fftheme-bondi_blue
{
  --theme-color-cta: var(--color-bondi-blue);
}
html.ut-fftheme-malibu_green
{
  --theme-color-cta: var(--color-malibu-green);
}
html.ut-fftheme-scarlett
{
  --theme-color-cta: var(--color-scarlett);
}
html.ut-fftheme-trendy_green
{
  --theme-color-cta: var( --color-trendy-green);
}
html.ut-fftheme-tahiti_gold
{
  --theme-color-cta: var(--color-tahiti-gold);
}
html.ut-fftheme-crimson_glory
{
  --theme-color-cta: var(--color-crimson-glory);
}
html.ut-fftheme-dark_grey
{
  --theme-color-cta: var(--color-dark-grey);
}




body
{
  font-size: 15px;/*1.5rem*/
  line-height: 25px;/*2.5rem*/
}

.wh-rtd-editor,
.wh-rtd-embeddedobject__preview /* RTD sets font-size: 12px; on .wh-rtd-embeddedobject_box */
{
  font: var(--rtd-text-font);
}

.wh-rtd-editor-bodynode
{
  padding: 15px;
}

.wh-rtd-editor-bodynode > *:first-child
{
  margin-top: 0;
}

.wh-rtd__preview__columntd
{
  text-align: left;
  vertical-align: top;
}

h1
{
  font: var(--rtd-h1-font);
  margin-top: 50px;/*5rem*/
  text-transform: uppercase;
}

.extraheading
{
  font-size: 40px;/*4.0rem*/
  font-weight: 700;
  line-height: 40px;/*4.0rem*/
  margin-top: 50px;/*5.0rem*/
  text-transform: uppercase;
}

h2
{
  font: var(--rtd-h2-font);
  margin-top: 50px;/*5.0rem*/
  text-transform: uppercase;
  margin-bottom: 30px;/*3.0rem*/
}
html.utwente--ws2021 h2
{
  margin-bottom: 5px;/*30px - 25px (p margin-top)*/
}

/* When jumping to the h2, jump to 50px above the h2 contents
   FIXME: we should also add a CSS variable for this so
          widgets can set their own scroll-margin-top on targets to scroll to.
*/
:where(main) *
{
  scroll-margin-top: calc(var(--navbar-height) + 50px);
}

a.wh-rtd__anchor,
a.wh-anchor
{
  display: block;
}

.introtext
{
  font: var(--rtd-intro-font);
  margin-top: 50px;/*5.0rem*/
  text-transform: uppercase;
}
.introtext b
{
  font-weight: 700;
}

.introtext-news,
.introtext-news b
{
  font-weight: 600;
  margin-top: 70px;/*7.0rem*/
}

h3,
.style_as_h3
{
  font: var(--rtd-h3-font);
  text-transform: uppercase;
}
h3
{
  margin-top: 50px;/*5.0rem*/
}

h4 /* styled as bold p */
{
  font: var(--rtd-h4-font);
  margin-top: 25px;/*2.5rem*/
}

p,
.carrouselwidget,
.photoalbumwidget,
.pagetags,
.wh-rtd__tablewrap
{
  margin-top: 25px;/*2.5rem*/
}



/* Within a normal pagecontent container ? */
.main__contents > .widgetsblock,
.foldabledeeplink__text > .widgetsblock
{
  /* distance also used by .embeddedobject */
  margin-top: 50px;
  margin-bottom: 50px;
}

/* A widgetsblock without wrapper (transparent on the grey background)
   Used by 'Project Portal - Browse Projects'
*/
.main > .widgetsblock
{
}



h4 + p
{
  margin-top: 0;
}

/* Duplicated classnames here are to get higher specificy than:
   .main__contents--content8col .foldabledeeplink__text>*
*/
ul.unordered.unordered,
ul.checkmarks.checkmarks,
ul.stripemarks.stripemarks,
ol.ordered.ordered,
li ul,
li ol
{
  list-style-type: none;
  margin-top: 30px/*3.0rem*/;
  padding-left: calc(var(--pageside-padding, 10px) + 25px);
}
html.utwente--ws2021 ul,
html.utwente--ws2021 ol
{
  margin-bottom: 0;
}

li ul,
li ol
{
  margin-left: 30px/*3.0rem*/;
  padding-left: 0;
}

ol.ordered,
li ol
{
  counter-reset: numlist;
}

li ul,
li ol
{
  margin-top: 0;
}

ul.unordered li,
ul.checkmarks li,
ul.stripemarks li,
ol.ordered li
{
  margin: 0;
  padding: 0;
  position: relative;
}

ul.unordered li:before
{
  color: var(--color-grey-dark);
  line-height: inherit;
  position: absolute;
}

ul.checkmarks > li:before
{
  color: var(--color-malibu-green);
  line-height: inherit;
  position: absolute;
  content: "\e931";
  font-family: 'UT-iconfont';
}
ul.stripemarks > li:before
{
  color: var(--color-grey-normal);
  line-height: inherit;
  position: absolute;
  content: "\e930";
  font-family: 'UT-iconfont';
}

ul.checkmarks > li + li,
ul.stripemarks > li + li
{
  padding-top: 10px;
}


/* FIXME shouldn't need the not rule, but the RTD doesn't set a direction */
html:not(dir) ul.unordered li:before,
html[dir=ltr] ul.unordered li:before,
html:not(dir) ul.checkmarks li:before,
html[dir=ltr] ul.checkmarks li:before,
html:not(dir) ul.stripemarks li:before,
html[dir=ltr] ul.stripemarks li:before
{
  right: calc(100% + 10px);
}


ol.ordered li:before
{
  box-sizing: border-box;
  color: var(--color-grey-dark);
  line-height: inherit;
  padding-right: 5px;
  position: absolute;
  right: 100%;
  min-width: 20px;
}

ul.unordered li:before
{
  content: "\25cf"; /* black circle */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}

ul.unordered ul li:before
{
  content: "\25cb"; /* white circle */
}

ul.unordered ul ul li:before
{
  content: "\25a0"; /* black square */
}

ul.unordered ul ul ul li:before
{
  content: "\25a1"; /* white square */
}

ol.ordered li:before
{
  counter-increment: numlist;
  content: counter(numlist, decimal) ".";
}

ol.ordered ol li:before
{
  counter-increment: numlist;
  content: counter(numlist, lower-alpha) ".";
}

ol.ordered ol ol li:before
{
  counter-increment: numlist;
  content: counter(numlist, lower-roman) ".";
}

a
{
  color: var(--rtd-link-color);
  text-decoration: underline;
}

html.site--mesa p.normal a,
html.site--mesa ul.unordered a,
html.site--mesa ul.ordered a,
html.site--techmed p.normal a,
html.site--techmed ul.unordered a,
html.site--techmed ul.ordered a,
html.site--digital p.normal a,
html.site--digital ul.unordered a,
html.site--digital ul.ordered a,
html.site--itc p.normal a,
html.site--itc ul.unordered a,
html.site--itc ul.ordered a
{
  color: var(--theme-color, var(--themecolor)); /* Fallback for ITC ws2016 */
}

a:hover
{
  color: var(--rtd-link-color-hover);
}

h4 a
{
  color: inherit;
  text-decoration: none;
}

/* Explicitly set font-weight for <b> tags, otherwise the wrong font might be selected */
b
{
  font-weight: 600;
}

.wh-rtd__img
{
  max-width: 100%;
}

.wh-rtd__img--floatleft
{
  margin: 0 10px 10px 0;
}
.wh-rtd__img--floatright
{
  margin: 0 0 10px 10px;
}

/* Small devices */

@media (max-width: 750px)
{
  .wh-rtd__img
  {
    max-width: 100%;
  }
}

/* Extra small devices */

@media (max-width: 480px)
{
  body
  {
    --rtd-h1-font:     700 40px/40px var(--rtd-heading-fontfamily);
    --rtd-h2-font:     700 18px/20px var(--rtd-heading-fontfamily);
    --rtd-h3-font:     700 15px/25px var(--rtd-heading-fontfamily);
 /* --rtd-h4-font:     600 15px/25px var(--rtd-heading-fontfamily); */

    --rtd-intro-font:  300 18px/20px var(--rtd-text-fontfamily);
  }

  /* Mobile defaults */
  h1
  {
    margin-top: 50px;/*5.0rem*/
  }

  .extraheading
  {
    font-size: 26px;/*2.6rem*/
    line-height: 26px;/*2.6rem*/
    margin-top: 50px;/*5.0rem*/
  }

  h2,
  .introtext
  {
    margin-top: 50px;/*5.0rem*/
  }

  h2:before
  {
    height: 50px;
    margin-top: -50px;
  }

  h3
  {
    margin-top: 50px;/*5.0rem*/
  }
}


/* FAQ */
.question
{
  font-size: 18px;/*1.8rm*/
  font-weight: 300;
}

.answer
{
  /* Use border-left, because padding-left and margin-left are set by main.scss */
  border-left: 15px solid transparent;
  margin-top: 15px;
}


.wh-rtd__tablecell
{
  padding-left:10px;
}

/* RTD preview embedded widget styling */
.widget--rtdpreview + .widget--rtdpreview
{
  margin-top: 20px;
}

.widget--rtdpreview .widget__award-text,
.widget--rtdpreview .widget__award-from,
.widget--rtdpreview .widget__listitem,
.widget--rtdpreview .widget__quote-text,
.widget--rtdpreview .widget__quote-from,
.widget--rtdpreview .widget__tabs-titletext,
.widget--rtdpreview .widget__title-subtitle
{
  display: block;
}

.widget--rtdpreview .widget__infoslide + .widget__infoslide
{
  margin-top: 20px;
}

.widget--rtdpreview .widget__photoslide
{
  height: 170px;
  width: 300px;
}

.widget--rtdpreview .widget__photoslide.widget__photoslide--roundthumb
{
  height: 100px;
  width: 100px;
}

.widget--rtdpreview .widget__quote-text:before
{
  content: "“";
}

.widget--rtdpreview .widget__quote-text:after
{
  content: "”";
}

.widget--rtdpreview .widget__tabs-page
{
  margin-left: 20px;
}

.embeddednewsevents__previewfilters ul,
.embeddednewsevents__previewfilters li
{
  list-style: none;
  margin: 0;
  padding: 0;
}

.embeddednewsevents__previewfilters li
{
  margin-left: 1em;
}

/* Table styling */
.wh-rtd__tablewrap
{
  max-width: 100%;
}

.wh-rtd__tablewrap__scrollcontainer
{
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.wh-rtd__tablewrap__scrollcontainer.ps-active-x
{
  padding-bottom: 20px;
}
.wh-rtd__tablewrap__scrollcontainer .ps__rail-x
{
  opacity: 1 !important;
  background-color: #d2d2d2 !important;
  height: 4px !important;
}
.wh-rtd__tablewrap__scrollcontainer .ps__thumb-x
{
  background-color: #666 !important;
  border-radius: 0 !important;
  height: 4px !important;
  bottom: 0px !important;
}

.wh-rtd__tablecell
{ /* for small font use more readable font see issue #1113 */
  font-family: Arial, Helvetica, sans-serif;
}
/* except headings */
.wh-rtd__tablecell h1,
.wh-rtd__tablecell h2,
.wh-rtd__tablecell h3,
.wh-rtd__tablecell h4
{
  font-family: "Univers Next W02", Arial, Helvetica, sans-serif;
}

table.withlines .wh-rtd__tablecell,
table.widewithlines .wh-rtd__tablecell,
table.zebra .wh-rtd__tablecell,
table.widezebra .wh-rtd__tablecell
{
  border-bottom: 1px solid #dcddde;
}
.wh-rtd__table th,
.wh-rtd__table td
{
  font-weight: normal;
}
table.withlines th,
table.withlines td,
table.widewithlines th,
table.widewithlines td,
table.zebra th,
table.zebra td,
table.widezebra th,
table.widezebra td
{
  padding: 11px 10px;
}
.wh-rtd__table th > *:first-child,
.wh-rtd__table td > *:first-child
{
  margin-top: 0;
}
.wh-rtd__table th > *:last-child,
.wh-rtd__table td > *:last-child
{
  margin-bottom: 0;
}
table.withlines .wh-rtd--hasrowheader th,
table.withlines .wh-rtd--hascolheader th,
table.withlines .wh-rtd--hascolheader td,
table.widewithlines .wh-rtd--hasrowheader th,
table.widewithlines .wh-rtd--hascolheader th,
table.widewithlines .wh-rtd--hascolheader td,
table.zebra .wh-rtd--hasrowheader th,
table.zebra .wh-rtd--hascolheader th,
table.zebra .wh-rtd--hascolheader td,
table.widezebra .wh-rtd--hasrowheader th,
table.widezebra .wh-rtd--hascolheader th,
table.widezebra .wh-rtd--hascolheader td,
table.withlines .wh-rtd--hasrowheader ~ tr th,
table.widewithlines .wh-rtd--hasrowheader ~ tr th,
table.zebra .wh-rtd--hasrowheader ~ tr th,
table.widezebra .wh-rtd--hasrowheader ~ tr th
{
  color: var(--color-grey-dark);
  font-size: 13px;
}

table.withlines .wh-rtd__tablecell,
table.zebra .wh-rtd__tablecell
{
  font-size: 13px;
}

table.withlines .wh-rtd--hasrowheader th,
table.withlines .wh-rtd--hascolheader td,
table.widewithlines .wh-rtd--hasrowheader th,
table.widewithlines .wh-rtd--hascolheader td,
table.zebra .wh-rtd--hasrowheader th,
table.zebra .wh-rtd--hascolheader td,
table.widezebra .wh-rtd--hasrowheader th,
table.widezebra .wh-rtd--hascolheader td,
table.withlines .wh-rtd--hasrowheader ~ tr th,
table.widewithlines .wh-rtd--hasrowheader ~ tr th,
table.zebra .wh-rtd--hasrowheader ~ tr th,
table.widezebra .wh-rtd--hasrowheader ~ tr th
{
  border-right: 1px solid var(--color-grey-lines);
}
.wh-rtd__tablecell.wh-rtd__tablecell.greencontent
{
  color: var(--color-malibu-green);
  background-color: rgba(63, 156, 53, 0.03);
  border-bottom: 1px solid var(--color-malibu-green);
}
.wh-rtd__tablecell.wh-rtd__tablecell.redcontent
{
  color: var(--color-mesa);
  background-color: rgba(236, 26, 56, 0.03);
  border-bottom: 1px solid var(--color-mesa);
}
table.widezebra tr:nth-of-type(2n)
{
  background: var(--color-grey-light);
}
table.widezebra tr:nth-of-type(2n+1)
{
  background: var(--color-white);
}


.widget__image
{
  cursor: pointer;
}

.widget__image-photo
{
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;

  position: relative;
}
