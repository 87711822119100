/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
.transportexperts-graph {
  position: relative;
  max-width: 2000px;
  margin: -15% 120px 0;
  opacity: 0;
  transition: opacity 300ms;
  /* Interaction fx: */
}
@media (min-width: 2000px) {
  .transportexperts-graph {
    margin-top: -13%;
  }
}
.transportexperts-graph--ready {
  opacity: 1;
}
.transportexperts-graph-labels {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms;
}
.transportexperts-graph--afterready .transportexperts-graph-labels {
  opacity: 1;
}
.transportexperts-graph svg {
  position: relative;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  z-index: 0;
}
.transportexperts-graph__grouplabel {
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
  z-index: 1;
  display: inline-flex;
  align-items: top;
  cursor: pointer;
}
.transportexperts-graph__grouplabel__infoicon {
  margin-left: 3px;
  font-size: 16px;
}
.transportexperts-graph__grouplabel:first-child {
  left: -5%;
  top: 17%;
}
.transportexperts-graph__grouplabel:nth-child(2) {
  right: -5%;
  top: 17%;
}
.transportexperts-graph__grouplabel:nth-child(3) {
  right: -5%;
  bottom: 17%;
}
.transportexperts-graph__grouplabel:nth-child(4) {
  left: -5%;
  bottom: 17%;
}
.transportexperts-graph__grouplabel--dogwood-rose {
  color: var(--color-dogwood-rose);
}
.transportexperts-graph__grouplabel--tahiti-gold {
  color: var(--color-tahiti-gold);
}
.transportexperts-graph__grouplabel--bondi-blue {
  color: var(--color-bondi-blue);
}
.transportexperts-graph__grouplabel--malibu-green {
  color: var(--color-malibu-green);
}
.transportexperts-graph__grouplabel--crimson-glory {
  color: var(--color-crimson-glory);
}
.transportexperts-graph__grouplabel--trendy-green {
  color: var(--color-trendy-green);
}
.transportexperts-graph__grouplabel--cool-black {
  color: var(--color-cool-black);
}
.transportexperts-graph__grouplabel__title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: clamp(18px, 2vw, 24px);
}
.transportexperts-graph--ready .transportexperts-graph__grouplabel {
  opacity: 1;
}
.transportexperts-graph .domain__circle {
  transform-origin: 0 0;
  cursor: pointer;
  transition: all 300ms;
}
.transportexperts-graph .domain__label {
  position: absolute;
  transition: opacity 300ms;
}
.transportexperts-graph .domain__label--left .domain__label__inner {
  right: 100%;
  justify-content: flex-end;
}
.transportexperts-graph .domain__label--outerup .domain__label__inner {
  transform: translateY(-50%);
}
.transportexperts-graph .domain__label--outerdown .domain__label__inner {
  transform: translateY(50%);
}
.transportexperts-graph .domain__label--right .domain__label__inner {
  left: 100%;
}
.transportexperts-graph .domain__label--top .domain__label__inner {
  bottom: 0;
}
.transportexperts-graph .domain__label--bottom .domain__label__inner {
  top: 0;
}
.transportexperts-graph .domain__label--topleft .domain__label__inner {
  justify-content: center;
  transform: translate(50%, -15px);
}
.transportexperts-graph .domain__label--topright .domain__label__inner {
  justify-content: center;
  transform: translate(-50%, -15px);
}
.transportexperts-graph .domain__label--bottomleft .domain__label__inner {
  justify-content: center;
  transform: translate(50%, 15px);
}
.transportexperts-graph .domain__label--bottomright .domain__label__inner {
  justify-content: center;
  transform: translate(-50%, 15px);
}
.transportexperts-graph .domain__label__inner {
  position: absolute;
  display: inline-block;
  z-index: 1;
  color: var(--color-grey-dark);
  min-width: 150px;
  font-weight: 600;
  font-size: clamp(11px, 1vw, 14px);
  line-height: 135%;
  text-align: center;
  display: inline-flex;
}
.transportexperts-graph .domain__label__inner span {
  display: inline-block;
  position: relative;
}
.transportexperts-graph__paths, .transportexperts-graph__grid, .transportexperts-graph__domains {
  transform-origin: center;
}
.transportexperts-graph .connection {
  transition-property: all;
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 1280px) {
  .transportexperts-graph .connection {
    stroke-width: 3px;
  }
}
.transportexperts-graph .dot {
  transform-origin: center;
  opacity: 0;
}
.transportexperts-graph .dot__circle, .transportexperts-graph .dot__path {
  transform-origin: 0 0;
}
@media (max-width: 1280px) {
  .transportexperts-graph .dot__circle, .transportexperts-graph .dot__path {
    stroke-width: 3px;
  }
}
.transportexperts-graph .dot__label {
  position: absolute;
  outline: 1px solid red;
  transform: translate(-50%, -50%);
}
.transportexperts-graph .dot-hover {
  opacity: 0;
  transition: opacity 300ms, stroke 300ms;
}
.transportexperts-graph .dot-hover .dot__path, .transportexperts-graph .dot-hover .dot__circle {
  transition: transform 300ms;
  fill: #fff !important;
  cursor: pointer;
}
.transportexperts-graph__grid, .transportexperts-graph__dots, .transportexperts-graph__paths, .transportexperts-graph__domains, .transportexperts-graph__dots-hover {
  transform-origin: center;
  transition: transform 700ms;
}
.transportexperts-graph--ready .transportexperts-graph__grid, .transportexperts-graph--ready .transportexperts-graph__paths, .transportexperts-graph--ready .transportexperts-graph__domains {
  transform: scale(1, 0.6);
}
.transportexperts-graph--ready .domain__circle {
  transform: scale(1, 1.667);
}
.transportexperts-graph .domain__circle.active, .transportexperts-graph .domain__circle.hover {
  fill: #fff;
}
.transportexperts-graph .domain__circle--dogwood-rose {
  stroke: #f5cce3;
  fill: #f5cce3;
}
.transportexperts-graph .domain__circle--dogwood-rose.hover {
  stroke: var(--color-dogwood-rose);
  fill: #fff;
}
.transportexperts-graph .domain__circle--dogwood-rose.active {
  stroke: var(--color-dogwood-rose);
  fill: var(--color-dogwood-rose);
}
.transportexperts-graph .domain__circle--tahiti-gold {
  stroke: #fbe4ce;
  fill: #fbe4ce;
}
.transportexperts-graph .domain__circle--tahiti-gold.hover {
  stroke: var(--color-tahiti-gold);
  fill: #fff;
}
.transportexperts-graph .domain__circle--tahiti-gold.active {
  stroke: var(--color-tahiti-gold);
  fill: var(--color-tahiti-gold);
}
.transportexperts-graph .domain__circle--bondi-blue {
  stroke: #cceaf0;
  fill: #cceaf0;
}
.transportexperts-graph .domain__circle--bondi-blue.hover {
  stroke: var(--color-bondi-blue);
  fill: #fff;
}
.transportexperts-graph .domain__circle--bondi-blue.active {
  stroke: var(--color-bondi-blue);
  fill: var(--color-bondi-blue);
}
.transportexperts-graph .domain__circle--malibu-green {
  stroke: #d9ebd7;
  fill: #d9ebd7;
}
.transportexperts-graph .domain__circle--malibu-green.hover {
  stroke: var(--color-malibu-green);
  fill: #fff;
}
.transportexperts-graph .domain__circle--malibu-green.active {
  stroke: var(--color-malibu-green);
  fill: var(--color-malibu-green);
}
.transportexperts-graph .domain__circle--crimson-glory {
  stroke: #efcdd2;
  fill: #efcdd2;
}
.transportexperts-graph .domain__circle--crimson-glory.hover {
  stroke: var(--color-crimson-glory);
  fill: #fff;
}
.transportexperts-graph .domain__circle--crimson-glory.active {
  stroke: var(--color-crimson-glory);
  fill: var(--color-crimson-glory);
}
.transportexperts-graph .domain__circle--trendy-green {
  stroke: #E7E4D1;
  fill: #E7E4D1;
}
.transportexperts-graph .domain__circle--trendy-green.hover {
  stroke: var(--color-trendy-green);
  fill: #fff;
}
.transportexperts-graph .domain__circle--trendy-green.active {
  stroke: var(--color-trendy-green);
  fill: var(--color-trendy-green);
}
.transportexperts-graph .domain__circle--cool-black {
  stroke: #99ABBF;
  fill: #99ABBF;
}
.transportexperts-graph .domain__circle--cool-black.hover {
  stroke: var(--color-cool-black);
  fill: #fff;
}
.transportexperts-graph .domain__circle--cool-black.active {
  stroke: var(--color-cool-black);
  fill: var(--color-cool-black);
}
.transportexperts-graph .domain__label {
  pointer-events: none;
  opacity: 0.3;
}
.transportexperts-graph .domain__label.active, .transportexperts-graph .domain__label.hover {
  pointer-events: auto;
  opacity: 1;
}
.transportexperts-graph .dot-hover.disabled {
  opacity: 0.1;
}
.transportexperts-graph .dot-hover.hover, .transportexperts-graph .dot-hover.active {
  opacity: 1;
}
.transportexperts-graph .dot-hover.hover .dot__path, .transportexperts-graph .dot-hover.hover .dot__circle {
  stroke-width: 5px;
}
@media (max-width: 1280px) {
  .transportexperts-graph .dot-hover.hover .dot__path, .transportexperts-graph .dot-hover.hover .dot__circle {
    stroke-width: 4px;
  }
}
.transportexperts-graph__dots-hover.person-hover .dot-hover:not(.hover) {
  opacity: 0.4;
}
.transportexperts-graph__dots-hover.person-hover .dot-hover:not(.hover) circle {
  opacity: 0 !important;
}
.transportexperts-graph__dots-hover.activedomains .dot-hover.active .dot__path, .transportexperts-graph__dots-hover.activedomains .dot-hover.active .dot__circle {
  stroke-width: 5px;
}
@media (max-width: 1280px) {
  .transportexperts-graph__dots-hover.activedomains .dot-hover.active .dot__path, .transportexperts-graph__dots-hover.activedomains .dot-hover.active .dot__circle {
    stroke-width: 4px;
  }
}
.transportexperts-graph .dot-hover.active.hover--disable {
  opacity: 0.2;
}
.transportexperts-graph .connection {
  stroke: #C3C3C3;
}
.transportexperts-graph .connection.hover {
  stroke-dashoffset: 0 !important;
  opacity: 1;
}
.transportexperts-graph .connection.domain-active.person-active {
  stroke-dashoffset: 0 !important;
  opacity: 1;
}
.transportexperts-graph .connection.hover--disable, .transportexperts-graph .connection.active--disable {
  stroke: #F3F3F3 !important;
}
.transportexperts-graph__paths.person-hover .connection:not(.hover) {
  stroke: #F3F3F3;
}