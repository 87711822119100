#suggest-container {
  margin: 0;
  position: absolute;
  left: 0;
  top: 100%;
}

.ui-autocomplete {
  list-style-type: none;
  margin: 0;
  padding: 15px;
  z-index: 100;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-lines);
  border-radius: 2px;
  margin-top: -5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.ui-autocomplete > li {
  cursor: pointer;
  color: #999;
}

.ui-autocomplete > li > span {
  color: var(--color-black);
}

.ui-autocomplete > li:hover {
  text-decoration: underline;
}