@charset "UTF-8";
@import "@mod-utwente_design/scss/config-colors.css";
.widget-didyouknow {
  background-color: var(--color-white);
  padding: 70px 25px 55px 45px;
  text-transform: uppercase;
  font-size: 26px;
  line-height: 100%;
  position: relative;
}
.widget-didyouknow__paperclip {
  position: absolute;
  background-image: url("./paperclip.png");
  background-size: contain;
  top: -12px;
  left: 35px;
  width: 21px;
  height: 62px;
}
.widget-didyouknow::before, .widget-didyouknow::after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
}
.widget-didyouknow::before {
  right: 15px;
  bottom: 100%;
  border-bottom: 5px solid #dcddde;
  border-left: 100px solid transparent;
}
.widget-didyouknow::after {
  left: 15px;
  top: 100%;
  border-top: 5px solid #dcddde;
  border-right: 150px solid transparent;
}
.widget-didyouknow__text {
  color: var(--color-black);
  position: relative;
  font-weight: 300;
}
.widget-didyouknow__text::before, .widget-didyouknow__text::after {
  font-size: 45px;
  font-weight: 500;
}
.widget-didyouknow__text::before {
  display: inline-block;
  position: absolute;
  left: -23px;
  padding-top: 17px;
  content: "“";
}
.widget-didyouknow__text::after {
  content: "”";
  padding-left: 4px;
  position: relative;
  top: 14px;
}