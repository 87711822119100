/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*
                                           Links Panel
      Design -> Image size / Image width / Width PadV PadH / Visible at width
         320 ->  320 x 137 / wide        / fluid      45px 15px /             .. t/m 479px
         720 ->  750 x 320 / wide        / 570(12col) 45px 30px   / 480px (xsmall) t/m 959px
1080 en 1280 ->  930 x 400 / 12col       / 400        45px 30px   / 960px (medium) t/m ..
        1440 -> 1110 x 400 / 12col       / 474        45px 30px


Line when:
- widget below isn't BLOCK and widget layout is mobile

*/
.embeddedobject--linkswithimage {
  contain: layout paint style;
}

.linkswithimage__image {
  background-size: cover;
}

.linkswithimage__image::before {
  display: block;
}

.linkswithimage__linkspanel {
  padding: 45px 30px;
  background-color: var(--color-white);
  color: #1e2328;
}

.linkswithimage__linkspanel p:first-child {
  margin-top: 0;
}

.linkswithimage__linkspanel__title {
  font: 300 26px/26px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: #1e2328;
}
@media (max-width: 479px) {
  .linkswithimage__linkspanel__title {
    font: 300 18px / 20px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
  html[lang=el] .linkswithimage__linkspanel__title {
    font-family: Roboto Condensed;
  }
}

.linkswithimage__linkspanel__title + .linkswithimage__linkspanel__links {
  margin-top: 20px;
}

.linkswithimage__linkspanel__title b {
  font-weight: 700;
}

ul.linkswithimage__linkspanel__links,
.linkswithimage__linkspanel__links li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.linkswithimage__linkspanel__links li + li {
  margin-top: 11px; /*  23px visually... measure using distance between uppercase letter on two links minus lineheight */
}

.linkswithimage__linkspanel__links a {
  text-decoration: underline;
  color: inherit;
}

.linkswithimage__linkspanel__links li {
  display: flex;
  align-items: baseline;
}

.linkswithimage__linkspanel__links li .icon {
  flex: none;
  width: 12px;
  height: 12px;
  color: #3f9c35;
  font-size: 14px;
  margin-right: 10px;
  /*
    position: relative;
    top: 3px;
  */
}

@media (max-width: 479px) {
  .embeddedobject--linkswithimage {
    border-bottom: 1px solid #afb1b3;
  }
  .embeddedobject--linkswithimage {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }
  .linkswithimage__linkspanel {
    padding: 45px 15px;
  }
  .linkswithimage__image::before {
    content: "";
    padding-top: 42.8125%;
  }
  .embeddedobject--notopaquebelow {
    border-bottom: 1px solid #afb1b3;
  }
}
@media (min-width: 480px) and (max-width: 959px) {
  .embeddedobject--linkswithimage {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }
  .linkswithimage__linkspanel.linkswithimage__linkspanel {
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #afb1b3;
    margin-top: -50px;
    padding: 40px 30px;
  }
}
@media (min-width: 480px) and (max-width: 959px) and (max-width: 750px) {
  .linkswithimage__linkspanel.linkswithimage__linkspanel {
    margin-left: 55px;
    margin-right: 55px;
  }
}
@media (min-width: 480px) and (max-width: 959px) and (max-width: 480px) {
  .linkswithimage__linkspanel.linkswithimage__linkspanel {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (min-width: 480px) and (max-width: 959px) and (min-width: 0) {
  .linkswithimage__linkspanel.linkswithimage__linkspanel {
    margin-left: var(--pageside-padding);
    margin-right: var(--pageside-padding);
  }
}
@media (min-width: 480px) and (max-width: 959px) {
  .linkswithimage__image::before {
    content: "";
    padding-top: 42.6667%;
  }
}
@media (min-width: 960px) {
  .embeddedobject--linkswithimage.embeddedobject--linkswithimage.embeddedobject--linkswithimage {
    --layout: 12col;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 960px;
    width: var(--page-contentwidth-12col);
    padding-top: 50px;
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .embeddedobject--linkswithimage.embeddedobject--linkswithimage.embeddedobject--linkswithimage {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 960px) and (min-width: 1440px) {
  .page--toplevelhome .embeddedobject--linkswithimage.embeddedobject--linkswithimage.embeddedobject--linkswithimage {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 960px) {
  .linkswithimage__linkspanel {
    position: absolute;
    top: 0;
    right: 65px;
    width: 400px;
    border: 1px solid #afb1b3;
    padding: 45px 30px;
  }
  .linkswithimage__image::before {
    content: "";
    padding-top: 43%;
  }
}
@media (min-width: 1440px) {
  .linkswithimage__linkspanel {
    right: 80px;
    width: 400px;
  }
}