/***************************************************************
*
*  UT Application Icons font
*
*/

@font-face {
  font-family: 'UT-application-icons';
  src: url('ut-application-icons.ttf?nvbf7p') format('truetype'),
       url('ut-application-icons.woff?nvbf7p') format('woff'),
       url('ut-application-icons.svg?nvbf7p#UT-application-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ut-appicon_"], [class*=" ut-appicon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ut-application-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ut-appicon_01_mail_48px:before {
  content: "\e900";
}
.ut-appicon_02_studielink_48px:before {
  content: "\e901";
}
.ut-appicon_03_osiris_48px:before {
  content: "\e902";
}
.ut-appicon_04_admission:before {
  content: "\e903";
}
.ut-appicon_05_result_48px:before {
  content: "\e904";
}
.ut-appicon_06_payment_48px:before {
  content: "\e905";
}
.ut-appicon_07_arrange_payment_48px:before {
  content: "\e906";
}
.ut-appicon_08_enrolment_48px:before {
  content: "\e907";
}
.ut-appicon_09_statement_48px:before {
  content: "\e908";
}
.ut-appicon_10_kickin_48px:before {
  content: "\e909";
}
.ut-appicon_11_start_48px:before {
  content: "\e90a";
}
