.embeddedobject__programmelist {
  margin: 30px 0 0 0;
  padding: 0;
  width: 100%;
}
.embeddedobject__programmelist tr {
  margin: 0;
  padding: 0;
}
.embeddedobject__programmelist td {
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: top;
}
.embeddedobject__programmelist-timespan {
  width: 120px;
}
.embeddedobject__programmelist-description > *:first-child {
  margin-top: 0;
}