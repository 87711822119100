.consentblocker {
  background: rgba(30, 35, 40, 0.7);
  cursor: default;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  font-family: "Univers Next W02", Arial, Helvetica, sans-serif;
}
.consentblocker__title, .consentblocker__description {
  max-width: 550px;
}
.consentblocker__title {
  font-size: 14px;
  font-weight: 700;
}
.consentblocker a {
  color: inherit;
}

.embeddedobject__play,
.widget__photoslide-video.widget__photoslide--poster:before,
.carrousel__cell-playbutton {
  display: none;
}
html.utwente--consent .embeddedobject__play,
html.utwente--consent .widget__photoslide-video.widget__photoslide--poster:before,
html.utwente--consent .carrousel__cell-playbutton {
  display: block;
}

.consentblocker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}