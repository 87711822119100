@font-face {
  font-family: 'UT-iconfont-48px';
  src: url('ut-iconfont-48px/ut-iconfont-48px.ttf?jarixv') format('truetype'),
       url('ut-iconfont-48px/ut-iconfont-48px.woff?jarixv') format('woff'),
       url('ut-iconfont-48px/ut-iconfont-48px.svg?jarixv#UT-iconfont-48px') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ut-largeicon_"], [class*=" ut-largeicon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'UT-iconfont-48px' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*font-size: 48px;*/
}

/* workaround chrome bug https://gitlab.webhare.com/utwente/serviceportal/-/issues/332 */
[class^="ut-largeicon_"]::before, [class*=" ut-largeicon_"]::before {
  color: inherit;
}

html
{
  /* Used by utwente_base */
  --uticon-large-font:                   'UT-iconfont-48px' !important;
  --uticon-large-admission-requirements: "\e900";
  --uticon-large-phd:                    "\e901";
  --uticon-large-executive-education:    "\e902";
  --uticon-large-events:                 "\e903";
  --uticon-large-person:                 "\e904";
  --uticon-large-paperclip:              "\e905";
}


.ut-largeicon_01-admission-requirements:before {
  content: "\e900";
}
.ut-largeicon_02-phd:before {
  content: "\e901";
}
.ut-largeicon_03-executive-education:before {
  content: "\e902";
}
.ut-largeicon_04-events:before {
  content: "\e903";
}
.ut-largeicon_05-person:before {
  content: "\e904";
}
.ut-largeicon_06-paperclip:before {
  content: "\e905";
}
.ut-largeicon_07-vcard:before {
  content: "\e906";
}
.ut-largeicon_08-diploma:before {
  content: "\e907";
}
.ut-largeicon_09-people-join:before {
  content: "\e908";
}
.ut-largeicon_10-company:before {
  content: "\e909";
}
.ut-largeicon_11-mail:before {
  content: "\e90a";
}
.ut-largeicon_12-pointer:before {
  content: "\e90b";
}
.ut-largeicon_13-services:before {
  content: "\e90c";
}
.ut-largeicon_14-news:before {
  content: "\e90d";
}
.ut-largeicon_15-binding-study-advice:before {
  content: "\e90e";
}
.ut-largeicon_16-price-tag:before {
  content: "\e90f";
}
.ut-largeicon_17-admission-requirements:before {
  content: "\e910";
}
.ut-largeicon_18-exchange-programs:before {
  content: "\e911";
}
.ut-largeicon_19-accreditation:before {
  content: "\e912";
}
.ut-largeicon_20-high-tea:before {
  content: "\e913";
}
.ut-largeicon_21-chat:before {
  content: "\e914";
}
.ut-largeicon_22-plus-round:before {
  content: "\e915";
}
.ut-largeicon_23-info-round:before {
  content: "\e916";
}
.ut-largeicon_24-questionmark-round:before {
  content: "\e917";
}
.ut-largeicon_25-price-round:before {
  content: "\e918";
}
.ut-largeicon_26-search:before {
  content: "\e919";
}
.ut-largeicon_27-flag:before {
  content: "\e91a";
}
.ut-largeicon_28-education:before {
  content: "\e91b";
}
.ut-largeicon_29-read:before {
  content: "\e91c";
}
.ut-largeicon_30-globe:before {
  content: "\e91d";
}
.ut-largeicon_31-phone:before {
  content: "\e91e";
}
.ut-largeicon_32-trophy:before {
  content: "\e91f";
}
.ut-largeicon_33-ongoing:before {
  content: "\e920";
}
.ut-largeicon_34-settings:before {
  content: "\e921";
}
.ut-largeicon_35-people:before {
  content: "\e922";
}
.ut-largeicon_36-edit-pen:before {
  content: "\e923";
}
.ut-largeicon_37-donation:before {
  content: "\e924";
}
.ut-largeicon_38-lightbulb-idea:before {
  content: "\e925";
}
.ut-largeicon_39-book:before {
  content: "\e926";
}
.ut-largeicon_40-goal:before {
  content: "\e927";
}
.ut-largeicon_41-student_backpack:before {
  content: "\e928";
}
.ut-largeicon_42-study-transfer:before {
  content: "\e929";
}
.ut-largeicon_43-brochure:before {
  content: "\e92a";
}
.ut-largeicon_44-clock-duration:before {
  content: "\e92b";
}
.ut-largeicon_45-transfer:before {
  content: "\e92c";
}
.ut-largeicon_46-thumbs-up:before {
  content: "\e92d";
}
.ut-largeicon_47-knowledge-safety:before {
  content: "\e92e";
}
.ut-largeicon_48-export-control:before {
  content: "\e92f";
}
.ut-largeicon_49-compliance:before {
  content: "\e930";
}
.ut-largeicon_50-guides-and-tools:before {
  content: "\e931";
}
.ut-largeicon_51-int-cooperation-research-and-educational:before {
  content: "\e932";
}
.ut-largeicon_52-int-business-cooperation-knowledge-transfer:before {
  content: "\e933";
}
.ut-largeicon_53-recruitment-and-int-staff-mobility:before {
  content: "\e934";
}
.ut-largeicon_54-incident:before {
  content: "\e935";
}
.ut-largeicon_55-biodiversity:before {
  content: "\e936";
}
.ut-largeicon_56-energy:before {
  content: "\e937";
}
.ut-largeicon_57-events:before {
  content: "\e938";
}
.ut-largeicon_58-finance:before {
  content: "\e939";
}
.ut-largeicon_59-food-and-drinks:before {
  content: "\e93a";
}
.ut-largeicon_60-procurement-and-purchasing:before {
  content: "\e93b";
}
.ut-largeicon_61-travel-and-mobility:before {
  content: "\e93c";
}
.ut-largeicon_62-waste:before {
  content: "\e93d";
}
.ut-largeicon_63-water:before {
  content: "\e93e";
}
.ut-largeicon_64-light-switch:before {
  content: "\e93f";
}
.ut-largeicon_65-erlenmyer:before {
  content: "\e940";
}

.ut-largeicon_hr01-ziek-melden:before {
  content: "\e941";
}
.ut-largeicon_hr05-alle-vacatures:before {
  content: "\e942";
}
.ut-largeicon_hr12-id-bewijs-paspoort:before {
  content: "\e944";
}
.ut-largeicon_hr20-werktijden:before {
  content: "\e945";
}
.ut-largeicon_hr25-thuiswerk-rooster:before {
  content: "\e946";
}
.ut-largeicon_hr31-actuele-verloningsperiode:before {
  content: "\e947";
}
.ut-largeicon_hr38-sluitingsdag:before {
  content: "\e943";
}
.ut-largeicon_hr39-ouderschapsverlof:before {
  content: "\e948";
}
.ut-largeicon_hr51-loonschalen:before {
  content: "\e949";
}
.ut-largeicon_hr57-bezettingsoverzicht:before {
  content: "\e94a";
}
.ut-largeicon_hr61-Jobalert:before {
  content: "\e94b";
}
.ut-largeicon_hr64-declaratie:before {
  content: "\e94c";
}
.ut-largeicon_hr90-fiets:before {
  content: "\e94d";
}
.ut-largeicon_hr91-wagenpark:before {
  content: "\e94e";
}
.ut-largeicon_hr107-parkeerkosten:before {
  content: "\e94f";
}
.ut-largeicon_hr109-bedrijfseigendommen:before {
  content: "\e950";
}
.ut-largeicon_hr111-rekening:before {
  content: "\e951";
}
.ut-largeicon_hr113-individueel-keuze-budget:before {
  content: "\e952";
}
.ut-largeicon_hr121-alle-loonmutaties:before {
  content: "\e953";
}
.ut-largeicon_hr147-cao:before {
  content: "\e954";
}
.ut-largeicon_hr155-360-graden-feedback:before {
  content: "\e955";
}
.ut-largeicon_hr157-verlof:before {
  content: "\e956";
}
.ut-largeicon_hr171-tol:before {
  content: "\e957";
}
.ut-largeicon_hr173-medewerker-in-dienst:before {
  content: "\e958";
}
.ut-largeicon_hr177-mw-in-dienst:before {
  content: "\e959";
}
.ut-largeicon_hr181-sollicitant:before {
  content: "\e95a";
}
.ut-largeicon_hr182-hrm:before {
  content: "\e95b";
}
.ut-largeicon_hr184-delegeren:before {
  content: "\e95c";
}
.ut-largeicon_hr187-noodgeval:before {
  content: "\e95d";
}
.ut-largeicon_hr188-pz:before {
  content: "\e95e";
}
.ut-largeicon_hr190-familie:before {
  content: "\e95f";
}
.ut-largeicon_hr191-wie-is-wie:before {
  content: "\e960";
}
.ut-largeicon_hr199-bos-bloemen:before {
  content: "\e961";
}
.ut-largeicon_hr201-verjaardag:before {
  content: "\e962";
}
.ut-largeicon_hr202-sportabonnement-beeindigen:before {
  content: "\e963";
}
.ut-largeicon_hr204-pomp:before {
  content: "\e964";
}
.ut-largeicon_hr205-sleutels:before {
  content: "\e965";
}
.ut-largeicon_66-ball:before {
  content: "\e966";
}
.ut-largeicon_67-masks:before {
  content: "\e967";
}
.ut-largeicon_68-check:before {
  content: "\e968";
}
