@import "@mod-utwente_design/scss/config-colors.css";
.embeddedobject-featuredpages ul {
  list-style-type: none;
  margin: 30px 0 0;
  padding: 0;
  border-top: 1px solid #dcddde;
}
.embeddedobject-featuredpages a {
  display: flex;
  text-decoration: none;
  position: relative;
  padding: 15px 0;
  align-items: center;
  z-index: 0;
  color: #1e2328;
}
.embeddedobject-featuredpages .hoverbarfx::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -20px;
  bottom: 0;
  width: 0;
  background-color: #f7f8f8;
  transition: width 300ms;
  z-index: -1;
}
.embeddedobject-featuredpages .hoverbarfx:hover::before {
  width: 100%;
}
.embeddedobject-featuredpages__item {
  border-bottom: 1px solid #dcddde;
}
.embeddedobject-featuredpages__item__image {
  flex: none;
  display: flex;
  align-items: center;
  width: 60px;
  font-size: 48px;
  margin-right: 15px;
}
.embeddedobject-featuredpages__item__image img {
  width: 58px;
  height: auto;
}
.embeddedobject-featuredpages__item__text {
  align-self: flex-start;
  padding-right: 40px;
}
.embeddedobject-featuredpages__item__text b {
  font-size: 18px;
  font-weight: 700;
}
.embeddedobject-featuredpages__item__arrow {
  font-size: 16px;
  position: absolute;
  right: 12px;
  margin-top: -8px;
  top: 50%;
  color: var(--color-grey-normal);
  transition: color 0.3s;
}
.embeddedobject-featuredpages__item:hover .embeddedobject-featuredpages__item__arrow {
  color: inherit;
}