/*
In numbers widget

NOTES:
- In tablet/desktop layout we give the value column a fixed width so it can align with other innumbers widgets
- In mobile layout we allow the value column to shrink to fit (using our grid + display:contents;/--innumbers-row trick)

FIXME: or should we use a table for this??

DOM:

.innumbers
.innumbers--hasicons
.innumbers--withpercentageindicator
    .innumbers__title
    .innumbers__table
*/

.innumbers
{
  padding-bottom: 30px;

  --innumbers-item-value-columnwidth: 190px;
  --innumbers-items-spacing:    10px;

  --innumbers-item-column-spacing: 20px;

  --innumbers-item-icon-color:  var(--ffthemecolor);
  --innumbers-item-icon-size:   60px;

  --innumbers-item-value-color: var(--ffthemecolor);
  --innumbers-item-value-font:  700 58px var(--rtd-heading-fontfamily);
  --innumbers-item-value-font-scaling: 700 clamp(22px, 7vw, 58px) var(--rtd-heading-fontfamily);

  --innumbers-item-title-color: var(--rtd-text-color);
  --innumbers-item-title-font:  700 18px/20px var(--rtd-heading-fontfamily);

  --innumbers-item-note-color:  var(--rtd-text-color);
  --innumbers-item-note-font:   normal 16px/20px var(--rtd-heading-fontfamily);

  --innumbers-subheading-font:  700 26px var(--rtd-heading-fontfamily);
}

@media (max-width: 1000px)
{
  .innumbers
  {
    --innumbers-item-value-columnwidth: min-content;
    --innumbers-item-column-spacing:    12px;
    --innumbers-item-icon-size:         clamp(40px, 7vw, 60px);
    --innumbers-item-note-font:         normal 14px/19px var(--rtd-heading-fontfamily);
  }

  .innumbers__row__icon.innumbers__row__icon
  {
    margin-right:  8px;
  }

  .innumbers__row__note
  {
    margin-top: 3px;
  }
}

.innumbers__note
{
  /* FIXME: staat niet in het nieuwe design... feature verwijderen of stylen? */
}



.innumbers__table
{
  display: grid;
  grid-template-columns: var(--innumbers-item-value-columnwidth) var(--innumbers-item-column-spacing) min-content 1fr; /* value spacer icon title+note */
  row-gap: var(--innumbers-items-spacing);
  align-items: center;
}

.innumbers__heading .innumbers__row__title
{
  font: var(--innumbers-subheading-font);
  text-transform: uppercase;
  margin-top: 30px;
}

.innumbers__heading
{
  grid-column: 1 / -1;
}

.innumbers__row
{
  display: contents;
}

/* emulate subgrid */
  .innumbers__row > *
, .innumbers__row--statistic::before
{
  grid-row: var(--innumbers-row);
}

.innumbers__row--total
{
  grid-column: 1 / -1;

  margin-top: 22px;
  border-top: 1px solid var(--ffthemecolor);
  padding-top: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.innumbers__total__label
{
  grid-column: 1;
  color: var(--innumbers-item-title-color);
  font:  var(--innumbers-item-title-font);
}

.innumbers__total__value
{
  grid-column: 2 / span 3;
  color: var(--innumbers-item-value-color);
  font:  var(--innumbers-item-value-font);
  font:  var(--innumbers-item-value-font-scaling);
  text-align: right;
}


.innumbers__row__icon
{
  grid-column: 3;
  width:  var(--innumbers-item-icon-size);
  height: var(--innumbers-item-icon-size);
  fill:   var(--innumbers-item-icon-color);
  margin-right: var(--innumbers-item-column-spacing);

  display: flex;
  align-items: center;
  justify-content: center;
}

.innumbers__row__icon svg
{
  width:  100%;
  height: 100%;
  object-fit: contain;
}
.innumbers__row__icon.innumbers__icon--trophy svg
{
  width:  55%;
  height: 55%;
}
.innumbers__row__value
{
  grid-column: 1;
  color: var(--innumbers-item-value-color);
  font:  var(--innumbers-item-value-font);
  font:  var(--innumbers-item-value-font-scaling);
  text-align: right;
}

.innumbers__row__text
{
  grid-column: 4;
}

    .innumbers__row__title
    {
      color: var(--innumbers-item-title-color);
      font:  var(--innumbers-item-title-font);
    }

    .innumbers__row__note
    {
      color: var(--innumbers-item-note-color);
      font:  var(--innumbers-item-note-font);
    }


/* Without icons and percentage we use a small grey line as visual divider */
.innumbers--withouticons .innumbers__row__text
{
  border-left: 1px solid #D8D8D8;
  height: 100%;
  padding-left: var(--innumbers-item-column-spacing);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Without percentage */
.innumbers--withpercentageindicator .innumbers__row__text
{
  border-left: 1px solid var(--innumbers-item-icon-color);
  height: 100%;
  padding-left: var(--innumbers-item-column-spacing);

  /* now the percentage indicator creates our own background color we need whitespace within this area */
  padding-top: 15px;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.innumbers--withpercentageindicator .innumbers__row::before
{
  content: "";
  grid-column: 4;

  width: var(--innumbers-row-percentage);
  height: 100%;
  background-color: var(--ffthemecolor);
  opacity: 0.15;
}





.innumbers__row__value
{
  opacity: 0;
  transform: translate(-100%);
  transition: transform 0.5s ease-out
            , opacity   0.7s;
  transition-delay: 0.3s;
}

  .innumbers__row.innumbers--animate-in .innumbers__row__value
, .wh-rtd .innumbers__row__value
{
  opacity: 1;
  transform: translate(0%);
}


.innumbers--withpercentageindicator .innumbers__row::before
{
  width: 0;
  transition: width 0.35s ease-out;
  transition-delay: 0.5s;
}

  .innumbers--withpercentageindicator .innumbers__row.innumbers--animate-in::before
, .wh-rtd .innumbers--withpercentageindicator .innumbers__row::before
{
  width: var(--innumbers-row-percentage);
}


.innumbers__total__value
{
  opacity: 0;
  transform: translate(100%);
  transition: transform 0.5s ease-out
            , opacity   0.7s;
  transition-delay: 0.3s;
}

  .innumbers--animate-in .innumbers__total__value
, .wh-rtd .innumbers__total__value
{
  opacity: 1;
  transform: translate(0%);
}
