/* clone of above, but they are only valid in Webkit */
input[type="file"]
{
  font: inherit;
}
  input[type="file"]::shadow button
, input[type="file"]::-webkit-file-upload-button
{
  color: var(--color-white);

  /* reset default browser styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border: none;
  border-radius: 0;

  display: inline-block;
  margin: 0;

  font: inherit;

  height: 38px;
  line-height: 38px;

  min-width: 100px;

  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  cursor: pointer;

  background-color: var(--color-grey-lines);
}
  input[type="file"]:hover::shadow button
, input[type="file"]:hover::-webkit-file-upload-button
{
  background-color: #a9abad;
}

  input[type="file"]::shadow button
, input[type="file"]::-webkit-file-upload-button
, input[type="file"]::-ms-browse
, input[type="file"]
{
  border: 0 !important;

  background-color: #92c8ee !important;
  color: var(--color-white) !important;
}
