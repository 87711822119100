.embeddedobject-tablewithtabs__wrapper {
  background-color: var(--color-grey-lighter);
  padding-top: 50px;
  padding-bottom: 20px;
  position: relative;
  z-index: 0;
}
.embeddedobject-tablewithtabs__wrapper::before {
  content: "";
  background-color: var(--color-grey-lighter);
  display: block;
  position: absolute;
  top: 0;
  right: -95px;
  bottom: 0;
  left: -95px;
  z-index: -1;
}
@media (max-width: 749px) {
  html.utwente--ws2021 .embeddedobject-tablewithtabs__wrapper::before {
    right: calc(-8.333vw - 30px);
    left: calc(-8.333vw - 30px);
  }
}
@media (max-width: 480px) {
  html.utwente--ws2021 .embeddedobject-tablewithtabs__wrapper::before {
    right: -20px;
    left: -20px;
  }
}
.embeddedobject-tablewithtabs h3.embeddedobject-tablewithtabs__heading {
  margin-bottom: 40px;
  margin-top: 0;
}
.embeddedobject-tablewithtabs__description {
  margin-bottom: 35px;
}
h3 + .embeddedobject-tablewithtabs__description {
  margin-top: -35px;
}
@media (max-width: 550px) {
  h3 + .embeddedobject-tablewithtabs__description {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.embeddedobject-tablewithtabs__description > *:first-child {
  margin-top: 0;
}
.embeddedobject-tablewithtabs ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.embeddedobject-tablewithtabs .wh-details__summary, .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line,
.embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink {
  font-weight: 700;
  font-size: 15px;
  display: flex;
  font-weight: 700;
  line-height: 100%;
  padding: 16px 48px 15px 60px;
  text-decoration: none;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-lines);
}
.embeddedobject-tablewithtabs .wh-details__summary .title, .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line .title,
.embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink .title {
  flex: 1 1 auto;
  font-weight: 700;
}
.embeddedobject-tablewithtabs .wh-details__summary > i, .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line > i,
.embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink > i {
  font-size: 12px;
  width: 16px;
}
.embeddedobject-tablewithtabs .wh-details__summary .ec, .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line .ec,
.embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink .ec {
  font-weight: 400;
  color: var(--color-grey-normal);
  font-size: 15px;
  flex: none;
  width: 35px;
  text-align: right;
  margin-right: 25px;
}
@media (max-width: 960px) {
  .embeddedobject-tablewithtabs .wh-details__summary .ec, .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line .ec,
  .embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink .ec {
    margin-right: -5px;
  }
}
.embeddedobject-tablewithtabs .wh-details__summary i, .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line i,
.embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink i {
  flex: none;
  color: var(--color-grey-normal);
  transition: color 300ms;
}
.embeddedobject-tablewithtabs .wh-details__summary:hover i, .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line:hover i,
.embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink:hover i {
  color: var(--color-black);
}
.embeddedobject-tablewithtabs .wh-details__summary::before, .embeddedobject-tablewithtabs .wh-details__summary::after {
  font-size: 12px;
}
@media (max-width: 960px) {
  .embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__tab__title.wh-details__summary > .ec {
    margin-right: -5px;
  }
}
.embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line,
.embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink {
  padding-right: 10px;
  color: inherit;
}
.embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line > .ec,
.embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink > .ec {
  margin-right: 45px;
}
@media (max-width: 960px) {
  .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line > .ec,
  .embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink > .ec {
    margin-right: 20px;
  }
}
.embeddedobject-tablewithtabs__tab {
  padding-bottom: 40px;
}
.embeddedobject-tablewithtabs__tab > .wh-details {
  position: relative;
}
.embeddedobject-tablewithtabs__tab > .wh-details > .wh-details__summary {
  position: relative;
  border: 0 none;
  padding-bottom: 12px;
  padding-right: 80px;
}
.embeddedobject-tablewithtabs__tab > .wh-details > .wh-details__summary .wh-details__summary__hline {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -3px;
  border-bottom: 1px solid var(--color-grey-lines);
}
.embeddedobject-tablewithtabs__tab > .wh-details > .wh-details__container {
  padding-bottom: 3px;
}
.embeddedobject-tablewithtabs__tab__vline {
  content: "";
  display: block;
  position: absolute;
  top: 18px;
  left: 30px;
  bottom: 0;
  border-left: 1px solid var(--theme-color-cta);
}
.embeddedobject-tablewithtabs__tab__vline::before {
  content: "";
  background-color: var(--theme-color-cta);
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
  left: -3px;
  top: 0;
  border-radius: 50%;
}
.embeddedobject-tablewithtabs__tab__title::after, .embeddedobject-tablewithtabs__tab__title::before {
  margin-right: 30px;
}
.embeddedobject-tablewithtabs__fields {
  border-bottom: 1px solid var(--color-grey-lines);
  border-left: 1px solid var(--color-grey-lines);
  border-right: 1px solid var(--color-grey-lines);
  background-color: var(--color-white);
  margin-top: 3px !important;
}
.embeddedobject-tablewithtabs__fields > li {
  padding: 0 30px;
  position: relative;
  z-index: 1;
}
.embeddedobject-tablewithtabs__fields > li::before {
  content: "";
  border: 1px solid var(--theme-color-cta);
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 26px;
  bottom: -3px;
  background-color: #fff;
  border-radius: 50%;
}
.embeddedobject-tablewithtabs__fields > li:last-child .wh-details__summary {
  border-bottom: 0 none;
}
.embeddedobject-tablewithtabs__fields .wh-details__summary, .embeddedobject-tablewithtabs__fields a.embeddedobject-tablewithtabs__fields__line,
.embeddedobject-tablewithtabs__fields .embeddedobject-tablewithtabs__fields__line--nolink {
  font-size: 18px;
  padding-left: 30px;
}
@media (max-width: 960px) {
  .embeddedobject-tablewithtabs__fields .wh-details__summary, .embeddedobject-tablewithtabs__fields a.embeddedobject-tablewithtabs__fields__line,
  .embeddedobject-tablewithtabs__fields .embeddedobject-tablewithtabs__fields__line--nolink {
    font-size: 15px;
  }
}
.embeddedobject-tablewithtabs__fields .wh-details__container {
  margin-top: -1px;
}
.embeddedobject-tablewithtabs__fields .wh-details__container__inner {
  background-color: var(--color-grey-lighter);
  border-top: 1px solid var(--color-grey-lines);
  padding: 15px 30px;
  border-bottom: 1px solid var(--color-grey-dark);
}
.embeddedobject-tablewithtabs__fields .wh-details__container__inner p {
  line-height: 190%;
}
.embeddedobject-tablewithtabs__fields .wh-details__container__inner a.embeddedobject-tablewithtabs__fields__line {
  color: var(--color-dark-grey);
  text-underline-position: under;
  font-size: 18px;
  font-weight: 300;
}
.embeddedobject-tablewithtabs__fields .wh-details__container__inner a.embeddedobject-tablewithtabs__fields__line:hover {
  color: var(--color-dark-grey-hover);
}
@media (max-width: 550px) {
  .embeddedobject-tablewithtabs__wrapper {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  .embeddedobject-tablewithtabs h3.embeddedobject-tablewithtabs__heading {
    margin-bottom: 10px;
  }
  .embeddedobject-tablewithtabs .wh-details__summary, .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line,
  .embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink {
    padding: 16px 48px 15px 20px;
  }
  .embeddedobject-tablewithtabs a.embeddedobject-tablewithtabs__fields__line,
  .embeddedobject-tablewithtabs .embeddedobject-tablewithtabs__fields__line--nolink {
    padding-right: 12px;
  }
  .embeddedobject-tablewithtabs__tab {
    padding-bottom: 20px;
  }
  .embeddedobject-tablewithtabs__tab > .wh-details > .wh-details__summary {
    padding-left: 35px;
    padding-right: 55px;
  }
  .embeddedobject-tablewithtabs__tab__title::after, .embeddedobject-tablewithtabs__tab__title::before {
    margin-right: 0;
  }
  .embeddedobject-tablewithtabs__tab__vline {
    left: 15px;
  }
  .embeddedobject-tablewithtabs__fields .wh-details__container__inner {
    padding: 15px 20px;
  }
  .embeddedobject-tablewithtabs__fields > li {
    padding: 0 0 0 15px;
  }
  .embeddedobject-tablewithtabs__fields > li::before {
    left: 11px;
  }
}