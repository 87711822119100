.ut-autocomplete {
  background: #fff;
  z-index: 1000;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-top: 0 none;
  color: #333;
  font: inherit;
}
.ut-autocomplete > div {
  padding: 9px 10px 7px;
}
.ut-autocomplete > div:hover:not(.group), .ut-autocomplete > div.selected {
  background: #eeeeee;
  cursor: pointer;
}
.ut-autocomplete .group {
  background: #fff;
  font-weight: 700;
}