::-webkit-input-placeholder {
  opacity: 0.4;
}

::-moz-placeholder {
  opacity: 0.4;
}

input:-moz-placeholder {
  opacity: 0.4;
}