/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*
Used by:
- Filteredoverview feedback bar
- LinksPerCategory_Tabs widget
- tags.scss
*/
/*
Used in:
- default .tagslist__tag (used by linkspercategory_colors and linkspercategory_tabs widgets)
- slideshow widget slide tag
*/
/*
Used by
- linkspercategory_tabs embeddedobject
- linkspercategory_color embeddedobject
*/
.tagslist__container {
  display: -webkit-flex;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: -8px;
}

/*
LinksPerCategory_tabs (categoriekeuze widget)
    line-height:   30px;
    border:        1px solid $color-grey-normal;
    border-radius: 2px;
    color:         $color-grey-dark;

Studiefinder tags
    line-height:   24px;
    border:        1px solid $color-grey-normal;
    border-radius: 3px;
    color:         $color-grey-normal;
*/
.tagslist__tag {
  display: -webkit-inline-flex;
  display: inline-flex;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: 700 15px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 2px;
  background-color: var(--color-white);
  border: 1px solid #afb1b3;
  color: #53575b;
  cursor: pointer;
}
html[lang=el] .tagslist__tag {
  font-family: Roboto Condensed;
}

.tagslist__container .tagslist__tag {
  margin-right: 10px;
  margin-bottom: 8px;
}

.tagslist__tag:hover {
  color: #1e2328;
  border-color: #1e2328;
}

.tagslist__tag--grey {
  background-color: #53575b;
  border: 1px solid #53575b;
  color: var(--color-white);
}

.tagslist__tag--grey:hover {
  background-color: #1e2328;
  color: var(--color-white);
}

.pagetags a {
  display: inline-block;
  border-radius: 2px;
  text-decoration: none;
  white-space: nowrap;
  line-height: 18px;
  padding: 3px 7px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  background-color: var(--color-white);
  transition: background-color 0.15s;
}
.pagetags a:hover {
  background-color: #f8f8f8;
}
.pagetags a + a {
  margin-left: 5px;
}

@media (max-width: 600px) {
  .embeddedobject--imageswithcaption {
    grid-column: 1/-1 !important;
  }
  .imageswithcaption__item__meta {
    padding: 45px 0 30px 0 !important;
    display: grid;
    width: 100%;
    column-gap: var(--main-grid-gap);
    grid-column-gap: var(--main-grid-gap);
    grid-template-columns: var(--main-grid);
  }
  .imageswithcaption__item__meta > * {
    grid-column: var(--main-8col-layout);
  }
  .imageswithcaption-author {
    position: static !important;
  }
}
/* Note: Watchout! Serviceportal doesn't use grid layout! */
html.serviceportal .embeddedobject--imageswithcaption {
  position: relative;
}
@media (max-width: 600px) {
  html.serviceportal .imageswithcaption__item__meta {
    padding: 52px 20px 20px 20px !important;
  }
}

.imageswithcaption__images {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-behavior: smooth;
  display: flex;
  /* lightest grey with WCAG 4.53:1 contrastratio when using on white */
  --color-grey-text-small: #767677;
  --color-grey-text-small-on-lighter-grey: #727272;
}
.imageswithcaption__images::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.imageswithcaption__item__image {
  display: block;
  width: 100%;
  height: auto;
}

.imageswithcaption--multiple .imageswithcaption__item__image {
  flex: 0 0 100%;
  scroll-snap-align: start;
  scroll-snap-margin-left: 0;
  scroll-snap-margin-right: 0;
  scroll-margin-left: 0;
  scroll-margin-right: 0;
}
.imageswithcaption--multiple .imageswithcaption__item__meta {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}
.imageswithcaption--multiple .imageswithcaption__item__meta.active {
  pointer-events: auto;
  opacity: 1;
}

.imageswithcaption__jumpbuttons {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.imageswithcaption__jumpbutton {
  cursor: pointer;
  display: block;
  flex: none;
  position: relative;
  text-align: center;
  width: 20px;
  color: var(--color-grey-normal);
}

.imageswithcaption__jumpbutton-active {
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 1s ease-out;
  width: 20px;
  color: var(--color-black);
}
.slideshow--activedot .imageswithcaption__jumpbutton-active {
  opacity: 1;
}
.slideshow--paused .imageswithcaption__jumpbutton-active {
  animation: blink-animation 2s linear infinite;
}
.imageswithcaption--paused .imageswithcaption__jumpbutton-active {
  animation: blink-animation 2s linear infinite;
}

.imageswithcaption__metaslides {
  background-color: var(--color-grey-lighter);
  display: grid;
}

.imageswithcaption__metaslides > * {
  grid-column: 1;
  grid-row: 1;
}

.imageswithcaption-link {
  margin-top: 10px;
  color: #5A5E62;
  font: 500 18px/35px "Univers Next W02", Arial, Helvetica, sans-serif;
}

.imageswithcaption--single .imageswithcaption__item__image {
  margin-bottom: 15px;
}
.imageswithcaption--single .imageswithcaption-author {
  color: var(--color-grey-text-small);
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  text-align: right;
}
.imageswithcaption--single .imageswithcaption-title {
  color: var(--color-grey-text-small);
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
  text-align: right;
}
.imageswithcaption--single .imageswithcaption-description {
  margin-top: 15px;
  color: var(--color-grey-black);
}
.imageswithcaption--single .imageswithcaption-link {
  color: #5A5E62;
}

.imageswithcaption--multiple .imageswithcaption__item__meta {
  position: relative;
  padding: 52px 30px 20px 50px;
  color: var(--color-black);
}
.imageswithcaption--multiple .imageswithcaption-author {
  position: absolute;
  top: 6px;
  right: 9px;
  color: var(--color-grey-text-small-on-lighter-grey);
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
}
.imageswithcaption--multiple .imageswithcaption-title {
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 4px;
}
.imageswithcaption--multiple .imageswithcaption-description {
  font-size: 15px;
  line-height: 25px;
}
.imageswithcaption--multiple .imageswithcaption-link {
  color: #5A5E62;
}