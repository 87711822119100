html
{
  /* Used for ctalinks */
  --biglist-item-height: 79px;
  --biglist-item-border-color: #DCDDDE;
  --biglist-item-color:        #1E2328;
  --biglist-item-title-font:   13px/20px 'Univers Next W02';
  --biglist-item-when-font:    15px/25px 'Univers Next W02'; /* Univers LT Std 55 Roman */
  --biglist-item-ctalink-font: 500 18px/20px 'Univers Next W02';
  --biglist-item-hover-background: #F6F6F6;
}


.component-ctalinks__item
{
  display: flex;
  align-items: center;
  min-height: var(--biglist-item-height);
  padding: 15px 0;

  border-top: 1px solid var(--biglist-item-border-color);

  color: var(--biglist-item-color);
  text-decoration: none;
}
@media (max-width: 750px)
{
  .component-ctalinks__item
  {
    /* wrap signup to next line on mobile */
    flex-wrap: wrap;
  }
}


.component-ctalinks__item:last-child
{
  border-bottom: 1px solid var(--biglist-item-border-color);
}

.component-ctalinks__item:hover
{
  background: var(--biglist-item-hover-background);
}


.component-ctalinks__item__title
{
  flex: 1 0 0px;
  font: var(--biglist-item-title-font);
}
@media (max-width: 750px)
{
  .component-ctalinks__item__title
  {
    /* wrap to next line on mobile */
    flex: 1 0 100%;
  }
}


.component-ctalinks__when
{
  flex: 2 0 0px;
  font: var(--biglist-item-when-font);
  margin-left: 10px; /* prevent previous label from sticking */
}

.component-ctalinks__link
{
  flex: none;

  font: var(--biglist-item-ctalink-font);
  text-decoration: underline;
}

.component-ctalinks__icon
{
  flex: none;
  margin-left: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  background: var(--theme-color-cta);
  border-radius: 50%;
  color: #FFFFFF;
}
