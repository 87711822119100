
button.skiplinksbutton
{
  border: 0;
  appearance: none;

  /*position: absolute;*/
  align-self: top;
  flex: none;

  position: sticky;
  left: 15px;
  top: 9px;
  z-index: 100;

  display: flex;
  align-items: center;

  /* decorative */
  padding: 0 16px;
  min-height: 44px;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 16px;

  /* be hidden, but be available for focus by keyboard */
  opacity: 0; /* visibility: hidden; would remove it from the tab navigation */
  pointer-events: none;
}

/* ADDME:  button.skiplinks:hover should trigger a transition-delay which hiden the button */

  button.skiplinksbutton:focus
/*, button.skiplinks:active*/
, button.skiplinksbutton:hover /* in Safari the button shortly loses focus upon click, so prevent it from disappearing before the click lands */
{
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;

  /* https://www.florin-pop.com/blog/2019/03/css-pulse-effect/ */
  box-shadow: 0 0 0 0 rgba(255,255,255, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

main:focus
{
  outline: 0;
}

@keyframes pulse
{
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


/*
The skiplinks functionality can set the focus on the header, however the <header> uses
the full width and it's visually much better to have the visible focus around the main visual
(the photo/video sliders or title over the universe header)
*/
.contentpart__header:focus
{
  outline: 0;
}

.contentpart__header:focus .contentpart__title
{
  outline: -webkit-focus-ring-color auto 5px;
  box-shadow: 0 0 6px -webkit-focus-ring-color;
  outline-offset: 3px;
}

  .contentpart__header:focus .contentpart__header-messagesarea:not(:empty)
, .contentpart__header--photos:focus .contentpart__header-messagesarea:empty ~ .contentpart__header-photos
, .contentpart__header--photos:focus .contentpart__header-messagesarea:empty ~ .contentpart__header-photoscontainer ~ .contentpart__title-container /* Facts & Figures uses this extra wrapper around -photos */
, .contentpart__header--homevideo:focus .contentpart__header-messagesarea:empty ~ .contentpart__header-videos
{
  outline: -webkit-focus-ring-color auto 5px;
  box-shadow: 0 0 6px -webkit-focus-ring-color;
  outline-offset: 3px;
}

.contentpart__header--photos:focus .contentpart__title
{
  outline: 0;
}
