/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
html {
  --header-inset-overlap: 110px;
}

.header--titleasinset + .contentpart__main .introtext {
  margin-top: 25px;
}

@media (min-width: 751px) {
  .header--titleasinset {
    /* The title in white area which is pushed down by the CTA.
       So there is no overlap so we don't need a padding for the CTA. */
    --header-title-cta-padding: 0;
    --header-grid-rows: [top] auto
                        [header-top] 1fr
                        [sliderdots] var(--header-sliderdots-height)
                        [title-above] var(--header-inset-overlap) /*min-content*/
                        [title-below] 0px
                        [bottom] 0px;
  }
  .header--titleasinset .contentpart__title-container {
    padding-top: 55px;
    grid-row: title-above/below; /* start the title in the white inset, but allow text to grow below the image area */
  }
  .header--titleasinset .contentpart__title {
    color: var(--color-black);
    display: block;
  }
  .header--titleasinset h1 {
    margin-top: 0;
  }
  .header--titleasinset .contentpart__fftitle {
    color: var(--color-black);
    font-size: 58px;
    font-weight: 300;
  }
  .header--titleasinset .contentpart__fftitle__slogan {
    margin-bottom: 3px;
  }
  .header--titleasinset .contentpart__fftitle__sitetitle {
    font-weight: 700;
  }
  .header--titleasinset .contentpart__header-sliderdots {
    grid-row: sliderdots;
  }
  .contentpart__title-background {
    background-color: #FFFFFF;
    grid-column: 3/span 10;
    grid-row: title-above/title-below;
    z-index: 1;
  }
}
@media (max-width: 750px) {
  .header--titleasinset .contentpart__title-background {
    display: none;
  }
  .header--titleasinset .contentpart__title-container {
    grid-row: 5;
    margin-top: 25px;
  }
  .header--titleasinset .contentpart__title {
    color: var(--color-black);
  }
  .header--titleasinset .contentpart__fftitle {
    color: var(--color-black);
  }
}