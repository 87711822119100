.widget-soundcloud__wrapper {
  position: relative;
  height: 115px;
  overflow: hidden;
  cursor: pointer;
}
.widget-soundcloud--loaded .widget-soundcloud__wrapper {
  background-color: transparent;
}
.widget-soundcloud--withvisual .widget-soundcloud__wrapper {
  height: 150px;
}
.widget-soundcloud--aspect16_9 .widget-soundcloud__wrapper {
  height: auto;
}
.widget-soundcloud--aspect16_9 .widget-soundcloud__wrapper::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}
.widget-soundcloud__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 300ms;
  background-color: #616365;
}
.widget-soundcloud__image::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0126c0;
  opacity: 0.3;
  transition: opacity 300ms;
}
.widget-soundcloud__title {
  color: var(--color-white);
  font-size: 16px;
  line-height: 135%;
  position: absolute;
  bottom: 10px;
  left: 15px;
  right: 15px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.24);
}
.widget-soundcloud__play {
  position: absolute;
  background: var(--color-white);
  color: var(--color-grey-dark);
  display: block;
  height: 50px;
  width: 50px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  padding-left: 3px;
  transform: translate(-50%, -50%);
  transition: color 0.3s;
}
.widget-soundcloud__wrapper:hover .widget-soundcloud__play {
  color: var(--color-black);
}
.widget-soundcloud__wrapper:hover .widget-soundcloud__image {
  transform: scale(1.1);
}
.widget-soundcloud__wrapper:hover .widget-soundcloud__image::after {
  opacity: 0.2;
}
.widget-soundcloud iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}