.datacloudpage--roboticsexperts .datacloud-content {
  min-height: 960px;
}
@media (max-width: 1500px) {
  .datacloudpage--roboticsexperts .datacloud-content {
    min-height: 64vw;
  }
}

html.datacloudpage--roboticsexperts main {
  z-index: 3;
}

/* The graph */
.roboticsexperts-graph {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
}
.roboticsexperts-graph-labels {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms;
}
.roboticsexperts-graph__grouplabel {
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
  z-index: 1;
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
}
.roboticsexperts-graph__grouplabel__infoicon {
  margin-left: 3px;
  font-size: 16px;
}
.roboticsexperts-graph__grouplabel--dogwood-rose {
  color: var(--color-dogwood-rose);
}
.roboticsexperts-graph__grouplabel--tahiti-gold {
  color: var(--color-tahiti-gold);
}
.roboticsexperts-graph__grouplabel--bondi-blue {
  color: var(--color-bondi-blue);
}
.roboticsexperts-graph__grouplabel--malibu-green {
  color: var(--color-malibu-green);
}
.roboticsexperts-graph__grouplabel--crimson-glory {
  color: var(--color-crimson-glory);
}
.roboticsexperts-graph__grouplabel--trendy-green {
  color: var(--color-trendy-green);
}
.roboticsexperts-graph__grouplabel--cool-black {
  color: var(--color-cool-black);
}
.roboticsexperts-graph__grouplabel--cashmere {
  color: var(--color-cashmere);
}
.roboticsexperts-graph__grouplabel--windsor {
  color: var(--color-windsor);
}
.roboticsexperts-graph__grouplabel--malibu-blue {
  color: var(--color-malibu-blue);
}
.roboticsexperts-graph__grouplabel--mosque {
  color: var(--color-mosque);
}
.roboticsexperts-graph__grouplabel--tangerine {
  color: var(--color-tangerine);
}
.roboticsexperts-graph__grouplabel--scarlett {
  color: var(--color-scarlett);
}
.roboticsexperts-graph__grouplabel--matterhorn {
  color: var(--color-matterhorn);
}
.roboticsexperts-graph__grouplabel--dark-grey {
  color: var(--color-dark-grey);
}
.roboticsexperts-graph__grouplabel__title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: clamp(18px, 2vw, 24px);
}
.roboticsexperts-graph--afterready .roboticsexperts-graph-labels {
  opacity: 1;
}
.roboticsexperts-graph--afterready .roboticsexperts-graph__grouplabel {
  opacity: 1;
}
.roboticsexperts-graph svg {
  position: relative;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  z-index: 0;
}
.roboticsexperts-graph--filtersactive .roboticsexperts-graph__domain {
  opacity: 0.6;
}
.roboticsexperts-graph__domain {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s;
}
.roboticsexperts-graph__domain.active, .roboticsexperts-graph__domain.hover {
  opacity: 1;
}
.roboticsexperts-graph__domain.disabled circle {
  fill: #bbb !important;
  pointer-events: none;
}
.roboticsexperts-graph__domain__label {
  position: absolute;
  transition: opacity 300ms;
  display: inline-flex;
  transform: translateY(50%);
  z-index: 1;
  transition: text-shadow 300ms;
}
.roboticsexperts-graph__domain__label.disabled {
  opacity: 0.8;
  pointer-events: none;
}
.roboticsexperts-graph__domain__label:not(.disabled).hover, .roboticsexperts-graph__domain__label:not(.disabled):hover, .roboticsexperts-graph__domain__label:not(.disabled).active {
  text-shadow: 0 0 1px #000;
}
.roboticsexperts-graph__domain__label__inner {
  position: relative;
  z-index: 1;
  color: var(--color-grey-dark);
  min-width: 150px;
  font-weight: 400;
  font-size: clamp(11px, 1vw, 13px);
  line-height: 135%;
  display: inline-flex;
  justify-content: inherit;
}
.roboticsexperts-graph__domain__label__inner span {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.roboticsexperts-graph__dot {
  cursor: pointer;
  transform: translate(0, 0);
  opacity: 0;
}
.roboticsexperts-graph__dot__segment {
  opacity: 0;
}
.roboticsexperts-graph__dot__inner {
  transform-origin: inherit;
}
.roboticsexperts-graph__dot.shake .roboticsexperts-graph__dot__inner {
  animation: shaking 1s linear infinite;
}
.roboticsexperts-graph__dot.shake:hover .roboticsexperts-graph__dot__inner {
  animation: paused;
}
.roboticsexperts-graph--ready .roboticsexperts-graph__dot {
  opacity: 1;
  transition: transform 1000ms cubic-bezier(0.64, 0.57, 0.7, 1.1);
}
.roboticsexperts-graph--ready .roboticsexperts-graph__dot__segment {
  transition: opacity 300ms, transform 300ms;
}
.roboticsexperts-graph--ready .roboticsexperts-graph__dot:hover {
  z-index: 1;
}
.roboticsexperts-graph--ready .roboticsexperts-graph__dot:hover .roboticsexperts-graph__dot__segment, .roboticsexperts-graph--ready .roboticsexperts-graph__dot .roboticsexperts-graph__dot__segment.active, .roboticsexperts-graph--ready .roboticsexperts-graph__dot .roboticsexperts-graph__dot__segment.hover {
  opacity: 1;
}
.roboticsexperts-graph--ready .roboticsexperts-graph__dot.outside {
  transition-duration: 700ms;
}
.roboticsexperts-graph--ready .roboticsexperts-graph__dot:hover path {
  transform: scale(1.3);
}

@keyframes shaking {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }
  12% {
    transform: rotate(2deg) translate(2px, 0);
  }
  25% {
    transform: rotate(2deg) translate(0, 2px);
  }
  37% {
    transform: rotate(0deg) translate(-2px, 0);
  }
  50% {
    transform: rotate(0deg) translate(0, 0px);
  }
  62% {
    transform: rotate(-2deg) translate(2px, 0);
  }
  75% {
    transform: rotate(-2deg) translate(0, 2px);
  }
  87% {
    transform: rotate(0deg) translate(-2px, 0);
  }
  100% {
    transform: rotate(0deg) translate(0, 0);
  }
}