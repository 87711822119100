/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@import "@mod-utwente_design/scss/config-colors.css";
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
.itcspecsheet {
  margin-top: 20px;
  border-collapse: collapse;
}

.itcspecsheet th,
.itcspecsheet td {
  vertical-align: baseline;
  padding: 0;
}

.itcspecsheet th {
  text-align: left;
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
  color: #1e2328;
  padding-right: 25px;
}

/*
// Small devices
$responsive-small-devices: 750px;
// Extra small devices
$responsive-xsmall-devices: 480px;
*/
@media (max-width: 479px) {
  .itcspecsheet table,
  .itcspecsheet tbody,
  .itcspecsheet tr,
  .itcspecsheet th,
  .itcspecsheet td {
    display: block;
    padding: 0;
  }
  .itcspecsheet th {
    line-height: 12px;
  }
  .itcspecsheet td {
    padding-left: 26px;
    margin-bottom: 10px;
  }
}
.itcspecsheet td {
  font-size: 13px;
  color: #282F35;
}

.itcspecsheet .icon {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #afb1b3;
  font-weight: 400;
  font-size: 16px;
  width: 16px;
  height: 16px;
  text-align: center;
  /*
    html.site--itc &
    {
      color: $color-mosque;
    }
  */
  margin-right: 10px;
  vertical-align: middle;
}

.itcperson__content {
  display: flex;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  z-index: 0;
  border-bottom: 1px solid rgb(224, 224, 225);
  align-items: center;
  padding: 10px;
}

.itcperson__photo {
  flex: none;
}

.itcperson__photo > img {
  display: block;
  width: 70px;
  width: 70px;
  border-radius: 50%;
}

.itcperson__photo--noimage::before {
  display: block;
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #f0f1f2;
}

.itcperson__meta {
  margin-left: 30px;
}

.itcperson__title {
  text-transform: uppercase;
}

.itcperson__subtitle {
  margin-top: 7px;
  font-weight: 300;
}

/*
itcpartner
itcpartner__link
itcpartner__content
itcpartner__meta
itcpartner__title
itcpartner__metadatabar
itcpartner__description
itcpartner__imagecontainer
*/
.itcpartner {
  position: relative;
}

.itcpartner__bglink {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.itcpartner__content {
  pointer-events: none;
}

.itcpartner__content a {
  pointer-events: auto;
}

.itcpartners--detailview .itcpartner {
  position: relative;
}
.itcpartners--detailview .itcpartner.hidden {
  display: none;
}
.itcpartners--detailview .itcpartner__content {
  display: flex;
  position: relative;
  margin-top: 25px;
}
.itcpartners--detailview .itcpartner__meta {
  flex: 1 0 0px;
  min-width: 0;
}
.itcpartners--detailview .itcpartner__imagecontainer {
  flex: none;
  margin-right: 30px;
}
@media (max-width: 749px) {
  .itcpartners--detailview .itcpartner__imagecontainer {
    display: none;
  }
}

.itcpartner__title,
.itcpartner__title a {
  color: #1e2328;
  font-size: 18px; /*1.8rem*/
  font-weight: 700;
  line-height: 20px; /*2.0rem*/
  text-transform: uppercase;
}

.itcpartner__metadatabar {
  font-size: 13px;
  font-weight: 400;
  color: #5a5e62;
}

.itcpartner__metadatabar > * {
  display: inline !important;
}

.itcpartner__imagecontainer {
  width: 300px;
  height: 168px;
  background-color: #f0f1f2;
  padding: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-origin: content-box;
}

.itcpartner__metadatabar > * {
  display: inline-block;
}

.itcpartner__metadatabar > * + *:before {
  display: inline-block;
  content: ",";
  padding-right: 7px;
}

.itcpartner:last-child .itcpartner__content {
  border-bottom: 0;
  padding-bottom: 0;
}