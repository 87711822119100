.theme__docstate {
  border-radius: 4px;
  bottom: 10px;
  color: #ffffff;
  left: 10px;
  overflow: hidden;
  padding: 0 4px;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  z-index: -1;
}
.theme__docstate .theme__docstate-cta {
  bottom: 0;
  left: 33%;
  position: absolute;
  right: 33%;
  top: 0;
}
.theme__docstate .theme__docstate-cta-hover {
  bottom: 0;
  left: 67%;
  position: absolute;
  right: 0;
  top: 0;
}
.theme__docstate .theme__docstate-state {
  position: relative;
}
.theme__docstate .theme__docstate-state:after {
  content: "@1x";
}
@media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2), (min-resolution: 2dppx), (min-resolution: 192dpi) {
  .theme__docstate .theme__docstate-state:after {
    content: "@2x";
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (-moz-min-device-pixel-ratio: 3), (min-resolution: 3dppx), (min-resolution: 288dpi) {
  .theme__docstate .theme__docstate-state:after {
    content: "@3x";
  }
}
html.dompack--debug-uds .theme__docstate {
  visibility: visible;
  z-index: 99999;
}