/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*
Used by:
- Filteredoverview feedback bar
- LinksPerCategory_Tabs widget
- tags.scss
*/
/*
Used in:
- default .tagslist__tag (used by linkspercategory_colors and linkspercategory_tabs widgets)
- slideshow widget slide tag
*/
/*
Used by
- linkspercategory_tabs embeddedobject
- linkspercategory_color embeddedobject
*/
.tagslist__container {
  display: -webkit-flex;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: -8px;
}

/*
LinksPerCategory_tabs (categoriekeuze widget)
    line-height:   30px;
    border:        1px solid $color-grey-normal;
    border-radius: 2px;
    color:         $color-grey-dark;

Studiefinder tags
    line-height:   24px;
    border:        1px solid $color-grey-normal;
    border-radius: 3px;
    color:         $color-grey-normal;
*/
.tagslist__tag {
  display: -webkit-inline-flex;
  display: inline-flex;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: 700 15px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 2px;
  background-color: var(--color-white);
  border: 1px solid #afb1b3;
  color: #53575b;
  cursor: pointer;
}
html[lang=el] .tagslist__tag {
  font-family: Roboto Condensed;
}

.tagslist__container .tagslist__tag {
  margin-right: 10px;
  margin-bottom: 8px;
}

.tagslist__tag:hover {
  color: #1e2328;
  border-color: #1e2328;
}

.tagslist__tag--grey {
  background-color: #53575b;
  border: 1px solid #53575b;
  color: var(--color-white);
}

.tagslist__tag--grey:hover {
  background-color: #1e2328;
  color: var(--color-white);
}

.pagetags a {
  display: inline-block;
  border-radius: 2px;
  text-decoration: none;
  white-space: nowrap;
  line-height: 18px;
  padding: 3px 7px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  background-color: var(--color-white);
  transition: background-color 0.15s;
}
.pagetags a:hover {
  background-color: #f8f8f8;
}
.pagetags a + a {
  margin-left: 5px;
}

/*
320 - Title 18px/20px universe, 20px below, 10px below links (45 above/below widget)
750 - Title 26px/26px universe, 30px below, 10px below links (65 above widget, 70 below widget)
1080 - Title 26px/26px universe (100 above widget, 100 below widget)
*/
.embeddedobject--linkspercategory_color.embeddedobject--linkspercategory_color {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
}
@media (max-width: 960px) {
  .embeddedobject--linkspercategory_color.embeddedobject--linkspercategory_color {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .embeddedobject--linkspercategory_color.embeddedobject--linkspercategory_color {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}

.lpcc__container {
  contain: layout paint style;
}

@media (max-width: 959px) {
  .lpcc__container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .lpcc__titleandlegend {
    order: 3;
    margin-top: 10px;
  }
  .lpcc__titleandlegend .lpcc__title {
    display: none;
  }
  .lpcc__legend__item {
    margin-right: 20px;
  }
}
@media (min-width: 960px) {
  .embeddedobject--linkspercategory_color.embeddedobject--linkspercategory_color {
    margin-top: 50px;
  }
  .lpcc__container {
    display: flex;
  }
  .lpcc__title--mobile { /* On mobile and tablet we want to show this, but on desktop we hide it.
   (so we can show the version of the title next to the tags)
   However, this title is the anchor, so we may not use display: none; on it.
   So only visually hide it.

   FIXME: how do screenreaders handle this? do they detect it's not visible/relevant?
*/
    overflow: hidden;
    height: 0px;
    visibility: hidden;
    padding: 0 !important;
    margin: 0 !important;
  }
  .lpcc__titleandlegend {
    flex: 0 0 auto;
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .lpcc__titleandlegend {
    width: calc((100% - 20px - 11 * 30px) / 12 * 4 + 3 * 30px);
    width: calc((100% - var(--pageside-padding) * 2 - 11 * 30px) / 12 * 4 + 3 * 30px);
  }
}
@media (min-width: 960px) and (min-width: 961px) {
  .lpcc__titleandlegend {
    width: 290px;
  }
}
@media (min-width: 960px) and (min-width: 1440px) {
  .page--toplevelhome .lpcc__titleandlegend {
    width: 350px;
  }
}
@media (min-width: 960px) {
  .lpcc__title + .lpcc__legend {
    margin-top: 15px;
  }
  .lpcc__legend .filler {
    flex: 1 1 40px;
    min-width: 10px;
    max-width: 25px;
  }
  .lpcc__links {
    margin-left: 30px;
  }
  .lpcc__links .filler {
    flex: 1 1 40px;
    min-width: 10px;
    max-width: 50px;
  }
}
.lpcc__title {
  font-size: 26px; /*2.6rem*/
  font-weight: 700;
  line-height: 26px; /*2.6rem*/
  text-transform: uppercase;
}
@media (max-width: 480px) {
  .lpcc__title {
    font-size: 18px; /*1.8rem*/
    line-height: 20px; /*2.0rem*/
  }
}

.lpcc__legend {
  display: flex;
  flex-wrap: wrap;
}

.lpcc__legend__item {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
}

.lpcc__legend__item::before {
  display: inline-block;
  content: "";
  flex: none;
  width: 9px;
  height: 9px;
  border: 1px solid #53575b;
  border-radius: 2px;
  margin-right: 8px;
}

.lpcc__legend__item--grey::before {
  background-color: #53575b;
}

@media (max-width: 749px) {
  .embeddedobject--linkspercategory_color {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .lpcc__title--mobile {
    margin-bottom: 20px;
  }
}
@media (min-width: 750px) and (max-width: 959px) {
  .embeddedobject--linkspercategory_color {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .lpcc__legend__item {
    margin-right: 50px;
  }
  .lpcc__title--mobile {
    margin-bottom: 30px;
  }
}
@media (min-width: 960px) {
  /*
  Because of the foldable stuff (page content divided into parts when there are multiple H2's)
  we need to be sure to override the width with high specificy

  We MUST override .main__contents--content8col .foldabledeeplink__text>*
  */
  .foldabledeeplink__foldcontainer > .foldabledeeplink__text > .lpcc__container {
    --layout: 12col;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 960px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .foldabledeeplink__foldcontainer > .foldabledeeplink__text > .lpcc__container {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 960px) and (min-width: 1440px) {
  .page--toplevelhome .foldabledeeplink__foldcontainer > .foldabledeeplink__text > .lpcc__container {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (min-width: 960px) {
  .embeddedobject--linkspercategory_color {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}