.utwente-cookiedialog h2 {
  margin: 10px 0px 20px;
}
.utwente-cookiedialog p {
  line-height: 25px;
  margin: 0 0 10px;
  color: #666;
}
.utwente-cookiedialog button {
  margin-right: 10px;
}
.utwente-cookiedialog .utwente-cookiedialog__buttons {
  margin-top: 20px;
}