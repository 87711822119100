/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/** @short Position the element within the text area of the grid (8 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 8-column text width
@include grid-text-width();

// Constrain to 8-column text width, but update margins (note the $ prefix!)
@include grid-text-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full grid area (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to 12-column grid width
@include grid-grid-width();

// Constrain to 12-column grid width, but update margins (note the $ prefix!)
@include grid-grid-width($margin-left: 50px, $margin-right: 50px);
*/
/** @short Position the element within the full page width (12 columns)
    @param props A map of properties to overwrite (will otherwise be 'unset'), supported properties are $margin-left,
        $margin-right and $width
    @example

// Constrain to full page width
@include grid-full-width();

// Constrain to full page width, but update margins and width (note the $ prefix!)
@include grid-full-width($width: 500px, $margin-left: auto, $margin-right: auto);
*/
/** @short Apply this to the current slide in a slideshow with fading effect
*/
/** @short Apply this to the next slide to show in a slideshow with fading effect
*/
/** @short Apply this to the current slide that's being hidden in a slideshow with fading effect
*/
/** @short Styling of a header slideshow slider dot
*/
/** @short Styling of the active header slideshow slider dot
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/** @short reset a button to be a like a normal element
*/
.contentpart__header-messagesarea-wrapper {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  position: relative;
  z-index: var(--zindex-header);
  margin: 0 auto;
  background-color: var(--color-grey-light);
}
.contentpart__header-messagesarea-wrapper > * {
  grid-column: var(--main-8col-layout);
}
@media (max-width: 1279px) {
  .contentpart__header-messagesarea-wrapper {
    margin-top: var(--header-margin);
  }
}
@media (max-width: 1279px) {
  .contentpart__header-messagesarea-wrapper {
    margin-bottom: calc(0px - var(--header-margin));
  }
}
.contentpart__header-messagesarea-wrapper:empty {
  display: none;
}

@media (max-width: 960px) {
  .contentpart__header-crumbs + .contentpart__header-messagesarea-wrapper {
    margin-top: 0;
  }
}
.contentpart__header {
  display: grid;
  width: 100%;
  column-gap: var(--main-grid-gap);
  grid-column-gap: var(--main-grid-gap);
  grid-template-columns: var(--main-grid);
  grid-template-rows: var(--header-grid-rows);
  margin-top: var(--header-margin);
  min-height: var(--header-background-height);
  position: relative;
  z-index: var(--zindex-header);
}
.contentpart__header::before {
  background-color: var(--color-grey-light);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  min-height: var(--header-background-height);
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--zindex-headerbg);
}
.contentpart__header--photos::before {
  bottom: calc(var(--header-photos-max-height) - var(--header-background-height) + var(--header-spacing));
}
.contentpart__header--photos.contentpart__header--fixedheight::before {
  bottom: calc(var(--header-photos-fixed-height) - var(--header-background-height) + var(--header-spacing));
}
.contentpart__header--homevideo::before {
  bottom: calc(var(--header-videos-height) - var(--header-background-height) + var(--header-spacing));
}
.contentpart__header--withfilters {
  grid-template-rows: [top] auto [header-top] 1fr [title-above] calc(var(--header-background-height) - var(--header-filters-height)) [title-below] minmax(var(--header-filters-height), auto) [bottom];
}
.contentpart__header--withfilters::before {
  bottom: 0;
}

.contentpart__header-messagesarea,
.header-countdown-background,
.header-countdown {
  grid-column: var(--notification-grid-area);
  grid-row: top/header-top;
  margin-top: var(--header-spacing);
  position: relative;
  z-index: var(--zindex-messagesarea);
}

.contentpart__header-photoscontainer {
  grid-area: header-top/left/bottom/right;
  height: var(--header-photos-height);
}

.contentpart__header--photos.header--hascountdown .header-countdown-background,
.contentpart__header--photos.header--hascountdown .header-countdown {
  grid-area: header-top/left/bottom/right;
  margin: 0 var(--header-spacing);
}

.contentpart__header-photos,
.contentpart__header-videos {
  align-items: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  grid-area: header-top/left/bottom/right;
  height: var(--header-photos-height);
  margin: 0 var(--header-spacing);
  position: relative;
}
.contentpart__header--fixedheight .contentpart__header-photos,
.contentpart__header--fixedheight .contentpart__header-videos {
  height: var(--header-photos-fixed-height);
}
.contentpart__header:not(.contentpart__header--fixedheight) .contentpart__header-photos::before,
.contentpart__header:not(.contentpart__header--fixedheight) .contentpart__header-videos::before {
  content: "";
  display: block;
  float: left;
  width: 0;
}

.contentpart__header-photos::before {
  padding-top: var(--header-photos-aspect-padding);
}

.contentpart__header-slide {
  opacity: 1;
  transition: opacity var(--header-slideshow-duration);
  flex: 1;
  height: 100%;
  max-width: var(--header-photos-width);
  position: absolute;
  width: 100%;
}
html.site--itc .contentpart__header-slide::before {
  background-image: url("../../../webdesigns/ws2016/web/img/itc/header-paintstroke.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--zindex-itc-swoosh);
}
.contentpart__header-slide::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  bottom: 0;
  content: "";
  display: block;
  height: var(--header-background-height);
  left: 0;
  position: absolute;
  right: 0;
}
.contentpart__header-slide + .contentpart__header-slide {
  display: none;
}

.contentpart__header-slide.slideshow--slidein {
  opacity: 0;
}

.contentpart__header-slide.slideshow--slideout {
  opacity: 0;
}

.contentpart__header-heroimage,
.contentpart__header-heroimage--background {
  background-repeat: no-repeat;
  background-size: cover;
}

.contentpart__header-photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.contentpart__header-heroimage--background,
.contentpart__header-photo {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.contentpart__header-sliderdots {
  justify-self: start; /* shrink-wrap at the start so we don't overlap content on the horizontal strip whe're in (such as a search query input) */
  color: var(--color-white);
  display: var(--header-sliderdots-display);
  flex-direction: column;
  grid-column: var(--main-8col-layout);
  grid-row: title-below/bottom;
  justify-content: center;
  z-index: var(--zindex-title);
  /* Because of the "paintbrush shoows stroke" the filter query needs to be pushed a little from the bottom.
     However to keep nice alignment with the sliderdots they need to be pushed up too. */
}
.header--content12col .contentpart__header-sliderdots {
  grid-column: var(--main-12col-layout);
}
.contentpart__header--withfilters .contentpart__header-sliderdots {
  margin-bottom: var(--header-filters-margin); /* keep some distance from the ITC swoosh */
}

.contentpart__header-sliderdots-contents {
  display: flex;
  flex-direction: row;
}
.contentpart__header--photos.header--content12col .contentpart__header-sliderdots-contents, .contentpart__header--homevideo.header--content12col .contentpart__header-sliderdots-contents {
  margin-left: var(--header-title-margin);
  margin-right: var(--header-title-margin);
}

.contentpart__header-sliderdot {
  cursor: pointer;
  display: block;
  flex: none;
  position: relative;
  text-align: center;
  width: 20px;
}

.contentpart__header-sliderdot-active {
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity var(--header-slideshow-duration) ease-out;
  width: 20px;
}
.slideshow--activedot .contentpart__header-sliderdot-active {
  opacity: 1;
}
.slideshow--paused .contentpart__header-sliderdot-active {
  animation: blink-animation 2s linear infinite;
}

.contentpart__header-author {
  bottom: var(--header-author-bottom);
  color: var(--color-grey-lines);
  font-size: 12px;
  font-weight: var(--weight-light-condensed);
  height: var(--header-author-height);
  line-height: var(--header-author-height);
  position: absolute;
  right: var(--header-author-right);
  z-index: var(--zindex-title);
}

.contentpart__header-universe {
  background: var(--color-grey-light);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
html.site--itc .contentpart__header-universe::before {
  background-image: url("../../../webdesigns/ws2016/web/img/itc/header-paintstroke.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--zindex-itc-swoosh);
}

.contentpart__header-videos {
  background: var(--color-grey-light);
  height: var(--header-videos-height);
  margin-left: auto;
  margin-right: auto;
  max-width: var(--header-videos-width);
  width: calc(100% - 2 * var(--header-spacing));
  z-index: 0;
}
.contentpart__header-videos::before {
  padding-top: var(--header-videos-aspect-padding);
}

.contentpart__header-video,
.contentpart__header-videoposter,
.contentpart__header-videomobile {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.contentpart__header-video {
  display: none;
  z-index: 2;
}
.contentpart__header-videos--fallback .contentpart__header-video {
  display: none;
}
.contentpart__header-video--current {
  display: var(--header-videos-display);
}
.contentpart__header-video video {
  object-fit: cover;
}

.contentpart__header-videoposter {
  background: center/cover no-repeat;
  display: none;
  z-index: 1;
}
.contentpart__header-video--current + .contentpart__header-videoposter {
  display: var(--header-videos-display);
}
.contentpart__header-video--current:not(.contentpart__header-video--playing) + .contentpart__header-videoposter {
  z-index: 3;
}
.contentpart__header-videos--fallback .contentpart__header-videoposter {
  display: var(--header-videos-display);
  opacity: 0;
  transition: opacity var(--header-slideshow-duration);
  z-index: 1;
}
.contentpart__header-videos--fallback .contentpart__header-video--current:not(.contentpart__header-video--playing) + .contentpart__header-videoposter {
  opacity: 1;
  z-index: 2;
}

.contentpart__header-videomobile {
  background-repeat: no-repeat;
  background-size: cover;
  display: var(--header-videomobile-display);
}

.contentpart__header-cta {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  grid-area: header-top/left/bottom/right;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: var(--header-photos-width);
  pointer-events: none;
  position: relative;
  width: calc(100% - 2 * var(--header-spacing));
  z-index: var(--zindex-header-cta);
}
.contentpart__header-videos + .contentpart__header-cta {
  max-width: var(--header-videos-width);
  width: calc(100% - 2 * var(--header-spacing));
}
.contentpart__header--withfilters .contentpart__header-cta {
  grid-area: header-top/left/title-above/right;
}
.contentpart__header--withfilters .contentpart__header-cta .headercta {
  margin-left: auto;
  position: relative;
}

.contentpart__header-overlay {
  bottom: 0;
  left: var(--header-overlay-left);
  margin-right: 20px;
  margin-top: 15px;
  pointer-events: none;
  position: var(--header-overlay-position);
  transform: var(--header-overlay-transform);
  width: var(--header-overlay-width);
}
.contentpart__header-overlay img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.contentpart__title-container {
  grid-column: var(--main-8col-layout);
  grid-row: title-above/title-below;
  position: relative;
  z-index: var(--zindex-title);
}
.header--hascta .contentpart__title-container {
  /* CTA can potentially overlap, so create space..
     FIXME: whe may be able to float the CTA and make the text wrap around it? */
  padding-right: var(--header-title-cta-padding);
}
.header--content12col .contentpart__title-container {
  grid-column: var(--main-12col-layout);
}

.contentpart__title {
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin: 0;
  width: -moz-fit-content;
  width: fit-content;
}
.contentpart__header--photos .contentpart__title, .contentpart__header--homevideo .contentpart__title {
  color: var(--color-white);
}
.contentpart__header--photos.header--content12col .contentpart__title, .contentpart__header--homevideo.header--content12col .contentpart__title {
  margin-left: var(--header-title-margin);
  margin-right: var(--header-title-margin);
}
.contentpart__title .subtitle {
  font-weight: var(--weight-normal-condensed);
}

.contentpart__header-crumbs {
  background: var(--color-grey-light);
  border-bottom: 1px solid var(--color-grey-lines);
  display: var(--breadcrumbs-display);
  margin-top: var(--header-margin);
  position: relative;
}
.contentpart__header-crumbs .contentpart__breadcrumblist {
  min-height: var(--navbar-height);
}

.contentpart__header-photoscontainer {
  overflow: hidden;
}

html.ut--headeranimation {
  --header-spacing: 0px; /* MUST have the px unit so the calc() for .contentpart__header-cta works */
}
html.ut--headeranimation .contentpart__header-photos {
  margin: 0;
}
html.ut--headeranimation .contentpart__header-slide {
  max-width: none;
}

/*
Make the filter form partially clickthrough so the header
slideshow buttons can be reached even though visually the filter form
will overlay the jumpbuttons.

Because initially a free search input in the header will be small and
when focussed it'll grow and cover the jumpbuttons. This means the filtercontainer
needs to be the full width initially without blocking the jumpbutton clicks.
*/
.contentpart__header--photos .filteredoverview__form {
  pointer-events: none; /* so clicks have the chance to reach the header slides jumpbuttons */
}
.contentpart__header--photos .filteredoverview__toggleandsearchbar input,
.contentpart__header--photos .filteredoverview__toggleandsearchbar button,
.contentpart__header--photos .filteredoverview__toggleandsearchbar label {
  pointer-events: auto;
}
.contentpart__header--photos .filters__expandedcontent {
  pointer-events: auto;
}