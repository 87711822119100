/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@import "@mod-utwente_design/scss/config-colors.css";
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*

NOTE: .timeline__items::before content communicates to Javascript what masonry settings to use.
      Format: columns:gutter-between-items:gutter-between-headers:gutter-between-header+normalitem

*/
/* 2 x 270 width */
/* 2 x 380 width */
.embeddedobject--timeline.embeddedobject--timeline {
  --layout: 12col;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
  margin-bottom: 100px;
}

.timeline__content.timeline__content {
  --layout: 12col;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 960px;
  width: var(--page-contentwidth-12col);
  padding-left: 10px;
  padding-right: 10px;
  /*
    @media (min-width: 0)
    {
      padding-left:  var(--pageside-padding);
      padding-right: var(--pageside-padding);
    }
  */
}
@media (max-width: 960px) {
  .timeline__content.timeline__content {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .page--toplevelhome .timeline__content.timeline__content {
    width: 1130px;
    width: var(--page-contentwidth-12col);
  }
}
@media (max-width: 750px) {
  .timeline__content.timeline__content {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (max-width: 480px) {
  .timeline__content.timeline__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1221px) and (max-width: 1350px) {
  .embeddedobject--timeline.embeddedobject--timeline,
  .header__titleholder__contents.header__titleholder__contents--12col {
    padding-right: 50px;
  }
}
.timeline__bookmarks {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  z-index: 2;
}
@media (max-width: 1180px) {
  .timeline__bookmarks {
    display: none;
  }
}

.timeline__bookmarks__inner {
  position: absolute;
  right: 0;
}

.timeline__bookmarks__item {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font: 13px "Univers Next W02", Arial, Helvetica, sans-serif;
  color: #dcddde;
  margin-bottom: 16px;
  cursor: pointer;
}

.timeline__bookmarks__item--hide {
  display: none;
}

.timeline__bookmarks__item::after {
  content: "";
  display: inline-block;
  height: 2px;
  width: 4px;
  background-color: #000000;
  margin-left: 13px;
}

.timeline__bookmarks__item--inview {
  color: #162731;
}

.timeline__intro {
  border: 1px solid #dcddde;
  background-color: #f0f1f2;
  border-radius: 2px;
  color: #5a5e62;
  line-height: 20px;
  font-family: Arial;
  font-size: 13.4px;
  letter-spacing: 0.25px;
}
@media (min-width: 750px) {
  .timeline__intro {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 479px) {
  .timeline__intro {
    padding: 25px;
  }
}
@media (min-width: 480px) {
  .timeline__intro {
    padding: 17px;
  }
}
@media (min-width: 700px) {
  .timeline__intro {
    padding: 30px;
  }
}
@media (min-width: 1180px) {
  .timeline__intro {
    padding: 35px;
  }
}

.timeline__intro > *:first-child {
  margin-top: 0;
}

.timeline__intro > *:last-child {
  margin-bottom: 0;
}

.timeline__introline {
  width: 1px;
  background-color: #dcddde;
  margin: 0 auto;
}

@media (max-width: 699px) {
  .timeline__introline {
    height: 50px;
  }
  .timeline__items {
    padding-bottom: 50px;
  }
}
@media (min-width: 700px) {
  .timeline__introline {
    height: 60px;
  }
  .timeline__items {
    padding-bottom: 50px;
  }
}
@media (min-width: 1180px) {
  .timeline__introline {
    height: 75px;
  }
  .timeline__items {
    padding-bottom: 50px;
  }
}
.timeline__noresults {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin-top: 40px;
  font: 300 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  text-align: center;
}
html[lang=el] .timeline__noresults {
  font-family: Roboto Condensed;
}

.embeddedobject--timeline--noresults .timeline__noresults {
  display: block;
}

.timeline__items {
  position: relative;
  width: 100% !important;
  overflow: hidden;
}

.timeline__line {
  position: absolute;
  left: 50%;
  margin-left: -0.5px;
  top: 0;
  bottom: 0px;
  border-left: 1px solid #dcddde;
}

.timeline__line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -6px;
  width: 11px;
  border-top: 1px solid #dcddde;
}

.timeline__item {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: var(--color-white);
  border: 1px solid #dcddde;
  border-radius: 2px;
}

.timeline__item__filler {
  flex: 1 1 0px;
}

.timeline__header {
  font: 700 26px "Univers Next W02", Arial, Helvetica, sans-serif;
}

.timeline__line + .timeline__header {
  padding-top: 0;
}

.timeline__item__headercontent {
  position: relative;
  background-size: cover;
  margin-left: 0;
  margin-right: 0;
  padding-top: 56%;
}

.timeline__item__meta {
  padding-top: 27px;
}

.timeline__item__meta > * {
  margin-left: 30px;
  margin-right: 30px;
}

.timeline__item__when {
  margin-bottom: 2px;
}

.timeline__item__when,
.timeline__item__label__when {
  font: 300 15px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.timeline__item__title {
  margin-bottom: 20px;
  font: 700 18px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.timeline__item__intro {
  color: #1e2328;
  /*
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/

  users: Facebook, YouTube, Ebay, ... (Twitter soon?)
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.timeline__item__intro > *:first-child {
  margin-top: 0;
}

.timeline__item__intro > *:last-child {
  margin-bottom: 0;
}

.timeline__item__buttonbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  box-sizing: content-box;
  color: #53575b;
  font: 300 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  border-top: 1px solid #dcddde;
  margin-top: 25px;
  padding-top: 16px;
  margin-bottom: 14px;
}
html[lang=el] .timeline__item__buttonbar {
  font-family: Roboto Condensed;
}

.timeline__item + .timeline__item__label::after {
  display: block;
  content: "";
  position: absolute;
  z-index: 1;
  border: 1px solid #dcddde;
  border-radius: 50%;
  background-color: var(--color-white);
  top: -3.5px;
  width: 7px;
  height: 7px;
}

.timeline__header + .timeline__item__label::after {
  display: block;
  content: "";
  position: absolute;
  z-index: 1;
  background-color: #53575b;
  border-radius: 50%;
  top: -2.6px;
  right: -2.5px;
  width: 5px;
  height: 5px;
}

@media (max-width: 749px) {
  .timeline__item--hide {
    display: none;
  }
}
@media (min-width: 750px) {
  .timeline__item {
    position: absolute;
    -webkit-transition: -webkit-transform 0.5s; /* -webkit-transition - iOS <7.1, Android <4.4  ... -webkit-transform - SF <9, iOS <9.1, Webkit up to 4.4.4 */
    transition: transform 0.5s;
  }
  .timeline__item--hide {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@media (max-width: 749px) {
  .timeline__items::before {
    display: none;
    content: "1:75:30:30";
  }
  .timeline__item + .timeline__item {
    margin-top: 50px;
  }
  .timeline__intro + .timeline__item {
    margin-top: 50px;
  }
  .timeline__header + .timeline__item__label {
    position: absolute;
    border-top: 1px solid #afb1b3;
    margin-top: 16px;
    left: 80px;
    right: 50%;
    opacity: 1;
    transition: opacity 0.25s;
  }
  .timeline__header--hide + .timeline__item__label {
    opacity: 0;
  }
  .timeline__item__when {
    display: none;
  }
  .timeline__item + .timeline__item__label {
    position: absolute;
    left: 50%;
    right: auto;
    margin-top: -25px;
    padding-left: 10px;
  }
  .timeline__item + .timeline__item__label::after {
    left: -3.5px;
    top: 8px;
  }
}
@media (min-width: 750px) {
  .timeline__items::before {
    display: none;
    content: "2:50:75:23";
  }
  .timeline__item__label {
    position: absolute;
    border-top: 1px solid #afb1b3;
    margin-top: 20px;
    opacity: 1;
    transition: opacity 0.25s;
  }
  .timeline__item--hide + .timeline__item__label {
    opacity: 0;
  }
  .timeline__item__label__when {
    display: none;
  }
  .timeline__item__label--leftside {
    left: 0;
    right: 50%;
  }
  .timeline__item__label--rightside {
    left: 50%;
    right: 0;
  }
  .timeline__item__label--leftside::after {
    right: -3.5px;
  }
  .timeline__item__label--rightside::after {
    left: -3.5px;
  }
  .timeline__item__header {
    text-align: left;
  }
  .timeline__header.timeline__content--leftside {
    text-align: right;
  }
  .timeline__header + .timeline__item__label {
    border-top-color: #1e2328;
    margin-top: 15px;
  }
  .timeline__item__label::after {
    border-color: #1e2328;
  }
  .timeline__header + .timeline__item__label--leftside {
    left: calc(50% - 55px);
  }
  .timeline__header + .timeline__item__label--rightside {
    right: calc(50% - 55px);
  }
  .timeline__item--highlight + .timeline__item__label {
    left: 50%;
    right: auto;
    margin-top: -15px;
    border-color: #afb1b3;
  }
  .timeline__item--highlight + .timeline__item__label::after {
    border-color: #afb1b3;
  }
  .timeline__item--highlight {
    display: -webkit-flex;
    display: flex;
  }
  .timeline__item--highlight .timeline__item__header {
    flex: 0 0 62%;
  }
  .timeline__item__meta {
    flex: 1 1 0px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
  .timeline__item--highlight:nth-child(even) .timeline__item__header {
    order: 2;
  }
  .timeline__item--highlight .timeline__item__headercontent {
    padding-top: 57.5%;
  }
}
@media (min-width: 1180px) {
  .timeline__items::before {
    display: none;
    content: "2:50:180:23";
  }
  .timeline__item__label {
    margin-top: 30px;
  }
}
.filteredoverview--timeline .filteredoverview__toggleandsearchbar {
  display: -webkit-flexbox;
  display: flex;
  align-items: center;
}
.filteredoverview--timeline .filteredoverview__togglefiltersbutton {
  margin-right: 0;
}
.filteredoverview--timeline .filteredoverview__custom {
  flex: 1 1 0px;
  text-align: right;
  font: 300 18px/35px "Univers Next W02", Arial, Helvetica, sans-serif;
}
@media (max-width: 767px) {
  .filteredoverview--timeline .wh-form__fieldline:not(.wh-form__fieldline--hidden) {
    display: flex;
  }
  .filteredoverview--timeline .tlcolumn + .tlcolumn {
    margin-top: 11px;
  }
}
@media (min-width: 768px) {
  .filteredoverview--timeline .tlcolumncontainer {
    display: -webkit-flexbox;
    display: flex;
  }
  .filteredoverview--timeline .tlcolumn {
    flex: none;
  }
  .filteredoverview--timeline .tlcolumn + .tlcolumn {
    margin-left: 30px;
  }
}