.coursefinder__listcalendar {
  border-top: 1px solid #DCDDDE;
  margin-top: 10px;
}
.coursefinder__listcalendar .month {
  min-height: 50px;
  max-height: 50px;
  overflow: hidden;
  border-bottom: 1px solid #DCDDDE;
  transition: max-height 0.3s;
}
.coursefinder__listcalendar .month.active {
  max-height: none;
}
.coursefinder__listcalendar .month .course {
  cursor: pointer;
}
.coursefinder__listcalendar .month .course--more {
  display: none; /* hide more node (used in calendar table) */
}
.coursefinder__listcalendar .month__title {
  font-size: 18px;
  height: 50px;
  line-height: 26px;
  font-weight: 700;
  padding: 12px 15px 5px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding-right: 60px;
  position: relative;
}
.coursefinder__listcalendar .month__toggle {
  color: #AAACAE;
  display: inline-block;
  height: 16px;
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -12px;
}
.coursefinder__listcalendar .month__toggle .less {
  display: none;
}
.coursefinder__listcalendar .month__toggle .more {
  display: inline-block;
}
.coursefinder__listcalendar .month.active .month__toggle .less {
  display: inline-block;
}
.coursefinder__listcalendar .month.active .month__toggle .more {
  display: none;
}
.coursefinder__listcalendar .month__day__title {
  background-color: #F0F1F2;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  padding: 5px 15px;
  color: #5A5E62;
}
.coursefinder__listcalendar .month__day__title.today {
  font-weight: 600;
  color: #fff;
  background-color: #5A5E62;
}
.coursefinder__listcalendar .coursefinder__calendar__courses {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.coursefinder__listcalendar .coursefinder__calendar__courses > .course {
  display: flex;
  align-items: center;
  min-height: 50px;
  position: relative;
  padding: 0 15px 0 30px;
  font-size: 13px;
  border-bottom: 1px solid #DCDDDE;
}
.coursefinder__listcalendar .coursefinder__calendar__courses > .course::after {
  top: 22px;
  left: 15px;
}

.coursefinder__calendar {
  padding: 0 10px;
}

.coursefinder__dialog {
  padding: 0;
  width: 620px;
  max-width: 100%;
  min-height: 100px;
}
.coursefinder__dialog__close {
  position: absolute;
  padding: 2px;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  font-size: 16px;
  cursor: pointer;
  color: #AAACAE;
}

@keyframes coursefinder__spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.coursefinder__dialog__loading .coursefinder__loadertitle {
  text-align: center;
  background-color: #1E2328;
  color: #fff;
  margin-top: 0;
  padding: 20px;
}
.coursefinder__dialog__loading .coursefinder__loader {
  width: 50px;
  height: 50px;
  margin: 20px auto;
}
.coursefinder__dialog__loading .coursefinder__loader > svg {
  animation: coursefinder__spin 2s infinite linear;
}

.coursefinder__dialog__courseoptions .courseoptions__header {
  color: #fff;
  background-color: #1E2328;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.coursefinder__dialog__courseoptions .courseoptions__header a {
  color: inherit;
}
.coursefinder__dialog__courseoptions .courseoptions__header .courseoptions__image {
  position: relative;
  background-color: #333;
  flex: 1 1 29%;
  max-width: 29%;
}
.coursefinder__dialog__courseoptions .courseoptions__header .courseoptions__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.coursefinder__dialog__courseoptions .courseoptions__header .courseoptions__image::after {
  content: "";
  display: block;
  padding-top: 100%; /* square image */
}
.coursefinder__dialog__courseoptions .courseoptions__header .courseoptions__title {
  padding: 20px;
}
.coursefinder__dialog__courseoptions .courseoptions__header h3 {
  margin: 0;
}
.coursefinder__dialog__courseoptions .courseoptions__nooptions {
  font-weight: 600;
  padding: 20px;
}
.coursefinder__dialog__courseoptions .courseoptions__form {
  padding-bottom: 30px;
}
.coursefinder__dialog__courseoptions .courseoptions__form > ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0 30px;
}
.coursefinder__dialog__courseoptions .courseoptions__form > ul > li {
  position: relative;
}
.coursefinder__dialog__courseoptions .courseoptions__form > ul > li::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 5px;
  height: 5px;
  border: 1px solid #AAACAE;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 60px;
}
.coursefinder__dialog__courseoptions .courseoptions__form > ul > li.isfull {
  opacity: 0.5;
}
.coursefinder__dialog__courseoptions .courseoptions__form > ul > li.open::after {
  border: 0 none;
  background-color: #3F9C35;
}
.coursefinder__dialog__courseoptions .courseoptions__form > ul > li.waitinglist::after {
  border-color: #EC7A08;
}
.coursefinder__dialog__courseoptions .courseoptions__form > ul > li:nth-child(2n+1) {
  background-color: #F0F1F2;
}
.coursefinder__dialog__courseoptions .courseoptions__form input {
  display: none;
}
.coursefinder__dialog__courseoptions .courseoptions__form .courseoptions__more {
  padding-left: 20px;
  margin-top: -25px;
  padding-bottom: 25px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .courseoptions__more a {
  color: #5A5E62;
  font-size: 18px;
  font-weight: 300;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__button {
  margin-left: 60px;
  min-width: 120px;
  text-align: center;
}
.coursefinder__dialog__courseoptions .courseoptions__form .radiobtn {
  position: absolute;
  top: 20px;
  left: 20px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel {
  display: flex;
  flex-flow: row;
  min-height: 50px;
  font-size: 13px;
  line-height: 20px;
  padding: 16px 20px 14px 70px;
  position: relative;
  cursor: pointer;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel::after {
  margin: 13px 0 0 20px !important;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel > span {
  color: #5A5E62;
  display: inline-block;
  white-space: nowrap;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel > span + span {
  padding-left: 10px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .sessions {
  flex: 0 1 270px;
  overflow-y: hidden;
  max-height: 20px;
  transition: max-height 0.3s;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .session {
  display: flex;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .session + .session {
  padding-top: 5px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .session:last-child {
  padding-bottom: 10px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .weekday {
  flex: 0 0 80px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .date {
  flex: 0 0 100px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .time {
  flex: 0 0 90px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .note {
  font-style: italic;
  text-align: center;
  color: #1E2328;
  flex: 1 1 auto;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel .duration {
  flex: 0 1 80px;
  position: relative;
}
.coursefinder__dialog__courseoptions .courseoptions__form .wh-form__optionlabel::before {
  position: absolute;
  left: 20px;
  top: 15px;
}
.coursefinder__dialog__courseoptions .courseoptions__form .multiplesessions .duration {
  text-decoration: underline;
}
.coursefinder__dialog__courseoptions .courseoptions__footer {
  font-style: italic;
  background-color: #F0F1F2;
  padding: 20px 40px 20px 60px;
  font-size: 13px;
}

.coursefinder__calendar__nav {
  display: flex;
  flex-flow: row;
  margin-top: 20px;
}
.coursefinder__calendar__nav .monthname {
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  text-transform: uppercase;
  min-width: 200px;
}
.coursefinder__calendar__nav .previousmonth, .coursefinder__calendar__nav .nextmonth {
  display: inline-block;
  font-size: 16px;
  height: 26px;
  width: 26px;
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.7;
  padding-top: 4px;
  transition: opacity 0.2s;
}
.coursefinder__calendar__nav .previousmonth:hover, .coursefinder__calendar__nav .nextmonth:hover {
  opacity: 1;
}
.coursefinder__calendar__nav--minlimit .previousmonth, .coursefinder__calendar__nav--maxlimit .nextmonth {
  cursor: default;
  opacity: 0.3 !important;
}

.wh-monthtable tr:last-child .coursefinder__calendar__daypopup {
  top: auto;
  bottom: -40px;
}

.coursefinder__calendar__daypopup {
  z-index: 100;
  position: absolute;
  top: -40px;
  left: -40px;
  right: -40px;
  padding: 20px 15px 20px 20px;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
  max-height: 100%;
  transform: scale(0.01, 0.01);
}
.coursefinder__calendar__daypopup ul {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: auto !important;
}
.coursefinder__calendar__daypopup.show {
  transform: scale(1, 1);
  opacity: 1;
  max-height: 100vh;
}
.coursefinder__calendar__daypopup .dayofweek {
  display: block;
  font-size: 13px;
  line-height: 15px;
  margin-top: -10px;
  font-weight: 300;
}
.coursefinder__calendar__daypopup .course--close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  text-align: right;
}
.coursefinder__calendar__daypopup .course--more {
  display: none !important;
}

.coursefinder__calendar__tablewrapper {
  margin: 20px -20px;
}
.coursefinder__calendar__tablewrapper table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}
.coursefinder__calendar__tablewrapper th, .coursefinder__calendar__tablewrapper td {
  text-align: left;
  position: relative;
}
.coursefinder__calendar__tablewrapper .wh-monthtable__weekday {
  padding-left: 20px;
  padding-bottom: 12px;
  color: #5A5E62;
  font-size: 13px;
  font-weight: 600;
}
.coursefinder__calendar__tablewrapper th::after, .coursefinder__calendar__tablewrapper td::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #DCDDDE;
}
.coursefinder__calendar__tablewrapper th:nth-child(1)::after, .coursefinder__calendar__tablewrapper td:nth-child(1)::after {
  left: 20px;
}
.coursefinder__calendar__tablewrapper th:nth-child(5)::after, .coursefinder__calendar__tablewrapper td:nth-child(5)::after {
  right: 20px;
}
.coursefinder__calendar__tablewrapper .wh-monthtable__day {
  width: 20%;
  height: 180px;
}
.coursefinder__calendar__tablewrapper .wh-monthtable__day ul {
  list-style-type: none;
  margin: 0;
  padding: 10px 0 0;
  font-size: 12px;
  color: #5A5E62;
  line-height: 20px;
  max-width: 100%;
  display: block;
  position: absolute;
  top: 50px;
  left: 20px;
  right: 15px;
  height: 115px;
  overflow: hidden;
  white-space: nowrap;
}
.coursefinder__calendar__tablewrapper .wh-monthtable__day > .day-container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 10px 20px 20px;
}
.coursefinder__calendar__tablewrapper .wh-monthtable__day .day {
  display: inline-block;
  font-size: 26px;
  line-height: 30px;
  font-weight: 300; /* thin condensed */
}
.coursefinder__calendar__tablewrapper .wh-monthtable__day--today .day {
  border-bottom: 1px solid #1e2328;
}
.coursefinder__calendar__tablewrapper .wh-monthtable__day--disabled {
  background-color: #F0F1F2;
  cursor: default;
}
.coursefinder__calendar__tablewrapper .wh-monthtable__day--previousmonth, .coursefinder__calendar__tablewrapper .wh-monthtable__day--nextmonth {
  color: #AAACAE;
}

.coursefinder__calendar__courses .time {
  font-size: 10px;
  display: inline-block;
  padding-right: 5px;
}
.coursefinder__calendar__courses .course {
  display: block;
  position: relative;
  padding-left: 10px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.coursefinder__calendar__courses .course--more {
  padding-left: 0;
}
.coursefinder__calendar__courses .course::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 5px;
  height: 5px;
  border: 1px solid #AAACAE;
  border-radius: 50%;
}
.coursefinder__calendar__courses .course--open::after {
  border: 0 none;
  background-color: #3F9C35;
}
.coursefinder__calendar__courses .course--waitinglist::after {
  border-color: #EC7A08;
}
.coursefinder__calendar__courses .course--more::after {
  display: none;
}
.coursefinder__calendar__courses .course--open .name {
  font-weight: 600;
  color: #1E2328;
}