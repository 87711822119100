html.wh-wrdauth--isloggedin .ut-login__topmenu:after {
  content: "";
  position: absolute;
  background-color: #0f0;
  width: 5px;
  height: 5px;
  top: 17px;
  right: 20px;
  border-radius: 100%;
}

.ut-login__fullname {
  font-weight: bold;
}

.ut-login__loginname {
  margin-top: 10px;
  font-size: 80%;
}