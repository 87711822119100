@charset "UTF-8";
/*******************************************************************************
 * CONFIGURATION
 */
@import "@mod-utwente_design/scss/config-colors.css";
/*

Design Contentwidth Sidemargin TOTAL  Column Gutter MenuOpen HP-columns
1600     Fixed 1110         15  1140  65     30     TRUE     12
1440     Fixed 1110         15  1140  65     30     TRUE     12
1280     Fixed  930         15   960  50     30     TRUE     12
1080     Fixed  930         15   960  50     30     FALSE    12
 750     Fluid  690         15   720  30     30     FALSE    10 less cols so we can switch to mobile style without huge blocks)
 320     Fluid    X         20     X  30/X   20     FALSE    6  ()

*/
/*******************************************************************************
 * MIXINS
 */
/*
Use the have an element span over an X amount of design columns
IMPORTANT NOTES:
- Only use this within 12 column container!!
-
*/
/*
NOTE: unlike .main__contents--fullwidth we use the full viewport width (no padding)
*/
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
/* NOTE: To use
         - The container must be positioned
         - The container cannot be inline, make it block so the effect can use 100% width
         - Content within must be positioned so the effect won't be drawn over the content
*/
/*
Used by:
- Filteredoverview feedback bar
- LinksPerCategory_Tabs widget
- tags.scss
*/
/*
Used in:
- default .tagslist__tag (used by linkspercategory_colors and linkspercategory_tabs widgets)
- slideshow widget slide tag
*/
/*
Used by
- linkspercategory_tabs embeddedobject
- linkspercategory_color embeddedobject
*/
.tagslist__container {
  display: -webkit-flex;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: -8px;
}

/*
LinksPerCategory_tabs (categoriekeuze widget)
    line-height:   30px;
    border:        1px solid $color-grey-normal;
    border-radius: 2px;
    color:         $color-grey-dark;

Studiefinder tags
    line-height:   24px;
    border:        1px solid $color-grey-normal;
    border-radius: 3px;
    color:         $color-grey-normal;
*/
.tagslist__tag {
  display: -webkit-inline-flex;
  display: inline-flex;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: 700 15px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 2px;
  background-color: var(--color-white);
  border: 1px solid #afb1b3;
  color: #53575b;
  cursor: pointer;
}
html[lang=el] .tagslist__tag {
  font-family: Roboto Condensed;
}

.tagslist__container .tagslist__tag {
  margin-right: 10px;
  margin-bottom: 8px;
}

.tagslist__tag:hover {
  color: #1e2328;
  border-color: #1e2328;
}

.tagslist__tag--grey {
  background-color: #53575b;
  border: 1px solid #53575b;
  color: var(--color-white);
}

.tagslist__tag--grey:hover {
  background-color: #1e2328;
  color: var(--color-white);
}

.pagetags a {
  display: inline-block;
  border-radius: 2px;
  text-decoration: none;
  white-space: nowrap;
  line-height: 18px;
  padding: 3px 7px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  background-color: var(--color-white);
  transition: background-color 0.15s;
}
.pagetags a:hover {
  background-color: #f8f8f8;
}
.pagetags a + a {
  margin-left: 5px;
}

.slideshowwidget {
  display: -webkit-flex;
  display: flex;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}

.slideshowwidget__previousbutton,
.slideshowwidget__nextbutton {
  position: relative;
}

.slideshowwidget__overlay {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 2;
  pointer-events: none;
  transition: opacity 0.25s;
  opacity: 1;
}

.slideshowwidget--videoplaying .slideshowwidget__overlay {
  opacity: 0;
}

.slideshowwidget__panelleft {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.slideshowwidget__slideinfo__top {
  display: -webkit-flex;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.slideshowwidget__slideinfo__title {
  margin-top: 3px;
}

.slideshowwidget__slideinfo__title p {
  margin-top: 0;
  margin-bottom: 0;
}

.slideshowwidget__slideinfo__tag {
  margin-left: 15px;
  flex: none;
  display: -webkit-inline-flex;
  display: inline-flex;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  font: 700 15px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 2px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--color-white);
  border: 1px solid #afb1b3;
  color: #53575b;
}
html[lang=el] .slideshowwidget__slideinfo__tag {
  font-family: Roboto Condensed;
}

.slideshowwidget__slideinfo__description > *:first-child {
  margin-top: 0;
}

.slideshowwidget__titleholder {
  display: -webkit-flex;
  display: flex;
  margin-right: 30px;
}

.slideshowwidget__titleholder .slideshowwidget__link:first-of-type {
  margin-left: auto;
}

.slideshowwidget__titleholder .slideshowwidget__link + .slideshowwidget__link {
  margin-left: 15px;
}

.slideshowwidget__link {
  font: 300 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
}
html[lang=el] .slideshowwidget__link {
  font-family: Roboto Condensed;
}

.slideshowwidget__panelright {
  background-color: #FEF;
}

.slideshowwidget .carrousel__viewport {
  width: 100%;
  height: 100%;
}

.slideshowwidget .carrousel__cell {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
}

.slideshowwidget .carrousel__cell.carrousel__cell--aligned {
  z-index: 1;
}

.slideshowwidget__slideinfo {
  opacity: 0;
  transition: opacity 1.5s;
  transition-delay: 0.5;
  pointer-events: none;
}

.slideshowwidget__slideinfo.active {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0;
}

.slideshowwidget__slideinfo .slideshowwidget__x {
  padding-right: 30px;
}

.slideshowwidget__slideinfo__link {
  font: 300 18px / 100% "Univers Next W02", Arial, Helvetica, sans-serif;
  display: inline-block;
}
html[lang=el] .slideshowwidget__slideinfo__link {
  font-family: Roboto Condensed;
}

.slideshowwidget__linksbar {
  display: -webkit-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.slideshowwidget__linksbar .slideshowwidget__linksbar__filler {
  flex: 1 1 30px;
  min-width: 20px;
  max-width: 40px;
}

.slideshowwidget__linksbar .slideshowwidget__link {
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*
// The infoslides have a gradient fading to white, so it already
// feels like whitespace. So we don't need to add much margin.

FIXME: do we need this for dossier/regio layout?
       it adds unneeded extra whitespace on mobile.
.slideshowwidget__infoslides + .slideshowwidget__panelleft__bottombar
{
  margin-top: 10px;
}
*/
.slideshowwidget__panelleft__bottombar {
  display: -webkit-flexbox;
  display: flex;
  align-items: center;
  flex: none;
}

/*.widget__infosliderdots*/
/* FIXME: we should have a sliderdots component instead of 3 places with mixins
   (.widget__infosliderdots, .widget_photosliderdots and .slideshowwidget__slidedots);
*/
.slideshowwidget__slidedots {
  display: -webkit-flex;
  display: flex;
  color: var(--color-white);
}

/*.widget__infosliderdot*/
.slideshowwidget__slidedot {
  cursor: pointer;
  display: block;
  flex: none;
  position: relative;
  text-align: center;
  width: 20px;
  color: #dcddde;
}
.slideshow--paused .slideshowwidget__slidedot.slideshow--activedot {
  color: #1e2328;
}

/*.widget__infosliderdotactive*/
.slideshowwidget__slidedotactive {
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 1s ease-out;
  width: 20px;
  color: #1e2328;
}
.slideshow--activedot .slideshowwidget__slidedotactive {
  opacity: 1;
}
.slideshow--paused .slideshowwidget__slidedotactive {
  animation: blink-animation 2s linear infinite;
}

.slideshowwidget__slidedot.active {
  opacity: 1;
}

.slideshowwidget__previousbutton {
  margin-left: auto;
}

/*
  .slideshowwidget__previousbutton
, .slideshowwidget__nextbutton
{
    // trick iOS into hw acceleration to prevent compositing slowdowns with scrolling content
    //-webkit-transform: translate3D(0,0,0);
    //-webkit-backface-visibility: hidden;
}
*/
@media (min-width: 751px) {
  .slideshowwidget {
    height: 450px;
    contain: layout paint size style;
  }
  .slideshowwidget.slideshowwidget.slideshowwidget {
    --layout: 12col;
  }
  .slideshowwidget__panelleft {
    /*
    IE FIX option A)
        flex: 0 0 50%;
        max-width: 50%;
    */
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
    min-width: 0;
    padding-left: calc((100% - 960px) / 2 + 10px) !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .slideshowwidget__panelleft {
    padding-left: 10px;
  }
}
@media (min-width: 751px) and (max-width: 750px) {
  .slideshowwidget__panelleft {
    padding-left: 55px;
  }
}
@media (min-width: 751px) and (max-width: 480px) {
  .slideshowwidget__panelleft {
    padding-left: 15px;
  }
}
@media (min-width: 751px) and (min-width: 1440px) {
  .page--toplevelhome .slideshowwidget__panelleft {
    padding-left: calc((100% - 1130px) / 2 + 10px) !important;
  }
}
@media (min-width: 751px) and (min-width: 0) {
  .page .slideshowwidget__panelleft {
    padding-left: calc((100% - var(--page-contentwidth-12col)) / 2 + var(--pageside-padding)) !important;
  }
}
@media (min-width: 751px) {
  .slideshowwidget__infoslides {
    contain: layout paint size style;
    -webkit-flex: 1 1 0px;
    flex: 1 1 0px;
    position: relative;
  }
  .slideshowwidget__infoslides::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    pointer-events: none;
  }
  .slideshowwidget--default .slideshowwidget__infoslides::after {
    background: linear-gradient(to bottom, rgba(240, 241, 242, 0) 0%, rgb(240, 241, 242) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .slideshowwidget--regio .slideshowwidget__infoslides::after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .slideshowwidget--dossier .slideshowwidget__infoslides::after {
    display: none;
  }
  .slideshowwidget__slideinfo {
    max-height: 100%;
    overflow: hidden;
  }
  .slideshowwidget__panelright {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    min-width: 0;
  }
  .slideshowwidget__nextbutton {
    position: relative;
    margin-right: -60px;
    z-index: 2;
  }
}
@media (min-width: 751px) {
  .slideshowwidget__slideinfo {
    position: absolute;
    z-index: -1;
    width: 100%;
  }
  .slideshowwidget__slideinfo.active {
    visibility: visible;
    z-index: 0;
  }
}
@media (max-width: 750px) {
  .slideshowwidget {
    -webkit-flex-direction: column;
    flex-direction: column;
    height: auto;
  }
  .slideshowwidget__panelright {
    order: 1;
    height: 320px;
  }
  .slideshowwidget__panelleft {
    order: 2;
  }
  /* Flex will wrap the float's.
     Due to the float's being 0 wide they will overlap
     (as if position: absolute was used)
     but still grow their container because they're in their flow.
     (we allow the slideinfo child to grow the full width but
     setting the width)
  */
  .slideshowwidget__slideinfo {
    width: 0;
    float: left;
  }
  .slideshowwidget__infoslides {
    overflow-x: hidden;
  }
  .slideshowwidget__titleholder {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 750px) and (max-width: 750px) {
  .slideshowwidget__titleholder {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (max-width: 750px) and (max-width: 480px) {
  .slideshowwidget__titleholder {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 750px) and (min-width: 0) {
  .slideshowwidget__titleholder {
    padding-left: var(--pageside-padding);
    padding-right: var(--pageside-padding);
  }
}
@media (max-width: 750px) {
  .slideshowwidget__x {
    width: 100vw;
    padding-left: calc((100% - 960px) / 2 + 10px) !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 750px) and (max-width: 960px) {
  .slideshowwidget__x {
    padding-left: 10px;
  }
}
@media (max-width: 750px) and (max-width: 750px) {
  .slideshowwidget__x {
    padding-left: 55px;
  }
}
@media (max-width: 750px) and (max-width: 480px) {
  .slideshowwidget__x {
    padding-left: 15px;
  }
}
@media (max-width: 750px) and (min-width: 1440px) {
  .page--toplevelhome .slideshowwidget__x {
    padding-left: calc((100% - 1130px) / 2 + 10px) !important;
  }
}
@media (max-width: 750px) and (min-width: 0) {
  .page .slideshowwidget__x {
    padding-left: calc((100% - var(--page-contentwidth-12col)) / 2 + var(--pageside-padding)) !important;
  }
}
@media (max-width: 750px) {
  .slideshowwidget__slideinfo__description > * {
    max-width: calc(100vw - 110px);
  }
}
@media (max-width: 750px) and (max-width: 750px) {
  .slideshowwidget__x.slideshowwidget__x {
    padding-left: 55px !important;
  }
}
@media (max-width: 750px) {
  .slideshowwidget__panelleft__bottombar {
    padding-left: calc((100% - 960px) / 2 + 10px) !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 750px) and (max-width: 960px) {
  .slideshowwidget__panelleft__bottombar {
    padding-left: 10px;
  }
}
@media (max-width: 750px) and (max-width: 750px) {
  .slideshowwidget__panelleft__bottombar {
    padding-left: 55px;
  }
}
@media (max-width: 750px) and (max-width: 480px) {
  .slideshowwidget__panelleft__bottombar {
    padding-left: 15px;
  }
}
@media (max-width: 750px) and (min-width: 1440px) {
  .page--toplevelhome .slideshowwidget__panelleft__bottombar {
    padding-left: calc((100% - 1130px) / 2 + 10px) !important;
  }
}
@media (max-width: 750px) and (min-width: 0) {
  .page .slideshowwidget__panelleft__bottombar {
    padding-left: calc((100% - var(--page-contentwidth-12col)) / 2 + var(--pageside-padding)) !important;
  }
}
.slideshowwidget--default .slideshowwidget__panelleft {
  background-color: #f0f1f2;
}
@media (min-width: 751px) {
  .slideshowwidget--default .slideshowwidget__panelleft {
    padding-top: 50px;
  }
}
@media (max-width: 750px) {
  .slideshowwidget--default .slideshowwidget__panelleft {
    padding-top: 40px;
  }
  .slideshowwidget--default .slideshowwidget__x {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.slideshowwidget--default .slideshowwidget__title {
  font: 700 15px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.slideshowwidget--default .slideshowwidget__link {
  text-align: right;
}
.slideshowwidget--default .slideshowwidget__slideinfo__title {
  font: 700 26px/30px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.slideshowwidget--default .slideshowwidget__slideinfo__title b {
  font-weight: 300;
}
.slideshowwidget--default .slideshowwidget__slideinfo__description {
  margin-top: 25px;
}
.slideshowwidget--default .slideshowwidget__slideinfo__link {
  margin-top: 26px;
}

.slideshowwidget--regio {
  border-bottom: 1px solid #afb1b3;
}
@media (min-width: 751px) {
  .slideshowwidget--regio {
    border-top: 1px solid #afb1b3;
  }
}
.slideshowwidget--regio .slideshowwidget__panelleft {
  background-color: var(--color-white);
}
@media (min-width: 751px) {
  .slideshowwidget--regio .slideshowwidget__panelleft {
    padding-top: 80px;
  }
}
@media (max-width: 750px) {
  .slideshowwidget--regio .slideshowwidget__panelleft {
    padding-top: 40px;
  }
  .slideshowwidget--regio .slideshowwidget__x {
    padding-top: 0;
    padding-bottom: 40px;
  }
}
.slideshowwidget--regio .slideshowwidget__titleholder {
  margin-bottom: 30px;
}
.slideshowwidget--regio .slideshowwidget__title {
  font: 300 26px/26px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.slideshowwidget--regio .slideshowwidget__title b {
  font-weight: 700;
}
.slideshowwidget--regio .slideshowwidget__slideinfo__title {
  font: 300 26px/26px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.slideshowwidget--regio .slideshowwidget__slideinfo__title b {
  font-weight: 700;
}

.slideshowwidget--dossier {
  border-top: 1px solid #afb1b3;
  border-bottom: 1px solid #afb1b3;
}
.slideshowwidget--dossier .slideshowwidget__panelleft {
  background-color: var(--color-white);
}
@media (min-width: 751px) {
  .slideshowwidget--dossier .slideshowwidget__panelleft {
    padding-top: 80px;
  }
}
@media (max-width: 750px) {
  .slideshowwidget--dossier .slideshowwidget__panelleft {
    padding-top: 40px;
  }
  .slideshowwidget--dossier .slideshowwidget__x {
    padding-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
  }
}
.slideshowwidget--dossier .slideshowwidget__title {
  font: 700 26px/26px "Univers Next W02", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.slideshowwidget--dossier .slideshowwidget__titleholder + .slideshowwidget__infoslides {
  margin-top: 20px;
}
.slideshowwidget--dossier .slideshowwidget__slideinfo__title {
  font: 700 18px/25px "Univers Next W02", Arial, Helvetica, sans-serif;
}
.slideshowwidget--dossier .slideshowwidget__slideinfo__title b {
  font-weight: 300;
}
.slideshowwidget--dossier .slideshowwidget__slideinfo__description > * {
  font: 300 18px/25px "Univers Next W02", Arial, Helvetica, sans-serif;
}
.slideshowwidget--dossier .slideshowwidget__slideinfo__description b {
  font-weight: 700;
}
.slideshowwidget--dossier .slideshowwidget__x > .slideshowwidget__linksbar {
  margin-top: 27px;
}
@media (max-width: 480px) {
  .slideshowwidget--dossier .slideshowwidget__title {
    font: 700 18px/25px "Univers Next W02", Arial, Helvetica, sans-serif;
  }
  .slideshowwidget--dossier .slideshowwidget__slideinfo__top {
    flex-direction: column;
  }
  .slideshowwidget--dossier .slideshowwidget__slideinfo__tag {
    order: -1;
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
}
@media (min-width: 481px) {
  .slideshowwidget--dossier .slideshowwidget__slideinfo__title::before {
    /*
    \00a0 non breakable space
    \200b zero width space
    \A newline
    */
    float: left;
    content: "​\a​";
    white-space: pre;
    vertical-align: top;
  }
}

.carrousel__cell__poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.carrousel__cell__poster img {
  vertical-align: bottom;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.carrousel__cell__playbutton {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  line-height: 56px;
  text-align: center;
  transition: background-color 0.25s, opacity 0.25s;
}

.playbutton__icon::before {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e951";
  font-size: 16px;
}

.carrousel__cell__playbutton {
  opacity: 1;
}

.carrousel__cell.active .carrousel__cell__playbutton {
  opacity: 0;
  pointer-events: none;
}

/* ITC uses fixed colors for the buttons */
html.site--itc .slideshowwidget__previousbutton, html.site--itc .slideshowwidget__nextbutton {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 67px;
  transition: background-color 0.5s;
  cursor: pointer;
}
html.site--itc .slideshowwidget__previousbutton:hover, html.site--itc .slideshowwidget__nextbutton:hover {
  transition: background-color 0.25s;
}
html.site--itc .slideshowwidget__previousbutton, html.site--itc .slideshowwidget__nextbutton:hover {
  background-color: var(--color-white);
  color: #afb1b3;
}
html.site--itc .slideshowwidget__previousbutton:hover,
html.site--itc .slideshowwidget__nextbutton {
  background-color: #00675a;
  color: var(--color-white);
}
html.site--itc .themecolorlayer {
  display: none;
}
html.site--itc .carrousel__cell__playbutton {
  background-color: #00675a;
}
html.site--itc .carrousel__cell__playbutton:hover {
  background-color: var(--color-white);
}
html.site--itc .carrousel__cell__playbutton .playbutton__icon {
  color: var(--color-white);
}
html.site--itc .carrousel__cell__playbutton:hover .playbutton__icon {
  color: #00675a;
}

/* Use the animating themecolor for buttons */
html:not(.site--itc) .slideshowwidget__previousbutton, html:not(.site--itc) .slideshowwidget__nextbutton {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 67px;
  transition: background-color 0.5s;
  cursor: pointer;
}
html:not(.site--itc) .slideshowwidget__previousbutton:hover, html:not(.site--itc) .slideshowwidget__nextbutton:hover {
  transition: background-color 0.25s;
}
html:not(.site--itc) .slideshowwidget__previousbutton > *,
html:not(.site--itc) .slideshowwidget__nextbutton > * {
  position: relative;
}
html:not(.site--itc) .slideshowwidget__previousbutton .themecolorlayer,
html:not(.site--itc) .slideshowwidget__nextbutton .themecolorlayer {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.25s;
}
html:not(.site--itc) .slideshowwidget__previousbutton:not(:hover) .themecolorlayer {
  background-color: var(--color-white) !important;
}
html:not(.site--itc) .slideshowwidget__previousbutton {
  position: relative;
  color: #afb1b3;
}
html:not(.site--itc) .slideshowwidget__previousbutton:hover {
  color: var(--color-white);
}
html:not(.site--itc) .slideshowwidget__previousbutton:hover .themecolorlayer {
  opacity: 1;
}
html:not(.site--itc) .slideshowwidget__nextbutton {
  color: var(--color-white);
  transition: background-color 0.25s;
  background-color: rgba(255, 255, 255, 0);
}
html:not(.site--itc) .slideshowwidget__nextbutton .themecolorlayer {
  opacity: 1;
}
html:not(.site--itc) .slideshowwidget__nextbutton:hover {
  color: var(--color-black);
  background-color: rgb(255, 255, 255);
}
html:not(.site--itc) .slideshowwidget__nextbutton:hover .themecolorlayer {
  opacity: 0;
}
html:not(.site--itc) .carrousel__cell__playbutton:hover {
  animation: none !important;
  background-color: var(--color-white);
}
html:not(.site--itc) .carrousel__cell__playbutton:not(:hover) .playbutton__icon {
  animation: none !important;
  color: var(--color-white) !important;
}

.slideshowwidget .carrousel__cell .videocontainer iframe {
  opacity: 0;
  transition: opacity 0.5s;
}

.slideshowwidget .carrousel__cell.active .videocontainer iframe {
  opacity: 1;
}