.curiousu__paymentzone
{
  border-top:1px solid #CBCBCB;
  padding-top:20px;
}
.curiousu__paymentzone small
{
  font-size: inherit;
}

.curiousu__paymentzone form
{
  margin-bottom:30px;
}

.curiousu__paymentzone #btn_Back
{
  margin-right: 20px;
}
