@import "@mod-utwente_design/scss/config-colors.css";
/*
  basic styling is loaded by @mod-utwente_design/js/foldablecontent
*/
.wh-details__container__inner > .widgetsblock > .widgetsholder__titleholder > .widgetsholder__title {
  background-color: transparent;
}
.wh-details__container__inner > .widgetsblock .widget {
  background-color: var(--color-white);
}

.embeddedobject-faq .wh-details__summary {
  padding: 15px 50px 15px 0;
  font-size: 18px;
  line-height: 140%;
  font-weight: bold;
}
.embeddedobject-faq .wh-details__summary .hoverbarfx {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.embeddedobject-faq .wh-details__summary .hoverbarfx::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -20px;
  bottom: 0;
  width: 0;
  background-color: #f7f8f8;
  transition: all 300ms;
  z-index: -1;
}
.embeddedobject-faq .wh-details__summary .hoverbarfx:hover::before {
  width: 100%;
}
.embeddedobject-faq .wh-details__summary:focus .hoverbarfx::before {
  width: 100%;
  opacity: 0.3;
}
.embeddedobject-faq .wh-details__summary:hover .hoverbarfx::before {
  width: 100%;
  opacity: 1;
}
.embeddedobject-faq .wh-details .wh-details__container__inner {
  padding: 25px 30px;
  border-top: 1px solid #dcddde;
  background-color: #f7f8f8;
}

.widget-section--personalized,
.widget-section--loggedin { /* Inside Serviceportal personalized section (has Tahiti background color with opacity) */ }
.widget-section--personalized .hoverbarfx::before,
.widget-section--loggedin .hoverbarfx::before {
  display: none !important;
}
.widget-section--personalized .embeddedobject-faq .wh-details .wh-details__container__inner,
.widget-section--loggedin .embeddedobject-faq .wh-details .wh-details__container__inner {
  background-color: transparent;
}

.embeddedobject-faq .feedback {
  margin-top: 15px;
  position: relative;
}
.embeddedobject-faq .feedback__description {
  font-size: 14px;
  line-height: 150%;
  font-style: italic;
  padding-top: 20px;
  padding-bottom: 15px;
}
.embeddedobject-faq .feedback__toggle {
  opacity: 1;
  transition: opacity 300ms;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
}
.embeddedobject-faq .feedback__toggle input {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.embeddedobject-faq .feedback__toggle .question {
  padding-right: 20px;
}
.embeddedobject-faq .feedback__toggle .choices {
  display: flex;
  height: 47px;
}
.embeddedobject-faq .feedback__toggle .choices label {
  cursor: pointer;
  display: block;
  margin-left: 3px;
}
.embeddedobject-faq .feedback__toggle .choices div {
  width: 63px;
  display: block;
  text-align: center;
  line-height: 47px;
  color: var(--color-white);
  transition: color 0.2s, background-color 0.2s;
  border-radius: 32px;
  text-transform: uppercase;
  min-width: 85px;
  font-size: 18px;
}
.embeddedobject-faq .feedback__toggle input[value=yes]:checked + div::before {
  font-size: 20px;
  position: relative;
  top: 4px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e931";
}
.embeddedobject-faq .feedback__toggle input[value=yes]:checked + div > span {
  display: none;
}
.embeddedobject-faq .feedback--send .feedback__toggle {
  display: none;
}
.embeddedobject-faq .feedback--send .wh-form__page {
  margin-top: 20px;
  font-weight: bold;
}
.embeddedobject-faq .feedback--send .wh-form__buttongroup {
  display: none;
}
.embeddedobject-faq .feedback__positive {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 300ms;
}
.embeddedobject-faq .feedback--ispositive .feedback__toggle, .embeddedobject-faq .feedback--isnegative .feedback__toggle {
  opacity: 0;
  pointer-events: none;
}
.embeddedobject-faq .feedback--isnegative .feedback__toggle {
  height: 0;
}
.embeddedobject-faq .feedback--ispositive .feedback__positive {
  opacity: 1;
}
.embeddedobject-faq .feedback__formholder {
  display: none;
}
.embeddedobject-faq .feedback.feedback--isnegative .feedback__formholder {
  display: block;
}
.embeddedobject-faq .wh-form .wh-form__fieldgroup {
  display: flex;
}
.embeddedobject-faq .wh-form .wh-form__buttongroup {
  padding-top: 0;
}
.embeddedobject-faq .wh-form button {
  width: auto;
}
.embeddedobject-faq__list {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.embeddedobject-faq__list__item {
  border-bottom: 1px solid #dcddde;
}
.embeddedobject-faq__list__item:first-child {
  border-top: 1px solid #dcddde;
}
.embeddedobject-faq .wh-details__container__inner {
  padding-top: 10px;
}