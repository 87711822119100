.wh-details__summary {
  position: relative;
  cursor: pointer;
}
.wh-details__summary:focus {
  outline: 0 none;
}
.wh-details__summary::before, .wh-details__summary::after {
  font-family: "UT-iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 12px;
  width: 16px;
  color: var(--color-grey-normal);
}
.wh-details__summary::before {
  display: inline-block;
  content: "\e92f";
  transition: color 300ms;
}
.wh-details__summary:hover::before {
  color: var(--color-black);
}
.wh-details__summary::after {
  display: none;
  content: "\e930";
}
.wh-details__summary::-webkit-details-marker {
  display: none;
}
.wh-details__summary::marker {
  display: none;
}
.wh-details__container {
  height: 0;
  overflow: hidden;
}
.wh-details__container__inner > *:first-child, .wh-details__container__inner > .wh-anchor:first-child + * {
  margin-top: 0;
}
.wh-details__container__inner > *:last-child {
  margin-bottom: 0;
}
.wh-details[open] > .wh-details__summary::before, .wh-details--open > .wh-details__summary::before {
  display: none;
}
.wh-details[open] > .wh-details__summary::after, .wh-details--open > .wh-details__summary::after {
  color: var(--color-black);
  display: inline-block;
}
.wh-details[open] > .wh-details__container, .wh-details--open > .wh-details__container {
  height: auto;
}