.ut-itcintranet-yearselector
{
  margin-bottom:15px;
}
.ut-itcintranet-yearselector a
{
  margin-right:15px;
}

table.ut-itcintranet-table
{
  width:100%;
  border-collapse: collapse;
  border:none;
}
table.ut-itcintranet-table th
, table.ut-itcintranet-table td
{
  padding-left:10px;
  padding-right:10px;
  vertical-align: middle;
  text-align:left;
}

.ut-itcintranet__menu:after
{
  content:"";
  display:block;
  clear:both;
}
.ut-itcintranet__menublock
{
  width:300px;
  float:left;
}

h2.itc-subnav
{
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

h2.itc-subnav > a
{
  font-size: 0.5em;
}

h2.itc-subnav > a.left
{
  float: left;
}

h2.itc-subnav > a.right
{
  float: right;
}
