.activities__filterpanel__querywrapper {
  position: relative;
  margin: 20px 20px 15px 20px;
  flex: none;
}

.activities__filterpanel__querywrapper .icon {
  position: absolute;
  left: 25px;
  top: 50%;
  margin-top: -8px;
  color: #AAACAE;
  font-size: 16px;
}

.activities__filterpanel__query {
  -webkit-appearance: none;
  display: block;
  padding-left: 50px;
  width: 100%;
  height: 47px;
  background-color: #FFFFFF;
  color: #5A5E62;
  font: 13px/20px "Univers Next W02";
  border: 1px solid #DCDDDE;
}

.activities__filterpanel__query::before {
  color: #AAACAE;
  width: 16px;
  height: 16px;
  margin-bottom: 15px;
}

.activities__filterpanel__items.activities__filterpanel__items {
  flex: 1 1 auto;
  overflow-y: scroll;
  padding-top: 2px;
  padding-bottom: 20px;
}

.activities__filterpanel__heading {
  font: var(--tabstrip-filterpanel-options-font);
  font-weight: 600; /* bold non-consensed */
  margin: 0 20px 8px 20px;
}

.activities__filterpanel__noresults {
  display: none;
  padding: 0 20px;
  font: var(--tabstrip-filterpanel-options-font);
}

.activities__filterpanel--noresults .activities__filterpanel__heading {
  display: none;
}
.activities__filterpanel--noresults .activities__filterpanel__noresults {
  display: block;
}

.activities__filterpanel__item {
  display: flex;
  align-items: baseline;
  font: var(--tabstrip-filterpanel-options-font);
}

.activities__filterpanel__item.hidden {
  display: none;
}

.activities__filterpanel__item > label {
  padding: 2px 0;
}

.activities__filterpanel__item > label + label {
  padding-left: 10px;
}

.activities__filterpanel__item + .activities__filterpanel__item {
  margin-top: 11px;
}

.whflattabs__filterpanel__inner {
  position: relative;
  padding-right: 0;
}

.activities__filterpanel__items.activities__filterpanel__items {
  padding: 0 20px 10px;
}

.whflattabs__filterpanel__inner .ps {
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  -ms-overflow-style: none;
}
@supports (-ms-overflow-style: none) {
  .whflattabs__filterpanel__inner .ps .ps-container {
    overflow: auto !important;
  }
}
.whflattabs__filterpanel__inner .ps .ps-container.ps-in-scrolling {
  pointer-events: none;
}