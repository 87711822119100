/*

iOS style switch
also see: https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/;


Example usage:

<input id="mytoggle" type="checkbox" class="ut-toggle" /><label for="mytoggle">[! placeholder for custom styling !]</label><label for="mytoggle">Toggle active</label>

*/
.wh-styledinput input[type=checkbox].ut-toggle {
  display: none;
}

.wh-styledinput input[type=checkbox].ut-toggle + label {
  display: inline-block;
  position: relative;
  width: 46px;
  height: 23px;
  border-radius: 15px;
  background-color: #e0e4e8;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.wh-styledinput input[type=checkbox].ut-toggle + label::before {
  content: "";
  position: absolute;
  top: -1px;
  height: 25px;
  margin-top: 0;
  left: -2px;
  width: 25px;
  border-radius: 50%;
  border-width: 0;
  background-color: var(--color-white);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  -webkit-transition: left 0.2s; /* iOS 6 */
  transition: left 0.2s;
}

/* OFF */
.wh-styledinput input[type=checkbox].ut-toggle + label {
  background-color: var(--color-grey-lines);
}

.wh-styledinput input[type=checkbox].ut-toggle + label:hover {
  background-color: #a9abad;
}

/* ON */
.wh-styledinput input[type=checkbox].ut-toggle:checked + label {
  background-color: #3f9c35;
}

.wh-styledinput input[type=checkbox].ut-toggle:checked + label:hover {
  background-color: #287c31;
}

/* Disabled */
.wh-styledinput input[type=checkbox].ut-toggle[disabled] + label {
  background-color: var(--color-grey-lines);
}

.wh-styledinput input[type=checkbox].ut-toggle[disabled] + label::before {
  display: none;
}

.wh-styledinput input[type=checkbox].ut-toggle:checked + label::before {
  left: calc(100% - 25px + 2px);
}